<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.editJob') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <TextField v-model="local.row.key" :disabled="true" />
                        <TextField v-model="local.row.description" :disabled="true" />
                        <TextField v-model="local.row.errorMessage" :disabled="true" />
                        <TextField v-model="local.row.dataTable" :disabled="true" />
                        <TextField v-model="local.row.dataId" :disabled="true" />
                        <TextAreaField v-model="local.row.errorStack" :disabled="true" />
                        <SwitchField v-model="local.row.correctiveActionRequired" :disabled="true" />
                        <TextField v-model="local.row.correctiveActionUrl" :disabled="true" />
                        <SwitchField v-model="local.row.correctiveActionCompleted" :required="true" />

                        <DateTimeField v-model="local.row.started" :disabled="true" />
                        <DateTimeField v-model="local.row.ended" :disabled="true" />

                        <button
                            class="button is-primary is-fullwidth login-button space-2"
                            @click="passes(save)"
                        >
                            <b-icon icon="content-save-outline" ></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>

                        <button
                            class="button is-fullwidth login-button space-2"
                            @click="correct"
                            :disabled="!local.row.correctiveActionUrl"
                        >
                            <b-icon icon="magnifier" ></b-icon>
                            <span>{{ $t('button.correct') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { getResource, putResource } from '../../../client/resource';
import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import { Job } from '../../../../../common/framework/model/Job';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import TextAreaField from '../../../fields/TextAreaField.vue';
import DateTimeField from '../../../fields/DateTimeField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import EmailField from '../../../fields/EmailField.vue';
import UserGroups from '../users/UserGroups.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserGroups,
        TextAreaField,
        DateTimeField,
        ValidationObserver,
    },
})
export default class EditJob extends Vue {
    // Constants
    readonly resourceType = 'job';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Job,
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<Job>(this.resourceType, this.id))!!;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    async correct() {
        this.$router.push(this.local.row.correctiveActionUrl!!);
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
