<template>
    <section>
        <br />
        <div>
            <b-table
                :current-page="local.parameters.page"
                :data="local.rows"
                :loading="local.loading"
                :per-page="local.parameters.pageSize"
                :total="local.total"
                backend-pagination
                backend-sorting
                hoverable
                paginated
                sticky-header
                @page-change="onPageChange"
                @sort="onSortChange"
            >
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                        </div>
                    </section>
                </template>

                <template #top-left>
                    <div class="content">
                        <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                    </div>
                </template>

                <b-table-column
                    v-slot="props"
                    field="id"
                    :label="$t('button.edit').toString()"
                    v-if="isAdmin || isAbleToEdit(props.row.userId)"
                >
                    <b-button size="is-small" @click="edit(props.row.id)">{{ $t('button.edit') }}</b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="id" :label="$t('button.delete').toString()" v-if="isAdmin">
                    <b-button type="is-danger" class="button is-danger is-small" @click="confirmDelete(props.row)"
                        >{{ $t('button.delete') }}
                    </b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="name" :label="$t('field.name').toString()">
                    <b-button class="button is-ghost" @click="preview(props.row)">{{ props.row.name }}</b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="category" :label="$t('field.category').toString()">
                    {{ props.row.category }}
                </b-table-column>
                <b-table-column v-slot="props" field="modified" :label="$t('field.modified').toString()">
                    {{ dateToString(props.row.modified) }}
                </b-table-column>
                <b-table-column v-slot="props" field="description" :label="$t('field.description').toString()">
                    {{ props.row.description }}
                </b-table-column>
                <b-table-column v-slot="props" field="fileExtension" :label="$t('field.fileExtension').toString()">
                    {{ props.row.fileExtension }}
                </b-table-column>
                <b-table-column v-slot="props" field="size" :label="$t('field.size').toString()">
                    {{ formatBytes(props.row.size) }}
                </b-table-column>
                <b-table-column v-slot="props" field="id" :label="$t('field.download').toString()">
                    <b-button @click="download(props.row)">{{ $t('field.download') }}</b-button>
                </b-table-column>
            </b-table>
        </div>
    </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { FileMeta } from '../../../../../common/application/model/FileMeta';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import TextField from '../../../../framework/fields/TextField.vue';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';
import { ValidationObserver } from 'vee-validate';

import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { dateToUiDateString } from '../../../../../common/framework/util/convert';
import { deleteFileS3, getPresignedUrlObjectS3 } from '../../../client/file_meta_client';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { errorToast, successToast } from '../../../service/toast_service';

@Component({
    components: {
        TextField,
        ValidationObserver,
        Multiselect,
        TextAreaField,
    },
})
export default class ViewDocuments extends Vue {
    // Properties
    @Prop(String) readonly resourceId!: string;
    @Prop(String) readonly resourceType!: string;

    // State
    shared = sharedState;
    local = {
        total: 0,
        loading: false,
        rows: [] as FileMeta[],
        parameters: {} as QueryParameters,
        showPreview: true,
    };

    // Functions
    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        this.local.parameters.pageSize = 100;

        await this.loadAsyncData();

        this.local.loading = false;
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this);
        parameters.set('pageSize', 100);
        parameters.set('resourceId', this.resourceId);

        this.local.rows = [];
        const rows = await getResources<FileMeta>(ApplicationResource.FILE_META, -1, parameters);

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        if (rows.length) {
            parameters.set('idIn', rows.map(({ id }) => id).join(','));
            this.local.total = (await getResourcePaging(ApplicationResource.FILE_META, parameters)).rowCount;
        }
    }

    async resetPagingAndLoadData() {
        await this.loadAsyncData();
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    onPageChange(page: number) {
        setQueryParameter(this, 'page', page.toString());
        this.local.parameters = getQueryParameters(this);
        this.$emit('activeTab', 3);
    }

    download(attachment: FileMeta) {
        window.open(
            `/api/file/resource/${this.resourceType}/attachments/${attachment.id}_${attachment.name}/download`,
            '_blank',
        );
    }

    confirmDelete(attachment: FileMeta) {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                try {
                    const fileName = `${attachment.id}_${attachment.name}`;
                    await deleteResource(ApplicationResource.FILE_META, attachment.id);
                    await deleteFileS3(this.resourceType, fileName);
                    successToast(this, 'message.resourceDeleted');
                    await this.resetPagingAndLoadData();
                } catch (e) {
                    errorToast(this);
                }
            },
        });
    }

    dateToString(date: Date): string {
        return dateToUiDateString(date);
    }

    formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    edit(id?: string) {
        if (id) {
            this.$router.push(`/file-meta/${id}/edit`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    async preview(attachment: FileMeta) {
        const fileName = `${attachment.id}_${attachment.name}`;
        const previewUrl = await getPresignedUrlObjectS3(this.resourceType, fileName);
        window.open(previewUrl.url, '_blank');
    }

    isAbleToEdit(userId: string) {
        if (this.shared.hasRole(ApplicationUserRole.COACH) || this.shared.hasRole(FrameworkUserRole.MANAGER)) {
            return userId === this.shared.context.userId;
        } else if (this.isAdmin) {
            return true;
        }
        return false;
    }
}
</script>
