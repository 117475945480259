<template>
    <div>
        <TextField name="labelFi" :maxlength="40" v-model="local.value.labelFi" :required="true" />
        <TextField name="labelEn" :maxlength="40" v-model="local.value.labelEn" :required="true" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import TextField from './TextField.vue';

interface DefaultOptionJsonData {
    readonly labelFi: string | undefined;
    readonly labelEn: string | undefined;
}

@Component({
    components: { ValidationProvider, TextField },
})
export default class DefaultOptionJsonDataField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Object) readonly value!: object;

    shared = sharedState;
    local = {
        value: {} as DefaultOptionJsonData,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: object) {
        if (newValue) {
            this.local.value = newValue as any as DefaultOptionJsonData;
        }
    }

    @Watch('local.value.labelFi')
    @Watch('local.value.labelEn')
    async inputValueChange() {
        this.$emit('input', this.local.value);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
