<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addCustomer') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <div class="columns">
                            <div class="column is-6">
                                <SwitchField v-model="local.row.invoiceManually" :disabled="!isAdmin" />
                            </div>
                            <div class="column is-6">
                                <NumberField v-model="local.row.minimumInvoiceableSessions" required />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <SelectField
                                    v-model="local.row.coachGroupId"
                                    :options="local.coachGroupOptions"
                                    :required="true"
                                    expanded
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <NumberField v-model="local.row.plannedSessionsPerWeek" />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.firstName"
                                    :required="true"
                                    :maxlength="128"
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.lastName"
                                    :required="true"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.customerNumber"
                                    :required="true"
                                    :maxlength="128"
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <SelectField
                                    v-model="local.row.customerState"
                                    :options="local.customerStateOptions"
                                    :required="true"
                                    expanded
                                    :disabled="!isAdmin"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <AutoCompleteField
                                    v-model="local.row.coachId"
                                    name="coach"
                                    resource="coach"
                                    field="firstName,lastName"
                                    :query="coachesSelectorParams"
                                    :disabled="!isAdmin"
                                ></AutoCompleteField>
                            </div>
                            <div class="column is-6">
                                <AutoCompleteField
                                    v-model="local.row.physiotherapistId"
                                    name="physiotherapist"
                                    resource="physiotherapist"
                                    field="firstName,lastName"
                                    :query="physiotherapistsSelectorParams"
                                    :disabled="!isAdmin"
                                ></AutoCompleteField>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.phoneNumber"
                                    :required="false"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <EmailField
                                    v-model="local.row.email"
                                    :required="false"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <AutoCompleteField
                                    v-model="local.row.invoicingCustomerId"
                                    name="invoicingCustomer"
                                    resource="customer"
                                    field="firstName"
                                    :query="customersSelectorParams"
                                ></AutoCompleteField>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.street"
                                    :required="false"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.city"
                                    :required="false"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.zip"
                                    :required="false"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.country"
                                    :required="false"
                                    :maxlength="30"
                                    :disabled="!isAdmin"
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <NumberField v-model="local.row.freeSessions" required :disabled="!isAdmin" />
                            </div>
                            <div class="column is-6">
                                <NumberField v-model="local.row.bankSessions" required :disabled="!isAdmin" />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-6">
                                <SelectField
                                    v-model="local.row.locationId"
                                    :options="local.locationOptions"
                                    :required="false"
                                    expanded
                                    :disabled="!isAdmin"
                                />
                            </div>
                            <div class="column is-6">
                                <SwitchField v-model="local.row.holdingFee" :disabled="!isAdmin" />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-12">
                                <TextField v-model="local.row.notes" :required="false" :disabled="!isAdmin" />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-12">
                                <h1 class="title">{{ $t('title.memberships') }}</h1>

                                <section class="columns">
                                    <div class="column is-12">
                                        <div class="columns">
                                            <div class="column is-6">
                                                <SelectField
                                                    v-model="local.membership.productType"
                                                    name="type"
                                                    :options="local.productTypeOptions"
                                                    :required="true"
                                                    expanded
                                                    :disabled="!isAdmin"
                                                />
                                            </div>
                                            <div class="column is-6">
                                                <SelectField
                                                    v-model="local.membership.priceType"
                                                    name="priceType"
                                                    :options="local.priceTypeOptions"
                                                    :required="true"
                                                    expanded
                                                    :disabled="!isAdmin"
                                                />
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column is-6">
                                                <DateField
                                                    v-model="local.membership.startDate"
                                                    name="startDate"
                                                    :required="true"
                                                    :disabled="!isAdmin"
                                                />
                                            </div>
                                            <div class="column is-6">
                                                <DateField v-model="local.membership.endDate" :disabled="!isAdmin" />
                                            </div>
                                        </div>
                                        <div class="columns">
                                            <div class="column is-12">
                                                <TextAreaField v-model="local.membership.notes" :disabled="!isAdmin" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <button
                            class="button is-primary is-fullwidth login-button space-2"
                            :disabled="!passes || local.loading"
                            @click="passes(add)"
                        >
                            <b-icon icon="plus-circle-outline"></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                        <button
                            class="button is-primary is-fullwidth login-button space-1"
                            :disabled="!passes"
                            @click="passes(addAndClose)"
                        >
                            <b-icon icon="plus-circle-outline"></b-icon>
                            <span>{{ $t('button.addAndClose') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import EmailField from '../../../../framework/fields/EmailField.vue';

import { sharedState } from '../../../../framework/state';
import { postResource, putResource } from '../../../../framework/client/resource';
import {
    getDatabaseOptions,
    getEnumerationOptions,
    getGroupOptions,
    getResourceOptions,
    getSimpleEnumerationOptions,
} from '../../../../framework/service/options';

import { Customer } from '../../../../../common/application/model/customer';
import { CustomerState } from '../../../../../common/application/model/enums/CustomerState';
import { PaymentTerms } from '../../../../../common/application/model/enums/PaymentTerms';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import {
    getCoachesSelectorParams,
    getCustomersSelectorParams,
    getPhysiotherapistsSelectorParams,
} from '../../../service/helper_service';
import { InvoiceChannel } from '../../../../../common/application/model/enums/procountor/invoice_channel';
import { Option } from '../../../service/application_options_service';
import NumberField from '../../../../framework/fields/NumberField.vue';
import { InvoicingCountry } from '../../../../../common/application/model/enums/procountor/invoicing_country';
import { InvoiceLanguage } from '../../../../../common/application/model/enums/procountor/invoice_language';
import DateField from '../../../../../browser/framework/fields/DateField.vue';
import TextAreaField from '../../../../../browser/framework/fields/TextAreaField.vue';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import { Membership } from '../../../../../common/application/model/membership';
import moment from 'moment/moment';

interface NewMembership extends Pick<Membership, 'startDate' | 'endDate' | 'notes' | 'priceType'> {
    customerId: string | null;
    productType: ProductType | null;
}

interface LocalState {
    loading: boolean;
    row: Customer;
    membership: NewMembership;
    customerStateOptions: Option[];
    invoiceChannelOptions: Option[];
    paymentTermsOptions: Option[];
    coachGroupOptions: Option[];
    locationOptions: Option[];
    languageOptions: Option[];
    productTypeOptions: Option[];
    priceTypeOptions: Option[];
}

@Component({
    components: {
        TextAreaField,
        DateField,
        EmailField,
        SwitchField,
        SelectField,
        TextField,
        ValidationObserver,
        AutoCompleteField,
        NumberField,
    },
})
export default class AddCustomer extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.CUSTOMER;

    // State
    shared = sharedState;

    local: LocalState = {
        loading: false,
        row: {
            country: InvoicingCountry.FINLAND,
            customerState: CustomerState.ACTIVE,
            bankSessions: 0,
            freeSessions: 0,
            invoiceManually: false,
            minimumInvoiceableSessions: 4,
        } as Customer,
        membership: {
            startDate: moment().add(1, 'month').startOf('month').toDate(),
            endDate: null,
            notes: null,
            priceType: 'new-individual',
            productType: null,
            customerId: null,
        },
        customerStateOptions: [],
        invoiceChannelOptions: [],
        paymentTermsOptions: [],
        coachGroupOptions: [],
        locationOptions: [],
        languageOptions: [],
        productTypeOptions: [],
        priceTypeOptions: [],
    };

    // Functions
    async mounted() {
        this.local.locationOptions = await getResourceOptions(ApplicationResource.LOCATION, 'name');
        this.local.customerStateOptions = getEnumerationOptions('CustomerState', Object.keys(CustomerState), this);
        this.local.invoiceChannelOptions = getSimpleEnumerationOptions(
            'InvoiceChannel',
            Object.keys(InvoiceChannel),
            this,
        );
        this.local.paymentTermsOptions = getEnumerationOptions('PaymentTerms', Object.keys(PaymentTerms), this);
        this.local.languageOptions = getSimpleEnumerationOptions('InvoiceLanguage', Object.keys(InvoiceLanguage), this);
        this.local.coachGroupOptions = await getGroupOptions();

        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this).filter(
            (option) =>
                option.id === ProductType.MEMBERSHIP ||
                option.id === ProductType.MASSAGE ||
                option.id === ProductType.HYBRID_MEMBERSHIP ||
                option.id === ProductType.MANAGEMENT_COACHING ||
                option.id === ProductType.ONLINE_COACHING ||
                option.id === ProductType.SUPERCELL_MEMBERSHIP,
        );

        this.local.priceTypeOptions = await getDatabaseOptions('MembershipPriceType', this);
    }

    add() {
        this.addResource(false);
    }

    addAndClose() {
        this.addResource(true);
    }

    async addResource(goBack: boolean) {
        try {
            this.local.loading = true;
            const newCustomer = await postResource<Customer>(this.resourceType, this.local.row);
            successToast(this, 'message.customerSaved');

            if (newCustomer) {
                await putResource<Customer>(this.resourceType, newCustomer.id, {
                    ...newCustomer,
                    invoicingCustomerId: newCustomer.id,
                });

                this.local.membership.customerId = newCustomer.id;
                await postResource<NewMembership>(ApplicationResource.MEMBERSHIP, this.local.membership);
            }

            this.local.loading = false;

            if (goBack) {
                this.back();
            } else {
                await this.$router.replace(`/${this.resourceType}/${newCustomer.id}`);
            }
        } catch (error) {
            this.local.loading = false;
            errorToast(this);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    back() {
        this.$router.go(-1);
    }

    get coachesSelectorParams() {
        return getCoachesSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get physiotherapistsSelectorParams() {
        return getPhysiotherapistsSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get customersSelectorParams() {
        return getCustomersSelectorParams(this.shared.context.scopes, this.local.row);
    }
}
</script>
<style scoped>
.card-header,
.collapse {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.11);
}
</style>
