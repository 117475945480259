<template>
    <div class="space-2">
        <h4 class="title is-4">{{ $t('title.roleMembers') }}</h4>

        <div class="buttons has-addons">
            <button class="button" @click="confirmDelete" :disabled="!local.selected">
                <b-icon icon="delete-outline" size="is-small"></b-icon>
                <span>{{ $t('button.delete') }}</span>
            </button>
        </div>

        <b-table
            :selected.sync="local.selected"
            :data="local.rows"
            :loading="local.loading"
            paginated
            backend-pagination
            :total="local.total"
            :per-page="local.perPage"
            :current-page="local.page"
            @page-change="onPageChange"
        >
            <b-table-column v-slot="props" field="name" :label="$t('field.email')">
                {{ props.row.email }}
            </b-table-column>
        </b-table>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { deleteResource, getResources } from '../../../client/resource';
import { getRoleUsers, getRoleUsersPaging } from '../../../client/users';
import { UserIdentity } from '../../../../../common/framework/model/UserIdentity';
import { UserRole } from '../../../../../common/framework/model/UserRole';
import { errorToast, successToast } from '../../../../application/service/toast_service';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { PageSize } from '../../../../../common/application/model/enums/PageSize';

@Component
export default class RoleMembers extends Vue {
    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        rows: new Array<UserIdentity>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.S,
        selected: undefined as UserIdentity | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    const userRoles = await getResources<UserRole>(
                        'user-role',
                        -1,
                        new Map([
                            ['roleId', this.id],
                            ['userId', this.local.selected.id],
                        ]),
                    );
                    try {
                        await deleteResource(FrameworkResource.USER_ROLE, userRoles[0].id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getRoleUsersPaging(this.id)).pageCount * this.local.perPage;
        this.local.rows = [];
        const rows = (await getRoleUsers(this.id, this.local.page - 1)) as Array<UserIdentity>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
