import { apiDelete, apiGet } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

export async function getVendorPurchaseInvoices(
    vendorId: string,
    customerIdIn: string,
    fileType?: string,
): Promise<any[]> {
    let url = `/api/resource/vendor/${vendorId}/invoices?customerIdIn=${customerIdIn}`;

    if (fileType) {
        url += `&fileType=${fileType}`;
    }

    const response = await apiGet(url);
    if (response.status === 200) {
        return (await response.json()) as any;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getPresignedUrlObjectS3(resourceType: string, filename: string): Promise<any> {
    const url = `/api/file/resource/${resourceType}/attachments/${filename}/presignedUrl`;
    const response = await apiGet(url);
    if (response.status === 200) {
        return (await response.json()) as any;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteFileS3(resourceType: string, filename: string): Promise<any> {
    try {
        const url = `/api/file/resource/${resourceType}/attachments/${filename}/delete`;
        await apiDelete(url);
    } catch (err) {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
