<template>
    <div class="is-vertical-center full-height">
        <div class="container">
            <section class="section">
                <div class="columns">
                    <div class="column is-6 is-offset-3 box">
                        <h1 class="title">{{ $t('title.loginError') }}</h1>
                        <form @submit.prevent="login">
                            <span>{{ $t('message.loginError') }}</span>
                            <button class="button is-primary is-fullwidth login-button" @click="login">
                                <b-icon icon="login"></b-icon>
                                <span>{{ $t('button.retryLogin') }}</span>
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../state';

const localState = {
    email: '',
    password: '',
};

@Component
export default class LoginErrorView extends Vue {
    data() {
        return {
            shared: sharedState,
            local: localState,
        };
    }

    async login() {
        await this.$router.replace('/login');
    }
}
</script>

<style scoped>
.login-button {
    margin: 1rem 0 0;
}
</style>
