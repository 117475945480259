<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addRole') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <text-field v-model="local.name" :maxlength="40" :required="true" />

                        <button
                            class="button is-primary is-fullwidth login-button space-2"
                            @click="passes(add)"
                        >
                            <b-icon icon="plus-circle-outline" ></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { postResource } from '../../../client/resource';
import { Role } from '../../../../../common/framework/model/Role';
import SwitchField from '../../../fields/SwitchField.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';

@Component({
    components: { SwitchField, SelectField, PhoneField, TextField, EmailField, ValidationObserver },
})
export default class AddRole extends Vue {
    readonly resourceType = 'role';

    shared = sharedState;
    local = {
        id: '',
        name: '',
        created: new Date(),
        modified: new Date(),
    };

    clear() {
        this.local.name = '';
    }

    async add() {
        const object: Role = Object.assign(
            {
                id: '',
                created: new Date(),
                modified: new Date(),
            },
            this.local,
        ) as any as Role;
        await postResource(this.resourceType, object);

        this.$router.go(-1);
        this.clear();
    }

    back() {
        this.$router.go(-1);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
