<template>
    <div class="is-flex is-flex-direction-column is-justify-content-space-evenly">
        <div class="columns">
            <div class="column is-12">
                <h1 class="is-size-2 has-text-weight-bold has-text-secondary">{{ local.sessionsNextWeek }}</h1>
                <h4 class="subtitle is-4">{{ $t('title.sessionsNextWeek') }}</h4>
            </div>
        </div>
        <div class="columns">
            <div class="column is-12">
                <h1 class="is-size-2 has-text-weight-bold has-text-primary">{{ local.completedSessionsLastWeek }}</h1>
                <h4 class="subtitle is-4">{{ $t('title.completedSessionsLastWeek') }}</h4>
            </div>
        </div>
        <div class="columns">
            <div class="column is-12">
                <h1 class="is-size-2 has-text-weight-bold" :class="'has-text-success'">
                    {{ local.completedSessionsThisMonth }}
                </h1>
                <h4 class="subtitle is-4">{{ $t('title.completedSessionsThisMonth') }}</h4>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';

import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { Session } from '../../../../../common/application/model/session';
import { getResources } from '../../../../framework/client/resource';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

@Component({
    components: {
        AutoCompleteMultipleField,
    },
})
export default class SessionOverview extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    // State
    shared = sharedState;
    local = {
        loading: false,
        sessionsNextWeek: 0,
        completedSessionsLastWeek: 0,
        completedSessionsThisMonth: 0,
    };

    async mounted() {
        this.local.loading = true;

        this.local.sessionsNextWeek = await this.getSessionsNextWeek();
        this.local.completedSessionsLastWeek = await this.getCompletedSessionsLastWeek();
        this.local.completedSessionsThisMonth = await this.getCompletedSessionsThisMonth();

        this.local.loading = false;
    }

    formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 0:00:00.000000`;
    }

    formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 23:59:59.000000`;
    }

    get isAdmin(): boolean {
        return this.shared.admin;
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some(
            (role) => role === (ApplicationUserRole.COACH || ApplicationUserRole.PHYSIOTHERAPIST),
        );
    }

    private async getSessionsNextWeek(): Promise<number> {
        const parameters = new Map<string, string>();

        let queryParts: Array<string> = [];

        const currentDate = new Date();
        const nextWeekStartDate = new Date(currentDate.getTime() + (8 - currentDate.getDay()) * 24 * 60 * 60 * 1000);
        const nextWeekEndDate = new Date(nextWeekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000);
        const nextWeekStartDateString = this.formatStartDate(nextWeekStartDate);
        const nextWeekEndDateString = this.formatEndDate(nextWeekEndDate);

        // Add the conditions for the date range (Monday to Sunday)
        queryParts.push('date>={startDate}');
        parameters.set('startDate', nextWeekStartDateString);

        queryParts.push('date<={endDate}');
        parameters.set('endDate', nextWeekEndDateString);

        if (queryParts.length > 0) {
            parameters.set('query', queryParts.join(' AND '));
        }

        const rows = (await getResources(this.resourceType, -1, parameters)) as Array<Session>;

        return rows.length;
    }

    private async getCompletedSessionsLastWeek(): Promise<number> {
        const parameters = new Map<string, string>();
        const queryParts: string[] = [];

        const currentDate = new Date();
        const lastWeekEndDate = new Date(currentDate.getTime() - currentDate.getDay() * 24 * 60 * 60 * 1000);
        const lastWeekStartDate = new Date(lastWeekEndDate.getTime() - 6 * 24 * 60 * 60 * 1000);
        const lastWeekStartDateString = this.formatStartDate(lastWeekStartDate);
        const lastWeekEndDateString = this.formatEndDate(lastWeekEndDate);

        // Add the conditions for the date range (Monday to Sunday)
        queryParts.push('date>={startDate}');
        parameters.set('startDate', lastWeekStartDateString);

        queryParts.push('date<={endDate}');
        parameters.set('endDate', lastWeekEndDateString);

        queryParts.push('completed={completed}');
        parameters.set('completed', 'true');

        if (queryParts.length > 0) {
            parameters.set('query', queryParts.join(' AND '));
        }

        const rows = (await getResources(this.resourceType, -1, parameters)) as Array<Session>;

        return rows.length;
    }

    private async getCompletedSessionsThisMonth(): Promise<number> {
        const parameters = new Map<string, string>();
        const queryParts: string[] = [];

        const currentDate = new Date();
        const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const currentMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const currentMonthStartDateString = this.formatStartDate(currentMonthStartDate);
        const currentMonthEndDateString = this.formatEndDate(currentMonthEndDate);

        // Add the conditions for the date range (Monday to Sunday)
        queryParts.push('date>={startDate}');
        parameters.set('startDate', currentMonthStartDateString);

        queryParts.push('date<={endDate}');
        parameters.set('endDate', currentMonthEndDateString);

        queryParts.push('completed={completed}');
        parameters.set('completed', 'true');

        if (queryParts.length > 0) {
            parameters.set('query', queryParts.join(' AND '));
        }

        const rows = (await getResources(this.resourceType, -1, parameters)) as Array<Session>;

        return rows.length;
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
