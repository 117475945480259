<template>
    <div class="container-fluid panel mx-10-fullhd">
        <section class="section">
            <h1 class="title">{{ $t('title.coachTasks') }}</h1>
            <div class="buttons has-addons">
                <button class="button" @click="add" v-if="isAdmin">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>
            <div class="row" style="margin-bottom: 10px">
                <button class="button is-ghost is-hidden-desktop" @click="onShowFilter">
                    >> {{ local.showFilter ? $t('button.hideFilters') : $t('button.showFilters') }}
                </button>
                <div class="columns" v-if="local.showFilter">
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="customer"
                            v-model="customerIds"
                            id-field="customerIdIn"
                            search-field="firstName"
                            name="customer"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="coach"
                            v-model="coachIds"
                            id-field="coachIdIn"
                            search-field="firstName"
                            name="coach"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-2">
                        <SwitchField v-model="local.parameters.completed" />
                    </div>
                </div>
            </div>
            <div class="table-container">
                <b-table
                    narrowed
                    :backend-sorting="true"
                    :backend-pagination="true"
                    :hoverable="true"
                    :striped="true"
                    :paginated="true"
                    :scrollable="true"
                    :sticky-header="true"
                    :current-page="local.parameters.page"
                    :data="local.rows"
                    :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                    :loading="local.loading"
                    :pagination-position="'top'"
                    :per-page="local.parameters.pageSize"
                    :selected.sync="local.selected"
                    :total="local.total"
                    @page-change="onPageChange"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left>
                        <div class="content">
                            <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>
                    <b-table-column v-slot="props" field="id2">
                        <div v-if="!props.row.completed">
                            <b-button
                                size="is-small"
                                :type="isTaskOverDue(props.row) ? 'is-danger' : 'is-info'"
                                @click="markCoachTaskCompleted(props.row)"
                            >
                                {{ $t('button.clickToComplete') }}
                            </b-button>
                        </div>
                        <div v-if="props.row.completed">
                            <b-button size="is-small" type="is-success" @click="markCoachTaskUncompleted(props.row)">
                                {{ $t('button.completed') }}
                            </b-button>
                        </div>
                    </b-table-column>
                    <b-table-column v-slot="props" field="title" :label="String($t('field.title')).toString()">
                        {{ props.row.title }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="customer" :label="String($t('field.customer')).toString()">
                        {{ getCustomerLabel(props.row.customerId) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="coach" :label="String($t('field.coach')).toString()">
                        {{ getCoachLabel(props.row.coachId) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="dueDate"
                        :label="String($t('field.dueDate')).toString()"
                        :sortable="true"
                    >
                        {{ dateToDateTimeString(props.row.dueDate) || '-' }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="completedOn"
                        :label="String($t('field.completedOn')).toString()"
                        :sortable="true"
                    >
                        {{ dateToDateTimeString(props.row.completedOn) }}
                    </b-table-column>
                </b-table>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources, putResource } from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { getResourceLabels } from '../../../../framework/service/label_service';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { P_SORT_FIELD, P_SORT_ORDER } from '../../../../../common/framework/constants';
import { formatToFinnishDateTime, getCoachResourceLabel } from '../../../service/helper_service';
import { getCustomerOptions, Option } from '../../../service/application_options_service';
import { CoachTask } from '../../../../../common/application/model/CoachTask';
import moment from 'moment';
import { Coach } from '../../../../../common/application/model/coach';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
        ValidationObserver,
    },
})
export default class CoachTasks extends Vue {
    @Prop(String) readonly customerIdIn!: string;
    @Prop(String) readonly coachIdIn!: string;

    readonly resourceType = ApplicationResource.COACH_TASK;

    shared = sharedState;
    local = {
        rows: new Array<CoachTask>(),
        total: 0,
        loading: false,
        selected: undefined as CoachTask | undefined,
        parameters: {} as QueryParameters,
        coachLabels: new Map<string, string>(),
        customerOptions: [] as Option[],
        showFilter: window.innerWidth >= 1023,
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${this.resourceType}/add`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${this.resourceType}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this);
        parameters.set(P_SORT_FIELD, 'dueDate');
        parameters.set(P_SORT_ORDER, SortOrder.ASC);

        this.local.total = (await getResourcePaging(this.resourceType, parameters)).rowCount;

        this.local.rows = [];

        if (this.coachIdIn) {
            parameters.set('coachIdIn', this.coachIdIn);
        }

        if (this.customerIdIn) {
            parameters.set('customerIdIn', this.customerIdIn);
        }

        const rows: CoachTask[] = (await getResources(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
        )) as Array<CoachTask>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        await this.getCoachLabels(rows);

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }

        this.local.customerOptions = await getCustomerOptions();
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    onPageChange(page: number) {
        setQueryParameter(this, 'page', page.toString());
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.parameters.completed')
    async completedSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    dropdownClosed(resourceIds: string[], idField: string): void {
        setQueryParameter(this, `${idField}`, resourceIds.join(','), true);
        this.local.parameters = getQueryParameters(this);
    }

    get customerIds(): string[] {
        return this.local.parameters.customerIdIn ? this.local.parameters.customerIdIn.split(',') : [];
    }

    get coachIds(): string[] {
        return this.local.parameters.coachIdIn ? this.local.parameters.coachIdIn.split(',') : [];
    }

    onRowClick(row: CoachTask): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    getCoachLabel(id: string): string | undefined {
        return this.local.coachLabels.get(id);
    }

    getCustomerLabel(customerId: string): string | undefined {
        const customer = this.local.customerOptions.find(({ id }) => id === customerId);
        return customer ? customer.label : '-';
    }

    private async getCoachLabels(rows: CoachTask[]) {
        this.local.coachLabels = await getResourceLabels<Coach>(
            rows,
            'coachId',
            ApplicationResource.COACH,
            getCoachResourceLabel,
        );
    }

    dateToDateTimeString(timestamp: string) {
        const date = timestamp ? new Date(timestamp) : null;
        return formatToFinnishDateTime(date);
    }

    async markCoachTaskCompleted(task: CoachTask): Promise<void> {
        await putResource<CoachTask>(this.resourceType, task.id, {
            ...task,
            completed: true,
            completedOn: new Date(),
        });

        await this.loadAsyncData();
    }

    async markCoachTaskUncompleted(session: CoachTask): Promise<void> {
        await putResource<CoachTask>(this.resourceType, session.id, {
            ...session,
            completed: false,
            completedOn: undefined,
        });

        await this.loadAsyncData();
    }

    onShowFilter() {
        this.local.showFilter = !this.local.showFilter;
    }

    isTaskOverDue(row: CoachTask) {
        return moment(new Date()).isAfter(moment(row.dueDate), 'day');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.title-column {
    max-width: 20px;
}
</style>
