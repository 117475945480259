<template>
    <div class="container-fluid mx-10-fullhd">
        <h1 class="title">{{ $t('title.sessionsPerCustomer') }}</h1>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-4">
                    <div class="is-flex">
                        <b-button
                            @click="previousMonth()"
                            type="is-primary"
                            class="mr-2"
                            icon-right="arrow-left"
                            style="margin-top: 32px"
                        />
                        <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                        <b-button
                            @click="nextMonth()"
                            type="is-primary"
                            class="ml-2"
                            icon-right="arrow-right"
                            style="margin-top: 32px"
                        />
                    </div>
                </div>
                <div class="column is-6">
                    <SwitchField v-model="local.parameters.sessionCountEqual0" :disabled="!isAdmin" />
                </div>
            </div>
        </div>
        <b-table
            v-if="local.rows.length"
            :data="local.rows"
            :hoverable="true"
            :striped="true"
            :scrollable="true"
            :backend-sorting="true"
            :backend-pagination="true"
            :sticky-header="true"
            :loading="local.loading"
            :pagination-position="'top'"
            :per-page="local.parameters.pageSize"
            :paginated="true"
            :current-page="local.parameters.page"
            :total="this.local.total"
            @page-change="onPageChange"
        >
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                    </div>
                </section>
            </template>
            <b-table-column v-slot="props" :label="$t('field.customer').toString()" width="40">
                {{ getCustomerLabel(props.row.columnValue) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="active"
                :label="$t('field.totalSessions').toString()"
                numeric
                width="40"
            >
                {{ props.row.sessionCount }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="active"
                :label="$t('field.completedSessions').toString()"
                numeric
                width="40"
            >
                {{ props.row.completedSessionCount }}
            </b-table-column>
        </b-table>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import MonthField from '../../../../framework/fields/MonthField.vue';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { utc } from 'moment';
import { P_QUERY } from '../../../../../common/framework/constants';
import { getNumberSessionsByRequest } from '../../../client/session_client';
import { NumberSessionsAndCompletedSessions } from '../../../../../common/application/model/number_sessions_by';
import OfficeAndNonOfficeSessionsTable from './OfficeAndNonOfficeSessionsTable.vue';
import { getCustomerOptions, Option } from '../../../service/application_options_service';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { PageSize } from '../../../../../common/application/model/enums/PageSize';

@Component({
    components: {
        AutoCompleteMultipleField,
        MonthField,
        OfficeAndNonOfficeSessionsTable,
        SwitchField,
    },
})
export default class SessionsPerCustomerTable extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    // State
    shared = sharedState;
    local = {
        loading: false,
        monthFilter: utc().startOf('month').toDate().toDateString(),
        rows: [] as NumberSessionsAndCompletedSessions[],
        total: 0,
        customerOptions: [] as Option[],
        parameters: {
            page: 1,
            pageSize: PageSize.S,
            sessionCountEqual0: false,
        } as QueryParameters,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.rows = []
        await this.getSessionsPerCustomer();
        this.local.customerOptions = await getCustomerOptions();
        this.local.loading = false;
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        this.local.rows = [];
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 0:00:00.000000`;
    }

    formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 23:59:59.000000`;
    }

    async getSessionsPerCustomer(): Promise<void> {
        const monthStartDate = new Date(this.local.monthFilter);
        const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 0);
        const monthStartDateString = this.formatStartDate(monthStartDate);
        const monthEndDateString = this.formatEndDate(monthEndDate);

        const totalParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
        ]);

        if (this.local.parameters.sessionCountEqual0) {
            totalParams.set('sessionCount', '0');
        }

        const customerTotalNumbersResult = await getNumberSessionsByRequest(
            this.local.parameters.page,
            totalParams,
            this.local.parameters.pageSize,
            'customer_id',
        );

        const customerTotalNumbers = customerTotalNumbersResult.numberSessions;
        this.local.total = customerTotalNumbersResult.rowCount;

        const completedParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate} AND completed={completed}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
            ['completed', 'true'],
        ]);

        if (this.local.parameters.sessionCountEqual0) {
            completedParams.set('sessionCount', '0');
        }

        const customerCompletedNumbers = (
            await getNumberSessionsByRequest(
                this.local.parameters.page,
                completedParams,
                this.local.parameters.pageSize,
                'customer_id',
            )
        ).numberSessions;

        const customerNumbers = customerTotalNumbers.map((item: any, index: number) => {
            const completedNumber = customerCompletedNumbers.find(
                (completedNumber: any) => completedNumber.columnValue === item.columnValue,
            );

            let completedSessionCount = 0;

            if (completedNumber) {
                completedSessionCount = completedNumber.sessionCount;
            }

            return {
                ...item,
                completedSessionCount,
            };
        });
        this.local.rows.push(...customerNumbers);
    }

    get isAdmin(): boolean {
        return this.shared.admin;
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }

    nextMonth() {
        this.local.monthFilter = utc(this.local.monthFilter).add(1, 'month').startOf('month').toDate().toDateString();
    }

    previousMonth() {
        this.local.monthFilter = utc(this.local.monthFilter)
            .subtract(1, 'month')
            .startOf('month')
            .toDate()
            .toDateString();
    }

    getCustomerLabel(customerId: string): string | undefined {
        const customer = this.local.customerOptions.find(({ id }) => id === customerId);
        return customer ? customer.label : '-';
    }

    @Watch('local.parameters.sessionCountEqual0')
    async sessionCountEqual0SwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
