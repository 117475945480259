import { render, staticRenderFns } from "./CoachTasks.vue?vue&type=template&id=f0b43512&scoped=true"
import script from "./CoachTasks.vue?vue&type=script&lang=ts"
export * from "./CoachTasks.vue?vue&type=script&lang=ts"
import style0 from "./CoachTasks.vue?vue&type=style&index=0&id=f0b43512&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0b43512",
  null
  
)

export default component.exports