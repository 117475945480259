export enum FrameworkResource {
    USER = 'user',
    GROUP = 'group',
    ROLE = 'role',
    USER_GROUP = 'user-group',
    USER_ROLE = 'user-role',
    AUTHENTICATION = 'authentication',
    ASSET = 'asset',
    ASSET_LINK = 'asset-link',
    JOB = 'job',
    SPACE = 'space',
    ENTITY = 'entity',
    BAKE_INFO = 'bake-info',
    NODE_INSTANCE = 'node-instance',
    NEXUS = 'nexus',
    CHANNEL = 'channel',
    CHANNEL_USER = 'channel-user',
    AUDIT_EVENT = 'audit-event',
    OPTION = 'option',
    TASK = 'task',
    TASK_COMMENT = 'task-comment',
    USER_ENTITY = 'user-entity',
    SCHEDULED_TASK = 'scheduled-task',
}
