<template>
    <div class="container-fluid panel">
        <section class="section">
            <div
                class="column is-4-widescreen is-6-desktop is-10 is-offset-4-widescreen is-offset-3-desktop is-offset-1"
            >
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addNextMonthlySalesInvoice') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <div class="columns">
                            <div class="column is-9">
                                <SelectField
                                    name="customer"
                                    v-model="local.row.customerId"
                                    :options="local.customerOptions"
                                    required
                                    expanded
                                />
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-9">
                                <DateField v-model="local.row.entryDate" :required="true" />
                            </div>
                            <div class="column is-3"></div>
                        </div>
                        <div class="columns">
                            <div class="column is-9">
                                <SwitchField
                                    v-model="local.row.onlyRetroactiveBilling"
                                    :disabled="local.row.addRetroactiveBilling"
                                    :required="true"
                                />
                            </div>
                            <div class="column is-3"></div>
                        </div>
                        <div class="columns">
                            <div class="column is-9">
                                <SwitchField
                                    v-model="local.row.addRetroactiveBilling"
                                    :disabled="local.row.onlyRetroactiveBilling"
                                    :required="true"
                                />
                            </div>
                            <div class="column is-3"></div>
                        </div>

                        <button class="button is-primary is-fullwidth login-button space-2" @click="passes(add)">
                            <b-icon icon="plus-circle-outline"></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../../framework/state';
import { getCustomerOptions, Option } from '../../../../service/application_options_service';
import { postNextMonthlySalesInvoiceRequest } from '../../../../client/sales_invoice_client';
import { ValidationObserver } from 'vee-validate';
import DateField from '../../../../../framework/fields/DateField.vue';
import NumberField from '../../../../../framework/fields/NumberField.vue';
import TextField from '../../../../../framework/fields/TextField.vue';
import SelectField from '../../../../../framework/fields/SelectField.vue';
import { AddNextMonthlySalesInvoiceReq } from '../../../../../../common/application/model/api/AddNextMonthlySalesInvoiceReq';
import SwitchField from '../../../../../framework/fields/SwitchField.vue';
import { Customer } from '../../../../../../common/application/model/customer';
import { getResource } from '../../../../../framework/client/resource';
import { ApplicationResource } from '../../../../../../common/application/enumeration/ApplicationResource';
import { successToast, errorToast } from '../../../../service/toast_service';
import { OptionValue } from '../../../../../framework/service/options';

@Component({
    components: {
        DateField,
        NumberField,
        SwitchField,
        SelectField,
        TextField,
        ValidationObserver,
    },
})
export default class AddNextMonthlySalesInvoice extends Vue {
    // Properties

    // State
    shared = sharedState;
    local = {
        row: {
            addRetroactiveBilling: false,
            onlyRetroactiveBilling: false,
        } as AddNextMonthlySalesInvoiceReq,
        customerOptions: [] as Option[],
        selectedCustomer: undefined as Customer | undefined,
    };

    // Functions
    async mounted() {
        this.local.customerOptions = await getCustomerOptions();

        this.local.row = {
            ...this.local.row,
            addRetroactiveBilling: false,
        };
    }

    @Watch('local.row.customerId')
    async selectedCustomerChanged(customerId: string) {
        if (!customerId) {
            return;
        }
        this.local.selectedCustomer = await getResource<Customer>(ApplicationResource.CUSTOMER, customerId);
    }

    async add() {
        try {
            const resp = await postNextMonthlySalesInvoiceRequest(this.local.row);
            successToast(this, 'message.salesInvoiceSaved');
            await this.$router.push(`/sales-invoice/${resp.addedSalesInvoiceId}`);
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
