<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.editOption') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <TextField name="type" :maxlength="40" v-model="local.row.type" :required="true" />
                        <TextField name="key" :maxlength="40" v-model="local.row.key" :required="true" />
                        <NumberField name="index" v-model="local.row.index" :required="true" />
                        <DefaultOptionJsonDataField name="metadata" v-model="local.row.metadata" />

                        <button
                            class="button is-primary is-fullwidth login-button option-2"
                            @click="passes(save)"
                        >
                            <b-icon icon="content-save-outline" ></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { getResource, putResource } from '../../../client/resource';
import TextField from '../../../fields/TextField.vue';
import SelectField from '../../../fields/SelectField.vue';
import AutoCompleteField from '../../../fields/AutoCompleteField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import TextAreaField from '../../../fields/TextAreaField.vue';
import NumberField from '../../../fields/NumberField.vue';
import { Option } from '../../../../../common/framework/model/Option';
import DefaultOptionJsonDataField from '../../../fields/DefaultOptionJsonDataField.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        TextField,
        AutoCompleteField,
        NumberField,
        TextAreaField,
        DefaultOptionJsonDataField,
        ValidationObserver,
    },
})
export default class EditOption extends Vue {
    // Constants
    readonly resourceType = 'option';

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Option,
        typeOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<Option>(this.resourceType, this.id))!!;
    }

    async save() {
        await putResource(this.resourceType, this.id, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
