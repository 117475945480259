<template>
    <section class="section">
        <h2 class="title is-2">{{ $t('title.reports') }}</h2>
        <hr class="is-primary" />
        <b-tabs type="is-boxed">
            <b-tab-item
                :label="String($t('title.overview')).toString()"
                icon="chart-box-outline"
                class="custom-tab-item"
            >
                <h1 class="title">{{ $t('title.overview') }}</h1>

                <div class="row p-2 pb-6">
                    <div class="columns has-text-centered" style="flex-wrap: wrap">
                        <div class="column is-6 has-text-centered my-4">
                            <SessionOverview />
                        </div>
                    </div>
                </div>
                <div class="row p-2 pb-6">
                    <SessionsPerProductTypeTable />
                </div>
                <div class="row p-2 pb-6">
                    <BankAndFreeSessionsReportTable />
                </div>
            </b-tab-item>
            <b-tab-item
                :label="String($t('title.coachPhysioPerformance')).toString()"
                icon="account"
                class="custom-tab-item"
            >
                <div class="row p-2 pb-6">
                    <SessionsPerCoachTable />
                </div>
                <div class="row p-2 pb-6">
                    <SessionsPerPhysiotherapistTable />
                </div>
            </b-tab-item>
            <b-tab-item :label="String($t('title.customers')).toString()" icon="account" class="custom-tab-item">
                <SessionsPerCustomerTable />
            </b-tab-item>
        </b-tabs>
    </section>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';

import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import SessionOverview from './SessionOverview.vue';
import SessionsPerProductTypeTable from './SessionsPerProductTypeTable.vue';
import SessionsPerCoachTable from './SessionsPerCoachTable.vue';
import BankAndFreeSessionsReportTable from './BankAndFreeSessionsReportTable.vue';
import SessionsPerPhysiotherapistTable from './SessionsPerPhysioTable.vue';
import SessionsPerCustomerTable from './SessionsPerCustomerTable.vue';

@Component({
    components: {
        AutoCompleteMultipleField,
        SessionOverview,
        SessionsPerProductTypeTable,
        SessionsPerCoachTable,
        BankAndFreeSessionsReportTable,
        SessionsPerPhysiotherapistTable,
        SessionsPerCustomerTable,
    },
})
export default class Reports extends Vue {
    // State
    shared = sharedState;
    local = {};

    get isAdmin(): boolean {
        return this.shared.admin;
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some(
            (role) => role === (ApplicationUserRole.COACH || ApplicationUserRole.PHYSIOTHERAPIST),
        );
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
