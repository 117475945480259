<template>
    <validation-provider
        :name="getFieldName().toString()"
        :rules="{ required: required, regex: local.regex }"
        v-slot="{ errors, valid }"
        tag="div"
    >
        <b-field
            :label="$t('field.' + getFieldName()).toString()"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :horizontal="horizontal"
            :message="errors"
        >
            <b-input
                v-if="!readonly"
                :name="getFieldName()"
                v-model="local.value"
                :disabled="disabled === true"
                type="textarea"
                :maxlength="maxlength"
                :rows="6"
            >
            </b-input>
            <div v-if="readonly">
                <div class="divider closer-0-5-top"></div>
                <span>{{ local.value }}</span>
            </div>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class TextAreaField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Number) readonly maxlength!: number;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        regex: new RegExp(
            /^[A-zÀ-úA-zÀ-ÿA-Za-zÀ-ÿA-Za-zÀ-ÖØ-öø-ÿŁłěßšŠ0-9 \t^,.;:!?&%'`\’"@|+*=\/\\\-_~#()${}<>\[\]\n]+$/,
        ),
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
