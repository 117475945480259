<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="box">
                    <h1 class="title">{{ $t('title.passwordChange') }}</h1>

                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <password-field v-model="local.oldPassword" :required="true" />

                        <password-field v-model="local.password" :required="true" />
                        <password-field name="newPasswordAgain" v-model="local.password2" :required="true" />

                        <button class="button is-primary is-fullwidth save-button" @click="passes(submit)">
                            <b-icon icon="content-save-outline"></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>
                    </ValidationObserver>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../state';
import { postPasswordChange } from '../../client/password';
import PasswordField from '../../fields/PasswordField.vue';

@Component({
    components: { PasswordField, ValidationObserver },
})
export default class PasswordChange extends Vue {
    shared = sharedState;
    local = {
        oldPassword: '',
        password: '',
        password2: '',
    };

    async submit() {
        if (this.local.password == this.local.password2) {
            await this.changePassword();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordsDoNotMatch').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async changePassword() {
        if (await postPasswordChange(this.local.password, this.local.oldPassword)) {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChanged').toString(),
                type: 'is-success',
                position: 'is-top',
            });
            this.local.oldPassword = '';
            this.local.password = '';
            this.local.password2 = '';
            (this.$refs['observer'] as any).reset();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChangeFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }
}
</script>

<style scoped>
.save-button {
    margin: 1rem 0 0;
}
</style>
