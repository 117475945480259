<template>
    <div class="container-fluid mx-10-fullhd">
        <h1 class="title">{{ $t('title.sessionsPerProductType') }}</h1>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-4">
                    <div class="is-flex">
                        <b-button
                            @click="previousMonth()"
                            type="is-primary"
                            class="mr-2"
                            icon-right="arrow-left"
                            style="margin-top: 32px"
                        />
                        <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                        <b-button
                            @click="nextMonth()"
                            type="is-primary"
                            class="ml-2"
                            icon-right="arrow-right"
                            style="margin-top: 32px"
                        />
                    </div>
                </div>
            </div>
        </div>
        <b-table
            v-if="local.rows.length"
            :data="local.rows"
            :hoverable="true"
            :striped="true"
            :scrollable="true"
            :current-page="1"
            :loading="local.loading"
            :per-page="local.rows.length"
        >
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                    </div>
                </section>
            </template>
            <b-table-column v-slot="props" field="vehicle_type" :label="$t('field.productType').toString()" width="40">
                {{ getProductTypeLabel(props.row.columnValue) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="active"
                :label="$t('field.totalSessions').toString()"
                numeric
                width="40"
            >
                {{ props.row.sessionCount }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="active"
                :label="$t('field.completedSessions').toString()"
                numeric
                width="40"
            >
                {{ props.row.completedSessionCount }}
            </b-table-column>
        </b-table>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import MonthField from '../../../../framework/fields/MonthField.vue';

import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { utc } from 'moment';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import { getNumberSessionsByRequest } from '../../../client/session_client';
import { P_QUERY } from '../../../../../common/framework/constants';
import { Option } from '../../../service/application_options_service';
import { getEnumerationOptions } from '../../../../framework/service/options';
import { NumberSessionsAndCompletedSessions } from '../../../../../common/application/model/number_sessions_by';

@Component({
    components: {
        AutoCompleteMultipleField,
        MonthField,
    },
})
export default class SessionsPerProductTypeTable extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    // State
    shared = sharedState;
    local = {
        loading: false,
        /*productTypes: [] as string[],*/
        monthFilter: utc().startOf('month').toDate().toDateString(),
        rows: [] as NumberSessionsAndCompletedSessions[],
        productTypeOptions: [] as Option[],
    };

    async mounted() {
        this.local.loading = true;

        await this.loadAsyncData();

        this.local.loading = false;
    }

    async loadAsyncData() {
        /*this.local.productTypes = Object.values(ProductType);*/
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this);
        await this.getSessionsPerProductType();
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        this.local.rows = [];
        await this.loadAsyncData();
    }

    formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 0:00:00.000000`;
    }

    formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 23:59:59.000000`;
    }

    async getSessionsPerProductType(): Promise<void> {
        const monthStartDate = new Date(this.local.monthFilter);
        const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 0);
        const monthStartDateString = this.formatStartDate(monthStartDate);
        const monthEndDateString = this.formatEndDate(monthEndDate);

        const totalSessionsParams = new Map<string, string>([
            [P_QUERY, 'date={startDate} AND date<={endDate}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
        ]);

        const sessionNumber = (await getNumberSessionsByRequest(-1, totalSessionsParams, 1000, 'product_type')).numberSessions;

        const completedSessionsParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate} AND completed={completed}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
            ['completed', 'true'],
        ]);

        const completedSessionNumber = (await getNumberSessionsByRequest(
            -1,
            completedSessionsParams,
            1000,
            'product_type',
        )).numberSessions;

        this.local.rows = sessionNumber.map((item: any, index: number) => {
            return {
                ...item,
                completedSessionCount: { ...completedSessionNumber[index] }.sessionCount,
            };
        });
    }

    nextMonth() {
        this.local.monthFilter = utc(this.local.monthFilter).add(1, 'month').startOf('month').toDate().toDateString();
    }

    previousMonth() {
        this.local.monthFilter = utc(this.local.monthFilter)
            .subtract(1, 'month')
            .startOf('month')
            .toDate()
            .toDateString();
    }

    get isAdmin(): boolean {
        return this.shared.admin;
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some(
            (role) => role === (ApplicationUserRole.COACH || ApplicationUserRole.PHYSIOTHERAPIST),
        );
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }

    getProductTypeLabel(productType: string): string | undefined {
        return this.local.productTypeOptions.find(({ id }) => id === productType)!!.label;
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
