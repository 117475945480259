<template>
    <validation-provider
        :name="getFieldName()"
        :rules="{ required: required && !readonly }"
        v-slot="{ errors, valid }"
        tag="div"
    >
        <b-field
            :label="$t(getFieldName() ? 'field.' + getFieldName() : '').toString()"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :label-position="labelPosition"
            :horizontal="horizontal"
            :message="errors"
        >
            <b-select
                v-if="!readonly"
                :disabled="disabled === true"
                :name="getFieldName()"
                v-model="local.value"
                :expanded="expanded"
            >
                <option v-for="o in options" :value="o.id" :key="o.id">
                    {{ o.label }}
                </option>
            </b-select>
            <span v-if="readonly" :class="readonlyClass !== undefined ? readonlyClass : ''">{{
                getLabel(local.value)
            }}</span>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { Option } from '../../application/service/application_options_service';

@Component({
    components: { ValidationProvider },
})
export default class SelectField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(String) readonly labelPosition!: string;
    @Prop(String) readonly readonlyClass!: string;
    @Prop(Array) readonly options!: Option[];
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly expanded!: boolean;

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getLabel(id: string) {
        if (this.options === undefined) {
            return '';
        }
        const filtered = this.options.filter((o) => o.id === id);
        if (filtered.length > 0) {
            return filtered[0].label;
        } else {
            return '';
        }
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
