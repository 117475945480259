export enum PaymentTerms {
    UNSPECIFIED = 'unspecified',
    TERM_0_DAYS = 'term-0-days',
    TERM_7_DAYS = 'term-7-days',
    TERM_10_DAYS = 'term-10-days',
    TERM_14_DAYS = 'term-14-days',
    TERM_21_DAYS = 'term-21-days',
    TERM_30_DAYS = 'term-30-days',
    TERM_45_DAYS = 'term-45-days',
    TERM_60_DAYS = 'term-60-days',
    TERM_90_DAYS = 'term-90-days',
    TERM_AS_AGREED = 'term-as-agreed',
}
