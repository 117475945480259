import { sharedState } from '../../framework/state';
import { getResources } from '../../framework/client/resource';
import { FrameworkUserRole } from '../../../common/framework/enumeration/FrameworkUserRole';

import { Customer } from '../../../common/application/model/customer';
import { ApplicationResource } from '../../../common/application/enumeration/ApplicationResource';
import { Coach } from '../../../common/application/model/coach';
import { Physiotherapist } from '../../../common/application/model/physiotherapist';

export async function getAccessibleCustomers() {
    if (sharedState.hasRole(FrameworkUserRole.ADMIN)) {
        return await getResources<Customer>(ApplicationResource.CUSTOMER, -1);
    } else {
        if (sharedState.context.scopes.coach.customerId!!.length > 0) {
            // return await getResources<Customer>(
            //     ApplicationResource.CUSTOMER,
            //     -1,
            //     new Map([['idIn', sharedState.context.scopes.coach.customerId!!.join(',')]]),
            // );
            return await getResources<Customer>(ApplicationResource.CUSTOMER, -1);
        } else {
            return [];
        }
    }
}

export async function getAccessibleCoaches() {
    return await getResources<Coach>(ApplicationResource.COACH, -1);
}

export async function getAccessiblePhysiotherapists() {
    return await getResources<Physiotherapist>(ApplicationResource.PHYSIOTHERAPIST, -1);
}
