import { getResource } from '../../client/resource';
import Space from '../../../../common/framework/model/Space';
import { FrameworkResource } from '../../../../common/framework/enumeration/FrameworkResource';
import { getGlobalState, hasGlobalState, setGlobalState } from '../../service/global_state_service';
import { FrameworkStateType } from '../../../../common/framework/enumeration/FrameworkStateType';
import { SpaceState } from './SpaceState';
import { setSpaceNavigationMenuLabel } from './space_navigation_service';

let globalSpace: Space | undefined;

export async function setCurrentSpaceId(id: string) {
    if (typeof id === 'undefined') {
        globalSpace = undefined;
        return;
    }

    if (typeof globalSpace !== 'undefined' && globalSpace.id === id) {
        return;
    }
    const space = (await getResource<Space>(FrameworkResource.SPACE, id))!!;
    globalSpace = space;

    const spaceState = {
        space,
    };
    await setGlobalSpaceState(space, spaceState);
    setSpaceNavigationMenuLabel(space);
}

export function getCurrentSpace(): Space {
    return getGlobalState<SpaceState>(FrameworkStateType.SPACE_STATE).space;
}

export function getCurrentSpaceId(): string | undefined {
    try {
        return getGlobalState<SpaceState>(FrameworkStateType.SPACE_STATE).space.id;
    } catch (e) {
        return undefined;
    }
}

async function setGlobalSpaceState(space: Space, spaceState: { space: Space }) {
    console.log('SPACE: ' + space.name);
    if (hasGlobalState(FrameworkStateType.SPACE_STATE)) {
        if (getGlobalState<SpaceState>(FrameworkStateType.SPACE_STATE).space.id !== space.id) {
            await setGlobalState(FrameworkStateType.SPACE_STATE, spaceState);
        }
    } else {
        await setGlobalState(FrameworkStateType.SPACE_STATE, spaceState);
    }
}
