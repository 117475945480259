<template>
    <section class="section">
        <div class="container-fluid px-0 mx-1">
            <h2 class="title is-2">{{ $t('title.dashboard') }}</h2>
            <CustomerSessionTable />
        </div>
    </section>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import CustomerSessionTable from './CustomerSessionTable.vue';

@Component({
    components: {
        CustomerSessionTable,
    },
})
export default class Dashboard extends Vue {}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
