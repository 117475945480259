export interface Cache<T> {
    values: Map<string, T>;
    callbacks: Map<string, Array<ValueLoadedToCacheCallback<T>>>;
}

export type ValueLoadedToCacheCallback<T> = (value: T | undefined, error: any) => void;

export function cachedLoad<T>(key: string, getOptions: () => Promise<T>, cache: Cache<T>): Promise<T> {
    const values = cache.values;
    const callbacks = cache.callbacks;
    return new Promise((resolve, reject) => {
        if (!values.has(key)) {
            if (callbacks.has(key)) {
                // console.log('option cache did not have: ' + key + " waiting...");
                callbacks.get(key)!!.push((options: T | undefined, error: any) => {
                    if (error) {
                        // console.log('option cache did not have: ' + key + " waiting received load error.");
                        reject(error);
                    } else {
                        // console.log('option cache did not have: ' + key + " waiting success.");
                        resolve(options || ({} as any));
                    }
                });
                return;
            } else {
                // console.log('option cache did not have: ' + key + " loading...");
                callbacks.set(key, []);
                getOptions()
                    .then((value: T) => {
                        // console.log('option cache did not have: ' + key + " load success.");
                        values.set(key, value);
                        resolve(values.get(key)!!);
                        for (const valueLoadedToCacheCallback of callbacks.get(key)!!) {
                            valueLoadedToCacheCallback(value, undefined);
                        }
                        callbacks.delete(key);
                    })
                    .catch((reason: any) => {
                        // console.log('option cache did not have: ' + key + " load failure.");
                        reject(reason);
                        for (const optionsLoaded of callbacks.get(key)!!) {
                            optionsLoaded(undefined, reason);
                        }
                        callbacks.delete(key);
                    });

                return;
            }
        } else {
            // console.log('option cache had: ' + key + "");
            resolve(values.get(key)!!);
        }
    });
}
