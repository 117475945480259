<template>
    <validation-provider
        :name="getFieldName()"
        :rules="{ required: required, min: 8, regex: /^[a-zåäöA-ZÅÄÖ0-9 ^,.;:!?&%'`@|+*=/\\\-_~#()${}<>\[\]\n]+$/ }"
        v-slot="{ errors, valid }"
        tag="div"
    >
        <b-field
            :label="$t('field.' + getFieldName()).toString()"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :horizontal="horizontal"
            :message="errors"
        >
            <b-input
                :name="getFieldName()"
                type="password"
                v-if="!readonly"
                :disabled="disabled === true"
                v-model="local.value"
                :maxlength="maxlength"
                password-reveal
                @keyup.native.enter="onEnter"
            >
            </b-input>
            <span v-if="readonly">{{ local.value }}</span>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class PasswordField extends Vue {
    @Prop(String) name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Number) readonly maxlength!: number;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly horizontal!: boolean;

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    onEnter() {
        this.$emit('enter');
    }
}
</script>
