<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-3">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addSalesInvoice') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <SelectField
                            name="customer"
                            v-model="local.row.customerId"
                            :options="local.customerOptions"
                            :required="true"
                        />
                        <DateField v-model="local.row.entryDate" :required="true" />
                        <DateField v-model="local.row.dueDate" :required="true" />
                        <DateField v-model="local.row.startDate" :required="true" />
                        <DateField v-model="local.row.endDate" :required="true" />

                        <button class="button is-primary is-fullwidth login-button space-2" @click="passes(add)">
                            <b-icon icon="plus-circle-outline"></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../framework/state';
import TextField from '../../../../../framework/fields/TextField.vue';
import SelectField from '../../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../../framework/fields/SwitchField.vue';
import { getCustomerOptions, Option } from '../../../../service/application_options_service';
import NumberField from '../../../../../framework/fields/NumberField.vue';
import DateField from '../../../../../framework/fields/DateField.vue';
import { AddMonthlySalesInvoiceReq } from '../../../../../../common/application/model/api/AddMonthlySalesInvoiceReq';
import { postMonthlySalesInvoiceRequest } from '../../../../client/sales_invoice_client';
import { ValidationObserver } from 'vee-validate';

@Component({
    components: { DateField, NumberField, SwitchField, SelectField, TextField, ValidationObserver },
})
export default class AddMonthlySalesInvoice extends Vue {
    // State
    shared = sharedState;
    local = {
        row: {} as AddMonthlySalesInvoiceReq,
        customerOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.customerOptions = await getCustomerOptions();
    }

    async add() {
        await postMonthlySalesInvoiceRequest(this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
