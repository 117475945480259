export enum ApplicationResource {
    ACCOUNT = 'account',
    CUSTOMER = 'customer',
    COACH = 'coach',
    GROUP = 'group',
    PRODUCT = 'product',
    LOCATION = 'location',
    SALES_INVOICE = 'sales-invoice',
    SALES_INVOICE_ROW = 'sales-invoice-row',
    FILE_META = 'file-meta',
    SESSION = 'session',
    MEMBERSHIP = 'membership',
    PUBLIC_HOLIDAY = 'public-holiday',
    PROCOUNTOR_REQUEST = 'procountor-request',
    PHYSIOTHERAPIST = 'physiotherapist',
    SESSIONS_REPORT = 'sessions-report',
    COACH_TASK = 'coach-task',
    WEEKLY_REPORT = 'weekly-report',
    BUSINESS_PARTNER = 'business-partner',
}
