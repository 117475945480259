<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.editGroup') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <text-field v-model="local.name" :maxlength="40" :required="true" />

                        <button
                            class="button is-primary is-fullwidth login-button space-2"
                            @click="passes(save)"
                        >
                            <b-icon icon="content-save-outline" ></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>
                    </validation-observer>

                    <group-members :id="id" />
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { getResource, putResource } from '../../../client/resource';
import { Group } from '../../../../../common/framework/model/Group';
import GroupMembers from './GroupMembers.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import UserGroups from '../users/UserGroups.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserGroups,
        ValidationObserver,
        GroupMembers,
    },
})
export default class EditGroup extends Vue {
    readonly resourceType = 'group';

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        id: '',
        name: '',
        created: new Date(),
        modified: new Date(),
    };

    async mounted() {
        const row = (await getResource(this.resourceType, this.id))!! as Group;
        Object.assign(this.local, row);
        this.local.name = row.name;
    }

    async save() {
        const row = (await getResource(this.resourceType, this.id))!!;
        await putResource(this.resourceType, this.id, Object.assign(row, this.local));
        this.$router.go(-1);
    }

    back() {
        this.$router.go(-1);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
