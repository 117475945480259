<template>
    <div class="container-fluid mx-10-fullhd">
        <h1 class="title">{{ $t('title.bankAndFreeSessionsReport') }}</h1>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-4">
                    <div class="is-flex">
                        <b-button @click="previousMonth()" type="is-primary" class="mr-2" icon-right="arrow-left" style="margin-top: 32px"/>
                        <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                        <b-button @click="nextMonth()" type="is-primary" class="ml-2" icon-right="arrow-right" style="margin-top: 32px"/>
                    </div>
                </div>
            </div>
        </div>
        <b-table
            :data="local.rows"
            :hoverable="true"
            :striped="true"
            :scrollable="true"
            :current-page="1"
            :loading="local.loading"
        >
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                    </div>
                </section>
            </template>
            <b-table-column
                v-slot="props"
                field="bankSessionsTotal"
                :label="$t('field.bankSessionsTotal').toString()"
                width="40"
            >
                {{ props.row.bankSessionsTotal }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="freeSessionsTotal"
                :label="$t('field.freeSessionsTotal').toString()"
                width="40"
            >
                {{ props.row.freeSessionsTotal }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="bankSessionsUsed"
                :label="$t('field.bankSessionsUsed').toString()"
                width="40"
            >
                {{ props.row.bankSessionsUsed }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="freeSessionsUsed"
                :label="$t('field.freeSessionsUsed').toString()"
                width="40"
            >
                {{ props.row.freeSessionsUsed }}
            </b-table-column>
        </b-table>
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import MonthField from '../../../../framework/fields/MonthField.vue';

import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { utc } from 'moment';
import { P_QUERY } from '../../../../../common/framework/constants';
import { getResources } from '../../../../framework/client/resource';
import { SessionsReport } from '../../../../../common/application/model/SessionsReport';

@Component({
    components: {
        AutoCompleteMultipleField,
        MonthField,
    },
})
export default class BankAndFreeSessionsReportTable extends Vue {
    readonly resourceType = ApplicationResource.SESSIONS_REPORT;

    // State
    shared = sharedState;
    local = {
        loading: false,
        monthFilter: utc().startOf('month').subtract(1, 'month').toDate().toDateString(),
        rows: [] as SessionsReport[],
    };

    async mounted() {
        this.local.loading = true;

        await this.loadAsyncData();

        this.local.loading = false;
    }

    async loadAsyncData() {
        await this.getSessionsReportPerMonth();
    }

    @Watch('local.monthFilter')
    async completedSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        this.local.rows = [];
        await this.loadAsyncData();
    }

    formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 0:00:00.000000`;
    }

    formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 23:59:59.000000`;
    }

    async getSessionsReportPerMonth(): Promise<void> {
        const monthStartDate = new Date(this.local.monthFilter);
        const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 0);
        const monthStartDateString = this.formatStartDate(monthStartDate);
        const monthEndDateString = this.formatEndDate(monthEndDate);

        const params = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
        ]);

        this.local.rows = (await getResources(this.resourceType, -1, params)) as Array<SessionsReport>;
    }

    get isAdmin(): boolean {
        return this.shared.admin;
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some(
            (role) => role === (ApplicationUserRole.COACH || ApplicationUserRole.PHYSIOTHERAPIST),
        );
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }

    nextMonth() {
        this.local.monthFilter = utc(this.local.monthFilter).add(1, 'month').startOf('month').toDate().toDateString();
    }

    previousMonth() {
        this.local.monthFilter = utc(this.local.monthFilter)
            .subtract(1, 'month')
            .startOf('month')
            .toDate()
            .toDateString();
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
