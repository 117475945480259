import { apiDelete, apiGet, apiPost, apiPut } from './index';
import { PagingInfo } from '../../../common/framework/model/PagingInfo';
import {
    ERROR_API_CALL_FAILED,
    P_PAGE,
    P_PAGE_SIZE,
    P_QUERY,
    P_SORT_FIELD,
    P_SORT_FIELDS,
    P_SORT_ORDER,
    P_SORT_ORDERS,
} from '../../../common/framework/constants';

export async function getResource<T>(resourceType: string, id: string): Promise<T | undefined> {
    const response = await apiGet('/api/resource/' + resourceType + '/' + id);
    if (response.status === 200) {
        return (await response.json()) as T;
    } else if (response.status === 404) {
        return undefined;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getResourcePaging(
    resourceType: string,
    parameters?: Map<string, string>,
    pageSize: number | undefined = undefined,
): Promise<PagingInfo> {
    const queryParameters = parameters
        ? '?' + Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    const response = await apiGet('/api/resource/' + resourceType + '/paging' + queryParameters, { pageSize });
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getResourcePagingNew(
    resourceType: string,
    parameters?: Map<string, any>,
    customPart?: string,
): Promise<PagingInfo> {
    let body: any = {};
    let queryParameters = '';

    const pagingParameters = new Map<string, string>();
    let queryString = ``;

    if (parameters) {
        const query = buildQuery(parameters, queryString, body, pagingParameters, customPart);
        queryParameters = query.queryParameters;
        body = query.body;
    }

    const response = await apiPost(`/api/resource-new/${resourceType}/paging${queryParameters}`, body);
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}


export async function getResources<T>(
    resourceType: string,
    page: number = -1,
    parameters?: Map<string, string>,
    pageSize: number | undefined = undefined,
): Promise<T[]> {
    const queryParameters = parameters
        ? '?' + Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    const response = await apiGet('/api/resource/' + resourceType + '' + queryParameters, { page, pageSize });
    if (response.status === 200) {
        return (await response.json()) as T[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteResource(resourceType: string, id: string): Promise<void> {
    const response = await apiDelete('/api/resource/' + resourceType + '/' + id);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(await response.json());
    }
}

export async function getResourcesNew<T>(
    resourceType: string,
    page: number = -1,
    parameters?: Map<string, any>,
    customPart?: string,
): Promise<T[]> {
    let body: any = {};
    let queryParameters = '';

    const pagingParameters = new Map<string, string>();
    let queryString = ``;

    if (parameters) {
        const query = buildQuery(parameters, queryString, body, pagingParameters, customPart);
        queryParameters = query.queryParameters;
        body = query.body;
    }

    const response = await apiPost(`/api/resource-new/${resourceType}${queryParameters}`, body);
    if (response.status === 200) {
        return (await response.json()) as T[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postResource<T>(resourceType: string, resource: T): Promise<T> {
    const response = await apiPost('/api/resource/' + resourceType + '', resource);
    if (response.status === 200) {
        return (await response.json()) as T;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function putResource<T>(resourceType: string, id: string, resource: T): Promise<T> {
    const response = await apiPut('/api/resource/' + resourceType + '/' + id, resource);
    if (response.status === 200) {
        return (await response.json()) as T;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export const buildQuery = (
    parameters: Map<string, any>,
    queryString: string,
    body: any,
    pagingParameters: Map<string, string>,
    customPart = '',
) => {
    const pagingKeys = [P_SORT_FIELD, P_SORT_FIELDS, P_SORT_ORDER, P_SORT_ORDERS, P_PAGE, P_PAGE_SIZE];
    const paginationKeys: string[] = [];
    const whereKeys: string[] = [];

    Array.from(parameters.keys()).forEach((key) => {
        if (pagingKeys.indexOf(key) > -1) {
            paginationKeys.push(key);
        } else {
            whereKeys.push(key);
        }
    });

    paginationKeys.forEach((key) => {
        if (parameters.get(key)) {
            pagingParameters.set(key, parameters.get(key)!!);
        }
    });

    whereKeys.forEach((key, index) => {
        const splitParam = parameters.get(key)!!.toString().split('_');
        const parameterType = Array.isArray(parameters.get(key)) ? 'IN' : splitParam.length > 1 ? splitParam[0] : '=';
        const queryValue = generateQueryValueString(key.toString(), parameterType);

        if (customPart.indexOf(key) === -1) {
            if (index === 0) {
                queryString += `${key} ${parameterType} ${queryValue}`;
            } else {
                queryString += ` AND ${key} ${parameterType} ${queryValue}`;
            }
        }

        if (splitParam.length > 1) {
            body[key] = splitParam[1];
        } else {
            body[key] = parameters.get(key);
        }
    });

    if (queryString.length) {
        body[P_QUERY] = queryString;
    }

    if (customPart.length) {
        if (body[P_QUERY] && body[P_QUERY].length) {
            body[P_QUERY] += ` AND ${customPart}`;
        } else {
            body[P_QUERY] = `${customPart}`;
        }
    }

    const queryParameters = pagingParameters
        ? '?' + Array.from(pagingParameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    return {
        body,
        queryString,
        queryParameters,
    };
};

export const generateQueryValueString = (value: string, paramType = '='): string => {
    if (paramType === 'IN') {
        return `({${value}})`;
    } else {
        return `{${value}}`;
    }
};
