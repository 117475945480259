<template>
    <div class="columns has-text-centered" style="flex-wrap: wrap">
        <div class="column is-6 has-text-centered my-4">
            <div class="is-flex is-flex-direction-column is-justify-content-space-evenly">
                <div class="columns">
                    <div class="column is-12">
                        <h1 class="is-size-2 has-text-weight-bold has-text-secondary">
                            {{ local.officeTotalSessions }}
                        </h1>
                        <h4 class="subtitle is-4">{{ $t('title.officeTotalSessions') }}</h4>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <h1 class="is-size-2 has-text-weight-bold has-text-primary">
                            {{ local.officeCompletedSessions }}
                        </h1>
                        <h4 class="subtitle is-4">{{ $t('title.officeCompletedSessions') }}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="column is-6 has-text-centered my-4">
            <div class="is-flex is-flex-direction-column is-justify-content-space-evenly">
                <div class="columns">
                    <div class="column is-12">
                        <h1 class="is-size-2 has-text-weight-bold has-text-secondary">
                            {{ local.nonOfficeTotalSessions }}
                        </h1>
                        <h4 class="subtitle is-4">{{ $t('title.nonOfficeTotalSessions') }}</h4>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12">
                        <h1 class="is-size-2 has-text-weight-bold has-text-primary">
                            {{ local.nonOfficeCompletedSessions }}
                        </h1>
                        <h4 class="subtitle is-4">{{ $t('title.nonOfficeCompletedSessions') }}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import MonthField from '../../../../framework/fields/MonthField.vue';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Coach } from '../../../../../common/application/model/coach';
import { getResources } from '../../../../framework/client/resource';
import { P_QUERY } from '../../../../../common/framework/constants';
import { getNumberSessionsByRequest } from '../../../client/session_client';

@Component({
    components: {
        AutoCompleteMultipleField,
        MonthField,
    },
})
export default class OfficeAndNonOfficeSessionsTable extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    @Prop(String) readonly monthFilter!: string;

    // State
    shared = sharedState;
    local = {
        loading: false,
        officeTotalSessions: 0,
        nonOfficeTotalSessions: 0,
        officeCompletedSessions: 0,
        nonOfficeCompletedSessions: 0,
        coaches: [] as Coach[],
    };

    async mounted() {
        this.local.loading = true;

        await this.loadAsyncData();

        this.local.loading = false;
    }

    async loadAsyncData() {
        this.local.officeTotalSessions = 0;
        this.local.officeCompletedSessions = 0;
        this.local.nonOfficeTotalSessions = 0;
        this.local.nonOfficeCompletedSessions = 0;

        this.local.coaches = (await getResources(ApplicationResource.COACH, -1)) as Array<Coach>;
        await this.getOfficeAndNonOfficeSessions();
    }

    formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 0:00:00.000000`;
    }

    formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 23:59:59.000000`;
    }

    @Watch('monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    async getOfficeAndNonOfficeSessions(): Promise<void> {
        const monthStartDate = new Date(this.monthFilter);
        const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 0);
        const monthStartDateString = this.formatStartDate(monthStartDate);
        const monthEndDateString = this.formatEndDate(monthEndDate);

        const totalParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
        ]);

        const coachTotalNumbers = (await getNumberSessionsByRequest(-1, totalParams, 1000, 'coach_id')).numberSessions;

        const completedParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate} AND completed={completed}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
            ['completed', 'true'],
        ]);

        const coachCompletedNumbers = (await getNumberSessionsByRequest(-1, completedParams, 1000, 'coach_id'))
            .numberSessions;

        const coachNumbers = coachTotalNumbers.map((item: any, index: number) => {
            return {
                ...item,
                completedSessionCount: { ...coachCompletedNumbers[index] }.sessionCount,
            };
        });

        for (const coachNumber of coachNumbers) {
            if (!coachNumber.columnValue) {
                continue;
            }

            const coach = this.local.coaches.find((coach) => coach.id === coachNumber.columnValue);

            if (!coach) {
                continue;
            }

            if (coach.office) {
                this.local.officeTotalSessions += Number(coachNumber.sessionCount);
                this.local.officeCompletedSessions += Number(coachNumber.completedSessionCount);
            } else {
                this.local.nonOfficeTotalSessions += Number(coachNumber.sessionCount);
                this.local.nonOfficeCompletedSessions += Number(coachNumber.completedSessionCount);
            }
        }
    }

    get isAdmin(): boolean {
        return this.shared.admin;
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
