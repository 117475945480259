import { postLogin, postLogout } from '../client/login';
import router from '../../Router';
import { appLogin, appLogout } from '../../app_hooks';

export async function passwordLogin(email: string, password: string): Promise<boolean> {
    const result = await postLogin(email, password);
    await appLogin();
    return result;
}

export async function logout() {
    console.log('logging out and clearing session storage.');
    await postLogout(router);
    await appLogout();
}
