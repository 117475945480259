import { isEmpty } from 'lodash';

import { Resource } from '../../../common/framework/enumeration/Resource';
import { getResources } from '../client/resource';

const labelMemoryStore: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();

export async function getResourceLabels<T>(
    rows: any[],
    idField: string,
    resource: Resource,
    resourceLabelField: string | ((resource: T) => string),
) {
    return await loadResourceLabels<T>(resource.toString(), resourceLabelField, collectSpaceIds(rows, idField));
}

function collectSpaceIds(rows: any[], field: string) {
    const spaceIds: string[] = [];
    for (const row of rows) {
        if (row[field] && !spaceIds.includes(row[field]!!)) {
            spaceIds.push(row[field]!!);
        }
    }
    return spaceIds;
}

async function loadResourceLabels<T>(
    resource: string,
    field: string | ((resource: T) => string),
    ids: string[],
): Promise<Map<string, string>> {
    if (!labelMemoryStore.has(resource)) {
        labelMemoryStore.set(resource, new Map<string, string>());
    }
    const resourceLabels = labelMemoryStore.get(resource)!!;

    const labels = new Map<string, string>();
    const idsToLoad: string[] = [];
    for (const id of ids) {
        if (resourceLabels.has(id)) {
            labels.set(id, resourceLabels.get(id)!!);
        } else {
            idsToLoad.push(id);
        }
    }

    // Load ids not found from memory store.
    const idsLoaded: string[] = [];
    if (idsToLoad.length > 0) {
        const rows = await getResources(resource, -1, new Map<string, string>([['idIn', ids.join(',')]]));
        for (const row of rows) {
            const id = (row as any).id as string;
            const label = typeof field === 'string' ? (row as any)[field] : field?.(row as T);
            if (isEmpty(label)) {
                // Mark rows with no label field content with ?.
                resourceLabels.set(id, '?');
                labels.set(id, '?');
            } else {
                resourceLabels.set(id, label);
                labels.set(id, label);
            }
            idsLoaded.push(id);
        }
    }

    const idsMissing = idsToLoad.filter((el) => !idsLoaded.includes(el));

    // Mark ids not loaded from database with -
    for (const id of idsMissing) {
        resourceLabels.set(id, '-');
        labels.set(id, '-');
    }

    return labels;
}
