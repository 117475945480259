<template>
    <validation-observer v-slot="{ passes }">
        <h1 class="title">{{ $t('title.weeklyReports') }}</h1>
        <div class="buttons has-addons">
            <button class="button" @click="add" v-if="isAdmin">
                <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                <span>{{ $t('button.add') }}</span>
            </button>
            <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                <b-icon icon="delete-outline" size="is-small"></b-icon>
                <span>{{ $t('button.delete') }}</span>
            </button>
        </div>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-3">
                    <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                </div>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :backend-sorting="true"
                :backend-pagination="true"
                :hoverable="true"
                :striped="true"
                :paginated="true"
                :scrollable="true"
                :sticky-header="true"
                :current-page="local.parameters.page"
                :data="local.rows"
                :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                :loading="local.loading"
                :pagination-position="'top'"
                :per-page="local.parameters.pageSize"
                :selected.sync="local.selected"
                :total="local.total"
                @page-change="onPageChange"
                @sort="onSortChange"
                @click="onRowClick"
            >
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                        </div>
                    </section>
                </template>

                <template #top-left>
                    <div class="content">
                        <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                    </div>
                </template>
                <b-table-column v-slot="props" field="id" :visible="isAdmin">
                    <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                        >{{ $t('button.detail') }}
                    </b-button>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="week"
                    :label="String($t('field.week')).toString()"
                    :sortable="true"
                >
                    {{ getWeekNumber(props.row.date) || '-' }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="date"
                    :label="String($t('field.date')).toString()"
                    :sortable="true"
                >
                    {{ dateToDateTimeString(props.row.date) || '-' }}
                </b-table-column>
                <b-table-column v-slot="props" field="url" :label="String($t('field.url')).toString()" :sortable="true">
                    {{ props.row.url }}
                </b-table-column>
            </b-table>
        </div>
    </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePagingNew, getResourcesNew } from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { P_PAGE, P_SORT_FIELD, P_SORT_ORDER } from '../../../../../common/framework/constants';
import { formatToFinnishDateTime } from '../../../service/helper_service';
import { Option } from '../../../service/application_options_service';
import { getEnumerationOptions } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import MonthField from '../../../../framework/fields/MonthField.vue';
import moment, { utc } from 'moment';
import { WeeklyReport } from '../../../../../common/application/model/weekly_report';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
        ValidationObserver,
        MonthField,
    },
})
export default class CustomerWeekLyReports extends Vue {
    @Prop(String) readonly customerId!: string;
    @Prop(String) readonly coachId!: string;

    readonly resourceType = ApplicationResource.WEEKLY_REPORT;

    shared = sharedState;
    local = {
        rows: new Array<WeeklyReport>(),
        total: 0,
        loading: false,
        selected: undefined as WeeklyReport | undefined,
        parameters: {} as QueryParameters,
        productTypeOptions: [] as Option[],
        monthFilter: utc().startOf('month').toDate().toDateString(),
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${this.resourceType}/add-from-customer/${this.customerId}/${this.coachId}`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${this.resourceType}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this);
        parameters.set(P_PAGE, this.local.parameters.page ? this.local.parameters.page : 1);
        parameters.set('customerId', this.customerId);
        parameters.set(P_SORT_FIELD, 'date');
        parameters.set(P_SORT_ORDER, SortOrder.DESC);
        parameters.set('startDateOfMonth', moment(new Date(this.local.monthFilter)).startOf('month').toISOString());
        parameters.set('endDateOfMonth', moment(new Date(this.local.monthFilter)).endOf('month').toISOString());

        let query = 'date>={startDateOfMonth} AND date<={endDateOfMonth}';

        this.local.total = (await getResourcePagingNew(this.resourceType, parameters, query)).rowCount;

        this.local.rows = [];

        const rows: WeeklyReport[] = (await getResourcesNew(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
            query,
        )) as Array<WeeklyReport>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    @Watch('local.parameters.completed')
    async completedSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    onRowClick(row: WeeklyReport): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    dateToDateTimeString(timestamp: string) {
        const date = new Date(timestamp);
        return formatToFinnishDateTime(date);
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }

    getWeekNumber(date: Date) {
        return moment(date).isoWeek();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
