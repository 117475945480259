import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=36392220&scoped=true"
import script from "./Reports.vue?vue&type=script&lang=ts"
export * from "./Reports.vue?vue&type=script&lang=ts"
import style0 from "./Reports.vue?vue&type=style&index=0&id=36392220&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36392220",
  null
  
)

export default component.exports