<template>
    <validation-provider :name="getFieldName()" :rules="{ required: required }" v-slot="{ errors, valid }">
        <b-field
            :label="$t(getFieldName() ? 'field.' + getFieldName() : '').toString()"
            :type="{
                'is-danger': errors[0],
                'is-success':
                    valid &&
                    (local.typed ===
                        local.filteredObjects
                            .filter((o) => o.id === local.selectedId)
                            .map((o) => o.label)
                            .join('') ||
                        (local.typed === '' && typeof local.selectedId === 'undefined')),
            }"
            :message="errors"
        >
            <b-autocomplete
                v-on:blur="focusOut()"
                icon="magnify"
                v-model="local.typed"
                field="label"
                :data="local.filteredObjects"
                :disabled="disabled === true"
                :open-on-focus="true"
                :clearable="true"
                @select="(option) => (local.selectedId = option ? option.id : '')"
                :name="getFieldName()"
            >
                <template #empty>{{ $t('message.noResultsFound') }}</template>
            </b-autocomplete>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { debounce, isEmpty, isNil } from 'lodash';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { P_TEXT_SEARCH_FIELDS, P_TEXT_SEARCH_PATTERN } from '../../../common/framework/constants';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { OptionValue } from '../service/options';
import { getResources } from '../client/resource';
import { PageSize } from '../../../common/application/model/enums/PageSize';
import { ApplicationResource } from '../../../common/application/enumeration/ApplicationResource';

@Component({
    components: { ValidationProvider },
})
export default class AutoCompleteField extends Vue {
    @Prop(String) name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(String) readonly resource!: string;
    @Prop(String) readonly field!: string;
    @Prop(Map) readonly query!: Map<string, string>;

    shared = sharedState;
    local = {
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.S,
        selected: undefined as any | undefined,
        typed: '',
        selectedId: undefined as string | undefined,
        filteredObjects: [] as OptionValue[],
    };

    async mounted() {
        await this.load();
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.selectedId = newValue;
        if (this.local.selectedId) {
            await this.setTypedAccordingToSelectedId();
        } else {
            this.local.typed = '';
        }
    }

    @Watch('query')
    async queryValueChange() {
        this.lastTyped = undefined;
        await this.load();
    }

    private async setTypedAccordingToSelectedId() {
        if (typeof this.local.selectedId === 'undefined') {
            this.local.typed = '';
            return;
        }
        let matchedObjects = this.local.filteredObjects.filter((v) => v.id === this.local.selectedId);
        if (matchedObjects.length === 0) {
            // If not in preloaded set then load from database.
            matchedObjects = (
                await getResources<any>(this.resource, -1, new Map([['id', this.local.selectedId!!]]))
            ).map((o) => {
                if (this.resource === ApplicationResource.CUSTOMER) {
                    return { id: o.id, label: `${o.firstName} ${o.lastName}` };
                }
                return { id: o.id, label: o[this.field] };
            });
        }
        const typed = matchedObjects.length > 0 ? matchedObjects[0]['label'] : '?';
        if (this.local.typed !== typed) {
            this.local.typed = typed;
        }
    }

    @Watch('local.selectedId')
    async selectedIChange(newValue: string) {
        if (this.local.typed === '-') {
            this.local.typed = '';
        }
        if (!isEmpty(newValue)) {
            this.$emit('input', newValue);
        } else {
            this.$emit('input', undefined);
        }
    }

    @Watch('local.typed')
    async typedValueChange() {
        if (this.local.typed === '-') {
            this.local.typed = '';
        }
        if (this.local.typed === '') {
            this.local.filteredObjects = [{ id: undefined as string | undefined, label: '' }];
        }
        await debounce(this.load, 1000)();
    }

    lastTyped: string | undefined = undefined;

    async load() {
        if (this.local.typed === this.lastTyped) {
            return;
        }
        this.lastTyped = this.local.typed;
        const parameters = new Map();
        parameters.set('pageSize', 500);
        if (!isNil(this.query)) {
            this.query.forEach((value: string, key: string) => {
                parameters.set(key, value);
            });
        }
        if (this.local.typed) {
            if (this.resource === ApplicationResource.CUSTOMER) {
                parameters.set(P_TEXT_SEARCH_FIELDS, `firstName,lastName`);
            } else {
                parameters.set(P_TEXT_SEARCH_FIELDS, this.field);
            }

            parameters.set(P_TEXT_SEARCH_PATTERN, `*${this.local.typed}*`);
        }

        this.local.filteredObjects = await getOptions(this.resource, this.field, parameters);

        if (this.local.filteredObjects.length === 0) {
            this.local.filteredObjects = [{ id: undefined as string | undefined, label: '-' }];
        } else {
            this.local.filteredObjects = [
                {
                    id: undefined as string | undefined,
                    label: '-',
                },
            ].concat(this.local.filteredObjects);
        }
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    async focusOut() {
        await debounce(this.ensureTypedCorrect, 100)();
    }

    async ensureTypedCorrect() {
        if (
            !(
                this.local.typed ===
                    this.local.filteredObjects
                        .filter((o) => o.id === this.local.selectedId)
                        .map((o) => o.label)
                        .join('') ||
                (this.local.typed === '' && typeof this.local.selectedId === 'undefined')
            )
        ) {
            await this.setTypedAccordingToSelectedId();
        }
    }
}

export async function getOptions(resource: string, field: string, parameters: Map<string, string>) {
    return (await getResources<any>(resource, -1, parameters)).map((o) => {
        if (
            resource === ApplicationResource.CUSTOMER ||
            resource === ApplicationResource.COACH ||
            resource === ApplicationResource.PHYSIOTHERAPIST
        ) {
            return { id: o.id, label: `${o.firstName} ${o.lastName}` };
        }
        return { id: o.id, label: o[field] };
    });
}
</script>
