<template>
    <div class="space-2">
        <h4 class="title is-4">{{ $t('title.groups') }}</h4>

        <form @submit.prevent="submit">
            <b-field v-for="group in local.groups" :key="group.id">
                <b-checkbox v-model="local.selectedUserGroupIds[group.id]">{{ group.name }}</b-checkbox>
            </b-field>
        </form>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { deleteResource, getResources, postResource } from '../../../client/resource';
import { Group } from '../../../../../common/framework/model/Group';
import { UserGroup } from '../../../../../common/framework/model/UserGroup';
import { errorToast, successToast } from '../../../../application/service/toast_service';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';

@Component
export default class UserGroups extends Vue {
    readonly resourceType = FrameworkResource.USER_GROUP;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        groups: [] as Group[],
        userGroups: [] as UserGroup[],
        selectedUserGroupIds: {} as any,
    };

    async mounted() {
        const groups = await getResources<Group>(FrameworkResource.GROUP);

        for (const group of groups) {
            this.local.selectedUserGroupIds[group.id] = false;
        }

        this.local.userGroups = this.id
            ? await getResources<UserGroup>(this.resourceType, -1, new Map([['userId', this.id]]))
            : [];

        for (const userGroup of this.local.userGroups) {
            this.local.selectedUserGroupIds[userGroup.groupId] = true;
        }

        this.local.groups = groups;
    }

    async submit() {
        await this.save();
    }

    async saveWithUserId(userId: string) {
        const selectedGroupIds = [] as string[];
        for (const selectedGroupId in this.local.selectedUserGroupIds) {
            if (this.local.selectedUserGroupIds[selectedGroupId]) {
                selectedGroupIds.push(selectedGroupId);
            }
        }

        const existingGroupIds = this.local.userGroups.map((ug) => ug.groupId);

        for (const selectedGroupId of selectedGroupIds) {
            if (existingGroupIds.indexOf(selectedGroupId) === -1) {
                await postResource<UserGroup>(this.resourceType, {
                    id: '',
                    userId: userId,
                    groupId: selectedGroupId,
                    created: new Date(),
                    modified: new Date(),
                });
            }
        }

        for (const existingGroupId of existingGroupIds) {
            if (selectedGroupIds.indexOf(existingGroupId) === -1) {
                try {
                    const userGroup = this.local.userGroups.filter((ug) => ug.groupId === existingGroupId)[0];
                    await deleteResource(this.resourceType, userGroup.id);
                    successToast(this, 'message.userGroupRemoved');
                } catch (e) {
                    errorToast(this);
                }
            }
        }

        this.local.userGroups = await getResources<UserGroup>(this.resourceType, -1, new Map([['userId', userId]]));
    }

    async save() {
        await this.saveWithUserId(this.id);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
