<template>
    <div class="container-fluid">
        <h1 class="title">{{ $t('title.customersWithoutSalesInvoice') }}</h1>
        <span class="description">
            {{ $t('title.customersWithoutSalesInvoiceDescription', descriptionParams) }}
        </span>
        <br />
        <br />
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-3">
                    <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" />
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <b class="column is-3">{{ $t('field.total') }}: {{ local.total }}</b>
            </div>
        </div>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-12">
                    <b-pagination
                        v-model="local.page"
                        class="is-justify-content-end"
                        :disabled="local.loading"
                        order="is-right"
                        :total="local.total"
                        :per-page="local.pageSize"
                        :range-before="2"
                        :range-after="2"
                        size="small"
                    />
                </div>
            </div>
        </div>
        <div class="table-container">
            <div class="table-container">
                <b-table
                    :hoverable="true"
                    :striped="true"
                    :scrollable="true"
                    :backend-sorting="true"
                    :sticky-header="true"
                    :current-page="local.page"
                    :data="local.rows"
                    :loading="local.loading"
                    :selected.sync="local.selected"
                    :sort-desc.sync="local.sortOrderAsc"
                    :sort-by.sync="local.sortField"
                    :total="local.total"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left>
                        <div class="content">
                            <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id" width="5%">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>
                    <b-table-column field="churnedCustomer" sortable v-slot="props">
                        <b-tooltip :label="props.row.status.tooltip" position="is-bottom">
                            <b-icon icon="clock-alert-outline" :type="props.row.status.icon"></b-icon>
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column v-slot="props" field="id2" width="5%">
                        <b-tag v-bind:class="getStatusBadgeClass(props.row)">
                            {{ props.row.holdingFee ? $t('button.holdingFee') : $t('button.noHoldingFee') }}
                        </b-tag>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="firstName"
                        :label="String($t('field.firstName')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.firstName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="lastName"
                        :label="String($t('field.lastName')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.lastName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="customerNumber"
                        :label="String($t('field.customerNumber')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.customerNumber }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="bankSessions"
                        :label="String($t('field.bankSessions')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.bankSessions }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="freeSessions"
                        :label="String($t('field.freeSessions')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.freeSessions }}
                    </b-table-column>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { Customer } from '../../../../../../../common/application/model/customer';
import { getHoldingFeeBadgeClass } from '../../../../../service/helper_service';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';
import { sharedState } from '../../../../../../framework/state';
import { P_PAGE, P_PAGE_SIZE, P_SORT_FIELD, P_SORT_ORDER } from '../../../../../../../common/framework/constants';
import { utc } from 'moment';
import { FrameworkUserRole } from '../../../../../../../common/framework/enumeration/FrameworkUserRole';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import AutoCompleteMultipleField from '../../../../../fields/AutoCompleteMultipleField.vue';
import MonthField from '../../../../../../framework/fields/MonthField.vue';
import { getNumberSessionsByRequest } from '../../../../../client/customer_client';

interface CustomersWithoutSalesInvoiceTableRow extends Customer {
    membershipEndDate: string;
    status: {
        tooltip: string;
        icon: 'is-warning' | 'is-danger' | 'is-success';
    };
}

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        MonthField,
    },
})
export default class CustomersWithoutSalesInvoiceTable extends Vue {
    readonly resourceType = ApplicationResource.CUSTOMER;

    shared = sharedState;
    local = {
        rows: new Array<CustomersWithoutSalesInvoiceTableRow>(),
        total: 0,
        loading: false,
        page: 1,
        pageSize: 100,
        sortOrderAsc: true,
        sortField: 'lastName',
        selected: undefined as CustomersWithoutSalesInvoiceTableRow | undefined,
        showFilter: window.innerWidth >= 1023,
        monthFilter: utc().startOf('month').subtract(1, 'month').toDate().toDateString(),
    };

    async mounted() {
        this.local.loading = true;

        await this.loadAsyncData();

        this.local.loading = false;
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${ApplicationResource.CUSTOMER}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.CUSTOMER}/${this.local.selected.id}`);
        }
    }

    async loadAsyncData() {
        const parameters = new Map();
        parameters.set(P_PAGE, this.local.page);
        parameters.set(P_PAGE_SIZE, this.local.pageSize);
        parameters.set(P_SORT_FIELD, this.local.sortField);
        parameters.set(P_SORT_ORDER, this.local.sortOrderAsc ? 'asc' : 'desc');
        // Sales Invoice Params
        parameters.set('invoicingPeriodStart', utc(this.local.monthFilter).startOf('month').toISOString());
        parameters.set('invoicingPeriodEnd', utc(this.local.monthFilter).endOf('month').endOf('day').toISOString());

        parameters.set('membershipStartDate', utc(this.local.monthFilter).startOf('month').toISOString());
        parameters.set('membershipEndDate', utc(this.local.monthFilter).endOf('month').endOf('day').toISOString());

        const result: {
            rowCount: number;
            rows: (Customer & { membershipEndDate: string })[];
        } = await getNumberSessionsByRequest(parameters);

        this.local.total = result.rowCount;

        this.local.rows = result.rows.map((row: Customer & { membershipEndDate: string }) => {
            return {
                ...row,
                status: {
                    icon: this.getCustomerChurnedStatus(row),
                    tooltip: this.getCustomerChurnedTooltip(row),
                },
            };
        });

        if (this.local && this.local.rows.indexOf(this.local.selected!) == -1) {
            this.local.selected = undefined;
        }
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        this.local = {
            ...this.local,
            sortField: field,
            sortOrderAsc: order === 'asc',
            page: 1,
        };
        this.loadAsyncData();
        // setQueryParameters(this, parameters);
        // this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.page')
    async onPageChange(page: number) {
        this.local.page = page;
        this.loadAsyncData();
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    onRowClick(row: Customer): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    getStatusBadgeClass(customer: Customer): string {
        return getHoldingFeeBadgeClass(customer);
    }

    getCustomerChurnedStatus(customer: Customer & { membershipEndDate: string }) {
        if (!customer.membershipEndDate) {
            return 'is-success';
        }
        if (
            utc(customer.membershipEndDate).isSameOrAfter(utc(this.local.monthFilter).startOf('month')) &&
            utc(customer.membershipEndDate).isSameOrBefore(utc(this.local.monthFilter).endOf('month'))
        ) {
            return 'is-warning';
        } else if (utc(customer.membershipEndDate).isSameOrBefore(utc(this.local.monthFilter).startOf('month'))) {
            // It should not happen. Just in case
            return 'is-danger';
        } else {
            return 'is-success';
        }
    }

    getCustomerChurnedTooltip(customer: Customer & { membershipEndDate: string }) {
        const status = this.getCustomerChurnedStatus(customer);
        if (status === 'is-warning') {
            return this.$t('field.incomingChurnedCustomer').toString();
        } else if (status === 'is-danger') {
            return this.$t('field.churnedCustomer').toString();
        } else {
            return this.$t('field.activeCustomer').toString();
        }
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }

    get descriptionParams() {
        return {
            month: utc(this.local.monthFilter).locale(this.$i18n.locale).format('MM.YYYY'),
            startDate: utc(this.local.monthFilter).startOf('month').locale(this.$i18n.locale).format('DD.MM'),
            endDate: utc(this.local.monthFilter).endOf('month').locale(this.$i18n.locale).format('DD.MM'),
        };
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
