import { Vue } from 'vue/types/vue';
import { isNil } from 'lodash';

export function getFieldNameFromVModelProperty(component: Vue) {
    const data = component.$vnode.data;
    if (data && (data as any).model && (data as any).model.expression && isNil((component as any).name)) {
        const vModel = (data as any).model.expression as string;
        return vModel.substring(vModel.lastIndexOf('.') + 1);
    } else {
        return (component as any).name;
    }
}

export function setQueryParameter(parameterName: string, parameterValue: string | undefined, component: Vue) {
    const query = { ...component.$route.query };

    if (parameterValue) {
        query[parameterName] = parameterValue;
    } else {
        delete query[parameterName];
    }

    component.$router.replace({ query });
}

export function getQueryParameter(queryParameterName: string, component: Vue) {
    const query = { ...component.$route.query };

    return !isNil(query[queryParameterName]) ? query[queryParameterName].toString() : undefined;
}
