import { Scopes } from '../../framework/model/Scopes';

export function getEmptyApplicationScopes(scopes: Scopes): Scopes {
    return {
        ...scopes,
        coach: {
            customerId: [],
        },
    };
}
