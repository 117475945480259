import { apiGet } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

export async function getNumberSessionsByRequest(
    parameters: Map<string, string>,
    page?: number,
    pageSize?: number,
): Promise<any> {
    const queryParameters = parameters
        ? Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    const response = await apiGet(`/api/customer/without-sales-invoice?${queryParameters}`, {
        page,
        pageSize,
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
