<template>
    <div class="container-fluid panel mx-10-fullhd">
        <section class="section">
            <h1 class="title">{{ $t('title.memberships') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add" v-if="isAdmin">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="detail" :disabled="!local.selected">
                    <b-icon icon="eye-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.detail') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <div class="row" style="margin-bottom: 10px">
                <button class="button is-ghost is-hidden-desktop" @click="onShowFilter">
                    >> {{ local.showFilter ? $t('button.hideFilters') : $t('button.showFilters') }}
                </button>
                <div class="columns" v-if="local.showFilter">
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="customer"
                            v-model="customerIds"
                            id-field="customerIdIn"
                            search-field="firstName"
                            name="customer"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="coach"
                            v-model="coachIds"
                            id-field="coachIdIn"
                            search-field="firstName"
                            name="coach"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-3">
                        <SelectField
                            name="priceType"
                            v-model="local.parameters.priceType"
                            :options="local.priceTypeOptions"
                        />
                    </div>
                </div>
            </div>
            <div class="table-container">
                <b-table
                    :backend-sorting="true"
                    :backend-pagination="true"
                    :hoverable="true"
                    :striped="true"
                    :paginated="true"
                    :scrollable="true"
                    :sticky-header="true"
                    :current-page="local.parameters.page"
                    :data="local.rows"
                    :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                    :loading="local.loading"
                    :pagination-position="'top'"
                    :per-page="local.parameters.pageSize"
                    :selected.sync="local.selected"
                    :total="local.total"
                    @page-change="onPageChange"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left>
                        <div class="content">
                            <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id" :visible="isAdmin" width="5%">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="customer"
                        :label="$t('field.customer').toString()"
                        :sortable="true"
                    >
                        {{ getCustomerLabel(props.row.customerId) || '-' }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="startDate"
                        :label="$t('field.startDate').toString()"
                        :sortable="true"
                    >
                        {{ dateToDateString(props.row.startDate) || '-' }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="endDate"
                        :label="$t('field.endDate').toString()"
                        :sortable="true"
                    >
                        {{ props.row.endDate ? dateToDateString(props.row.endDate) : '-' }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="productType" :label="$t('field.type').toString()">
                        {{ getProductTypeLabel(props.row.productType) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="priceType" :label="$t('field.priceType').toString()">
                        {{ getPriceTypeOptionLabel(props.row.priceType) }}
                    </b-table-column>
                </b-table>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { getCustomerOptions, Option } from '../../../service/application_options_service';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { Membership } from '../../../../../common/application/model/membership';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { formatToFinnishDate } from '../../../service/helper_service';
import { getDatabaseOptions, getEnumerationOptions, getOptionLabel } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
    },
})
export default class Memberships extends Vue {
    readonly resourceType = ApplicationResource.MEMBERSHIP;

    shared = sharedState;
    local = {
        rows: new Array<Membership>(),
        total: 0,
        loading: false,
        selected: undefined as Membership | undefined,
        parameters: {} as QueryParameters,
        productTypeOptions: [] as Option[],
        customerOptions: [] as Option[],
        priceTypeOptions: [] as Option[],
        showFilter: window.innerWidth >= 1023,
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this).filter(
            (option) =>
                option.id === ProductType.MEMBERSHIP ||
                option.id === ProductType.MASSAGE ||
                option.id === ProductType.HYBRID_MEMBERSHIP ||
                option.id === ProductType.MANAGEMENT_COACHING ||
                option.id === ProductType.ONLINE_COACHING ||
                option.id === ProductType.SUPERCELL_MEMBERSHIP,
        );
        this.local.priceTypeOptions = await getDatabaseOptions('MembershipPriceType', this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${this.resourceType}/add`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${this.resourceType}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this);

        this.local.total =
            (await getResourcePaging(this.resourceType, parameters)).rowCount;

        this.local.rows = [];

        const rows: Membership[] = (await getResources(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
        )) as Array<Membership>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }

        this.local.customerOptions = await getCustomerOptions();
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    onPageChange(page: number) {
        setQueryParameter(this, 'page', page.toString());
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.parameters.priceType')
    async selectedPriceTypeChange() {
        if (this.local.loading) {
            return;
        }

        if (!this.local.parameters.priceType) {
            delete this.local.parameters.priceType;
        }
        await this.loadAsyncData();
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    onRowClick(row: Membership): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    dateToDateString(timestamp: string) {
        const date = new Date(timestamp);
        return formatToFinnishDate(date);
    }

    dropdownClosed(resourceIds: string[], idField: string): void {
        setQueryParameter(this, `${idField}`, resourceIds.join(','), true);
        this.local.parameters = getQueryParameters(this);
    }

    get customerIds(): string[] {
        return this.local.parameters.customerIdIn ? this.local.parameters.customerIdIn.split(',') : [];
    }

    get coachIds(): string[] {
        return this.local.parameters.coachIdIn ? this.local.parameters.coachIdIn.split(',') : [];
    }

    getProductTypeLabel(productTypeId: string): string | undefined {
        const productType = this.local.productTypeOptions.find(({ id }) => id === productTypeId);
        return productType ? productType.label : '-';
    }

    getCustomerLabel(customerId: string): string | undefined {
        const customer = this.local.customerOptions.find(({ id }) => id === customerId);
        return customer ? customer.label : '-';
    }

    onShowFilter() {
        this.local.showFilter = !this.local.showFilter;
    }

    getPriceTypeOptionLabel(id: string) {
        return getOptionLabel(id, this.local.priceTypeOptions);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
