import moment from 'moment';

export function constructMonthsFromNowString(offset: number): string {
    return moment().add(offset, 'months').endOf('month').format('YYYY-MM-DD');
}

export const getLastBusinessDayOfTheMonth = (date: Date): Date => {
    const lastDayOfTheMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //Get last business day of the month
    if (lastDayOfTheMonth.getDay() === 0) {
        return new Date(lastDayOfTheMonth.setDate(lastDayOfTheMonth.getDate() - 2));
    } else if (lastDayOfTheMonth.getDay() === 6) {
        return new Date(lastDayOfTheMonth.setDate(lastDayOfTheMonth.getDate() - 1));
    } else {
        return lastDayOfTheMonth;
    }
}
