import { sharedState } from '../../state';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { NavigationItem } from '../../navigation/NavigationItem';
import Space from '../../../../common/framework/model/Space';
import { getAccessibleSpaces } from '../../service/resource_service';
import { getCurrentSpaceId, setCurrentSpaceId } from './space_service';
import { FrameworkUserRole } from '../../../../common/framework/enumeration/FrameworkUserRole';

const spaceSelectionNavigationItems: NavigationItem[] = [];
const spaceSelectionNavigationMenu: NavigationItem = {
    key: 'spaceSelection',
    icon: 'map-outline',
    label: undefined,
    labelKey: '',
    url: undefined,
    dividerBefore: false,
    get visible() {
        return (
            (sharedState.enabled(FrameworkFeature.XR_SPACES) || sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
            (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.XR_SPACES_PUBLIC_LIST)) &&
            spaceSelectionNavigationItems.length > 0
        );
    },
    children: spaceSelectionNavigationItems,
};

const spaceNavigationItems: NavigationItem[] = [];
const spaceNavigationMenu: NavigationItem = {
    key: 'space',
    icon: 'map',
    label: undefined,
    labelKey: 'nav.company',
    url: undefined,
    dividerBefore: false,
    get visible() {
        return (
            (sharedState.enabled(FrameworkFeature.XR_SPACES) || sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
            (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.ANONYMOUS_ACCESS)) &&
            typeof getCurrentSpaceId() !== 'undefined'
        );
    },
    children: spaceNavigationItems,
};

const spaceConfigurationNavigationItems: NavigationItem[] = [];
const spaceConfigurationNavigationMenu: NavigationItem = {
    key: 'spaceConfiguration',
    icon: 'wrench-outline',
    label: undefined,
    labelKey: 'nav.configuration',
    url: undefined,
    dividerBefore: false,
    get visible() {
        return (
            (sharedState.admin ||
                sharedState.hasRole(FrameworkUserRole.EDITOR) ||
                sharedState.hasRole(FrameworkUserRole.MANAGER)) &&
            (sharedState.enabled(FrameworkFeature.DATA_SPACES) || sharedState.enabled(FrameworkFeature.XR_SPACES))
        );
    },
    children: spaceConfigurationNavigationItems,
};

export async function registerSpaceNavigation() {
    await loadSpaceNavigationItems();
}

let loading = false;

export async function loadSpaceNavigationItems() {
    if (loading) {
        return;
    }

    sharedState.navigationStartMenus.unshift(spaceConfigurationNavigationMenu);
    sharedState.navigationStartMenus.unshift(spaceNavigationMenu);
    sharedState.navigationEndMenus.unshift(spaceSelectionNavigationMenu);

    console.log('clearing space navigation.');
    spaceSelectionNavigationItems.splice(0, spaceSelectionNavigationItems.length);
    spaceNavigationItems.splice(0, spaceNavigationItems.length);
    spaceConfigurationNavigationItems.splice(0, spaceConfigurationNavigationItems.length);

    console.log('loading space navigation.');
    if (
        (sharedState.enabled(FrameworkFeature.XR_SPACES) || sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
        (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.XR_SPACES_PUBLIC_LIST))
    ) {
        loading = true;
        const spaces = await getAccessibleSpaces();
        if (spaces.length > 0) {
            await setCurrentSpaceId(spaces[0].id);
        }
        console.log('adding spaces:' + spaces.length);
        for (const space of spaces) {
            spaceSelectionNavigationItems.push({
                key: space.key,
                icon: 'map-outline',
                label: space.name,
                labelKey: undefined,
                url: `/space/${space.id}/view`,
                dividerBefore: false,
                visible: true,
                children: [],
            });
        }

        loading = false;
    }

    if (
        (sharedState.enabled(FrameworkFeature.XR_SPACES) || sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
        (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.ANONYMOUS_ACCESS))
    ) {
        const spaceHomeItem: NavigationItem = {
            key: 'spaceHome',
            icon: 'home-outline',
            label: undefined,
            labelKey: 'nav.home',
            get url() {
                return `/space/${getCurrentSpaceId()}/view`;
            },
            dividerBefore: false,
            get visible() {
                return (
                    (sharedState.enabled(FrameworkFeature.XR_SPACES) ||
                        sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
                    (!sharedState.anonymous || sharedState.enabled(FrameworkFeature.ANONYMOUS_ACCESS)) &&
                    typeof getCurrentSpaceId() !== 'undefined'
                );
            },
            children: [],
        };
        spaceNavigationItems.push(spaceHomeItem);
    }

    if (
        (sharedState.enabled(FrameworkFeature.XR_SPACES) || sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
        sharedState.admin
    ) {
        const settingsItems = sharedState.navigationEndMenus.filter((m) => m.key === 'settings')[0].children;
        settingsItems.unshift({
            key: 'spaces',
            icon: 'inbox-multiple-outline',
            label: undefined,
            labelKey: 'nav.spaces',
            url: '/space',
            dividerBefore: false,
            get visible() {
                return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
            },
            children: [],
        });
    }

    if (
        (sharedState.enabled(FrameworkFeature.XR_SPACES) || sharedState.enabled(FrameworkFeature.DATA_SPACES)) &&
        (sharedState.admin ||
            sharedState.hasRole(FrameworkUserRole.EDITOR) ||
            sharedState.hasRole(FrameworkUserRole.MANAGER))
    ) {
        const configurationItems = spaceConfigurationNavigationMenu.children;
        configurationItems.unshift({
            key: 'entities',
            icon: 'sim',
            label: undefined,
            labelKey: 'nav.entities',
            url: '/entity',
            dividerBefore: false,
            get visible() {
                return (
                    (sharedState.admin ||
                        sharedState.hasRole(FrameworkUserRole.EDITOR) ||
                        sharedState.hasRole(FrameworkUserRole.MANAGER)) &&
                    sharedState.enabled(FrameworkFeature.ADMIN_API) &&
                    sharedState.enabled(FrameworkFeature.XR)
                );
            },
            children: [],
        });
    }

    console.log('added spaces:' + spaceSelectionNavigationItems.length);
    //(spaceNavigationMenu as any).visible = spaceNavigationItems.length > 0;
}

export function setSpaceNavigationMenuLabel(space: Space) {
    (spaceNavigationMenu as any).label = space.name;
}
