<template>
    <validation-provider
        :name="getFieldName().toString()"
        :rules="{ required: false }"
        v-slot="{ errors, valid }"
        tag="div"
    >
        <b-field
            :label="String($t('field.' + getFieldName())).toString()"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :horizontal="horizontal"
            :message="errors"
        >
            <b-switch :type="local.value ? 'is-success' : 'is-default'" v-if="!readonly" :name="getFieldName()" v-model="local.value" :disabled="disabled === true">
            </b-switch>
            <span v-if="readonly">{{ local.value }}</span>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class SwitchField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Boolean) readonly value!: boolean;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        value: undefined as boolean | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: boolean) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: boolean) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
