<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item :label="$t('title.info').toString()" icon="information-outline" class="custom-tab-item">
                        <h1 class="title">{{ $t('title.addSalesInvoiceRow') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="product"
                                        v-model="local.row.productId"
                                        resource="product"
                                        field="name"
                                        :query="productsSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.quantity" :required="true" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.unit"
                                        :options="local.unitOptions"
                                        :required="true"
                                        expanded
                                        name="unit"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.unitPrice" :required="true" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField
                                        v-model="local.row.discountPercent"
                                        :required="true"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.vatPercent" :required="true" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.vatStatus" :required="true" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(add)"
                                :disabled="!passes"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.add') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(addAndClose)"
                                :disabled="!passes"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.addAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../../../framework/fields/TextField.vue';
import SelectField from '../../../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../../../framework/fields/SwitchField.vue';

import { sharedState } from '../../../../../../framework/state';
import { getResource, postResource } from '../../../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../../../common/framework/enumeration/FrameworkUserRole';
import { SalesInvoiceRow } from '../../../../../../../common/application/model/sales_invoice_row';
import NumberField from '../../../../../../framework/fields/NumberField.vue';
import { getSimpleEnumerationOptions } from '../../../../../../framework/service/options';
import { InvoiceRowUnit } from '../../../../../../../common/application/model/enums/procountor/invoice_row_unit';
import AutoCompleteField from '../../../../../../framework/fields/AutoCompleteField.vue';
import { getProductsSelectorParams } from '../../../../../service/helper_service';
import { Option } from '../../../../../service/application_options_service';
import { Product } from '../../../../../../../common/application/model/product';

@Component({
    components: { SelectField, TextField, SwitchField, ValidationObserver, NumberField, AutoCompleteField },
})
export default class SalesInvoiceRowDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.SALES_INVOICE_ROW;

    // Properties
    @Prop(String) readonly salesInvoiceId!: string;

    // State
    shared = sharedState;
    local = {
        row: {
            id: '',
            created: new Date(),
            modified: new Date(),
            salesInvoiceId: this.salesInvoiceId,
            productId: '',
            unit: InvoiceRowUnit.PIECE,
            unitPrice: 1,
            vatPercent: 25.5,
            quantity: 1,
            discountPercent: 0,
            vatStatus: 1,
        } as SalesInvoiceRow,
        unitOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.unitOptions = getSimpleEnumerationOptions('InvoiceRowUnit', Object.keys(InvoiceRowUnit), this);
        this.local.row = {
            ...this.local.row,
            salesInvoiceId: this.salesInvoiceId,
        };
    }

    add() {
        this.addResource(false);
    }

    addAndClose() {
        this.addResource(true);
    }

    @Watch('local.row.productId')
    async monthFilterChanged() {
        if (!this.local.row.productId) {
            return;
        }
        const product = (await getResource<Product>(ApplicationResource.PRODUCT, this.local.row.productId))!!;

        if (product && product.active) {
            this.local.row = { ...this.local.row, unitPrice: product.price, vatPercent: product.vatPercentage };
        }
    }

    async addResource(goBack: boolean) {
        try {
            const newSalesInvoiceRow = await postResource<SalesInvoiceRow>(this.resourceType, this.local.row);
            successToast(this, 'message.customerSaved');

            if (goBack) {
                this.back();
            } else {
                await this.$router.replace(`/${this.resourceType}/${newSalesInvoiceRow.id}`);
            }
        } catch (error) {
            errorToast(this);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    back() {
        this.$router.go(-1);
    }

    get productsSelectorParams() {
        return getProductsSelectorParams(this.shared.context.scopes, this.local.row);
    }
}
</script>
