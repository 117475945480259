<template>
    <div class="container-fluid panel">
        <section class="section">
            <h1 class="title">{{ $t('title.customers') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add" v-if="isAdmin">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="view" :disabled="!local.selected" v-if="isCoach">
                    <b-icon icon="eye-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.detail') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
                <button class="button" @click="confirmCreateMonthlyInvoice" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.addMonthlyInvoice') }}</span>
                </button>
            </div>

            <div class="row" style="margin-bottom: 10px">
                <button class="button is-ghost is-hidden-desktop" @click="onShowFilter">
                    >> {{ local.showFilter ? $t('button.hideFilters') : $t('button.showFilters') }}
                </button>
                <div class="columns" v-if="local.showFilter">
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="coach"
                            v-model="coachIds"
                            id-field="coachId"
                            search-field="firstName,lastName"
                            name="coach"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            resource="physiotherapist"
                            v-model="physiotherapistIds"
                            id-field="physiotherapistId"
                            search-field="firstName,lastName"
                            name="physiotherapist"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-2">
                        <b-tooltip
                            :label="$t('message.searchFirstNameLastNameEmail')"
                            :active="local.showSearchInfo"
                            position="is-bottom"
                            type="is-info"
                            multilined
                        >
                            <b-field :label="String($t('field.search')).toString()" type="is-info">
                                <b-input
                                    :placeholder="$t('field.search')"
                                    v-model="local.parameters.textSearchPatternsNew"
                                    :expanded="true"
                                    @focus="local.showSearchInfo = true"
                                    @blur="local.showSearchInfo = false"
                                ></b-input>
                            </b-field>
                        </b-tooltip>
                    </div>
                    <div class="column is-2">
                        <SwitchField v-model="local.parameters.invoiceManually" :disabled="local.loading" />
                    </div>
                    <div class="column is-2">
                        <SwitchField v-model="local.hasBankSessions" />
                    </div>
                </div>
            </div>
            <div class="table-container">
                <b-table
                    :backend-sorting="true"
                    :backend-pagination="true"
                    :hoverable="true"
                    :striped="true"
                    :paginated="true"
                    :scrollable="true"
                    :sticky-header="true"
                    :current-page="local.parameters.page"
                    :data="local.rows"
                    :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                    :loading="local.loading"
                    :pagination-position="'top'"
                    :per-page="local.parameters.pageSize"
                    :selected.sync="local.selected"
                    :total="local.total"
                    @page-change="onPageChange"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left>
                        <div class="content">
                            <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id" width="5%">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>
                    <b-table-column field="churnedCustomer" sortable v-slot="props">
                        <b-tooltip :label="getCustomerChurnedTooltip(props.row)" position="is-bottom">
                            <b-icon icon="clock-alert-outline" :type="getCustomerChurnedStatus(props.row)"></b-icon>
                        </b-tooltip>
                    </b-table-column>
                    <b-table-column v-slot="props" field="id2" width="5%">
                        <b-tag v-bind:class="getStatusBadgeClass(props.row)">
                            {{ props.row.holdingFee ? $t('button.holdingFee') : $t('button.noHoldingFee') }}
                        </b-tag>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="firstName"
                        :label="String($t('field.firstName')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.firstName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="lastName"
                        :label="String($t('field.lastName')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.lastName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="customerNumber"
                        :label="String($t('field.customerNumber')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.customerNumber }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="coach"
                        :label="String($t('field.coach')).toString()"
                        :sortable="true"
                    >
                        {{ getCoachLabel(props.row.coachId) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="physiotherapist"
                        :label="String($t('field.physiotherapist')).toString()"
                    >
                        {{ getPhysiotherapistLabel(props.row.physiotherapistId) }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="bankSessions"
                        :label="String($t('field.bankSessions')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.bankSessions }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="freeSessions"
                        :label="String($t('field.freeSessions')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.freeSessions }}
                    </b-table-column>
                </b-table>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePagingNew, getResourcesNew } from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';

import { Customer } from '../../../../../common/application/model/customer';
import { CustomerType } from '../../../../../common/application/model/enums/CustomerType';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { getResourceLabels } from '../../../../framework/service/label_service';
import { debounce } from 'lodash';
import { P_PAGE, P_PAGE_SIZE } from '../../../../../common/framework/constants';
import {
    getCoachResourceLabel,
    getHoldingFeeBadgeClass,
    getPhysiotherapistResourceLabel,
} from '../../../service/helper_service';
import { postMonthlySalesInvoiceRequest } from '../../../client/sales_invoice_client';
import { AddMonthlySalesInvoiceReq } from '../../../../../common/application/model/api/AddMonthlySalesInvoiceReq';
import moment, { Moment } from 'moment';
import { Option } from '../../../service/application_options_service';
import { getEnumerationOptions } from '../../../../framework/service/options';
import { CustomerState } from '../../../../../common/application/model/enums/CustomerState';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { Membership } from '../../../../../common/application/model/membership';
import { Physiotherapist } from '../../../../../common/application/model/physiotherapist';
import { Coach } from '../../../../../common/application/model/coach';
import { getQueryParameter } from '../../../../framework/util/component_util';

interface CustomersLocalState {
    rows: Customer[];
    total: number;
    loading: boolean;
    selected: Customer | undefined;
    parameters: QueryParameters;
    coachLabels: Map<string, string>;
    showSearchInfo: boolean;
    searchAbleColumns: string[];
    customerStateOptions: Option[];
    showFilter: boolean;
    hasBankSessions: boolean;
    lastDayOfCurrentMonth: Moment;
    memberships: Membership[];
    physiotherapistLabels: Map<string, string>;
}

const searchAbleCustomerColumns: string[] = ['firstName', 'lastName', 'email', 'customerNumber'];

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
    },
})
export default class Customers extends Vue {
    readonly resourceType = ApplicationResource.CUSTOMER;

    shared = sharedState;
    local: CustomersLocalState = {
        rows: [],
        total: 0,
        loading: false,
        selected: undefined,
        parameters: {} as QueryParameters,
        coachLabels: new Map<string, string>(),
        showSearchInfo: false,
        searchAbleColumns: searchAbleCustomerColumns,
        customerStateOptions: [],
        showFilter: window.innerWidth >= 1023,
        hasBankSessions: false,
        lastDayOfCurrentMonth: moment().endOf('month'),
        memberships: [],
        physiotherapistLabels: new Map(),
    };

    async mounted() {
        console.log('mounted');
        this.local.loading = true;

        this.local.customerStateOptions = getEnumerationOptions('CustomerState', Object.keys(CustomerState), this);
        this.local.parameters = getQueryParameters(this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${ApplicationResource.CUSTOMER}/add`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${ApplicationResource.CUSTOMER}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.CUSTOMER}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-warning',
            onConfirm: async () => {
                if (!this.local.selected) {
                    return;
                }

                try {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    successToast(this, 'message.resourceDeleted');
                    await this.loadAsyncData();
                } catch (e) {
                    errorToast(this);
                }
            },
        });
    }

    confirmCreateMonthlyInvoice() {
        let startDate: Date;

        const lastDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        if (moment(new Date()).isSame(lastDayOfCurrentMonth, 'day')) {
            startDate = moment().startOf('month').toDate(); // First day of this month
        } else {
            startDate = moment().subtract(1, 'month').startOf('month').toDate(); // First day of last month
        }

        const month = startDate.getMonth().toString();

        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmCreateMonthlyInvoice').toString(),
            message:
                this.$t('message.confirmCreateMonthlyInvoice').toString() +
                this.$t(`enum.month.${month}`).toString() +
                '?',
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (!this.local.selected) {
                    return;
                }
                try {
                    const request: AddMonthlySalesInvoiceReq = {
                        customerId: this.local.selected.id,
                        startDate,
                    };
                    const invoiceId = (await postMonthlySalesInvoiceRequest(request)).addedSalesInvoiceId;
                    successToast(this, 'message.invoiceCreated');
                    await this.$router.push(`/${ApplicationResource.SALES_INVOICE}/${invoiceId}`);
                } catch (e) {
                    errorToast(this);
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this, this.local.searchAbleColumns.join(','));
        parameters.set(P_PAGE, this.local.parameters.page ? this.local.parameters.page : 1);
        parameters.set(P_PAGE_SIZE, this.local.parameters.pageSize ? this.local.parameters.pageSize : 100000);
        if (getQueryParameter('invoiceManually', this) === 'true') {
            parameters.set('invoiceManually', 'true');
        }

        let query = '';

        if (this.local.hasBankSessions) {
            parameters.set('bankSessions', '0');
            query = '(bankSessions>{bankSessions} OR bankSessions<{bankSessions})';
        }

        if (this.local.parameters.textSearchPatternsNew) {
            parameters.set('textSearchPatternsNew', `%${this.local.parameters.textSearchPatternsNew}%`);

            const textSearchConditions = searchAbleCustomerColumns.map((column) => {
                return `unaccent(${column}) ILIKE unaccent({textSearchPatternsNew})`;
            });
            const textSearchQuery = '(' + textSearchConditions.join(' OR ') + ')';

            query = (query.length > 0 ? query + ' AND ' : '') + textSearchQuery;
        }

        const [totalResult, customersResult] = await Promise.allSettled([
            getResourcePagingNew(this.resourceType, parameters, query),
            getResourcesNew<Customer>(this.resourceType, this.local.parameters.page - 1, parameters, query),
        ]);

        this.local.total = 0;
        this.local.rows = [];

        if (totalResult.status === 'fulfilled') {
            this.local.total = totalResult.value.rowCount;
        }

        if (customersResult.status === 'fulfilled') {
            this.local.rows = customersResult.value;
        }

        const [coachLabelResults, physiotherapistLabelsResults, membershipResults] = await Promise.allSettled([
            getResourceLabels<Coach>(this.local.rows, 'coachId', ApplicationResource.COACH, getCoachResourceLabel),
            this.getPhysiotherapistLabels(this.local.rows),
            this.local.rows.length > 0
                ? getResourcesNew<Membership>(
                      ApplicationResource.MEMBERSHIP,
                      -1,
                      new Map<string, any>([
                          ['customerId', this.local.rows.map(({ id }) => id)],
                          ['pageSize', '100000'],
                      ]),
                  )
                : new Promise<Membership[]>((resolve) => resolve([])),
        ]);

        if (coachLabelResults.status === 'fulfilled') {
            this.local.coachLabels = coachLabelResults.value;
        }

        if (physiotherapistLabelsResults.status === 'fulfilled') {
            this.local.physiotherapistLabels = physiotherapistLabelsResults.value;
        }

        if (membershipResults.status === 'fulfilled') {
            this.local.memberships = membershipResults.value;
        }

        //Remove customers that are churned in Coach view
        let churnedCustomers = 0;
        if (this.isCoach) {
            // Create a map for efficient lookup
            const membershipMap = new Map(
                this.local.memberships.map((membership) => [membership.customerId, membership]),
            );

            this.local.rows = this.local.rows.filter((row) => {
                const membership = membershipMap.get(row.id);
                if (
                    membership &&
                    membership.endDate &&
                    moment(membership.endDate).endOf('month').isBefore(this.local.lastDayOfCurrentMonth, 'day')
                ) {
                    churnedCustomers++;
                    return false; // Exclude this row
                }
                return true; // Include this row
            });
        }

        this.local.total -= churnedCustomers;
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    @Watch('local.parameters.customerType')
    selectedCustomerTypeChange(selectedCustomerType: CustomerType) {
        if (this.local.loading) {
            return;
        }

        setQueryParameter(this, 'customerType', selectedCustomerType, true);
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.parameters.invoiceManually')
    async invoiceManuallySwitchChanged(value: boolean) {
        setQueryParameter(this, 'invoiceManually', value ? 'true' : undefined, false);
        this.local.parameters.invoiceManually = value ? true : undefined;
    }

    @Watch('local.hasBankSessions')
    async hasBankSessionsSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    @Watch('local.parameters.textSearchPatternsNew')
    searchChange(searchString: string) {
        if (this.local.loading) {
            return;
        }

        this.deBounceSearch(searchString);
    }

    private deBounceSearch = debounce((searchString: string) => {
        this.setSearch(searchString);
    }, 1200);

    private async setSearch(searchString: string | undefined) {
        if (searchString === '') {
            delete this.local.parameters.textSearchPatternsNew;
        } else {
            this.local.parameters.textSearchPatternsNew = searchString;
        }

        await this.loadAsyncData();
    }

    dropdownClosed(resourceIds: string[], idField: string): void {
        setQueryParameter(this, `${idField}`, resourceIds, true);
        this.local.parameters = getQueryParameters(this);
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.CUSTOMER}/${this.local.selected.id}`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isCoach(): boolean {
        return this.shared.hasRole(ApplicationUserRole.COACH);
    }

    onRowClick(row: Customer): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    get coachIds() {
        if (Array.isArray(this.local.parameters.coachId)) {
            return this.local.parameters.coachId;
        } else {
            return this.local.parameters.coachId ? this.local.parameters.coachId.split(',') : [];
        }
    }

    get physiotherapistIds() {
        if (Array.isArray(this.local.parameters.physiotherapistId)) {
            return this.local.parameters.physiotherapistId;
        } else {
            return this.local.parameters.physiotherapistId ? this.local.parameters.physiotherapistId.split(',') : [];
        }
    }

    getCoachLabel(id: string): string | undefined {
        return this.local.coachLabels.get(id);
    }

    getPhysiotherapistLabel(id: string): string | undefined {
        return this.local.physiotherapistLabels.get(id);
    }

    private getPhysiotherapistLabels(rows: Customer[]) {
        return getResourceLabels<Physiotherapist>(
            rows,
            'physiotherapistId',
            ApplicationResource.PHYSIOTHERAPIST,
            getPhysiotherapistResourceLabel,
        );
    }

    getStatusBadgeClass(customer: Customer): string {
        return getHoldingFeeBadgeClass(customer);
    }

    onShowFilter() {
        this.local.showFilter = !this.local.showFilter;
    }

    getCustomerChurnedStatus(customer: Customer) {
        const membership = this.local.memberships.find((membership) => customer.id === membership.customerId);
        if (membership && membership.endDate) {
            if (moment(membership.endDate).endOf('month').isSame(this.local.lastDayOfCurrentMonth, 'day')) {
                return 'is-warning';
            } else if (moment(membership.endDate).endOf('month').isBefore(this.local.lastDayOfCurrentMonth, 'day')) {
                return 'is-danger';
            } else {
                return 'is-success';
            }
        }
        return 'is-success';
    }

    getCustomerChurnedTooltip(customer: Customer) {
        const status = this.getCustomerChurnedStatus(customer);
        if (status === 'is-warning') {
            return this.$t('field.incomingChurnedCustomer').toString();
        } else if (status === 'is-danger') {
            return this.$t('field.churnedCustomer').toString();
        } else {
            return this.$t('field.activeCustomer').toString();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
