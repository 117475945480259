import { sharedState } from '../framework/state';
import { FrameworkFeature } from '../../common/framework/enumeration/FrameworkFeature';
import { ApplicationUserRole } from '../../common/application/enumeration/ApplicationUserRole';

export async function registerApplicationNavigation() {
    sharedState.navigationEndMenus.unshift({
        icon: 'database',
        key: 'configuration',
        label: undefined,
        labelKey: 'nav.configuration',
        url: undefined,
        dividerBefore: false,
        get visible() {
            return (
                (sharedState.admin ||
                    sharedState.hasRole(ApplicationUserRole.COACH) ||
                    sharedState.hasRole(ApplicationUserRole.PHYSIOTHERAPIST)) &&
                sharedState.enabled(FrameworkFeature.ADMIN_API)
            );
        },
        children: [
            {
                icon: undefined,
                key: 'customers',
                label: undefined,
                labelKey: 'nav.customers',
                url: '/customer',
                dividerBefore: false,
                get visible() {
                    return (
                        (sharedState.admin ||
                            sharedState.hasRole(ApplicationUserRole.COACH) ||
                            sharedState.hasRole(ApplicationUserRole.PHYSIOTHERAPIST)) &&
                        sharedState.enabled(FrameworkFeature.ADMIN_API)
                    );
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'business-partners',
                label: undefined,
                labelKey: 'nav.businessPartners',
                url: '/business-partner',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'coaches',
                label: undefined,
                labelKey: 'nav.coaches',
                url: '/coach',
                dividerBefore: false,
                get visible() {
                    return (
                        sharedState.admin ||
                        (sharedState.hasRole(ApplicationUserRole.COACH) &&
                            sharedState.enabled(FrameworkFeature.ADMIN_API))
                    );
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'physiotherapists',
                label: undefined,
                labelKey: 'nav.physiotherapists',
                url: '/physiotherapist',
                dividerBefore: false,
                get visible() {
                    return (
                        (sharedState.admin || sharedState.hasRole(ApplicationUserRole.PHYSIOTHERAPIST)) &&
                        sharedState.enabled(FrameworkFeature.ADMIN_API)
                    );
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'sessions',
                label: undefined,
                labelKey: 'nav.sessions',
                url: '/session',
                dividerBefore: false,
                get visible() {
                    return (
                        (sharedState.admin ||
                            sharedState.hasRole(ApplicationUserRole.COACH) ||
                            sharedState.hasRole(ApplicationUserRole.PHYSIOTHERAPIST)) &&
                        sharedState.enabled(FrameworkFeature.ADMIN_API)
                    );
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'products',
                label: undefined,
                labelKey: 'nav.products',
                url: '/product',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'memberships',
                label: undefined,
                labelKey: 'nav.memberships',
                url: '/membership',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'coach-tasks',
                label: undefined,
                labelKey: 'nav.coachTasks',
                url: '/coach-task',
                dividerBefore: false,
                get visible() {
                    return (
                        (sharedState.admin ||
                            sharedState.hasRole(ApplicationUserRole.COACH) ||
                            sharedState.hasRole(ApplicationUserRole.PHYSIOTHERAPIST)) &&
                        sharedState.enabled(FrameworkFeature.ADMIN_API)
                    );
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'sales-invoices',
                label: undefined,
                labelKey: 'nav.salesInvoices',
                url: '/sales-invoice',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                icon: undefined,
                key: 'reports',
                label: undefined,
                labelKey: 'nav.reports',
                url: '/reports',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            // TODO: re-enable this when applicable
            // {
            //     icon: undefined,
            //     key: 'files',
            //     label: undefined,
            //     labelKey: 'nav.files',
            //     url: '/file',
            //     dividerBefore: true,
            //     get visible() { return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API); },
            //     children: [],
            // }
        ],
    });
}
