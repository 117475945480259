import { getEmptyScopes } from './framework_scope_service';
import { BrowserContext } from '../model/BrowserContext';

export function getAnonymousContext(): BrowserContext {
    return {
        userId: 'anonymous',
        email: 'anonymous',
        roles: [],
        groups: [],
        idToken: '',
        serviceLogo: '',
        serviceName: '',
        serviceTitle: '',
        version: '',
        locale: '',
        scopes: getEmptyScopes(),
    };
}
