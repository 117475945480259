import { getEmptyApplicationScopes } from '../../application/service/application_scope_service';
import { Scopes } from '../model/Scopes';

export function getEmptyFrameworkScopes(scopes: Scopes): Scopes {
    return {
        ...scopes,
        manager: {
            spaceId: [],
            customerId: [],
        },
        editor: {
            spaceId: [],
        },
        viewer: {
            spaceId: [],
        },
    };
}

export function getEmptyScopes(): Scopes {
    let scopes = {} as Scopes;
    scopes = getEmptyApplicationScopes(scopes);
    scopes = getEmptyFrameworkScopes(scopes);
    return scopes;
}
