<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item :label="$t('title.info').toString()" icon="information-outline" class="custom-tab-item">
                        <h1 class="title">{{ $t('title.membershipDetail') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="customer"
                                        v-model="local.row.customerId"
                                        resource="customer"
                                        field="firstName"
                                        :query="customersSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.productType"
                                        name="type"
                                        :options="local.productTypeOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.priceType"
                                        name="priceType"
                                        :options="local.priceTypeOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateField v-model="local.row.startDate" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <DateField v-model="local.row.endDate" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div v-if="local.initial.endDate === null && local.row.endDate !== null" class="columns">
                                <div class="column is-12 has-text-danger">
                                    {{ $t('message.sessionDeletionWarning') }}
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <TextAreaField v-model="local.row.notes" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(save)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(saveAndClose)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import DateField from '../../../../framework/fields/DateField.vue';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { Option } from '../../../service/application_options_service';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import { Membership } from '../../../../../common/application/model/membership';
import { getCustomersSelectorParams, getProductsSelectorParams } from '../../../service/helper_service';
import { getDatabaseOptions, getEnumerationOptions, OptionValue } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';

@Component({
    components: { TextAreaField, SelectField, TextField, ValidationObserver, AutoCompleteField, DateField },
})
export default class MembershipDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.MEMBERSHIP;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        initial: {} as Membership,
        row: {} as Membership,
        productTypeOptions: [] as Option[],
        priceTypeOptions: [] as OptionValue[],
    };

    // Functions
    async mounted() {
        const row = (await getResource<Membership>(this.resourceType, this.id))!!;

        this.local.initial = structuredClone(row);
        this.local.row = structuredClone(row);
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this).filter(
            (option) =>
                option.id === ProductType.MEMBERSHIP ||
                option.id === ProductType.MASSAGE ||
                option.id === ProductType.HYBRID_MEMBERSHIP ||
                option.id === ProductType.MANAGEMENT_COACHING ||
                option.id === ProductType.ONLINE_COACHING ||
                option.id === ProductType.SUPERCELL_MEMBERSHIP,
        );

        this.local.priceTypeOptions = await getDatabaseOptions('MembershipPriceType', this);
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async saveResource(goBack: boolean) {
        try {
            const updatedRow = await putResource<Membership>(this.resourceType, this.id, this.local.row);
            this.local.initial = updatedRow;

            successToast(this, 'message.membershipSaved');

            if (goBack) {
                this.back();
            }
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }

    get customersSelectorParams() {
        return getCustomersSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get productSelectorParams() {
        return getProductsSelectorParams(this.shared.context.scopes, this.local.row);
    }
}
</script>
