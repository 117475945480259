export enum FrameworkFeature {
    TERMS_OF_SERVICE = 'terms-of-service',
    PRIVACY_POLICY = 'privacy-policy',
    ADMIN_API = 'admin-api',
    ANONYMOUS_ACCESS = 'anonymous-access',
    PASSWORD_AUTHENTICATION = 'password-authentication',
    SELF_REGISTRATION = 'self-registration',
    AUTO_GRANT_VIEWER_ROLE = 'auto-grant-viewer-role',
    SAML_AUTHENTICATION = 'saml-authentication',
    GOOGLE_AUTHENTICATION = 'google-authentication',
    MICROSOFT_AUTHENTICATION = 'microsoft-authentication',
    /**
     * @deprecated
     */
    VOICE_CHAT = 'voice-chat',
    /**
     * @deprecated
     */
    XR = 'xr',
    XR_SPACES = 'xr-spaces',
    XR_SPACES_PUBLIC_LIST = 'xr-spaces-public-list',
    /**
     * @deprecated
     */
    XR_BAKES = 'xr-bakes',
    DATA_SPACES = 'data-spaces',
}
