import moment, { Moment, utc } from 'moment';
import { SalesInvoiceRow } from '../../../common/application/model/sales_invoice_row';
import { Scopes } from '../../../common/framework/model/Scopes';
import { DateTime } from 'luxon';
import { Coach } from '../../../common/application/model/coach';
import { Customer } from '../../../common/application/model/customer';
import { Physiotherapist } from '../../../common/application/model/physiotherapist';

export const searchAbleCustomerCoachColumns: string[] = ['firstName', 'lastName', 'email'];

export const getCustomersSelectorParams = (scopes: Scopes, row: any): Map<string, string> => {
    const parameters = new Map<string, string>();
    if (!row.customerId) {
        if (scopes.manager && scopes.manager.customerId) {
            const managerCustomers = scopes.manager.customerId.join(',');
            if (managerCustomers) {
                parameters.set('customerIdIn', managerCustomers);
            }
        }
    } else if (row.customerId) {
        parameters.set('customerIdIn', row.customerId);
    } else {
        parameters.set('customerIdIn', row.customerIdIn);
    }

    return parameters;
};

export const getCoachesSelectorParams = (scopes: Scopes, row: any): Map<string, string> => {
    const parameters = new Map<string, string>();

    if (row.coachId) {
        parameters.set('coachIdIn', row.coachId);
    } else {
        parameters.set('coachIdIn', row.coachIdIn);
    }

    return parameters;
};

export const getCoachResourceLabel = (resource: Coach) => `${resource.firstName} ${resource.lastName}`;
export const getCustomerResourceLabel = (resource: Customer) => `${resource.firstName} ${resource.lastName}`;
export const getPhysiotherapistResourceLabel = (resource: Physiotherapist) =>
    `${resource.firstName} ${resource.lastName}`;

export const getPhysiotherapistsSelectorParams = (scopes: Scopes, row: any): Map<string, string> => {
    const parameters = new Map<string, string>();

    if (row.coachId) {
        parameters.set('physiotherapist', row.physiotherapist);
    } else {
        parameters.set('physiotherapist', row.physiotherapist);
    }

    return parameters;
};

export const getProductsSelectorParams = (scopes: Scopes, row: any): Map<string, string> => {
    const parameters = new Map<string, string>();
    if (!row.productId) {
        if (scopes.manager && scopes.manager.productId) {
            const managerProducts = scopes.manager.productId.join(',');
            if (managerProducts) {
                parameters.set('productIdIn', managerProducts);
            }
        }
    } else if (row.productId) {
        parameters.set('productIdIn', row.productId);
    } else {
        parameters.set('productIdIn', row.productIdIn);
    }

    return parameters;
};

export function formatCurrency(value: number | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' }).format(value);
    }
}

export function formatNumber(value: number | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.NumberFormat('fi-FI').format(value);
    }
}

export function formatToFinnishDate(value: Date | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.DateTimeFormat('fi-FI').format(value);
    }
}

export function formatToFinnishDateTime(value: Date | null | undefined) {
    if (typeof value === 'undefined' || value === null) {
        return '-';
    } else {
        return new Intl.DateTimeFormat('fi-FI', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).format(value);
    }
}

export function sortAlphabetically(sortProperty: string) {
    return (itemA: any, itemB: any) => {
        const nameA = itemA[sortProperty].toLowerCase();
        const nameB = itemB[sortProperty].toLowerCase();

        return nameA.localeCompare(nameB);
    };
}

export function roundCurrencyValue(currencyValue: number) {
    return parseFloat(Number(Math.round(currencyValue * 100) / 100).toFixed(2));
}

export function convertLocalDateToUtc(date: Date): Date {
    const utcOffset = moment(date).utcOffset();
    const utcStartOfDay = utc(date).add(utcOffset, 'minutes').startOf('day');

    return utcStartOfDay.toDate();
}

export function calculateInvoiceRowSums(row: SalesInvoiceRow): {
    sumVat: number;
    sumVatExcluded: number;
    sumVatIncluded: number;
} {
    const sumVat = roundCurrencyValue((row.quantity * row.unitPrice * row.vatPercent) / 100);
    const sumVatExcluded = roundCurrencyValue(row.quantity * row.unitPrice);
    const sumVatIncluded = roundCurrencyValue(sumVat + sumVatExcluded);

    return {
        sumVat,
        sumVatExcluded,
        sumVatIncluded,
    };
}

export const getHoldingFeeBadgeClass = (object: any): string => {
    if (object.holdingFee) {
        return 'is-success';
    } else {
        return 'is-warning';
    }
};

export const UtcToLocalZoneWithoutChangingTime = (date: Date, timezone: string): Date => {
    return DateTime.fromISO(date.toString(), {
        zone: 'utc',
    })
        .setZone(timezone, { keepLocalTime: true })
        .toJSDate();
};

/**
 *
 * @param firstDate
 * @param lastDate
 */
export const getDateRange = (firstDate: Moment, lastDate: Moment): Moment[] => {
    const start = firstDate.clone();
    const end = lastDate.clone();
    if (start.isAfter(end, 'day')) {
        return [];
    }

    if (start.isSame(end, 'day')) {
        return [end];
    }

    const dates: Moment[] = [start.clone()];

    while (dates[dates.length - 1].isBefore(end, 'day')) {
        dates.push(dates[dates.length - 1].clone().add(1, 'day'));
    }
    return dates;
};

/**
 *
 * @param dateToAdjust
 * @param shift Can be also a negative number
 */
export const shiftMonth = (dateToAdjust: Moment, shift: number): Moment => {
    if (shift > 0) {
        return dateToAdjust.clone().add(shift, 'month');
    } else if (shift < 0) {
        return dateToAdjust.clone().subtract(Math.abs(shift), 'month');
    }

    return dateToAdjust.clone();
};
