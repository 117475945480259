<template>
    <section>
        <validation-observer v-slot="{ passes }">
            <div>
                <h2 class="title">{{ $t('title.documents') }}</h2>
                <div class="column is-12">
                    <b-field class="file is-primary">
                        <b-upload
                            :id="'fileBinaryUpload'"
                            v-model="local.droppedDocuments"
                            class="file-label"
                            rounded
                            accept=".pdf,.png,.jpeg,.jpg"
                            required
                            multiple
                            drag-drop
                            expanded
                            :loading="local.uploading"
                        >
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon icon="upload"></b-icon>
                                    </p>
                                    <p>{{ $t('field.uploadDocuments') }}</p>
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>

                <div class="columns" v-for="(document, index) in local.droppedDocuments" :key="document.lastModified">
                    <div class="column is-5">
                        <TextField v-model="document.uploadName" :required="true" :maxlength="100" />
                        <span v-if="local.droppedDocuments" class="tag is-primary is-small is-rounded">
                            {{ document.name }}
                            <button
                                class="delete is-small"
                                type="button"
                                @click="deleteDroppedDocument(document, index)"
                            ></button>
                        </span>
                        <br />
                        <br />
                        <b-field :label="$t('field.category').toString()">
                            <Multiselect
                                v-model="local.categories[index]"
                                :tag-placeholder="$t('field.tagPlaceholderCategory')"
                                :placeholder="$t('field.placeholderCategory')"
                                :select-label="$t('field.selectLabel')"
                                :deselect-label="$t('field.deselectLabel')"
                                :options="local.categoryOptions.map((category) => category.label)"
                                :multiple="false"
                                :taggable="true"
                                @tag="addTag($event)"
                            ></Multiselect>
                        </b-field>
                    </div>

                    <div class="column is-7">
                        <TextAreaField
                            name="description"
                            :required="false"
                            :maxlength="5000"
                            v-model="document.description"
                        />
                    </div>
                </div>

                <div class="column is-12">
                    <b-button
                        class="button is-primary is-fullwidth login-button space-2"
                        icon-left="content-save-outline"
                        @click="passes(add)"
                        :disabled="local.invalidBtn"
                    >
                        <span>{{ $t('button.add') }}</span>
                    </b-button>
                </div>
            </div>
            <ViewDocuments
                :resource-id="resourceId"
                :resource-type="resourceType"
                ref="documents"
                @activeTab="getActiveTab"
            />
        </validation-observer>
    </section>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';
import { getDatabaseOptions, OptionValue } from '../../../../framework/service/options';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { FileMeta } from '../../../../../common/application/model/FileMeta';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import TextField from '../../../../framework/fields/TextField.vue';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';
import { ValidationObserver } from 'vee-validate';
import { Option } from '../../../../../common/framework/model/Option';
import { errorToast, successToast } from '../../../service/toast_service';
import ViewDocuments from './ViewDocuments.vue';
import { Document } from '../../../../../common/application/model/Document';

@Component({
    components: {
        TextField,
        ValidationObserver,
        Multiselect,
        TextAreaField,
        ViewDocuments,
    },
})
export default class AddDocument extends Vue {
    // Properties
    @Prop(String) readonly resourceId!: string;
    @Prop(String) readonly resourceType!: string;
    @Prop(String) readonly customerId!: string;
    @Prop(Function) readonly uploadCallBack!: (fileId: string) => Promise<any>;

    override $refs!: {
        documents: ViewDocuments;
        multiselect: Multiselect;
    };

    // State
    shared = sharedState;
    local = {
        total: 0,
        loading: false,
        droppedDocuments: null as Document[] | null, //array that contains document objects that user just drop in the page
        categoryOptions: [] as OptionValue[],
        newUploadingDocument: { public: false } as FileMeta, //object that will be uploaded to S3 and file_meta table
        invalidBtn: true,
        categories: [] as string[],
        uploading: false,
    };

    // Functions
    async mounted() {
        this.local.loading = true;
        await this.loadAsyncData();
        this.local.loading = false;
    }

    async loadAsyncData() {
        this.local.newUploadingDocument = {
            ...this.local.newUploadingDocument,
            userId: this.shared.context.userId,
        };

        this.local.categoryOptions = (await getDatabaseOptions('DocumentCategory', this)).filter(
            ({ id }) => id !== undefined,
        );
    }

    async addTag(label: string) {
        const newOption = {
            id: '',
            type: 'document-category',
            key: label,
            index: 1,
            metadata: {
                labelEn: label,
                labelFi: label,
            },
            created: new Date(),
            modified: new Date(),
        };
        const option = await postResource<Option>('option', newOption);
        this.local.categoryOptions.push({
            id: option.id,
            label: option.key,
        });
    }

    async add() {
        try {
            if (!this.local.droppedDocuments) {
                return;
            } else {
                for (const [index, document] of this.local.droppedDocuments.entries()) {
                    const documentType = document.name.substring(document.name.lastIndexOf('.') + 1);
                    if (!document.uploadName.includes('.' + documentType)) {
                        document.uploadName = document.uploadName + '.' + documentType;
                    }

                    this.local.newUploadingDocument = {
                        ...this.local.newUploadingDocument,
                        id: '',
                        created: new Date(),
                        modified: new Date(),
                        name: document.uploadName,
                        mimeType: document.type,
                        fileExtension: documentType,
                        size: document.size,
                        category: document.category,
                        description: document.description || '',
                        resourceId: this.resourceId,
                        resourceType: this.resourceType as ApplicationResource,
                        customerId: this.customerId ? this.customerId : null,
                    };
                    const uploadedDocument = await postResource<FileMeta>(
                        ApplicationResource.FILE_META,
                        this.local.newUploadingDocument,
                    );
                    this.upload(uploadedDocument, index);
                    if (this.uploadCallBack) {
                        await this.uploadCallBack(uploadedDocument.id);
                    }
                }
            }
        } catch (error) {
            console.warn(error);
            errorToast(this);
        }
    }

    @Watch('local.droppedDocuments')
    selectedDocument() {
        if (this.local.droppedDocuments) {
            if (this.local.droppedDocuments.length > this.local.categories.length) {
                this.local.categories.push('Other');
            }
            for (let document of this.local.droppedDocuments) {
                if (!document.uploadName) {
                    document.uploadName = document.name;
                }
            }
        }
    }

    @Watch('local.categories')
    async validateBtn() {
        for (const category of this.local.categories) {
            if (!category) {
                this.local.invalidBtn = true;
                return;
            }
        }
        this.local.invalidBtn = false;
    }

    @Watch('local.categories')
    updateValueAction() {
        if (this.local.droppedDocuments) {
            this.local.droppedDocuments.map((document, index) => (document.category = this.local.categories[index]));
        }
    }

    @Watch('local.uploading')
    async successToastNotification() {
        if (!this.local.uploading) {
            await this.resetPagingAndLoadData();
            await (this.$refs.documents as any).resetPagingAndLoadData();
            successToast(this, 'message.documentSaved');
            return;
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async resetPagingAndLoadData() {
        this.local.newUploadingDocument = { public: false } as FileMeta;
        this.local.droppedDocuments = null;
        this.local.categories = [];
        await this.loadAsyncData();
    }

    private upload(uploadedDocument: FileMeta, index: number) {
        this.local.uploading = true;

        try {
            if (this.local.droppedDocuments) {
                const formData = new FormData();
                let file: any = this.local.droppedDocuments[index];
                let name = file.uploadName;
                file = new File([file], name, { type: file.type });

                formData.append('fileBinary', file);
                formData.append('fileId', uploadedDocument.id);

                const xhr = new XMLHttpRequest();

                xhr.addEventListener('loadend', () => {
                    this.local.uploading = false;
                });

                xhr.addEventListener('error', () => {
                    errorToast(this);
                    this.local.uploading = false;
                });

                xhr.addEventListener('abort', () => {
                    this.local.uploading = false;
                });

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status !== 200) {
                            console.warn('Upload returned error status: ' + xhr.status);
                            errorToast(this);
                        }
                    }
                };

                xhr.open(
                    'POST',
                    '/api/file/resource/' + this.resourceType + '/category/' + uploadedDocument.category,
                    true,
                );
                xhr.setRequestHeader('Authorization', 'Bearer ' + sharedState.context.idToken);
                xhr.send(formData);
            }
        } catch (error) {
            console.warn(error);
            errorToast(this);
        }
    }

    async deleteDroppedDocument(deleteDocument: any, index: number) {
        if (this.local.droppedDocuments) {
            if (this.local.droppedDocuments.length === 1) {
                await this.resetPagingAndLoadData();
            }
            this.local.droppedDocuments = this.local.droppedDocuments.filter(
                (document) => document.lastModified !== deleteDocument.lastModified,
            );
            this.local.categories.splice(index, 1);
        }
    }

    getActiveTab(value: number) {
        localStorage.setItem(`${this.resourceType}_ActiveTab`, JSON.stringify(value));
    }
}
</script>
