<template>
    <div class="container panel">
        <section class="section">
            <h1 class="title">{{ $t('title.users') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="edit" :disabled="!local.selected">
                    <b-icon icon="pencil-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.edit') }}</span>
                </button>
                <button class="button" @click="changePassword" :disabled="!local.selected">
                    <b-icon icon="pencil-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.changePassword') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <div class="columns">
                <div class="column is-3">
                    <TextComponent name="email" v-model="local.selectedUserEmailPrefix" />
                </div>
                <div class="column is-3">
                    <TextComponent name="lastName" v-model="local.selectedUserLastNamePrefix" />
                </div>
                <div class="column is-3">
                    <TextComponent name="firstName" v-model="local.selectedUserFirstNamePrefix" />
                </div>
            </div>

            <div class="buttons has-addons">
                <b-switch v-model="local.showLocked">
                    {{ $t('button.showLocked') }}
                </b-switch>
                <b-switch v-model="local.lockUsersExceptAdmins">
                    {{ $t('button.lockUsersExceptAdmins') }}
                </b-switch>
            </div>
            <b-table
                :hoverable="true"
                :paginated="true"
                :backend-pagination="true"
                :backend-sorting="true"
                :striped="true"
                :selected.sync="local.selected"
                :data="local.rows"
                :loading="local.loading"
                :total="local.total"
                :per-page="local.parameters.get('pageSize')"
                :current-page="local.page"
                :pagination-position="'top'"
                :default-sort="[local.parameters.get('sortField'), local.parameters.get('sortOrder')]"
                @page-change="onPageChange"
                @click="onRowClick"
                @sort="onSortChange"
            >
                <b-table-column v-slot="props" field="email" :label="$t('field.email').toString()" :sortable="true">
                    {{ props.row.email }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="lastName"
                    :label="$t('field.lastName').toString()"
                    :sortable="true"
                >
                    {{ props.row.lastName }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="firstName"
                    :label="$t('field.firstName').toString()"
                    :sortable="true"
                >
                    {{ props.row.firstName }}
                </b-table-column>
                <b-table-column v-slot="props" field="locked" :label="$t('field.locked').toString()">
                    <b-checkbox v-model="!!props.row.locked" disabled></b-checkbox>
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

import { sharedState } from '../../../state';
import { deleteUser, postToggleLockUsersExceptAdmins } from '../../../client/users';
import { UserIdentity } from '../../../../../common/framework/model/UserIdentity';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';

import TextComponent from '../../../fields/TextComponent.vue';

import { addDelayedWindowResizeListenerFunction } from '../../../service/window_resize_service';
import { getResourcePaging, getResources } from '../../../client/resource';
import { PageSize } from '../../../../../common/application/model/enums/PageSize';

@Component({
    components: { TextComponent },
})
export default class Users extends Vue {
    shared = sharedState;
    local = {
        rows: new Array<UserIdentity>(),
        total: 0,
        loading: false,
        page: 1,
        parameters: new Map(
            Object.entries({
                pageSize: PageSize.S,
                sortField: 'lastName',
                sortOrder: 'asc' as SortOrder,
            }),
        ),
        selected: undefined as UserIdentity | undefined,
        showLocked: false,
        selectedUserEmailPrefix: undefined as string | undefined,
        selectedUserFirstNamePrefix: undefined as string | undefined,
        selectedUserLastNamePrefix: undefined as string | undefined,
        lockUsersExceptAdmins: false,
    };

    @Watch('local.showLocked')
    async showLockedChanged() {
        await this.loadAsyncData();
    }

    @Watch('local.lockUsersExceptAdmins')
    async lockUsersExceptAdminsChanged() {
        await postToggleLockUsersExceptAdmins(this.local.lockUsersExceptAdmins);
        await this.loadAsyncData();
    }

    async mounted() {
        addDelayedWindowResizeListenerFunction(this, this.loadAsyncData);
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/users/add-user');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/users/edit-user/' + this.local.selected.id);
        }
    }

    changePassword() {
        if (this.local.selected) {
            this.$router.push('/users/change-user-password/' + this.local.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDeleteUser').toString(),
            message: this.$t('message.confirmDeleteUser').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteUser(this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    @Watch('local.selectedUserEmailPrefix')
    @Watch('local.selectedUserFirstNamePrefix')
    @Watch('local.selectedUserLastNamePrefix')
    async selectedAssetNameChange() {
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        await this.debouncedLoad(this.local.parameters);
    }

    debouncedLoad = debounce(async (parameters) => {
        if (this.local.loading) {
            return;
        }

        this.local.loading = true;

        const queryParts: Array<string> = [];
        parameters.delete('query');
        parameters.delete('email');
        parameters.delete('firstName');
        parameters.delete('lastName');

        if (this.local.selectedUserEmailPrefix) {
            queryParts.push('email ILIKE {email}');
            parameters.set('email', '%' + this.local.selectedUserEmailPrefix + '%');
        }
        if (this.local.selectedUserFirstNamePrefix) {
            queryParts.push('firstName ILIKE {firstName}');
            parameters.set('firstName', '%' + this.local.selectedUserFirstNamePrefix + '%');
        }
        if (this.local.selectedUserLastNamePrefix) {
            queryParts.push('lastName ILIKE {lastName}');
            parameters.set('lastName', '%' + this.local.selectedUserLastNamePrefix + '%');
        }

        if (this.local.showLocked) {
            queryParts.push('locked={showLocked}');
            parameters.set('showLocked', 'true');
        }

        if (queryParts.length > 0) {
            parameters.set('query', queryParts.join(' AND '));
        }

        this.local.total =
            (await getResourcePaging(FrameworkResource.USER, parameters)).pageCount * parameters.get('pageSize');

        this.local.rows = await getResources<UserIdentity>(FrameworkResource.USER, this.local.page - 1, parameters);

        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }, 300);

    onSortChange(field: string, order: SortOrder) {
        this.local.parameters.set('sortField', field);
        this.local.parameters.set('sortOrder', order);
        this.local.page = 1;
        this.loadAsyncData();
    }

    onRowClick(row: UserIdentity): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
</script>
