<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="box">
                    <h1 class="title">{{ $t('title.passwordChange') }}</h1>

                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <password-field name="ownPassword" v-model="local.oldPassword" :required="true" />

                        <password-field name="newPassword" v-model="local.password" :required="true" />
                        <password-field name="newPasswordAgain" v-model="local.password2" :required="true" />

                        <button class="button is-primary is-fullwidth save-button" @click="passes(submit)">
                            <b-icon icon="content-save-outline"></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>
                    </ValidationObserver>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import PasswordField from '../../../fields/PasswordField.vue';
import { sharedState } from '../../../state';
import { postUserPasswordChange } from '../../../client/password';

const localState = {
    oldPassword: '',
    password: '',
    password2: '',
};

@Component({
    components: { PasswordField, ValidationObserver },
})
export default class ChangeUserPassword extends Vue {
    override $refs!: {
        observer: Vue;
    };

    @Prop(String) public readonly id!: string;

    data() {
        return {
            shared: sharedState,
            local: localState,
        };
    }

    async submit() {
        if (localState.password == localState.password2) {
            await this.changePassword();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordsDoNotMatch').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async changePassword() {
        if (await postUserPasswordChange(localState.oldPassword, this.id, localState.password)) {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChanged').toString(),
                type: 'is-success',
                position: 'is-top',
            });
            localState.oldPassword = '';
            localState.password = '';
            localState.password2 = '';
            (this.$refs.observer as any).reset();
            this.$router.go(-1);
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChangeFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }
}
</script>

<style scoped>
.save-button {
    margin: 1rem 0 0;
}
</style>
