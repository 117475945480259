<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed" v-if="!local.loading">
                    <b-tab-item :label="$t('title.info').toString()" icon="information-outline" class="custom-tab-item">
                        <div class="columns">
                            <div class="column is-8">
                                <h1 class="title">{{ $t('title.salesInvoiceDetail') }}</h1>
                            </div>
                            <div class="column is-4">
                                <b-button
                                    size="is-small"
                                    type="is-primary"
                                    @click="refreshStatus"
                                    v-if="isAdmin"
                                    class="ml-4 my-2"
                                    :disabled="!local.row.procountorId"
                                    >{{ $t('button.refreshStatus') }}
                                </b-button>
                                <b-button
                                    size="is-small"
                                    type="is-primary"
                                    @click="confirmSendSalesInvoiceToProcountor"
                                    v-if="isAdmin"
                                    class="ml-4 my-2"
                                    :disabled="!local.salesInvoiceRows.length"
                                >
                                    <b-icon icon="send" size="is-small"></b-icon>
                                    <span>{{ $t('button.sendToProcountor') }}</span>
                                </b-button>
                            </div>
                        </div>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.procountorId" disabled />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.status"
                                        :options="local.statusOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateField v-model="local.row.date" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="customer"
                                        v-model="local.row.customerId"
                                        resource="customer"
                                        field="firstName"
                                        :query="customersSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateField v-model="local.row.invoicingPeriodStart" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <DateField v-model="local.row.invoicingPeriodEnd" required :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <template #trigger="props">
                                            <div
                                                class="card-header"
                                                role="button"
                                                aria-controls="contentIdForA11y3"
                                                :aria-expanded="props.open"
                                            >
                                                <p class="card-header-title">
                                                    {{ $t('field.counterParty') }}
                                                </p>
                                                <a class="card-header-icon">
                                                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                                                </a>
                                            </div>
                                        </template>

                                        <div class="card-content" v-if="local.row.paymentInfo">
                                            <div class="columns">
                                                <div class="column is-12">
                                                    <b-collapse
                                                        class="card"
                                                        animation="slide"
                                                        aria-id="contentIdForA11y3"
                                                    >
                                                        <template #trigger="props">
                                                            <div
                                                                class="card-header"
                                                                role="button"
                                                                aria-controls="contentIdForA11y3"
                                                                :aria-expanded="props.open"
                                                            >
                                                                <p class="card-header-title">
                                                                    {{ $t('field.counterPartyAddress') }}
                                                                </p>
                                                                <a class="card-header-icon">
                                                                    <b-icon
                                                                        :icon="props.open ? 'menu-down' : 'menu-up'"
                                                                    ></b-icon>
                                                                </a>
                                                            </div>
                                                        </template>

                                                        <div class="card-content" v-if="local.row.counterParty">
                                                            <div class="columns">
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.counterPartyAddress
                                                                                .name
                                                                        "
                                                                        :required="true"
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.counterPartyAddress
                                                                                .street
                                                                        "
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="columns">
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.counterPartyAddress
                                                                                .zip
                                                                        "
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.counterPartyAddress
                                                                                .city
                                                                        "
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-collapse>
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-12" v-if="local.row.counterParty.einvoiceAddress">
                                                    <b-collapse
                                                        class="card"
                                                        animation="slide"
                                                        aria-id="contentIdForA11y3"
                                                    >
                                                        <template #trigger="props">
                                                            <div
                                                                class="card-header"
                                                                role="button"
                                                                aria-controls="contentIdForA11y3"
                                                                :aria-expanded="props.open"
                                                            >
                                                                <p class="card-header-title">
                                                                    {{ $t('field.einvoiceAddress') }}
                                                                </p>
                                                                <a class="card-header-icon">
                                                                    <b-icon
                                                                        :icon="props.open ? 'menu-down' : 'menu-up'"
                                                                    ></b-icon>
                                                                </a>
                                                            </div>
                                                        </template>

                                                        <div class="card-content">
                                                            <div class="columns">
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.einvoiceAddress
                                                                                .operator
                                                                        "
                                                                        :required="false"
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.einvoiceAddress
                                                                                .address
                                                                        "
                                                                        :required="false"
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="columns">
                                                                <div class="column is-6">
                                                                    <TextField
                                                                        v-model="
                                                                            local.row.counterParty.einvoiceAddress.ediId
                                                                        "
                                                                        :required="false"
                                                                        :disabled="!isAdmin"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-collapse>
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <TextField
                                                        v-model="local.row.counterParty.email"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                                <div class="column is-6">
                                                    <TextField
                                                        v-model="local.row.counterParty.customerNumber"
                                                        required
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <template #trigger="props">
                                            <div
                                                class="card-header"
                                                role="button"
                                                aria-controls="contentIdForA11y3"
                                                :aria-expanded="props.open"
                                            >
                                                <p class="card-header-title">
                                                    {{ $t('field.paymentInfo') }}
                                                </p>
                                                <a class="card-header-icon">
                                                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                                                </a>
                                            </div>
                                        </template>

                                        <div class="card-content" v-if="local.row.paymentInfo">
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <SelectField
                                                        v-model="local.row.paymentInfo.paymentMethod"
                                                        :options="local.paymentMethodOptions"
                                                        expanded
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                                <div class="column is-6">
                                                    <SelectField
                                                        v-model="local.row.paymentInfo.currency"
                                                        :options="local.currencyOptions"
                                                        :required="true"
                                                        expanded
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <DateField
                                                        v-model="local.row.paymentInfo.dueDate"
                                                        required
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                                <div class="column is-6">
                                                    <NumberField
                                                        v-model="local.row.paymentInfo.currencyRate"
                                                        required
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-12">
                                                    <TextField
                                                        v-model="local.row.paymentInfo.bankAccount.accountNumber"
                                                        :required="true"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <template #trigger="props">
                                            <div
                                                class="card-header"
                                                role="button"
                                                aria-controls="contentIdForA11y3"
                                                :aria-expanded="props.open"
                                            >
                                                <p class="card-header-title">
                                                    {{ $t('field.extraInfo') }}
                                                </p>
                                                <a class="card-header-icon">
                                                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                                                </a>
                                            </div>
                                        </template>

                                        <div class="card-content" v-if="local.row.extraInfo">
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <SwitchField
                                                        v-model="local.row.extraInfo.accountingByRow"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                                <div class="column is-6">
                                                    <SwitchField
                                                        v-model="local.row.extraInfo.unitPricesIncludeVat"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.discountPercent" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.invoiceChannel"
                                        :options="local.invoiceChannelOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.vatStatus" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.language"
                                        :options="local.languageOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.usedFreeSessions" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.usedBankSessions" required :disabled="!isAdmin" />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(save)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(saveAndClose)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                    <b-tab-item
                        :label="$t('title.salesInvoiceRows').toString()"
                        icon="clipboard-text"
                        class="custom-tab-item"
                    >
                        <SalesInvoiceRows :sales-invoice-id="id" @sales-invoice-rows="getSalesInvoiceRows" />
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { isEqual } from 'lodash';

import TextField from '../../../../../framework/fields/TextField.vue';
import SelectField from '../../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../../framework/fields/SwitchField.vue';
import AutoCompleteField from '../../../../../framework/fields/AutoCompleteField.vue';
import DateField from '../../../../../framework/fields/DateField.vue';
import NumberField from '../../../../../framework/fields/NumberField.vue';

import { sharedState } from '../../../../../framework/state';
import { getResource, putResource } from '../../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../../common/framework/enumeration/FrameworkUserRole';
import { InvoiceChannel } from '../../../../../../common/application/model/enums/procountor/invoice_channel';
import { getCustomersSelectorParams } from '../../../../service/helper_service';
import { getSimpleEnumerationOptions } from '../../../../../framework/service/options';
import { SalesInvoice } from 'src/common/application/model/sales_invoice';
import { InvoiceStatus } from '../../../../../../common/application/model/enums/procountor/invoice_status';
import { Currency } from '../../../../../../common/application/model/enums/procountor/currency';
import { InvoiceLanguage } from '../../../../../../common/application/model/enums/procountor/invoice_language';
import { PaymentMethod } from '../../../../../../common/application/model/enums/procountor/payment_method';
import SalesInvoiceRows from './row/SalesInvoiceRows.vue';
import { Option } from '../../../../service/application_options_service';
import {
    getSalesInvoiceFromProcountorRequest,
    postSalesInvoiceToProcountorRequest,
} from '../../../../client/procountor_client';
import { SalesInvoiceRow } from '../../../../../../common/application/model/sales_invoice_row';
import { dateToDbDateString } from '../../../../../../common/framework/util/convert';

@Component({
    components: {
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AutoCompleteField,
        DateField,
        NumberField,
        SalesInvoiceRows,
    },
})
export default class SalesInvoiceDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.SALES_INVOICE;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as SalesInvoice,
        originalRow: {},
        statusOptions: [] as Option[],
        invoiceChannelOptions: [] as Option[],
        paymentMethodOptions: [] as Option[],
        currencyOptions: [] as Option[],
        languageOptions: [] as Option[],
        loading: false,
        salesInvoiceRows: [] as SalesInvoiceRow[],
    };

    // Functions
    async mounted() {
        this.local.loading = true;
        this.local.statusOptions = getSimpleEnumerationOptions('InvoiceStatus', Object.keys(InvoiceStatus), this);
        this.local.invoiceChannelOptions = getSimpleEnumerationOptions(
            'InvoiceChannel',
            Object.keys(InvoiceChannel),
            this,
        );
        this.local.paymentMethodOptions = getSimpleEnumerationOptions(
            'PaymentMethod',
            Object.keys(PaymentMethod),
            this,
        );
        this.local.currencyOptions = getSimpleEnumerationOptions('Currency', Object.keys(Currency), this);
        this.local.languageOptions = getSimpleEnumerationOptions('InvoiceLanguage', Object.keys(InvoiceLanguage), this);
        await this.loadAsyncData();
        this.local.loading = false;
    }

    async loadAsyncData() {
        this.local.row = (await getResource<SalesInvoice>(this.resourceType, this.id))!!;
        /*this.local.row = {
            ...this.local.row,
            invoicingPeriodEnd: UtcToLocalZoneWithoutChangingTime(this.local.row.invoicingPeriodEnd, 'Europe/Helsinki'),
            invoicingPeriodStart: UtcToLocalZoneWithoutChangingTime(
                this.local.row.invoicingPeriodStart,
                'Europe/Helsinki',
            ),
        };*/

        this.local.originalRow = {
            ...this.local.row,
            date: dateToDbDateString(new Date(this.local.row.date)), // dateToDbDateString is because dates of local.row will become string due to DateField.vue
            invoicingPeriodStart: dateToDbDateString(new Date(this.local.row.invoicingPeriodStart)),
            invoicingPeriodEnd: dateToDbDateString(new Date(this.local.row.invoicingPeriodEnd)),
        };
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async saveResource(goBack: boolean) {
        try {
            const updatedRow = await putResource<SalesInvoice>(this.resourceType, this.id, this.local.row);

            successToast(this, 'message.salesInvoiceSaved');

            if (goBack) {
                this.back();
            }
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }

    async refreshStatus() {
        const result = await getSalesInvoiceFromProcountorRequest(this.id);
        if (result) {
            await putResource<SalesInvoice>(this.resourceType, this.id, { ...this.local.row, status: result.status });
        }

        await this.loadAsyncData();
    }

    get customersSelectorParams() {
        return getCustomersSelectorParams(this.shared.context.scopes, this.local.row);
    }

    confirmSendSalesInvoiceToProcountor() {
        if (isEqual(this.local.originalRow, this.local.row)) {
            this.$buefy.dialog.confirm({
                title: this.$t('title.confirmSendMonthlyInvoice').toString(),
                message: this.$t('message.confirmSendMonthlyInvoice').toString(),
                cancelText: this.$t('button.cancel').toString(),
                confirmText: this.$t('button.ok').toString(),
                type: 'is-success',
                onConfirm: async () => {
                    try {
                        const result = await postSalesInvoiceToProcountorRequest(this.id);
                        if (result) {
                            await this.loadAsyncData();
                            successToast(this, 'message.invoiceSent');
                        }
                    } catch (e) {
                        errorToast(this);
                    }
                },
            });
        } else {
            this.$buefy.dialog.confirm({
                title: this.$t('title.wantToSaveChangesAndSendInvoice').toString(),
                message: this.$t('message.wantToSaveChangesAndSendInvoice').toString(),
                cancelText: this.$t('button.cancel').toString(),
                confirmText: this.$t('button.saveAndSend').toString(),
                type: 'is-success',
                onConfirm: async () => {
                    try {
                        await this.saveResource(false);
                        const result = await postSalesInvoiceToProcountorRequest(this.id);
                        if (result) {
                            await this.loadAsyncData();
                            successToast(this, 'message.invoiceSent');
                        }
                    } catch (e) {
                        errorToast(this);
                    }
                },
            });
        }
    }

    getSalesInvoiceRows(value: SalesInvoiceRow[]) {
        this.local.salesInvoiceRows = value;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header,
.collapse {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.11);
}
</style>
