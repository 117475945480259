<template>
    <div>
        <h1 class="title">{{ $t('title.salesInvoiceRows') }}</h1>
        <div class="buttons has-addons">
            <button class="button" @click="add" v-if="isAdmin">
                <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                <span>{{ $t('button.add') }}</span>
            </button>
        </div>
        <b-table
            :backend-pagination="true"
            :current-page="local.page"
            :data="local.rows"
            :loading="local.loading"
            :paginated="true"
            :per-page="local.perPage"
            :scrollable="true"
            :selected.sync="local.selected"
            :pagination-position="'top'"
            :total="local.total"
            @click="onRowClick"
            @page-change="onPageChange"
        >
            <template #top-left>
                <div class="content">
                    <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                </div>
            </template>

            <b-table-column v-slot="props" field="id" width="5%">
                <b-button size="is-small" type="is-primary" @click="detail(props.row.id)">{{
                    $t('button.detail')
                }}</b-button>
            </b-table-column>
            <b-table-column v-slot="props" field="product" :label="$t('field.product').toString()">
                {{ getProductLabel(props.row.productId) }}
            </b-table-column>
            <b-table-column v-slot="props" field="quantity" :label="$t('field.quantity').toString()">
                {{ props.row.quantity }}
            </b-table-column>
            <b-table-column v-slot="props" field="unit" :label="$t('field.unit').toString()">
                {{ props.row.unit }}
            </b-table-column>
            <b-table-column v-slot="props" field="unitPrice" :label="$t('field.unitPrice').toString()">
                {{ formatCurrencyValue(props.row.unitPrice) }}
            </b-table-column>
            <b-table-column v-slot="props" field="vatPercentage" :label="$t('field.vatPercent').toString()">
                {{ props.row.vatPercent }}
            </b-table-column>
        </b-table>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../../../framework/state';

import { deleteResource, getResourcePaging, getResources } from '../../../../../../framework/client/resource';
import { SalesInvoiceRow } from '../../../../../../../common/application/model/sales_invoice_row';
import { ApplicationResource } from '../../../../../../../common/application/enumeration/ApplicationResource';

import { FrameworkUserRole } from '../../../../../../../common/framework/enumeration/FrameworkUserRole';
import { errorToast, successToast } from '../../../../../service/toast_service';
import { formatCurrency } from '../../../../../service/helper_service';
import { PageSize } from '../../../../../../../common/application/model/enums/PageSize';
import { getResourceLabels } from '../../../../../../framework/service/label_service';

@Component
export default class SalesInvoiceRows extends Vue {
    readonly resourceType = ApplicationResource.SALES_INVOICE_ROW;

    @Prop(String) readonly salesInvoiceId!: string;

    shared = sharedState;
    local = {
        rows: new Array<SalesInvoiceRow>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.L,
        selected: undefined as SalesInvoiceRow | undefined,
        productLabels: new Map<string, string>(),
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/sales-invoice/' + this.salesInvoiceId + '/add-row');
    }

    detail(id?: string) {
        if (id) {
            this.$router.push('/sales-invoice-row/' + id);
        } else if (this.local.selected) {
            this.$router.push('/sales-invoice-row/' + this.local.selected.id);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = new Map<string, string>();
        parameters.set('salesInvoiceId', this.salesInvoiceId);

        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType, parameters)).rowCount;
        this.local.rows = [];

        const rows: SalesInvoiceRow[] = await getResources<SalesInvoiceRow>(
            this.resourceType,
            this.local.page - 1,
            parameters,
        );

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        this.$emit('sales-invoice-rows', this.local.rows);

        await this.getProductLabels(rows);

        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/sales-invoice-row/${this.local.selected.id}`);
        }
    }

    onRowClick(row: SalesInvoiceRow) {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    formatCurrencyValue(value: number | null | undefined) {
        return formatCurrency(value);
    }

    getProductLabel(id: string): string | undefined {
        return this.local.productLabels.get(id);
    }

    private async getProductLabels(rows: SalesInvoiceRow[]) {
        this.local.productLabels = await getResourceLabels(rows, 'productId', ApplicationResource.PRODUCT, 'name');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
