<template>
    <div class="is-vertical-center full-height">
        <div class="container">
            <section class="section">
                <div class="columns">
                    <div class="column is-6 is-offset-3 box">
                        <h1 class="title">{{ $t('title.passwordChange') }}</h1>

                        <ValidationObserver v-slot="{ passes }">
                            <password-field v-model="local.password" :required="true" />
                            <password-field name="newPasswordAgain" v-model="local.password2" :required="true" />

                            <button
                                class="button is-primary is-fullwidth login-button"
                                @click="passes(submit)"
                            >
                                <b-icon icon="content-save-outline" ></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                        </ValidationObserver>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../state';
import { postTokenPasswordChange } from '../../client/password';
import { $router } from '../../../main';
import PasswordField from '../../fields/PasswordField.vue';
import { appLogin } from '../../../app_hooks';

@Component({
    components: { PasswordField, ValidationObserver },
})
export default class TokenPasswordChange extends Vue {
    @Prop(String) readonly token!: string;

    shared = sharedState;
    local = {
        password: '',
        password2: '',
    };

    async changePassword() {
        if (await postTokenPasswordChange(this.local.password!!, this.token)) {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChanged').toString(),
                type: 'is-success',
                position: 'is-top',
            });
            this.local.password = '';
            this.local.password2 = '';
            await appLogin();
            await $router.push('/');
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordChangedFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async submit() {
        if (this.local.password == this.local.password2) {
            await this.changePassword();
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.passwordsDoNotMatch').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    mounted() {}
}
</script>

<style scoped>
.login-button {
    margin: 1rem 0 0;
}
</style>
