export enum PaymentMethod {
    BANK_TRANSFER = 'BANK_TRANSFER',
    DIRECT_PAYMENT = 'DIRECT_PAYMENT',
    CLEARING = 'CLEARING',
    CREDIT_CARD_CHARGE = 'CREDIT_CARD_CHARGE',
    FOREIGN_PAYMENT = 'FOREIGN_PAYMENT',
    OTHER = 'OTHER',
    CASH = 'CASH',
    DOMESTIC_PAYMENT_PLUSGIRO = 'DOMESTIC_PAYMENT_PLUSGIRO',
    DOMESTIC_PAYMENT_BANKGIRO = 'DOMESTIC_PAYMENT_BANKGIRO',
    DOMESTIC_PAYMENT_CREDITOR = 'DOMESTIC_PAYMENT_CREDITOR',
    DKLMPKRE = 'DKLMPKRE',
    NETS = 'NETS',
}
