<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item :label="$t('title.info').toString()" icon="information-outline" class="custom-tab-item">
                        <h1 class="title">{{ $t('title.addProduct') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.type"
                                        :options="local.productTypeOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.name"
                                        :required="true"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SwitchField v-model="local.row.active" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.code"
                                        :required="true"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.level" required :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.price" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.vatPercentage" required :disabled="!isAdmin" />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(add)"
                                :disabled="!passes"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.add') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(addAndClose)"
                                :disabled="!passes"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.addAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import DateField from '../../../../framework/fields/DateField.vue';
import NumberField from '../../../../framework/fields/NumberField.vue';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { getEnumerationOptions } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import { Product } from '../../../../../common/application/model/product';
import { Option } from '../../../service/application_options_service';

@Component({
    components: {
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AutoCompleteField,
        DateField,
        NumberField,
    },
})
export default class AddProduct extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.PRODUCT;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: { procountorId: null } as Product,
        productTypeOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this);
    }

    add() {
        this.addResource(false);
    }

    addAndClose() {
        this.addResource(true);
    }

    async addResource(goBack: boolean) {
        try {
            if (!this.local.row.active) {
                this.local.row = { ...this.local.row, active: false };
            }
            const newProduct = await postResource<Product>(this.resourceType, this.local.row);
            successToast(this, 'message.productSaved');

            if (goBack) {
                this.back();
            } else {
                await this.$router.replace(`/${this.resourceType}/${newProduct.id}`);
            }
        } catch (error) {
            errorToast(this);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    back() {
        this.$router.go(-1);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header,
.collapse {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.11);
}
</style>
