<template>
    <div class="space-2">
        <h4 class="title is-4">{{ $t('title.roles') }}</h4>

        <form @submit.prevent="submit">
            <b-field v-for="role in local.roles" :key="role.id">
                <b-checkbox v-model="local.selectedUserRoleIds[role.id]">{{ role.name }}</b-checkbox>
            </b-field>

            <!--<button class="button is-primary is-fullwidth login-button space-2">
<b-icon icon="content-save-outline" ></b-icon>
<span>{{$t('button.save')}}</span>
</button>-->
        </form>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { deleteResource, getResources, postResource } from '../../../client/resource';
import { Role } from '../../../../../common/framework/model/Role';
import { UserRole } from '../../../../../common/framework/model/UserRole';
import { errorToast, successToast } from '../../../../application/service/toast_service';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';

@Component
export default class UserRoles extends Vue {
    readonly resourceType = FrameworkResource.USER_ROLE;

    @Prop(String) readonly id!: string;

    shared = sharedState;
    local = {
        roles: [] as Role[],
        userRoles: [] as UserRole[],
        selectedUserRoleIds: {} as any,
    };

    async mounted() {
        const roles = await getResources<Role>(FrameworkResource.ROLE);

        for (const role of roles) {
            this.local.selectedUserRoleIds[role.id] = false;
        }

        this.local.userRoles = this.id
            ? await getResources<UserRole>(this.resourceType, -1, new Map([['userId', this.id]]))
            : [];

        for (const userRole of this.local.userRoles) {
            this.local.selectedUserRoleIds[userRole.roleId] = true;
        }

        this.local.roles = roles;
    }

    async submit() {
        await this.save();
    }

    async saveWithUserId(userId: string) {
        const selectedRoleIds = [] as string[];
        for (const selectedRoleId in this.local.selectedUserRoleIds) {
            if (this.local.selectedUserRoleIds[selectedRoleId]) {
                selectedRoleIds.push(selectedRoleId);
            }
        }

        const existingRoleIds = this.local.userRoles.map((ug) => ug.roleId);

        for (const selectedRoleId of selectedRoleIds) {
            if (existingRoleIds.indexOf(selectedRoleId) === -1) {
                await postResource<UserRole>(this.resourceType, {
                    id: '',
                    userId: userId,
                    roleId: selectedRoleId,
                    created: new Date(),
                    modified: new Date(),
                });
            }
        }

        for (const existingRoleId of existingRoleIds) {
            if (selectedRoleIds.indexOf(existingRoleId) === -1) {
                try {
                    const userRole = this.local.userRoles.filter((ug) => ug.roleId === existingRoleId)[0];
                    await deleteResource(this.resourceType, userRole.id);
                    successToast(this, 'message.userRoleRemoved');
                } catch (e) {
                    errorToast(this);
                }
            }
        }

        this.local.userRoles = await getResources<UserRole>(this.resourceType, -1, new Map([['userId', userId]]));
    }

    async save() {
        await this.saveWithUserId(this.id);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
