<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.editUser') }}</h1>

                    <ValidationObserver v-slot="{ passes }">
                        <text-field v-model="local.firstName" :maxlength="32" :required="true" />
                        <text-field v-model="local.lastName" :maxlength="32" :required="true" />
                        <email-field v-model="local.email" :maxlength="64" :required="true" />
                        <phone-field v-model="local.phone" :maxlength="30" :required="false" />
                        <select-field
                            name="locale"
                            v-model="local.locale"
                            :options="local.localeOptions"
                            :required="true"
                        />
                        <switch-field v-model="local.locked" :required="true" />

                        <user-roles :id="id" ref="userRoles" />
                        <user-groups :id="id" ref="userGroups" />

                        <button class="button is-primary is-fullwidth login-button space-2" @click="passes(save)">
                            <b-icon icon="content-save-outline"></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>
                    </ValidationObserver>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { UserInfo } from '../../../../../common/framework/model/UserInfo';
import { getUser, putUser } from '../../../client/users';
import UserGroups from './UserGroups.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import UserRoles from './UserRoles.vue';
import { successToast } from '../../../../application/service/toast_service';

@Component({
    components: {
        UserRoles,
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserGroups,
        ValidationObserver,
    },
})
export default class EditUser extends Vue {
    @Prop(String) public readonly id!: string;

    override $refs!: {
        userGroups: UserGroups;
        userRoles: UserRoles;
    };

    shared = sharedState;
    local = {
        localeOptions: [
            { id: 'fi', label: 'FI' },
            { id: 'en', label: 'EN' },
        ],
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        locale: '',
        locked: false,
    };

    public async mounted() {
        const userId = this.$props['id'];
        const user = await getUser(userId);

        this.local.firstName = user.firstName;
        this.local.lastName = user.lastName;
        this.local.email = user.email;
        this.local.phone = user.phone;
        this.local.locale = user.locale as any as string;
        this.local.locked = user.locked;
    }

    public async save() {
        await putUser(
            this.id,
            new UserInfo(
                this.local.firstName,
                this.local.lastName,
                this.local.email,
                this.local.phone,
                this.local.locale,
                this.local.locked,
            ),
        );
        (this.$refs.userGroups as any).save();
        (this.$refs.userRoles as any).save();

        await this.$router.push('/users');

        successToast(this, 'message.userSaved');
    }

    public back() {
        this.$router.go(-1);
    }
}
</script>
