<template>
    <div class="container-fluid panel mx-10-fullhd">
        <section class="section">
            <h1 class="title">{{ $t('title.coaches') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add" v-if="isAdmin">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="view" :disabled="!local.selected" v-if="isManager">
                    <b-icon icon="eye-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.detail') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <div class="row" style="margin-bottom: 10px">
                <button class="button is-ghost is-hidden-desktop" @click="onShowFilter">
                    >> {{ local.showFilter ? $t('button.hideFilters') : $t('button.showFilters') }}
                </button>
                <div class="columns" v-if="local.showFilter">
                    <div class="column is-2">
                        <b-tooltip
                            :label="$t('message.searchFirstNameLastNameEmail')"
                            :active="local.showSearchInfo"
                            position="is-bottom"
                            type="is-info"
                            multilined
                        >
                            <b-field :label="String($t('field.search')).toString()" type="is-info">
                                <b-input
                                    :placeholder="$t('field.search')"
                                    v-model="local.parameters.textSearchPatterns"
                                    :expanded="true"
                                    @focus="local.showSearchInfo = true"
                                    @blur="local.showSearchInfo = false"
                                ></b-input>
                            </b-field>
                        </b-tooltip>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <b-table
                    :backend-sorting="true"
                    :backend-pagination="true"
                    :hoverable="true"
                    :striped="true"
                    :paginated="true"
                    :scrollable="true"
                    :sticky-header="true"
                    :current-page="local.parameters.page"
                    :data="local.rows"
                    :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                    :loading="local.loading"
                    :pagination-position="'top'"
                    :per-page="local.parameters.pageSize"
                    :selected.sync="local.selected"
                    :total="local.total"
                    @page-change="onPageChange"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left>
                        <div class="content">
                            <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id" width="5%">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="firstName"
                        :label="String($t('field.firstName')).toString()"
                        :sortable="true"
                    >
                        <a v-bind:href="'/#/coach/' + props.row.id">{{ props.row.firstName }}</a>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="lastName"
                        :label="String($t('field.lastName')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.lastName }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="email"
                        :label="String($t('field.email')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.email }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="phoneNumber"
                        :label="String($t('field.phoneNumber')).toString()"
                    >
                        {{ props.row.phoneNumber }}
                    </b-table-column>
                </b-table>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { getResourceLabels } from '../../../../framework/service/label_service';
import { searchAbleCustomerCoachColumns } from '../../../service/helper_service';
import { P_TEXT_SEARCH_PATTERN } from '../../../../../common/framework/constants';
import { debounce } from 'lodash';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { Coach } from '../../../../../common/application/model/coach';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
    },
})
export default class Coaches extends Vue {
    readonly resourceType = ApplicationResource.COACH;

    shared = sharedState;
    local = {
        rows: new Array<Coach>(),
        total: 0,
        loading: false,
        selected: undefined as Coach | undefined,
        parameters: {} as QueryParameters,
        groupLabels: new Map<string, string>(),
        showSearchInfo: false,
        searchAbleColumns: searchAbleCustomerCoachColumns,
        showFilter: window.innerWidth >= 1023,
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${ApplicationResource.COACH}/add`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${ApplicationResource.COACH}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.COACH}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this, this.local.searchAbleColumns.join(','));
        this.local.total = (await getResourcePaging(this.resourceType, parameters)).rowCount;

        this.local.rows = [];

        const rows: Coach[] = (await getResources(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
        )) as Array<Coach>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        await this.getGroupLabels(rows);

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    onPageChange(page: number) {
        setQueryParameter(this, 'page', page.toString());
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.parameters.textSearchPatterns')
    searchChange(searchString: string) {
        if (this.local.loading) {
            return;
        }

        this.deBounceSearch(searchString);
    }

    private deBounceSearch = debounce((searchString: string) => {
        this.setSearch(searchString);
    }, 1200);

    private async setSearch(searchString: string | undefined) {
        if (searchString === '') {
            searchString = undefined;
        }

        setQueryParameter(this, P_TEXT_SEARCH_PATTERN, searchString, true);
        await this.loadAsyncData();
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.COACH}/${this.local.selected.id}`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isManager(): boolean {
        return this.shared.hasRole(ApplicationUserRole.COACH) || this.shared.hasRole(FrameworkUserRole.MANAGER);
    }

    onRowClick(row: Coach): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    getGroupLabel(id: string): string | undefined {
        return this.local.groupLabels.get(id);
    }

    private async getGroupLabels(rows: Coach[]) {
        this.local.groupLabels = await getResourceLabels(rows, 'id', FrameworkResource.GROUP, 'name');
    }

    onShowFilter() {
        this.local.showFilter = !this.local.showFilter;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
