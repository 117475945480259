export function successToast(component: any, key: string, params: any[] = []) {
    component.$buefy.toast.open({
        message: component.$t(key, params).toString(),
        type: 'is-success',
        position: 'is-top',
        duration: 4000,
    });
}

export function errorToast(component: any, key?: string) {
    component.$buefy.toast.open({
        message: component.$t(key || 'error.system.error').toString(),
        type: 'is-warning',
        position: 'is-top',
        duration: 4000,
    });
}
