<template>
    <div class="container panel">
        <section class="section">
            <h1 class="title">{{ $t('title.nodes') }}</h1>

            <b-table
                backend-pagination
                hoverable
                paginated
                striped
                :current-page="local.page"
                :data="local.rows"
                :loading="local.loading"
                :per-page="local.perPage"
                :selected.sync="local.selected"
                :total="local.total"
                @page-change="onPageChange"
                @click="onRowClick"
            >
                <b-table-column v-slot="props" field="id" :label="$t('field.id').toString()">
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column v-slot="props" field="online" :label="$t('field.online').toString()">
                    <b-tag :type="getOnlineTagClass(isOnline(props.row.lastHeartBeat))">
                        {{ isOnline(props.row.lastHeartBeat) }}
                    </b-tag>
                </b-table-column>
                <b-table-column v-slot="props" field="web" :label="$t('field.web').toString()">
                    <b-tag :type="getBooleanTagClass(props.row.web)">{{ props.row.web }}</b-tag>
                </b-table-column>
                <b-table-column v-slot="props" field="processor" :label="$t('field.processor').toString()">
                    <b-tag :type="getBooleanTagClass(props.row.processor)">{{ props.row.processor }}</b-tag>
                </b-table-column>
                <b-table-column v-slot="props" field="scheduler" :label="$t('field.scheduler').toString()">
                    <b-tag :type="getBooleanTagClass(props.row.scheduler)">{{ props.row.scheduler }}</b-tag>
                </b-table-column>
                <b-table-column v-slot="props" field="baker" :label="$t('field.baker').toString()">
                    <b-tag :type="getBooleanTagClass(props.row.baker)">{{ props.row.baker }}</b-tag>
                </b-table-column>
                <b-table-column v-slot="props" field="nexus" :label="$t('field.nexus').toString()">
                    <b-tag :type="getBooleanTagClass(props.row.nexus)">{{ props.row.nexus }}</b-tag>
                </b-table-column>
                <b-table-column v-slot="props" field="uptime" :label="$t('field.uptime').toString()">
                    {{ dateDiffToString(props.row.created, props.row.lastHeartBeat) }}
                </b-table-column>
                <b-table-column v-slot="props" field="id" :label="$t('field.softwareVersion').toString()">
                    {{ props.row.softwareVersion }}
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';

import { getResourcePaging, getResources } from '../../../client/resource';
import NodeInstance from '../../../../../common/framework/model/NodeInstance';
import { dateDifferenceToString, dateToUiDateTimeString } from '../../../../../common/framework/util/convert';
import {PageSize} from "../../../../../common/application/model/enums/PageSize";

@Component
export default class Nodes extends Vue {
    readonly resourceType = 'node-instance';

    shared = sharedState;
    local = {
        rows: new Array<NodeInstance>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.S,
        selected: undefined as NodeInstance | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType)).pageCount * this.local.perPage;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as Array<NodeInstance>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    getBooleanTagClass(enabledBoolean: boolean): string {
        return enabledBoolean ? 'is-dark' : 'is-light';
    }

    isOnline(lastHeartBeat: Date): boolean {
        return new Date().getTime() - new Date(lastHeartBeat).getTime() < 30 * 1000;
    }

    getOnlineTagClass(enabledBoolean: boolean): string {
        return enabledBoolean ? 'is-info' : 'is-light';
    }

    dateToString(date: Date) {
        return dateToUiDateTimeString(date);
    }

    dateDiffToString(laterDate: Date, earlierDate: Date) {
        return dateDifferenceToString(new Date(laterDate), new Date(earlierDate));
    }

    onRowClick(row: NodeInstance): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
