import { render, staticRenderFns } from "./CoachTasks.vue?vue&type=template&id=1b933f32&scoped=true"
import script from "./CoachTasks.vue?vue&type=script&lang=ts"
export * from "./CoachTasks.vue?vue&type=script&lang=ts"
import style0 from "./CoachTasks.vue?vue&type=style&index=0&id=1b933f32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b933f32",
  null
  
)

export default component.exports