import { isNil } from 'lodash';

import { jsonRequest } from './index';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import { ServiceRoute } from '../../../common/framework/model/ServiceRoute';

export async function requestService<P = {}, Q = {}, R = {}>(
    route: ServiceRoute,
    queryParameters: P | undefined = undefined,
    query: Q | undefined = undefined,
): Promise<R | undefined> {
    const response = await jsonRequest(
        route.method,
        '/api/service/' + route.type + (isNil(route.resourceId) ? '' : '/' + route.resourceId),
        query,
        queryParameters,
    );
    if (response.status === 200) {
        return (await response.json()) as R;
    } else if (response.status === 404) {
        return undefined;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
