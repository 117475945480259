import { apiDelete, apiGet, apiPost, apiPut } from './index';
import { PagingInfo } from '../../../common/framework/model/PagingInfo';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import { User } from '../../../common/framework/model/User';
import { UserInfo } from '../../../common/framework/model/UserInfo';
import { UserIdentity } from '../../../common/framework/model/UserIdentity';

export async function getUser(id: string): Promise<User> {
    const response = await apiGet('/api/admin/users/' + id);
    if (response.status === 200) {
        return (await response.json()) as User;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getUserPaging(locked?: boolean): Promise<PagingInfo> {
    const response = await apiGet('/api/admin/users/paging', {
        locked: locked !== undefined ? '' + !!locked : undefined,
    });
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getUsers(page: number, locked?: boolean): Promise<User[]> {
    const response = await apiGet('/api/admin/users', {
        page,
        locked: locked !== undefined ? '' + !!locked : undefined,
    });
    if (response.status === 200) {
        return (await response.json()) as User[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteUser(id: string): Promise<void> {
    const response = await apiDelete('/api/admin/users/' + id);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postUser(newUser: UserInfo): Promise<User> {
    const response = await apiPost('/api/admin/users', newUser);
    if (response.status === 200) {
        return (await response.json()) as User;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function putUser(userId: string, userInfo: UserInfo): Promise<User> {
    const response = await apiPut('/api/admin/users/' + userId, userInfo);
    if (response.status === 200) {
        return (await response.json()) as User;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getGroupUsersPaging(groupId: string): Promise<PagingInfo> {
    const response = await apiGet('/api/admin/groups/' + groupId + '/users/paging');
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getGroupUsers(groupId: string, page: number): Promise<UserIdentity[]> {
    const response = await apiGet('/api/admin/groups/' + groupId + '/users', { page });
    if (response.status === 200) {
        return (await response.json()) as UserIdentity[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getRoleUsersPaging(roleId: string): Promise<PagingInfo> {
    const response = await apiGet('/api/admin/roles/' + roleId + '/users/paging');
    if (response.status === 200) {
        return (await response.json()) as PagingInfo;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getRoleUsers(roleId: string, page: number): Promise<UserIdentity[]> {
    const response = await apiGet('/api/admin/roles/' + roleId + '/users', { page });
    if (response.status === 200) {
        return (await response.json()) as UserIdentity[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postToggleLockUsersExceptAdmins(lock: boolean): Promise<UserIdentity[]> {
    const response = await apiPost('/api/admin/users/toggle-lock-users-except-admins', {lock});
    if (response.status === 200) {
        return (await response.json()) as UserIdentity[];
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
