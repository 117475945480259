<template>
    <div class="container-fl">
        <div>
            <div v-if="coachView || physioView" class="columns">
                <b-button
                    type="is-dark"
                    class="mr-1"
                    size="is-medium"
                    :disabled="local.loading"
                    @click="navigateToAddSession()"
                >
                    <b-icon icon="calendar-clock"></b-icon>
                    <span>{{ $t('button.addSession') }}</span>
                </b-button>
                <b-button
                    type="is-dark"
                    class="mr-1"
                    size="is-medium"
                    :disabled="local.loading"
                    @click="navigateToMySessions()"
                >
                    <b-icon icon="dumbbell"></b-icon>
                    <span>{{ $t('button.mySessions') }}</span>
                </b-button>
                <b-button
                    type="is-dark"
                    class="button"
                    size="is-medium"
                    :disabled="local.loading"
                    @click="navigateToMyCustomerSessions()"
                >
                    <b-icon icon="timer"></b-icon>
                    <span>{{ $t('button.myCustomerSessions') }}</span>
                </b-button>
            </div>
            <hr class="is-primary" />

            <div v-if="coachView" class="row" style="margin-bottom: 10px">
                <div class="columns">
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            resource="coach"
                            v-model="coachIds"
                            id-field="id"
                            search-field="firstName"
                            name="coach"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                </div>
            </div>
            <div v-if="physioView" class="row" style="margin-bottom: 10px">
                <div class="columns">
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            resource="physiotherapist"
                            v-model="physioIds"
                            id-field="id"
                            search-field="firstName"
                            name="physiotherapist"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                </div>
            </div>
        </div>
        <div class="row space-1">
            <div class="columns">
                <div class="column is-12 has-text-centered">
                    <div class="is-3 is-flex is-justify-content-center">
                        <b-button
                            class="mr-2"
                            type="is-primary"
                            icon-right="arrow-left"
                            :disabled="local.loading"
                            @click="local.monthShift = local.monthShift - 1"
                        />
                        <h3 style="display: block; width: 9ch" class="title">{{ currentMonthLabel }}</h3>
                        <b-button
                            type="is-primary"
                            class="ml-2"
                            icon-right="arrow-right"
                            :disabled="local.loading"
                            @click="local.monthShift = local.monthShift + 1"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="is-flex my-4">
                <div class="is-flex mr-4">
                    <div :style="{ backgroundColor: 'darksalmon' }" class="color-box"></div>
                    <h6>{{ $t('field.publicHoliday') }}</h6>
                </div>
                <div class="is-flex mr-4">
                    <div :style="{ backgroundColor: 'mediumpurple' }" class="color-box"></div>
                    <h6>{{ $t('field.hybridMember') }}</h6>
                </div>
                <div class="is-flex mr-4">
                    <div :style="{ backgroundColor: 'mediumvioletred' }" class="color-box"></div>
                    <h6>{{ $t('field.onlineMember') }}</h6>
                </div>
                <div class="is-flex mr-4">
                    <div :style="{ backgroundColor: '#FFD700' }" class="color-box"></div>
                    <h6>{{ $t('field.coachTaskOutOfDate') }}</h6>
                </div>
                <div class="is-flex mr-4">
                    <h6>TBD: {{ $t('field.tbd') }}</h6>
                </div>
            </div>

            <h6>{{ $t('field.numberOfSessions') }}: {{ local.sessionsPageTotal }}/{{ local.sessionsTotal }}</h6>
        </div>

        <b-pagination
            v-model="local.page"
            class="is-justify-content-end"
            :disabled="local.loading"
            order="is-right"
            :total="local.total"
            :per-page="local.pageSize"
            :range-before="2"
            :range-after="2"
            size="small"
        />

        <div class="table-container">
            <b-table
                v-if="activeView"
                :bordered="true"
                :data="local.customerRows"
                :hoverable="true"
                :loading="local.loading"
                :paginated="false"
                :pagination-position="'top'"
                :row-class="rowClass"
                :scrollable="true"
                :striped="true"
                :sticky-header="true"
                class="calendar"
            >
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                        </div>
                    </section>
                </template>
                <b-table-column v-slot="props" field="id" :sticky="true">
                    <b-button size="is-small" type="is-primary" @click="goToCustomer(props.row)"
                        >{{ $t('button.detail') }}
                    </b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="name" :label="$t('field.name')" :sticky="true" :sortable="true">
                    <div style="overflow: hidden" @click="goToCustomer(props.row)">{{ props.row.name }}</div>
                </b-table-column>
                <b-table-column
                    v-for="dayColumn in local.dayColumns"
                    :key="dayColumn.key"
                    :field="dayColumn.headerLabel"
                    :label="dayColumn.headerLabel"
                    :header-class="dayColumn.classes.headerClass"
                    :cell-class="dayColumn.classes.cellClass"
                >
                    <template #header>
                        <b-tooltip :label="dayColumn.headerTooltipLabel" position="is-bottom">
                            {{ dayColumn.headerLabel }}
                        </b-tooltip>
                    </template>
                    <template v-slot="props">
                        <div class="cell-div">
                            <div class="is-flex cell-div-action-buttons-wrapper">
                                <button
                                    type="button"
                                    class="cell-div-surface-button"
                                    :disabled="local.loading"
                                    @click="addSessionFromCellButtonClick(props.row, dayColumn)"
                                ></button>
                                <div
                                    v-for="session in props.row.sessions[activeView].sessions[dayColumn.id] ?? []"
                                    :key="session.key"
                                    class="cell-div-action-button"
                                >
                                    <b-tooltip :label="getSessionResponsiblePersonName(session)" position="is-top">
                                        <b-button
                                            size="is-small"
                                            class="lesson-button"
                                            :type="getButtonType(session)"
                                            :disabled="local.loading"
                                            :style="{
                                                maxWidth: lessonButtonWidth(
                                                    props.row.sessions[activeView].sessions[dayColumn.id].length,
                                                ),
                                            }"
                                            @click="openSessionQuickActionsModal(session.id)"
                                            @pointerdown="startSessionPressTimer(session.id)"
                                            @pointerup="endSessionPressTimer"
                                            @pointerleave="endSessionPressTimer"
                                        >
                                            {{ getSessionResponsiblePersonName(session) }}
                                        </b-button>
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                    </template>
                </b-table-column>
                <b-table-column v-slot="props" :label="$t('field.completedSessions')" :sticky="true">
                    {{ props.row.completedSessionCount }}
                </b-table-column>
            </b-table>
        </div>
        <b-modal
            v-model="local.isQuickActionsModalActive"
            :has-modal-card="true"
            :trap-focus="true"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Session Modal"
            close-button-aria-label="Close"
            :aria-modal="true"
        >
            <template #default="props">
                <div class="columns" v-if="local.modalSession">
                    <div class="column p-2 pb-6">
                        <div v-if="!local.modalSession.completed">
                            <b-button
                                size="is-large"
                                type="is-success"
                                :disabled="local.modalSession.lateCancel"
                                icon-left="check"
                                @click="markSessionCompleted(local.modalSession, props.close)"
                            >
                                {{ $t('button.clickToComplete') }}
                            </b-button>
                        </div>
                        <div v-if="local.modalSession.completed">
                            <b-button
                                size="is-large"
                                type="is-success"
                                :disabled="local.modalSession.lateCancel"
                                icon-left="check"
                                @click="markSessionUncompleted(local.modalSession, props.close)"
                            >
                                {{ $t('button.completed') }}
                            </b-button>
                        </div>
                    </div>
                    <div class="column p-2 pb-6">
                        <div v-if="!local.modalSession.lateCancel">
                            <b-button
                                size="is-large"
                                type="is-purple"
                                :disabled="local.modalSession.completed"
                                icon-left="clock-alert-outline"
                                @click="markSessionLateCancel(local.modalSession, props.close)"
                            >
                                {{ $t('button.clickToLateCancel') }}
                            </b-button>
                        </div>
                        <div v-if="local.modalSession.lateCancel">
                            <b-button
                                size="is-large"
                                type="is-purple"
                                :disabled="local.modalSession.completed"
                                icon-left="clock-alert-outline"
                                @click="markSessionAsNotLateCancel(local.modalSession, props.close)"
                            >
                                {{ $t('button.lateCancel') }}
                            </b-button>
                        </div>
                    </div>
                    <div class="column p-2 pb-6">
                        <div>
                            <b-button
                                size="is-large"
                                type="is-danger"
                                icon-left="close"
                                @click="deleteSession(local.modalSession, props.close)"
                            >
                                {{ $t('button.delete') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment, { Moment, utc } from 'moment';

import SelectField from '../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../framework/state';
import {
    deleteResource,
    getResource,
    getResourcePaging,
    getResourcePagingNew,
    getResources,
    getResourcesNew,
    postResource,
    putResource,
} from '../../../framework/client/resource';
import { getOptionLabel, getResourceOptions } from '../../../framework/service/options';

import { Option } from '../../service/application_options_service';
import { ApplicationResource } from '../../../../common/application/enumeration/ApplicationResource';

import { Session } from '../../../../common/application/model/session';
import { Customer } from '../../../../common/application/model/customer';
import { Location } from '../../../../common/application/model/location';
import { P_PAGE_SIZE, P_QUERY, P_SORT_FIELDS, P_SORT_ORDERS } from '../../../../common/framework/constants';
import { PublicHoliday } from '../../../../common/application/model/public_holiday';
import { Membership } from '../../../../common/application/model/membership';
import { ProductType } from '../../../../common/application/model/enums/product_type';
import { CoachTask } from '../../../../common/application/model/CoachTask';
import AutoCompleteField from '../../../framework/fields/AutoCompleteField.vue';
import SwitchField from '../../../framework/fields/SwitchField.vue';
import DateTimeField from '../../../framework/fields/DateTimeField.vue';
import { FrameworkUserRole } from '../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationUserRole } from '../../../../common/application/enumeration/ApplicationUserRole';
import { ValidationObserver } from 'vee-validate';
import { errorToast, successToast } from '../../service/toast_service';
import { PageSize } from '../../../../common/application/model/enums/PageSize';
import { Coach } from '../../../../common/application/model/coach';
import { Physiotherapist } from '../../../../common/application/model/physiotherapist';
import {
    getCoachResourceLabel,
    getCustomerResourceLabel,
    getDateRange,
    getPhysiotherapistResourceLabel,
    shiftMonth,
} from '../../../../browser/application/service/helper_service';
import { isNil } from 'lodash';
import { PagingInfo } from '../../../../common/framework/model/PagingInfo';
import { DashboardView, Nullable, Writeable } from './CustomerSessionTable.utils';
import AutoCompleteMultipleField from '../../fields/AutoCompleteMultipleField.vue';

type DayOfMonth = string;

interface CustomersSessionTableColumn {
    id: string;
    key: string;
    headerLabel: string;
    headerTooltipLabel: string;
    date: Moment;
    isFirstDateOfWeek: boolean;
    isLastDateOfWeek: boolean;
    isWeekend: boolean;
    holiday: PublicHoliday | null;
    classes: {
        headerClass: string;
        cellClass: string;
    };
}

type ViewSessions = Record<
    DashboardView,
    {
        totalCount: number;
        completedSessionsCount: number;
        sessions: Record<string, Session[]>;
    }
>;

interface CustomersSessionTableRow {
    name: string;
    completedSessionCount: number;
    customer: Pick<Customer, 'coachId' | 'locationId' | 'id'>;
    sessions: ViewSessions;
    memberships: Membership[];
    coachOutOfDateTasks: CoachTask[];
}

interface CustomerSessionTableLocalState {
    initialized: boolean;

    coach: Coach | null;
    physiotherapist: Physiotherapist | null;
    selectedCoach: Nullable<Writeable<Pick<Coach, 'id'>>>;
    selectedPhysio: Nullable<Writeable<Pick<Physiotherapist, 'id'>>>;

    customerRows: CustomersSessionTableRow[];
    dayColumns: CustomersSessionTableColumn[];
    coachOptions: Option[];
    physiotherapistOptions: Option[];
    loading: boolean;
    isQuickActionsModalActive: boolean;
    pressTimer: ReturnType<typeof setTimeout> | null;
    modalSession: Session | null;
    isAddSessionModalActive: boolean;
    locationOptions: Option[];
    pressSessionStartTime: number;
    pressSessionEndTime: number;
    isSessionLongPress: boolean;
    isCellLongPress: boolean;
    scrollStartY: number;
    scrollStartX: number;
    sessionsPageTotal: number;
    sessionsTotal: number;
    monthShift: number;
    total: number; // Total amount of customers
    page: number; // Current page for customer pagination
    pageSize: PageSize; // Page size for pagination
}

@Component({
    components: {
        AutoCompleteMultipleField,
        SelectField,
        AutoCompleteField,
        SwitchField,
        DateTimeField,
        ValidationObserver,
    },
})
export default class CustomerSessionTable extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    shared = sharedState;
    local: CustomerSessionTableLocalState = {
        initialized: false,
        coach: null,
        physiotherapist: null,
        selectedCoach: { id: null },
        selectedPhysio: { id: null },

        customerRows: [] as CustomersSessionTableRow[],
        dayColumns: [] as CustomersSessionTableColumn[],
        coachOptions: [] as Option[],
        physiotherapistOptions: [] as Option[],
        loading: false,
        isQuickActionsModalActive: false,
        pressTimer: null,
        modalSession: null,
        isAddSessionModalActive: false,
        locationOptions: [] as Option[],
        pressSessionStartTime: 0,
        pressSessionEndTime: 0,
        isSessionLongPress: false,
        isCellLongPress: false,
        scrollStartY: 0,
        scrollStartX: 0,
        // Total amount of sessions on current pages
        sessionsPageTotal: 0,
        // Total amount of sessions on all pages
        sessionsTotal: 0,
        monthShift: 0,
        // Used for customer pagination
        total: 0,
        page: 1,
        pageSize: PageSize.L,
    };

    async mounted() {
        await this.initialize();
        this.local.initialized = true;

        await this.loadAsyncData();

        this.$nextTick(() => {
            this.scrollToCurrentWeek();
        });
    }

    private async initialize() {
        if (this.activeView === DashboardView.coachView) {
            const coachParameters = new Map<string, string>([['userId', this.shared.context.userId]]);
            this.local.coach = (await getResources<Coach>(ApplicationResource.COACH, -1, coachParameters))[0] ?? {};
            this.local.selectedCoach.id = this.local.coach.id;
        }

        if (this.activeView === DashboardView.physioView) {
            const physioParameters = new Map<string, string>([['userId', this.shared.context.userId]]);
            this.local.physiotherapist =
                (await getResources<Physiotherapist>(ApplicationResource.PHYSIOTHERAPIST, -1, physioParameters))[0] ??
                {};
            this.local.selectedPhysio.id = this.local.physiotherapist.id;
        }
    }

    /**
     * @param page
     * @param pageSize
     * @param coachId Applicable only if view is not adminView
     * @param physiotherapistId Applicable only if view is not adminView
     * @param view
     * @private
     */
    private async loadAsyncCustomers(
        page: number,
        pageSize: number,
        coachId: string | null,
        physiotherapistId: string | null,
        view: DashboardView | null,
    ): Promise<{
        totalCount: number;
        customers: Customer[];
    }> {
        const customers: { customers: Customer[]; totalCount: number } = await (async () => {
            if (view === DashboardView.adminView) {
                const customerParameters = new Map<string, any>();
                customerParameters.set(P_PAGE_SIZE, pageSize);
                customerParameters.set(P_SORT_FIELDS, 'lastName,firstName,customerNumber');
                customerParameters.set(P_SORT_ORDERS, 'asc,asc,asc,asc');
                const [customersPagingInfo, results]: [PagingInfo, Customer[]] = await Promise.all([
                    getResourcePaging(ApplicationResource.CUSTOMER, customerParameters, pageSize),
                    getResources<Customer>(ApplicationResource.CUSTOMER, page - 1, customerParameters, pageSize),
                ]);
                return {
                    customers: results,
                    totalCount: customersPagingInfo.rowCount,
                };
            }

            if (view === DashboardView.coachView && coachId) {
                const customerParameters = new Map<string, any>();
                customerParameters.set(P_PAGE_SIZE, pageSize);
                customerParameters.set(P_SORT_FIELDS, 'lastName,firstName,customerNumber');
                customerParameters.set(P_SORT_ORDERS, 'asc,asc,asc,asc');
                customerParameters.set('coachId', coachId);
                const [customersPagingInfo, results]: [PagingInfo, Customer[]] = await Promise.all([
                    getResourcePaging(ApplicationResource.CUSTOMER, customerParameters, pageSize),
                    getResources<Customer>(ApplicationResource.CUSTOMER, page - 1, customerParameters, pageSize),
                ]);
                return {
                    customers: results,
                    totalCount: customersPagingInfo.rowCount,
                };
            }

            if (view === DashboardView.physioView && physiotherapistId) {
                const customerParameters = new Map<string, any>();
                customerParameters.set(P_PAGE_SIZE, pageSize);
                customerParameters.set(P_SORT_FIELDS, 'lastName,firstName,customerNumber');
                customerParameters.set(P_SORT_ORDERS, 'asc,asc,asc,asc');
                customerParameters.set('physiotherapistId', physiotherapistId);
                const [customersPagingInfo, results]: [PagingInfo, Customer[]] = await Promise.all([
                    getResourcePaging(ApplicationResource.CUSTOMER, customerParameters, pageSize),
                    getResources<Customer>(ApplicationResource.CUSTOMER, page - 1, customerParameters, pageSize),
                ]);
                return {
                    customers: results,
                    totalCount: customersPagingInfo.rowCount,
                };
            }

            return {
                customers: [],
                totalCount: 0,
            };
        })();

        const memberships: Membership[] =
            customers.customers.length > 0
                ? await getResourcesNew<Membership>(
                      ApplicationResource.MEMBERSHIP,
                      -1,
                      new Map<string, any>([
                          ['customerId', customers.customers.map((it) => it.id)],
                          [P_PAGE_SIZE, '100000'],
                      ]),
                  )
                : [];

        // Remove customers that are churned in Coach view
        if (view === DashboardView.coachView) {
            const lastDayOfCurrentMonth = moment().endOf('month');

            // Create a map of memberships for quick lookup
            const membershipMap = new Map(memberships.map((membership) => [membership.customerId, membership]));

            // Filter out customers whose membership end date is before the last day of the current month
            // TODO: Ideally it should happen in query
            const filteredCustomers: Customer[] = customers.customers.filter((row) => {
                const membership = membershipMap.get(row.id);
                return !(
                    membership &&
                    membership.endDate &&
                    moment(membership.endDate).endOf('month').isBefore(lastDayOfCurrentMonth, 'day')
                );
            });
            const removedCustomersCount = customers.customers.length - filteredCustomers.length;
            return {
                totalCount: customers.totalCount - removedCustomersCount,
                customers: filteredCustomers,
            };
        }

        return {
            totalCount: customers.totalCount,
            customers: customers.customers,
        };
    }

    private async loadAsyncData() {
        this.local.loading = true;

        const customersResult = await this.loadAsyncCustomers(
            this.local.page,
            this.local.pageSize,
            this.local.selectedCoach.id,
            this.local.selectedPhysio.id,
            this.activeView,
        );

        const selectedMonthStartInUtc = this.selectedMonthInUtc().clone().startOf('month');
        const selectedMonthEndInUtc = this.selectedMonthInUtc().clone().endOf('month');

        const selectedMonthDaysInUtc = getDateRange(selectedMonthStartInUtc, selectedMonthEndInUtc);

        const pageCustomerIds = customersResult.customers.map((it) => it.id);

        const [
            locationOptions,
            coachOptions,
            physiotherapistOptions,
            customersMonthSessions,
            sessionsTotal,
            memberships,
            coachTasks,
            publicHolidays,
        ] = await Promise.all([
            getResourceOptions(ApplicationResource.LOCATION, 'name'),
            getResourceOptions<Coach>(ApplicationResource.COACH, getCoachResourceLabel),
            getResourceOptions<Physiotherapist>(ApplicationResource.PHYSIOTHERAPIST, getPhysiotherapistResourceLabel),
            this.getCustomerSessions(pageCustomerIds, selectedMonthStartInUtc, selectedMonthEndInUtc, this.activeView),
            this.getTotalSessionsCount(
                pageCustomerIds,
                selectedMonthStartInUtc,
                selectedMonthEndInUtc,
                this.activeView,
            ),
            this.getMembershipsNew(pageCustomerIds),
            this.getOutOfDateCoachTasksNew(pageCustomerIds, moment()),
            this.getPublicHolidaysNew(selectedMonthStartInUtc, selectedMonthEndInUtc),
        ]);

        const dayColumns = selectedMonthDaysInUtc.map((it) => {
            const weekDay = it.isoWeekday();
            const isFirstDateOfCurrentWeek = utc().startOf('isoWeek').isSame(it, 'day');
            const isFirstDateOfWeek = weekDay === 1;
            const isLastDateOfWeek = weekDay === 7;
            const isWeekend = weekDay === 6 || weekDay === 7;
            const isToday = utc().isSame(it, 'day');
            const holiday =
                publicHolidays.find((publicHoliday) => utc(publicHoliday.startDate).isSame(it, 'day')) ?? null;

            const headerClass = [
                holiday ? 'public-holiday-column' : null,
                isToday ? 'today-column' : null,
                isFirstDateOfCurrentWeek ? 'first-date-of-current-week' : null,
                isFirstDateOfWeek ? 'first-date-of-week' : null,
                isLastDateOfWeek ? 'last-date-of-week' : null,
            ]
                .filter((it) => it !== null)
                .join(' ');

            return {
                key: it.toISOString(),
                id: it.format('DD.MM.YYYY'),
                date: it,
                headerLabel: `${it.format('ddd')} ${it.format('DD.MM')}`,
                headerTooltipLabel: holiday ? this.$t('label.' + holiday.nameKey).toString() : it.format('DD.MM'),
                isFirstDateOfWeek,
                isLastDateOfWeek,
                isWeekend,
                holiday,
                classes: {
                    headerClass: headerClass,
                    cellClass: headerClass,
                },
            };
        });

        const customerRows = customersResult.customers.map((customer): CustomersSessionTableRow => {
            const cuSessions: Session[] = customersMonthSessions.filter(
                (session) => session.customerId === customer.id,
            );

            // It will show also TBD sessions + Coach sessions
            const coachViewSessions: Session[] = cuSessions.filter((it) => {
                return isNil(it.physiotherapistId);
            });

            // It will show also TBD sessions + Coach sessions
            const physioViewSessions: Session[] = cuSessions.filter((it) => {
                return isNil(it.coachId);
            });

            const viewSessions: ViewSessions = {
                adminView: {
                    sessions: CustomerSessionTable.groupByDay(selectedMonthDaysInUtc, cuSessions),
                    completedSessionsCount: cuSessions.filter((it) => it.completed || it.lateCancel).length,
                    totalCount: cuSessions.length,
                },
                coachView: {
                    sessions: CustomerSessionTable.groupByDay(selectedMonthDaysInUtc, coachViewSessions),
                    completedSessionsCount: coachViewSessions.filter((it) => it.completed || it.lateCancel).length,
                    totalCount: coachViewSessions.length,
                },
                physioView: {
                    sessions: CustomerSessionTable.groupByDay(selectedMonthDaysInUtc, physioViewSessions),
                    completedSessionsCount: physioViewSessions.filter((it) => it.completed || it.lateCancel).length,
                    totalCount: physioViewSessions.length,
                },
            };

            const coachOutOfDateTasks = selectedMonthDaysInUtc
                .map((day) => {
                    return coachTasks
                        .filter((coachTask) => coachTask.customerId === customer.id)
                        .filter((coachTask) => moment(coachTask.dueDate).isSame(day, 'day'));
                })
                .flat();

            return {
                name: getCustomerResourceLabel(customer),
                completedSessionCount: this.activeView ? viewSessions[this.activeView].completedSessionsCount : 0,
                customer: {
                    coachId: customer.coachId,
                    id: customer.id,
                    locationId: customer.locationId,
                },
                sessions: viewSessions,
                memberships: memberships.filter((membership) => membership.customerId === customer.id),
                coachOutOfDateTasks,
            };
        });

        this.local = {
            ...this.local,
            total: customersResult.totalCount,
            sessionsPageTotal: customerRows
                .map((it) => (this.activeView ? it.sessions[this.activeView].totalCount : 0))
                .reduce((a, b) => a + b, 0),
            sessionsTotal: sessionsTotal,
            locationOptions: locationOptions,
            coachOptions: coachOptions,
            physiotherapistOptions: physiotherapistOptions,
            customerRows: customerRows,
            dayColumns: dayColumns,
            loading: false,
        };
    }

    @Watch('local.monthShift')
    async monthShiftChanged() {
        await this.loadAsyncData();
    }

    @Watch('local.selectedCoach.id')
    onCoachIdChange() {
        if (!this.local.initialized) {
            return;
        }
        this.loadAsyncData();
    }

    @Watch('local.selectedPhysio.id')
    onPhysiotherapistIdChange() {
        if (!this.local.initialized) {
            return;
        }
        this.loadAsyncData();
    }

    @Watch('local.page')
    onPageChange() {
        this.loadAsyncData();
    }

    getCoachName(id: string): string {
        return getOptionLabel(id, this.local.coachOptions);
    }

    getPhysiotherapistName(id: string): string {
        return getOptionLabel(id, this.local.physiotherapistOptions);
    }

    /*onCellClick(row: Customer, column: any): void {
        const cellDate = moment(column.label, 'DD.MM').add(12, 'hours').toISOString();
        this.$router.push(`/${ApplicationResource.SESSION}/add-from-dashboard/${this.local.selectedCoach.id}/${row.id}/${cellDate}`);
    }*/

    goToSession(sessionId: string): void {
        this.$router.push(`/${ApplicationResource.SESSION}/${sessionId}`);
    }

    goToCustomer(row: CustomersSessionTableRow): void {
        this.$router.push(`/${ApplicationResource.CUSTOMER}/${row.customer.id}`);
    }

    getButtonType(session: Session): string {
        if (!session) {
            return '';
        }

        if (session.lateCancel) {
            return 'is-purple';
        }

        if (session.completed) {
            return 'is-success';
        }

        if (!session.completed && moment().isAfter(session.date)) {
            return 'is-danger';
        }

        return 'is-warning';
    }

    selectedMonthInUtc(): Moment {
        return shiftMonth(utc(), this.local.monthShift);
    }

    rowClass(row: CustomersSessionTableRow): string {
        if (row.memberships.find((it) => it.productType === ProductType.HYBRID_MEMBERSHIP)) {
            return 'hybrid-member-row';
        }

        if (row.memberships.find((it) => it.productType === ProductType.ONLINE_COACHING)) {
            return 'online-member-row';
        }

        if (row.coachOutOfDateTasks.length > 0) {
            return 'task-out-of-date';
        }

        return '';
    }

    scrollToCurrentWeek() {
        const tableContainer = this.$el.querySelector('.table-container');
        const currentWeekCell = this.$el.querySelector('.first-date-of-current-week');

        if (currentWeekCell && tableContainer) {
            const tableRect = tableContainer.getBoundingClientRect();
            const cellRect = currentWeekCell.getBoundingClientRect();
            const scrollLeft = cellRect.left - tableRect.left - (tableContainer.clientWidth / 2 - cellRect.width);

            tableContainer.scrollTo({ left: scrollLeft, behavior: 'smooth' });
        }
    }

    get today(): string {
        return moment().format('DD.MM');
    }

    getSessionResponsiblePersonName(session: Session): string {
        return session.coachId
            ? this.getCoachName(session.coachId)
            : session.physiotherapistId
              ? this.getPhysiotherapistName(session.physiotherapistId)
              : 'TBD';
    }

    /**
     * Loads all sessions of given customerIds in the given time without pagination
     * @param customerIds Applicable only if view is not adminView
     * @param start
     * @param end
     * @param view
     * @private
     */
    private async getCustomerSessions(
        customerIds: string[],
        start: Moment,
        end: Moment,
        view: DashboardView | null,
    ): Promise<Session[]> {
        if (view === DashboardView.adminView) {
            // Every Session
            const parameters = new Map<string, any>([
                ['startDateOfMonth', start.toISOString()],
                ['endDateOfMonth', end.toISOString()],
            ]);

            const queryString = 'date>={startDateOfMonth} AND date<={endDateOfMonth}';

            return await getResourcesNew<Session>(this.resourceType, -1, parameters, queryString);
        }

        if (customerIds.length === 0) {
            return [];
        }

        if (view === DashboardView.coachView) {
            // Every TBD SESSION AND Coach session for given customerIds
            const parameters = new Map<string, any>([
                ['startDateOfMonth', start.toISOString()],
                ['endDateOfMonth', end.toISOString()],
                ['customerIds', customerIds],
            ]);

            const queryString =
                'customerId IN ({customerIds}) AND date>={startDateOfMonth} AND date<={endDateOfMonth} AND physiotherapistId IS NULL';

            return await getResourcesNew<Session>(this.resourceType, -1, parameters, queryString);
        }

        if (view === DashboardView.physioView) {
            // Every TBD session and physio session for given customerIds
            const parameters = new Map<string, any>([
                ['startDateOfMonth', start.toISOString()],
                ['endDateOfMonth', end.toISOString()],
                ['customerIds', customerIds],
            ]);

            const queryString =
                'customerId IN ({customerIds}) AND date>={startDateOfMonth} AND date<={endDateOfMonth} AND coachId IS NULL';

            return await getResourcesNew<Session>(this.resourceType, -1, parameters, queryString);
        }

        return [];
    }

    /**
     * Loads all sessions count of given customerIds in the given time.
     * @param customerIds Applicable only if view is not adminView
     * @param start
     * @param end
     * @param view
     * @private
     */
    private async getTotalSessionsCount(
        customerIds: string[],
        start: Moment,
        end: Moment,
        view: DashboardView | null,
    ): Promise<number> {
        if (view === DashboardView.adminView) {
            // Every Session
            const parameters = new Map<string, any>([
                ['startDateOfMonth', start.toISOString()],
                ['endDateOfMonth', end.toISOString()],
            ]);

            const queryString = 'date>={startDateOfMonth} AND date<={endDateOfMonth}';

            const results = await getResourcePagingNew(this.resourceType, parameters, queryString);

            return results.rowCount;
        }

        if (customerIds.length === 0) {
            return 0;
        }

        if (view === DashboardView.coachView) {
            // Every TBD SESSION AND Coach session for given customerIds
            const parameters = new Map<string, any>([
                ['startDateOfMonth', start.toISOString()],
                ['endDateOfMonth', end.toISOString()],
                ['customerIds', customerIds],
            ]);

            const queryString =
                'customerId IN ({customerIds}) AND date>={startDateOfMonth} AND date<={endDateOfMonth} AND physiotherapistId IS NULL';

            const results = await getResourcePagingNew(this.resourceType, parameters, queryString);

            return results.rowCount;
        }

        if (view === DashboardView.physioView) {
            // Every TBD session and physio session for given customerIds
            const parameters = new Map<string, any>([
                ['startDateOfMonth', start.toISOString()],
                ['endDateOfMonth', end.toISOString()],
                ['customerIds', customerIds],
            ]);

            const queryString =
                'customerId IN ({customerIds}) AND date>={startDateOfMonth} AND date<={endDateOfMonth} AND coachId IS NULL';

            const results = await getResourcePagingNew(this.resourceType, parameters, queryString);

            return results.rowCount;
        }

        console.warn(`Unknown view: ${view}`);
        return 0;
    }

    private async getPublicHolidaysNew(start: Moment, end: Moment): Promise<PublicHoliday[]> {
        const holidayParameters = new Map<string, string>([
            ['startOfMonth', start.toISOString()],
            ['endOfMonth', end.toISOString()],
            [`${P_QUERY}`, `startDate > {startOfMonth} AND startDate < {endOfMonth}`],
            ['pageSize', '1000'],
        ]);

        return await getResources<PublicHoliday>(ApplicationResource.PUBLIC_HOLIDAY, -1, holidayParameters);
    }

    private async getMembershipsNew(customerIds: string[]): Promise<Membership[]> {
        if (customerIds.length === 0) {
            return [];
        }
        const membershipParameters = new Map<string, any>([
            ['customerId', customerIds],
            ['pageSize', '1000'],
        ]);

        return getResourcesNew<Membership>(ApplicationResource.MEMBERSHIP, -1, membershipParameters);
    }

    /**
     * dueDate of CoachTask has timezone saved. We can fetch by the end of today in user's timezone
     * @private
     */
    private async getOutOfDateCoachTasksNew(customerIds: string[], endDate: Moment): Promise<CoachTask[]> {
        if (customerIds.length === 0) {
            return [];
        }
        const parameters = new Map<string, any>([
            ['customerId', customerIds],
            ['date', endDate.toISOString()],
            ['pageSize', '1000'],
            ['completed', 'false'],
        ]);

        let queryString = 'dueDate <= {date}';

        return getResourcesNew(ApplicationResource.COACH_TASK, -1, parameters, queryString);
    }

    lessonButtonWidth(sessionsCount: number): string {
        return window.innerWidth >= 769 ? `${6 / sessionsCount}rem` : 'auto';
    }

    async openSessionQuickActionsModal(sessionId: string) {
        this.local.modalSession = (await getResource<Session>(this.resourceType, sessionId))!;
        this.local.isQuickActionsModalActive = true;
        this.local.pressSessionEndTime = 0;
    }

    startSessionPressTimer(sessionId: string) {
        this.local.pressSessionStartTime = new Date().getTime();
        this.local.pressSessionEndTime = 0;

        const tableContainer = this.$el.querySelector('.table-container')!;
        this.local.scrollStartX = tableContainer.scrollLeft;
        this.local.scrollStartY = tableContainer.scrollTop;

        this.local.pressTimer = setTimeout(() => {
            if (
                (this.local.pressSessionEndTime - this.local.pressSessionStartTime >= 800 ||
                    this.local.pressSessionEndTime === 0) &&
                this.local.scrollStartY === tableContainer.scrollTop &&
                this.local.scrollStartX === tableContainer.scrollLeft
            ) {
                this.local.isSessionLongPress = true;
                this.goToSession(sessionId);
            }
        }, 800);
    }

    endSessionPressTimer() {
        this.local.pressSessionEndTime = new Date().getTime();
        if (this.local.pressTimer !== null) {
            clearTimeout(this.local.pressTimer);
            this.local.pressTimer = null;
        }
    }

    addSessionFromCellButtonClick(row: CustomersSessionTableRow, column: CustomersSessionTableColumn) {
        this.addSessionFromCell(row, column);
    }

    async addSessionFromCell(row: CustomersSessionTableRow, column: CustomersSessionTableColumn) {
        if (this.local.isQuickActionsModalActive || this.local.isSessionLongPress) {
            return;
        }

        this.local.loading = true;

        const cellDate = column.date.clone().add(12, 'hours');
        const today = moment().add(12, 'hours');
        let coachId;
        let physiotherapistId;
        let productType;

        if (this.local.selectedCoach.id) {
            coachId = this.local.selectedCoach.id;
            productType = ProductType.COACHING;
        } else if (this.local.selectedPhysio.id) {
            physiotherapistId = this.local.selectedPhysio.id;
            productType = ProductType.PHYSIOTHERAPY;
        } else {
            coachId = row.customer.coachId ? row.customer.coachId : undefined;
            productType = ProductType.COACHING;
        }

        const locationId =
            row.customer.locationId ?? (await getResources<Location>(ApplicationResource.LOCATION))?.[0].id;

        try {
            const newSession = await postResource<Session>(this.resourceType, {
                coachId,
                physiotherapistId,
                customerId: row.customer.id,
                date: cellDate as any as Date,
                locationId,
                productType,
                completed: !cellDate.isAfter(today),
                lateCancel: false,
            } as Session);

            await this.loadAsyncData();

            successToast(this, 'message.sessionSaved');
        } catch (error) {
            errorToast(this);
        }

        this.local.isAddSessionModalActive = false;
        this.local.loading = false;
    }

    async markSessionCompleted(session: Session, closeModal: any): Promise<void> {
        if (session) {
            await putResource<Session>(this.resourceType, session.id, {
                ...session,
                completed: true,
            });
            this.local.modalSession = (await getResource<Session>(this.resourceType, session.id))!;
        }
        closeModal();
        await this.loadAsyncData();
    }

    async markSessionUncompleted(session: Session, closeModal: any): Promise<void> {
        if (session) {
            await putResource<Session>(this.resourceType, session.id, {
                ...session,
                completed: false,
            });

            this.local.modalSession = (await getResource<Session>(this.resourceType, session.id))!;
        }
        closeModal();
        await this.loadAsyncData();
    }

    async markSessionLateCancel(session: Session, closeModal: any): Promise<void> {
        if (session) {
            await putResource<Session>(this.resourceType, session.id, {
                ...session,
                lateCancel: true,
            });

            this.local.modalSession = (await getResource<Session>(this.resourceType, session.id))!;
        }
        closeModal();
        await this.loadAsyncData();
    }

    async markSessionAsNotLateCancel(session: Session, closeModal: any): Promise<void> {
        if (session) {
            await putResource<Session>(this.resourceType, session.id, {
                ...session,
                lateCancel: false,
            });

            this.local.modalSession = (await getResource<Session>(this.resourceType, session.id))!;
        }
        closeModal();
        await this.loadAsyncData();
    }

    async deleteSession(session: Session, closeModal: any): Promise<void> {
        if (session) {
            await deleteResource(this.resourceType, session.id);

            this.local.modalSession = {} as Session;
        }
        closeModal();
        await this.loadAsyncData();
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some((role) => role === ApplicationUserRole.COACH);
    }

    get isPhysiotherapist(): boolean {
        return this.shared.context.roles.some((role) => role === ApplicationUserRole.PHYSIOTHERAPIST);
    }

    async addSession(session: Session) {
        try {
            const newSession = await postResource<Session>(this.resourceType, session);

            await this.loadAsyncData();

            successToast(this, 'message.sessionSaved');
        } catch (error) {
            errorToast(this);
        }

        this.local.isAddSessionModalActive = false;
    }

    get currentMonthLabel(): string {
        return this.$t('enum.month.' + this.selectedMonthInUtc().month()).toString();
    }

    get activeView(): DashboardView | null {
        if (this.isAdmin) {
            return DashboardView.adminView;
        }
        if (this.isCoach) {
            return DashboardView.coachView;
        }
        if (this.isPhysiotherapist) {
            return DashboardView.physioView;
        }
        return null;
    }

    get coachView(): boolean {
        return this.activeView === DashboardView.coachView;
    }

    get physioView(): boolean {
        return this.activeView === DashboardView.physioView;
    }

    static groupByDay(days: Moment[], sessions: Session[]): Record<string, Session[]> {
        const daySessions: {
            id: DayOfMonth;
            sessions: Session[];
        }[] = days.map((day) => ({
            id: day.format('DD.MM.YYYY'),
            sessions: sessions.filter((session) => moment(session.date).isSame(day, 'day')),
        }));

        return daySessions.reduce(
            (previousValue, currentValue) => {
                previousValue[currentValue.id] = currentValue.sessions;
                return previousValue;
            },
            {} as Record<DayOfMonth, Session[]>,
        );
    }

    get coachIds(): string[] {
        return this.local.selectedCoach.id ? [this.local.selectedCoach.id] : [];
    }

    get physioIds(): string[] {
        return this.local.selectedPhysio.id ? [this.local.selectedPhysio.id] : [];
    }

    async dropdownClosed(resourceIds: string[]): Promise<void> {
        if (this.activeView === DashboardView.coachView) {
            this.local.selectedCoach.id = resourceIds[0] ?? this.local.coach?.id ?? null;
        }

        if (this.activeView === DashboardView.physioView) {
            this.local.selectedPhysio.id = resourceIds[0] ?? this.local.physiotherapist?.id ?? null;
        }
    }

    async navigateToAddSession() {
        if (this.activeView === DashboardView.coachView && this.local.selectedCoach) {
            await this.$router.push(`/${ApplicationResource.SESSION}/add-from-coach/${this.local.selectedCoach.id}`);
        } else if (this.activeView === DashboardView.physioView && this.local.selectedPhysio) {
            await this.$router.push(
                `/${ApplicationResource.SESSION}/add-from-physiotherapist/${this.local.selectedPhysio.id}`,
            );
        }
    }

    async navigateToMySessions() {
        if (this.activeView === DashboardView.coachView && this.local.coach) {
            await this.$router.push(`/${ApplicationResource.SESSION}?coachId=${this.local.coach.id}`);
        } else if (this.activeView === DashboardView.physioView && this.local.physiotherapist) {
            await this.$router.push(
                `/${ApplicationResource.SESSION}?physiotherapistId=${this.local.physiotherapist.id}`,
            );
        }
    }

    async navigateToMyCustomerSessions() {
        if (this.activeView !== DashboardView.physioView && this.activeView !== DashboardView.coachView) {
            return;
        }
        const queryString = this.local.customerRows
            .map((it) => it.customer)
            .map(({ id }) => `customerId=${id}`)
            .join('&');
        await this.$router.push(`/${ApplicationResource.SESSION}?${queryString}`);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (min-width: 769px) {
    .calendar::v-deep .table td,
    .calendar::v-deep .table th {
        max-width: 8rem;
        min-width: 8rem;
        position: relative;
    }

    .calendar::v-deep .table td.is-sticky,
    .calendar::v-deep .table th.is-sticky {
        max-width: 15rem;
    }

    .lesson-button {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cell-div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        .cell-div-surface-button {
            background: unset;
            width: 100%;
            height: 100%;
            border: unset;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &:disabled {
                background: unset !important;
            }
        }

        .cell-div-action-buttons-wrapper {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0.1rem;
            gap: 0.05rem;
            display: flex;

            .cell-div-action-button {
                flex: 1;

                button {
                    min-width: 0;
                    padding: clamp(0rem, 5%, 0.1rem);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .calendar::v-deep .table td,
    .calendar::v-deep .table th {
        border: 1px solid #dbdbdb !important;
    }
}

.color-box {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.5rem;
}
</style>
