import { registerFrameworkNavigation } from './framework/framework_navigation';
import {
    initializeModules,
    invokeUiModuleOnLoggedIn,
    invokeUiModuleOnLoggedOut,
} from '../common/framework/service/module_service';
import { sharedState } from './framework/state';
import { registerApplicationNavigation } from './application/application_navigation';
import { frameworkLoggedIn, frameworkLoggedOut } from './framework/framework_hooks';
import { applicationLoggedIn, applicationLoggedOut } from './application/application_hooks';

export async function appInitialize() {
    registerFrameworkNavigation();
    await initializeModules(sharedState.version.features);
    await registerApplicationNavigation();
}

export async function appLogin() {
    registerFrameworkNavigation();
    await registerApplicationNavigation();
    await frameworkLoggedIn();
    await applicationLoggedIn();
    await invokeUiModuleOnLoggedIn();
}

export async function appLogout() {
    registerFrameworkNavigation();
    await registerApplicationNavigation();
    await frameworkLoggedOut();
    await applicationLoggedOut();
    await invokeUiModuleOnLoggedOut();
}
