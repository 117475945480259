<template>
    <div class="container panel">
        <section class="section">
            <h1 class="title">{{ $t('title.apiTokens') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <b-table
                :selected.sync="local.selected"
                :data="local.rows"
                :loading="local.loading"
                paginated
                backend-pagination
                :total="local.total"
                :per-page="local.perPage"
                :current-page="local.page"
                @page-change="onPageChange"
            >
                <b-table-column v-slot="props" field="name" :label="$t('field.name')">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column v-slot="props" field="created" :label="$t('field.created')">
                    {{ formatDate(props.row.created) }}
                </b-table-column>
                <b-table-column v-slot="props" field="expiration" :label="$t('field.expiration')">
                    {{ formatDate(props.row.expiration) }}
                </b-table-column>
                <b-table-column v-slot="props" field="authenticated" :label="$t('field.lastAuthenticated')">
                    {{ formatDate(props.row.authenticated) }}
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { ApiToken } from '../../../../../common/framework/model/ApiToken';
import { deleteApiToken, getApiTokens } from '../../../client/api_token_client';
import { dateToUiDateString } from '../../../../../common/framework/util/convert';
import {PageSize} from "../../../../../common/application/model/enums/PageSize";

@Component
export default class ApiTokens extends Vue {
    readonly resourceType = 'api-token';

    shared = sharedState;
    local = {
        rows: new Array<ApiToken>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.S,
        selected: undefined as ApiToken | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/api-token/add');
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    await deleteApiToken(this.local.selected.id);
                    await this.loadAsyncData();
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.rows = [];
        const rows = await getApiTokens();
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    formatDate(date: Date) {
        return dateToUiDateString(date);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
