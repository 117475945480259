<template>
    <b-field
        :label="$t(getFieldName() ? 'field.' + getFieldName() : '').toString()"
        :style="width ? 'width: ' + width + 'px;' : ''"
    >
        <b-input
            v-if="!readonly"
            :name="getFieldName()"
            :disabled="disabled === true"
            v-model="local.value"
            :has-counter="!(hasCounter === false)"
            :maxlength="maxlength"
        >
        </b-input>
        <span v-if="readonly">{{ local.value }}</span>
    </b-field>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class TextComponent extends Vue {
    @Prop(String) name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Number) readonly maxlength!: number;
    @Prop(Number) readonly width!: number;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly hasCounter!: boolean;

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
