<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item
                        :label="String($t('title.info')).toString()"
                        icon="information-outline"
                        class="custom-tab-item"
                    >
                        <h1 class="title">{{ $t('title.addSession') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="customer"
                                        v-model="local.row.customerId"
                                        resource="customer"
                                        field="firstName"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6" v-if="isCoach || isAdmin">
                                    <AutoCompleteField
                                        :required="!local.row.coachId && !local.row.physiotherapistId"
                                        name="coach"
                                        v-model="local.row.coachId"
                                        resource="coach"
                                        field="firstName,lastName"
                                        :disabled="local.coachSelectDisabled"
                                    ></AutoCompleteField>
                                </div>
                                <div class="column is-6" v-if="isPhysiotherapist || isAdmin">
                                    <AutoCompleteField
                                        :required="!local.row.coachId && !local.row.physiotherapistId"
                                        name="physiotherapist"
                                        v-model="local.row.physiotherapistId"
                                        resource="physiotherapist"
                                        field="firstName,lastName"
                                        :disabled="local.physioSelectDisabled"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.locationId"
                                        :options="local.locationOptions"
                                        :required="true"
                                        expanded
                                        name="location"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.productType"
                                        name="type"
                                        :options="local.productTypeOptions"
                                        :required="true"
                                        expanded
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateTimeField v-model="local.row.date" name="date" required inline />
                                </div>
                                <div class="column is-6">
                                    <SwitchField v-model="local.row.completed" />
                                    <SwitchField v-model="local.row.lateCancel" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SwitchField v-model="local.recurring" />
                                </div>
                                <div class="column is-6" v-if="local.recurring">
                                    <b-tooltip
                                        :label="$t('message.membershipHaveEndDate')"
                                        position="is-bottom"
                                        :active="local.recurringDisabled"
                                    >
                                        <div class="is-flex">
                                            <b-radio
                                                :disabled="local.recurringDisabled"
                                                v-model="local.recurringEndType"
                                                native-value="occurrences"
                                                class="pb-6"
                                            />

                                            <NumberField
                                                v-model="local.occurrences"
                                                :disabled="!local.recurring || local.recurringEndType !== 'occurrences'"
                                            />
                                        </div>
                                    </b-tooltip>

                                    <div class="is-flex">
                                        <b-radio
                                            v-model="local.recurringEndType"
                                            native-value="end-date"
                                            class="pb-6"
                                        />
                                        <DateField
                                            v-model="local.recurringEndDate"
                                            :disabled="!local.recurring || local.recurringEndType !== 'end-date'"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="content" v-if="local.recurringWeekdayName && local.recurring">
                                <h6>{{ $t('field.weeklyOn') }} {{ local.recurringWeekdayName }}</h6>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                :disabled="!passes || local.loading"
                                @click="passes(add)"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.add') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                :disabled="!passes"
                                @click="passes(addAndClose)"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.addAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import DateTimeField from '../../../../framework/fields/DateTimeField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import TextField from '../../../../framework/fields/TextField.vue';

import { sharedState } from '../../../../framework/state';
import { getResource, getResources, postResource } from '../../../../framework/client/resource';

import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { Session } from '../../../../../common/application/model/session';
import { Customer } from '../../../../../common/application/model/customer';

import { errorToast, successToast } from '../../../service/toast_service';
import { Option } from '../../../service/application_options_service';
import { getEnumerationOptions, getResourceOptions } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import { Membership } from '../../../../../common/application/model/membership';
import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import DateField from '../../../../framework/fields/DateField.vue';
import NumberField from '../../../../framework/fields/NumberField.vue';
import { postRecurringSessionRequest } from '../../../client/session_client';
import { RecurringEndType } from '../../../../../common/application/model/enums/recurring_end_type';
import moment from 'moment';
import { isNil } from 'lodash';

interface AddSessionLocalState {
    loading: boolean;
    row: Session;
    locationOptions: Option[];
    productTypeOptions: Option[];
    customerMemberships: Membership[];
    coachSelectDisabled: boolean;
    physioSelectDisabled: boolean;
    recurring: boolean;
    recurringDisabled: boolean;
    recurringEndType: RecurringEndType;
    occurrences: number;
    recurringEndDate: Date | null;
    recurringWeekdayName: string;
}

@Component({
    components: {
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AutoCompleteField,
        DateTimeField,
        DateField,
        NumberField,
    },
})
export default class AddSession extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.SESSION;

    // Properties
    @Prop(String) readonly customerId!: string;
    @Prop(String) readonly coachId!: string;
    @Prop(String) readonly date!: string;
    @Prop(String) readonly physiotherapistId!: string;

    // State
    shared = sharedState;
    local: AddSessionLocalState = {
        loading: false,
        row: {
            customerId: '',
            productType: ProductType.COACHING,
            date: moment().startOf('day').add(12, 'hours').toDate(),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        } as Session,
        locationOptions: [] as Option[],
        productTypeOptions: [] as Option[],
        customerMemberships: [] as Membership[],
        coachSelectDisabled: false,
        physioSelectDisabled: false,
        recurring: false,
        recurringDisabled: false,
        recurringEndType: RecurringEndType.OCCURRENCES,
        occurrences: 52,
        recurringEndDate: null,
        recurringWeekdayName: '',
    };

    // Functions
    async mounted() {
        this.local.locationOptions = await getResourceOptions(ApplicationResource.LOCATION, 'name');
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this).filter(
            (option) =>
                option.id === ProductType.COACHING ||
                option.id === ProductType.MASSAGE_FIRST ||
                option.id === ProductType.MASSAGE_INTRO ||
                option.id === ProductType.PHYSIOTHERAPY,
        );

        if (!this.isAdmin) {
            if (this.isCoach) {
                this.local.productTypeOptions = this.local.productTypeOptions.filter(
                    (option) => option.id === ProductType.COACHING,
                );
            }
            if (this.isPhysiotherapist) {
                this.local.productTypeOptions = this.local.productTypeOptions.filter(
                    (option) => option.id === ProductType.PHYSIOTHERAPY,
                );
            }
        }

        if (this.coachId && this.coachId !== 'undefined') {
            this.local.row = {
                ...this.local.row,
                coachId: this.coachId,
            };
        }

        if (this.physiotherapistId && this.physiotherapistId !== 'undefined' && !this.local.row.coachId) {
            this.local.row = { ...this.local.row, productType: ProductType.PHYSIOTHERAPY };
            this.local.row = {
                ...this.local.row,
                physiotherapistId: this.physiotherapistId,
            };
        }

        if (this.date) {
            this.local.row = {
                ...this.local.row,
                date: new Date(this.date).toISOString() as any as Date,
            };
        }

        if (this.customerId) {
            const customer = (await getResource<Customer>(ApplicationResource.CUSTOMER, this.customerId))!!;
            this.local.row = {
                ...this.local.row,
                customerId: this.customerId,
                locationId: customer.locationId ? customer.locationId : '',
            };
        }
    }

    add() {
        this.addResource(false);
    }

    addAndClose() {
        this.addResource(true);
    }

    async addResource(goBack: boolean) {
        try {
            let newSession = {} as Session;

            if (!this.local.row.completed) {
                this.local.row = { ...this.local.row, completed: false };
            }

            if (!this.local.row.lateCancel) {
                this.local.row = { ...this.local.row, lateCancel: false };
            }
            this.local.loading = true;
            if (this.local.recurring) {
                newSession = await postRecurringSessionRequest(this.local.recurringEndType, {
                    session: this.local.row,
                    occurrences: this.local.occurrences,
                    recurringEndDate: this.local.recurringEndDate ?? undefined,
                });
            } else {
                newSession = await postResource<Session>(this.resourceType, this.local.row);
            }

            successToast(this, 'message.sessionSaved');
            this.local.loading = false;

            if (goBack) {
                this.back();
            } else {
                await this.$router.replace(`/${this.resourceType}/${newSession.id}`);
            }
        } catch (error) {
            this.local.loading = false;
            errorToast(this);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some((role) => role === ApplicationUserRole.COACH);
    }

    get isPhysiotherapist(): boolean {
        return this.shared.context.roles.some((role) => role === ApplicationUserRole.PHYSIOTHERAPIST);
    }

    back() {
        this.$router.go(-1);
    }

    @Watch('local.row.customerId')
    async customerChange() {
        if (this.local.row.customerId) {
            const parameters = new Map<string, string>();
            parameters.set('customerId', this.local.row.customerId);
            this.local.customerMemberships = await getResources<Membership>(
                ApplicationResource.MEMBERSHIP,
                -1,
                parameters,
            );

            this.local.recurringDisabled =
                this.local.customerMemberships.length > 0 &&
                !this.local.customerMemberships.every((it) => isNil(it.endDate));

            if (this.local.recurringDisabled) {
                this.local.recurringEndType = RecurringEndType.END_DATE;
            }
        }
    }

    @Watch('local.row.coachId')
    async coachSelectChange() {
        this.local.physioSelectDisabled = !!this.local.row.coachId;
    }

    @Watch('local.row.physiotherapistId')
    async coachOrPhysioChange() {
        this.local.coachSelectDisabled = !!this.local.row.physiotherapistId;
    }

    @Watch('local.row.date')
    async dateChange() {
        this.local.recurringWeekdayName = moment(this.local.row.date).format('dddd');
    }
}
</script>
