import { apiGet, apiPut } from './index';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import { Profile } from '../../../common/framework/model/Profile';

export async function getProfile(): Promise<Profile> {
    const response = await apiGet('/api/profile');
    if (response.status === 200) {
        return (await response.json()) as Profile;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function putProfile(profile: Profile): Promise<Profile> {
    const response = await apiPut('/api/profile', profile);
    if (response.status === 200) {
        return (await response.json()) as Profile;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
