import { BrowserContext } from '../common/framework/model/BrowserContext';
import { SoftwareVersion } from '../common/framework/model/SoftwareVersion';
import { FrameworkUserRole } from '../common/framework/enumeration/FrameworkUserRole';
import { NavigationItem } from './framework/navigation/NavigationItem';
import { Feature } from '../common/framework/enumeration/Feature';
import { getAnonymousContext } from '../common/framework/service/browser_context_service';
import { Role } from '../common/framework/enumeration/Role';
import { MetadataResult } from '../common/framework/api/service/metadata/MetadataResult';

export class AppState {
    public context: BrowserContext = getAnonymousContext();
    public metadata: MetadataResult = { resources: [] };
    public version = { logo: '', name: '', title: '', version: '', features: [] } as SoftwareVersion;

    public navigation: NavigationItem[] = [];
    public navigationStartMenus: NavigationItem[] = [];
    public navigationEndMenus: NavigationItem[] = [];

    public email: string | undefined;
    public routeAfterLogin: string | undefined;

    public mobileBurgerOpen = false;
    public connected = false;
    private loading = 0;

    public get authenticated() {
        return this.context.idToken.length > 0;
    }

    public get anonymous() {
        return this.context.userId === 'anonymous';
    }

    public get admin() {
        return this.context.roles.indexOf(FrameworkUserRole.ADMIN) !== -1;
    }

    public hasRole(role: Role) {
        return this.context.roles.indexOf(role.toString()) !== -1;
    }

    public enabled(feature: Feature) {
        return this.version.features.indexOf(feature) !== -1;
    }

    public isLoading() {
        return this.loading > 0;
    }

    public increaseLoadingCounter() {
        this.loading++;
    }

    public decreaseLoadingCounter() {
        setTimeout(() => {
            if (this.loading > 0) {
                this.loading--;
            }
        }, 250);
    }
}
