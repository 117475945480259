import { apiGet, apiPost } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

export async function postSalesInvoiceToProcountorRequest(salesInvoiceId: string): Promise<any> {
    const response = await apiPost(`/api/procountor/sales-invoice/${salesInvoiceId}`, {});
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getSalesInvoiceFromProcountorRequest(salesInvoiceId: string): Promise<any> {
    const response = await apiGet(`/api/procountor/sales-invoice/${salesInvoiceId}`);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getProductsFromProcountorRequest(): Promise<any> {
    const response = await apiGet(`/api/procountor/product`);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postSalesInvoicesToProcountorRequest(parameters: Map<string, string>): Promise<any> {
    const queryParameters = parameters
        ? '?' + Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';
    const response = await apiPost(`/api/procountor/sales-invoices${queryParameters}`, {});
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
