<template>
    <div class="container-fluid mx-10-fullhd">
        <h1 class="title">{{ $t('title.sessionsPerCoach') }}</h1>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-4">
                    <div class="is-flex">
                        <b-button
                            @click="previousMonth()"
                            type="is-primary"
                            class="mr-2"
                            icon-right="arrow-left"
                            style="margin-top: 32px"
                        />
                        <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                        <b-button
                            @click="nextMonth()"
                            type="is-primary"
                            class="ml-2"
                            icon-right="arrow-right"
                            style="margin-top: 32px"
                        />
                    </div>
                </div>
            </div>
        </div>
        <b-table
            v-if="local.rows.length"
            :data="local.rows"
            :hoverable="true"
            :striped="true"
            :scrollable="true"
            :backend-sorting="true"
            :backend-pagination="true"
            :sticky-header="true"
            :loading="local.loading"
            :pagination-position="'top'"
            :per-page="local.parameters.pageSize"
            :paginated="true"
            :current-page="local.parameters.page"
            :total="this.local.total"
            @page-change="onPageChange"
        >
            <template #empty>
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                    </div>
                </section>
            </template>
            <b-table-column v-slot="props" field="vehicle_type" :label="$t('field.coach').toString()">
                {{ getCoachLabel(props.row.columnValue) }}
            </b-table-column>
            <b-table-column v-slot="props" field="active" :label="$t('field.completedSessions').toString()" numeric>
                {{ props.row.sessionCount }}
            </b-table-column>
            <b-table-column v-slot="props" field="active" :label="$t('field.lateCancelSessions').toString()" numeric>
                {{ props.row.lateCancelSessionCount }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="active"
                :label="$t('field.sessionsDoneByOtherCoaches').toString()"
                numeric
            >
                {{ props.row.sessionCountFromOtherCoaches }}
            </b-table-column>
        </b-table>
        <OfficeAndNonOfficeSessionsTable :month-filter="local.monthFilter" />
    </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import MonthField from '../../../../framework/fields/MonthField.vue';

import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import { utc } from 'moment';
import { P_QUERY } from '../../../../../common/framework/constants';
import { getNumberSessionsByRequest } from '../../../client/session_client';
import { NumberSessionsByCoachId } from '../../../../../common/application/model/number_sessions_by';
import OfficeAndNonOfficeSessionsTable from './OfficeAndNonOfficeSessionsTable.vue';
import { PageSize } from '../../../../../common/application/model/enums/PageSize';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import { getCoachOptions, Option } from '../../../service/application_options_service';

@Component({
    components: {
        AutoCompleteMultipleField,
        MonthField,
        OfficeAndNonOfficeSessionsTable,
    },
})
export default class SessionsPerCoachTable extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    // State
    shared = sharedState;
    local = {
        loading: false,
        monthFilter: utc().startOf('month').toDate().toDateString(),
        rows: [] as NumberSessionsByCoachId[],
        total: 0,
        coachOptions: [] as Option[],
        parameters: {
            page: 1,
            pageSize: PageSize.S,
            sessionCountEqual0: false,
        } as QueryParameters,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.rows = [];
        await this.getSessionsPerCoach();
        this.local.coachOptions = await getCoachOptions();
        this.local.loading = false;
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        this.local.rows = [];
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    formatStartDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 0:00:00.000000`;
    }

    formatEndDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day} 23:59:59.000000`;
    }

    async getSessionsPerCoach(): Promise<void> {
        const monthStartDate = new Date(this.local.monthFilter);
        const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 0);
        const monthStartDateString = this.formatStartDate(monthStartDate);
        const monthEndDateString = this.formatEndDate(monthEndDate);

        const completedSessionsParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate} AND completed={completed}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
            ['completed', 'true'],
        ]);

        const completedCoachSessionNumberResult = await getNumberSessionsByRequest(
            this.local.parameters.page,
            completedSessionsParams,
            this.local.parameters.pageSize,
            'coach_id',
        );
        const completedCoachSessionNumber = completedCoachSessionNumberResult.numberSessions;
        this.local.total = completedCoachSessionNumberResult.rowCount;

        const lateCancelSessionsParams = new Map<string, string>([
            [P_QUERY, 'date>={startDate} AND date<={endDate} AND lateCancel={lateCancel}'],
            ['startDate', monthStartDateString],
            ['endDate', monthEndDateString],
            ['lateCancel', 'true'],
        ]);

        const lateCancelCoachSessionNumber = (
            await getNumberSessionsByRequest(
                this.local.parameters.page,
                lateCancelSessionsParams,
                this.local.parameters.pageSize,
                'coach_id',
            )
        ).numberSessions;

        const coachNumbers = completedCoachSessionNumber.map((item: any, index: number) => {
            const lateCancelNumber = lateCancelCoachSessionNumber.find(
                (lateCancelNumber: any) => lateCancelNumber.columnValue === item.columnValue,
            );

            let lateCancelSessionCount = 0;

            if (lateCancelNumber) {
                lateCancelSessionCount = lateCancelNumber.sessionCount;
            }

            return {
                ...item,
                lateCancelSessionCount,
            };
        });

        this.local.rows.push(...coachNumbers);
    }

    get isAdmin(): boolean {
        return this.shared.admin;
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some(
            (role) => role === (ApplicationUserRole.COACH || ApplicationUserRole.PHYSIOTHERAPIST),
        );
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }

    nextMonth() {
        this.local.monthFilter = utc(this.local.monthFilter).add(1, 'month').startOf('month').toDate().toDateString();
    }

    previousMonth() {
        this.local.monthFilter = utc(this.local.monthFilter)
            .subtract(1, 'month')
            .startOf('month')
            .toDate()
            .toDateString();
    }

    getCoachLabel(coachId: string): string | undefined {
        const coach = this.local.coachOptions.find(({ id }) => id === coachId);
        return coach ? coach.label : '-';
    }
}
</script>
<style scoped>
hr.is-primary {
    border-top: 1px solid #000;
}
</style>
