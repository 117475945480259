<template>
    <div class="container-fluid panel">
        <section class="section">
            <b-tabs v-model="local.parameters.activeTab" type="is-boxed">
                <b-tab-item :label="$t('title.salesInvoices')" icon="information-outline" class="custom-tab-item">
                    <SalesInvoicesDefaultTab v-if="local.parameters.activeTab === 0" />
                </b-tab-item>
                <b-tab-item :label="$t('title.reports')" icon="clipboard-text" class="custom-tab-item">
                    <div class="row p-2 pb-6">
                        <CustomersWithoutSalesInvoiceTable v-if="local.parameters.activeTab === 1" />
                    </div>
                </b-tab-item>
            </b-tabs>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { QueryParameters } from '../../../../../../common/application/model/QueryParameters';

import { getQueryParameters, setQueryParameter } from '../../../../service/query_parameter_service';
import CustomersWithoutSalesInvoiceTable from './reports/CustomersWithoutSalesInvoiceTable.vue';
import SalesInvoicesDefaultTab from './SalesInvoicesDefaultTab.vue';

@Component({
    components: {
        SalesInvoicesDefaultTab,
        CustomersWithoutSalesInvoiceTable,
    },
})
export default class SalesInvoices extends Vue {
    local = {
        parameters: {} as QueryParameters,
    };

    async mounted() {
        this.local.parameters = getQueryParameters(this);
        if (this.local.parameters.activeTab !== 0 && this.local.parameters.activeTab !== 1) {
            this.local.parameters.activeTab = 0;
        }
    }

    @Watch('local.parameters.activeTab')
    activeTabChanged(value: number) {
        setQueryParameter(this, 'activeTab', value.toString(), false);
        this.local.parameters.activeTab = value;
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
