import type { RouteConfig } from 'vue-router';

import Customers from './components/admin/customer/Customers.vue';
import AddCustomer from './components/admin/customer/AddCustomer.vue';
import SalesInvoices from './components/admin/invoice/sales/SalesInvoices.vue';
import AddMonthlySalesInvoice from './components/admin/invoice/sales/AddMonthlySalesInvoice.vue';
import AddNextMonthlySalesInvoice from './components/admin/invoice/sales/AddNextMonthlySalesInvoice.vue';
import AddSalesInvoiceRow from './components/admin/invoice/sales/row/AddSalesInvoiceRow.vue';
import SalesInvoiceRows from './components/admin/invoice/sales/row/SalesInvoiceRows.vue';
import EditDocument from './components/admin/document/EditDocument.vue';
import ScheduledTasks from './components/admin/scheduled-task/ScheduledTasks.vue';
import Dashboard from './components/dashboard/Dashboard.vue';
import Coaches from './components/admin/coach/Coaches.vue';
import Sessions from './components/admin/session/Sessions.vue';
import CoachDetail from './components/admin/coach/CoachDetail.vue';
import CustomerDetail from './components/admin/customer/CustomerDetail.vue';
import SessionDetail from './components/admin/session/SessionDetail.vue';
import SalesInvoiceDetail from './components/admin/invoice/sales/SalesInvoiceDetail.vue';
import SalesInvoiceRowDetail from './components/admin/invoice/sales/row/SalesInvoiceRowDetail.vue';
import AddCoach from './components/admin/coach/AddCoach.vue';
import Products from './components/admin/product/Products.vue';
import ProductDetail from './components/admin/product/ProductDetail.vue';
import AddProduct from './components/admin/product/AddProduct.vue';
import AddSession from './components/admin/session/AddSession.vue';
import Memberships from './components/admin/membership/Memberships.vue';
import AddMembership from './components/admin/membership/AddMembership.vue';
import MembershipDetail from './components/admin/membership/MembershipDetail.vue';
import PerformanceUnitLoginView from './components/anonymous/PerformanceUnitLoginView.vue';
import Reports from './components/admin/reports/Reports.vue';
import Physiotherapists from './components/admin/physiotherapist/Physiotherapists.vue';
import PhysiotherapistDetail from './components/admin/physiotherapist/PhysiotherapistDetail.vue';
import AddPhysiotherapist from './components/admin/physiotherapist/AddPhysiotherapist.vue';
import CoachTaskDetail from './components/admin/coach-task/CoachTaskDetail.vue';
import CoachTasks from './components/admin/coach-task/CoachTasks.vue';
import AddCoachTask from './components/admin/coach-task/AddCoachTask.vue';
import AddWeeklyReport from './components/admin/weekly-report/AddWeeklyReport.vue';
import WeeklyReportDetail from './components/admin/weekly-report/WeeklyReportDetail.vue';
import BusinessPartners from './components/admin/business-partner/BusinessPartners.vue';
import BusinessPartnerDetail from './components/admin/business-partner/BusinessPartnerDetail.vue';
import AddBusinessPartner from "./components/admin/business-partner/AddBusinessPartner.vue";
// import Files from './components/manager/file/Files.vue';
// import AddFile from './components/manager/file/AddFile.vue';
// import EditFile from './components/manager/file/EditFile.vue';
// import ViewFile from './components/manager/file/ViewFile.vue';

export function getApplicationRoutes(): RouteConfig[] {
    return [
        { path: '/customer', component: Customers },
        { path: '/customer/add', component: AddCustomer },
        { path: '/customer/:id', component: CustomerDetail, props: true },

        { path: '/business-partner', component: BusinessPartners },
        { path: '/business-partner/add', component: AddBusinessPartner },
        { path: '/business-partner/:id', component: BusinessPartnerDetail, props: true },

        { path: '/coach', component: Coaches },
        { path: '/coach/add', component: AddCoach },
        { path: '/coach/:id', component: CoachDetail, props: true },

        { path: '/session', component: Sessions, props: true },
        { path: '/session/add', component: AddSession },
        {
            path: '/session/add-from-customer/:customerId/:coachId/:physiotherapistId',
            component: AddSession,
            props: true,
        },
        { path: '/session/add-from-coach/:coachId', component: AddSession, props: true },
        { path: '/session/add-from-physiotherapist/:physiotherapistId', component: AddSession, props: true },
        { path: '/session/add-from-dashboard/:coachId/:customerId/:date', component: AddSession, props: true },
        { path: '/session/:id', component: SessionDetail, props: true },

        { path: '/membership', component: Memberships },
        { path: '/membership/add', component: AddMembership },
        { path: '/membership/add-from-customer/:customerId', component: AddMembership, props: true },
        { path: '/membership/:id', component: MembershipDetail, props: true },

        { path: '/product', component: Products },
        { path: '/product/add', component: AddProduct },
        { path: '/product/:id', component: ProductDetail, props: true },
        /*{ path: '/session/add', component: addSession },
        { path: '/session/:id', component: SessionDetail, props: true },*/

        { path: '/sales-invoice', component: SalesInvoices },
        /*{ path: '/sales-invoice/add', component: AddSalesInvoice },*/
        { path: '/sales-invoice/add-monthly', component: AddMonthlySalesInvoice },
        { path: '/sales-invoice/add-next-monthly', component: AddNextMonthlySalesInvoice },
        { path: '/sales-invoice/:id', component: SalesInvoiceDetail, props: true },
        { path: '/sales-invoice/:salesInvoiceId/add-row', component: AddSalesInvoiceRow, props: true },

        { path: '/sales-invoice-row', component: SalesInvoiceRows },
        { path: '/sales-invoice-row/:id', component: SalesInvoiceRowDetail, props: true },

        { path: '/login', component: PerformanceUnitLoginView },

        { path: '/dashboard', component: Dashboard },

        { path: '/scheduled-task', component: ScheduledTasks },

        { path: '/file-meta/:id/edit', component: EditDocument, props: true },

        { path: '/reports', component: Reports },

        { path: '/physiotherapist', component: Physiotherapists },
        { path: '/physiotherapist/add', component: AddPhysiotherapist },
        { path: '/physiotherapist/:id', component: PhysiotherapistDetail, props: true },

        { path: '/coach-task', component: CoachTasks, props: true },
        { path: '/coach-task/add', component: AddCoachTask },
        { path: '/coach-task/add-from-customer/:customerId', component: AddCoachTask, props: true },
        { path: '/coach-task/add-from-coach/:coachId', component: AddCoachTask, props: true },
        { path: '/coach-task/:id', component: CoachTaskDetail, props: true },

        { path: '/weekly-report/add', component: AddWeeklyReport },
        { path: '/weekly-report/add-from-customer/:customerId/:coachId', component: AddWeeklyReport, props: true },
        { path: '/weekly-report/:id', component: WeeklyReportDetail, props: true },

        // TODO: re-enable this when applicable
        // { path: '/file', component: Files },
        // { path: '/file/add', component: AddFile },
        // { path: '/file/:id', component: ViewFile, props: true },
        // { path: '/file/:id/edit', component: EditFile, props: true },
    ];
}
