import Nodes from './components/admin/node/Nodes.vue';
import Users from './components/admin/users/Users.vue';
import AddUser from './components/admin/users/AddUser.vue';
import EditUser from './components/admin/users/EditUser.vue';
import ApiTokens from './components/user/token/ApiTokens.vue';
import AddApiToken from './components/user/token/AddApiToken.vue';
import Groups from './components/admin/groups/Groups.vue';
import AddGroup from './components/admin/groups/AddGroup.vue';
import EditGroup from './components/admin/groups/EditGroup.vue';
import Roles from './components/admin/role/Roles.vue';
import AddRole from './components/admin/role/AddRole.vue';
import EditRole from './components/admin/role/EditRole.vue';
import Jobs from './components/admin/job/Jobs.vue';
import AddJob from './components/admin/job/AddJob.vue';
import EditJob from './components/admin/job/EditJob.vue';
import Options from './components/admin/option/Options.vue';
import AddOption from './components/admin/option/AddOption.vue';
import EditOption from './components/admin/option/EditOption.vue';
import PasswordChangeView from './components/user/PasswordChangeView.vue';
import EditProfile from './components/user/EditProfile.vue';
import RequestPasswordChangeView from './components/anonymous/RequestPasswordChangeView.vue';
import TokenPasswordChangeView from './components/anonymous/TokenPasswordChangeView.vue';
import LoginView from './components/anonymous/LoginView.vue';
import LoginErrorView from './components/anonymous/LoginErrorView.vue';
import LoggedOutView from './components/anonymous/LoggedOutView.vue';
import type { RouteConfig } from 'vue-router';
import ChangeUserPassword from './components/admin/users/ChangeUserPassword.vue';
import SelfRegisterView from './components/anonymous/SelfRegisterView.vue';

export function getFrameworkRoutes(): Array<RouteConfig> {
    return [
        { path: '/node', component: Nodes },

        { path: '/users', component: Users },
        { path: '/users/add-user', component: AddUser },
        { path: '/users/edit-user/:id', component: EditUser, props: true },
        { path: '/users/change-user-password/:id', component: ChangeUserPassword, props: true },

        { path: '/api-token', component: ApiTokens },
        { path: '/api-token/add', component: AddApiToken },

        { path: '/groups', component: Groups },
        { path: '/groups/add', component: AddGroup },
        { path: '/groups/:id/edit', component: EditGroup, props: true },

        { path: '/roles', component: Roles },
        { path: '/roles/add', component: AddRole },
        { path: '/roles/:id/edit', component: EditRole, props: true },

        { path: '/job', component: Jobs },
        { path: '/job/add', component: AddJob },
        { path: '/job/:id/edit', component: EditJob, props: true },

        { path: '/option', component: Options },
        { path: '/option/add', component: AddOption },
        { path: '/option/:id/edit', component: EditOption, props: true },

        { path: '/profile/password-change', component: PasswordChangeView },
        { path: '/profile/edit', component: EditProfile },

        { path: '/login/request-password-change', component: RequestPasswordChangeView },
        { path: '/login/self-register', component: SelfRegisterView },
        { path: '/password-change-with-token/:token', component: TokenPasswordChangeView, props: true },

        { path: '/profile/password-change', component: PasswordChangeView },

        { path: '/login', component: LoginView },
        { path: '/login-error', component: LoginErrorView },
        { path: '/logged-out', component: LoggedOutView },
        { path: '/logout' },
    ];
}
