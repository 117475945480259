import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { Feature } from '../../../../common/framework/enumeration/Feature';
import { FrameworkModule } from '../../../../common/framework/enumeration/FrameworkModule';
import { ModuleType } from '../../../../common/framework/enumeration/ModuleType';
import { loadSpaceNavigationItems, registerSpaceNavigation } from './space_navigation_service';
import { UiModule } from '../../../../common/framework/model/UiModule';

export class SpaceModule implements UiModule {
    get type(): ModuleType {
        return FrameworkModule.SPACE_UI;
    }

    get features(): Array<Feature> {
        return [FrameworkFeature.XR_SPACES, FrameworkFeature.DATA_SPACES];
    }

    async initialize() {
        await registerSpaceNavigation();
    }

    async dispose() {}

    async onLoggedIn() {
        await loadSpaceNavigationItems();
    }

    async onLoggedOut() {
        await loadSpaceNavigationItems();
    }
}
