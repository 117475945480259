import { apiPost } from './index';
import { sharedState } from '../state';
import { BrowserContext } from '../../../common/framework/model/BrowserContext';
import { TokenPasswordChangeRequest } from '../../../common/framework/model/TokenPasswordChangeRequest';
import { TokenPasswordChange } from '../../../common/framework/model/TokenPasswordChange';
import { PasswordChange } from '../../../common/framework/model/PasswordChange';
import { UserPasswordChange } from '../../../common/framework/model/UserPasswordChange';
import { SelfRegisterRequest } from '../../../common/framework/model/SelfRegisterRequest';

export async function postRequestTokenPasswordChange(email: string): Promise<boolean> {
    const response = await apiPost('/api/request-token-password-change', new TokenPasswordChangeRequest(email));

    return response.status === 200;
}

export async function postTokenPasswordChange(password: string, passwordChangeToken: string): Promise<boolean> {
    const tokenPasswordChange = new TokenPasswordChange(password, passwordChangeToken);
    const response = await apiPost('/api/token-password-change', tokenPasswordChange);
    if (response.status === 200) {
        sharedState.context = (await response.json()) as BrowserContext;
        return true;
    } else {
        return false;
    }
}

export async function postPasswordChange(password: string, oldPassword: string): Promise<boolean> {
    const passwordChange = new PasswordChange(password, oldPassword);
    const response = await apiPost('/api/password-change', passwordChange);
    return response.status === 200;
}

export async function postUserPasswordChange(
    ownPassword: string,
    userId: string,
    userPassword: string,
): Promise<boolean> {
    const userPasswordChange: UserPasswordChange = {
        ownPassword,
        userId,
        userPassword,
    };
    const response = await apiPost('/api/admin/user-password-change', userPasswordChange);
    return response.status === 200;
}

export async function postRequestSelfRegister(email: string, firstName: string, lastName: string): Promise<boolean> {
    const request: SelfRegisterRequest = {
        email,
        firstName,
        lastName,
    };
    const response = await apiPost('/api/self-register', request);

    return response.status === 200;
}
