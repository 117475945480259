export enum ProductType {
    MEMBERSHIP = 'membership',
    COACHING = 'coaching',
    MASSAGE_FIRST = 'massage-first',
    MASSAGE_INTRO = 'massage-intro',
    MASSAGE = 'massage',
    PHYSIOTHERAPY = 'physiotherapy',
    DOUBLE_COACHING = 'double-coaching',
    HYBRID_COACHING = 'hybrid-coaching',
    HYBRID_MEMBERSHIP = 'hybrid-membership',
    GROUP_COACHING = 'group-coaching',
    HOLDING_FEE = 'holding-fee',
    ONLINE_COACHING = 'online-coaching',
    MANAGEMENT_COACHING = 'management-coaching',
    SUPERCELL_MEMBERSHIP = 'supercell-membership',
}
