import { Module } from '../model/Module';
import { Feature } from '../enumeration/Feature';
import { ModuleType } from '../enumeration/ModuleType';
import { UiModule } from '../model/UiModule';

const modules: Array<Module> = [];
const initializedModules: Array<Module> = [];

export function registerModule(module: Module) {
    modules.push(module);
}

export function getModule(type: ModuleType): Module | undefined {
    return modules.filter((m) => m.type === type)[0];
}

export async function initializeModules(enabledFeatures: Array<Feature>) {
    for (const module of modules) {
        for (const feature of module.features) {
            if (enabledFeatures.indexOf(feature) !== -1) {
                await module.initialize();
                initializedModules.push(module);
                console.log('initialized module: ' + module.type);
                break;
            }
        }
    }
}

export async function disposeModules() {
    for (const module of initializedModules) {
        await module.dispose();
        console.log('disposed module: ' + module.type);
    }
}

export async function invokeUiModuleOnLoggedIn() {
    for (const module of initializedModules) {
        await (module as UiModule).onLoggedIn();
        console.log('invoked module onLoggedIn: ' + module.type);
    }
}

export async function invokeUiModuleOnLoggedOut() {
    for (const module of initializedModules) {
        await (module as UiModule).onLoggedOut();
        console.log('invoked module onLoggedOut: ' + module.type);
    }
}
