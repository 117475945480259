<template>
    <div class="container panel">
        <section class="section">
            <h1 class="title">{{ $t('title.options') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="edit" :disabled="!local.selected">
                    <b-icon icon="pencil-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.edit') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <SelectComponent name="optionType" v-model="local.selectedOptionType" :options="local.typeOptions" />

            <b-table
                hoverable
                paginated
                striped
                :selected.sync="local.selected"
                :data="local.rows"
                :loading="local.loading"
                backend-pagination
                :total="local.total"
                :per-page="local.perPage"
                :current-page="local.page"
                @page-change="onPageChange"
                @click="onRowClick"
            >
                <b-table-column v-slot="props" field="type" width="30%" :label="$t('field.type').toString()">
                    {{ props.row.type }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="index"
                    width="50"
                    :numeric="true"
                    :label="$t('field.index').toString()"
                >
                    {{ props.row.index }}
                </b-table-column>
                <b-table-column v-slot="props" field="key" :label="$t('field.key').toString()">
                    {{ props.row.key }}
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { P_SORT_FIELDS, P_SORT_ORDERS } from '../../../../../common/framework/constants';

import { deleteResource, getResourcePaging, getResources } from '../../../client/resource';
import { Option } from '../../../../../common/framework/model/Option';
import { OptionValue } from '../../../service/options';
import SelectComponent from '../../../fields/SelectComponent.vue';
import { errorToast, successToast } from '../../../../application/service/toast_service';
import {PageSize} from "../../../../../common/application/model/enums/PageSize";

@Component({
    components: { SelectComponent },
})
export default class Options extends Vue {
    readonly resourceType = 'option';

    shared = sharedState;
    local = {
        rows: new Array<Option>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.S,
        selectedOptionType: undefined as string | undefined,
        selected: undefined as Option | undefined,
        typeOptions: [] as OptionValue[],
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/option/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/option/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const typeOptions = (await getResources<Option>(this.resourceType, -1)).map((o) => o.type);
        this.local.typeOptions = typeOptions
            .filter((t, index) => typeOptions.indexOf(t) == index)
            .sort((a, b) => a.localeCompare(b))
            .map((t) => {
                return {
                    id: t,
                    label: t,
                };
            });
        this.local.typeOptions.unshift({ id: undefined, label: ' ' });

        const parameters = new Map<string, string>();
        if (typeof this.local.selectedOptionType !== 'undefined') {
            parameters.set('type', this.local.selectedOptionType);
        }
        parameters.set(P_SORT_FIELDS, 'type,index,key');
        parameters.set(P_SORT_ORDERS, 'asc,asc,asc');

        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType, parameters)).pageCount * this.local.perPage;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1, parameters)) as Array<Option>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    @Watch('local.selectedOptionType')
    async selectedOptionTypeChanged() {
        await this.loadAsyncData();
    }

    onRowClick(row: Option): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
