import { render, staticRenderFns } from "./SalesInvoiceRows.vue?vue&type=template&id=788975b8&scoped=true"
import script from "./SalesInvoiceRows.vue?vue&type=script&lang=ts"
export * from "./SalesInvoiceRows.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788975b8",
  null
  
)

export default component.exports