<template>
    <div class="space-bottom-1">
        <validation-provider :name="getFieldName()" :rules="{ required: required }" v-slot="{ errors, valid }">
            <b-field
                :label="$t('field.' + getFieldName()).toString()"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :horizontal="horizontal"
                :message="errors"
                tag="div"
            >
                <b-datepicker
                    v-if="!readonly"
                    icon="calendar"
                    :name="getFieldName()"
                    v-model="local.date"
                    :inline="inline === true"
                    :month-names="local.monthNames"
                    :day-names="local.dayNames"
                    :disabled="disabled === true"
                    :date-formatter="dateToString"
                    :editable="true"
                    :first-day-of-week="1"
                >
                </b-datepicker>
                <span v-if="readonly">{{ formatDate(local.value) }}</span>
            </b-field>
        </validation-provider>
        <validation-provider :name="getFieldName() + 'Time'" :rules="{ required: required }" v-slot="{ errors, valid }">
            <b-field
                v-if="!readonly"
                :label="$t('field.' + getFieldName() + 'Time').toString()"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :horizontal="horizontal"
                :message="errors"
            >
                <b-timepicker
                    v-if="!readonly"
                    :name="getFieldName() + 'Time'"
                    v-model="local.time"
                    :disabled="disabled === true"
                    icon="clock"
                    hour-format="24"
                >
                </b-timepicker>
            </b-field>
        </validation-provider>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import {
    dateAndTimeToDbDateTimeString,
    dateToDbDateString,
    dateToUiDateTimeString,
} from '../../../common/framework/util/convert';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class DateTimeField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly inline!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;
    @Prop(Boolean) readonly disabled!: string;

    shared = sharedState;
    local = {
        value: undefined as Date | undefined,
        date: undefined as Date | undefined,
        time: undefined as Date | undefined,
        dayNames: [] as string[],
        monthNames: [] as string[],
    };

    async mounted() {
        this.local.monthNames = [
            this.$t('enum.month.january').toString(),
            this.$t('enum.month.february').toString(),
            this.$t('enum.month.march').toString(),
            this.$t('enum.month.april').toString(),
            this.$t('enum.month.may').toString(),
            this.$t('enum.month.june').toString(),
            this.$t('enum.month.july').toString(),
            this.$t('enum.month.august').toString(),
            this.$t('enum.month.september').toString(),
            this.$t('enum.month.october').toString(),
            this.$t('enum.month.november').toString(),
            this.$t('enum.month.december').toString(),
        ];
        this.local.dayNames = [
            this.$t('enum.weekDay.sunday').toString(),
            this.$t('enum.weekDay.monday').toString(),
            this.$t('enum.weekDay.tuesday').toString(),
            this.$t('enum.weekDay.wednesday').toString(),
            this.$t('enum.weekDay.thursday').toString(),
            this.$t('enum.weekDay.friday').toString(),
            this.$t('enum.weekDay.saturday').toString(),
        ];

        await this.modelValueChange(this.value as any as Date);
    }

    @Watch('value')
    async modelValueChange(newValue: Date | string) {
        if (typeof newValue === 'string') {
            this.local.value = new Date(newValue);
            this.local.date = new Date(newValue);
            this.local.time = new Date(newValue);
        } else {
            if (newValue) {
                this.local.value = newValue;
                this.local.date = new Date(newValue);
                this.local.time = new Date(newValue);
            } else {
                this.local.value = newValue;
                this.local.date = undefined;
                this.local.time = undefined;
            }
        }
    }

    @Watch('local.date')
    async inputDateChange(date: Date) {
        if (date) {
            if (date.getHours() < 3) {
                date.setHours(12);
            }
            this.$emit('input', dateAndTimeToDbDateTimeString(date, this.local.time!!));
        } else {
            this.$emit('input', undefined);
        }
    }

    @Watch('local.time')
    async inputTimeChange(time: Date) {
        if (this.local.date) {
            if (time.getHours() < 3) {
                time.setHours(12);
            }
            this.$emit('input', dateAndTimeToDbDateTimeString(this.local.date!!, time));
        } else {
            this.$emit('input', undefined);
        }
    }

    formatDate(date: string) {
        if (!date) {
            return '';
        }
        return dateToUiDateTimeString(new Date(date));
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }

    dateToString(date: Date) {
        if (!date) {
            return '';
        }
        return dateToDbDateString(date);
    }
}
</script>
