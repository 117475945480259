<template>
    <div class="is-vertical-center full-height">
        <div class="container">
            <section class="section">
                <div class="columns">
                    <div class="column is-6 is-offset-3 box">
                        <div class="buttons has-addons">
                            <button class="button" @click="back">
                                <b-icon icon="arrow-left" size="is-small"></b-icon>
                                <span>{{ $t('button.back') }}</span>
                            </button>
                        </div>

                        <h1 class="title">{{ $t('title.passwordChange') }}</h1>

                        <ValidationObserver v-slot="{ passes }">
                            <email-field v-model="local.email" :required="true" />
                            <button
                                class="button is-primary is-fullwidth login-button space-2 space-bottom-1"
                                @click="passes(submit)"
                            >
                                <b-icon icon="email-outline" ></b-icon>
                                <span>{{ $t('button.emailPasswordChangeInstructions') }}</span>
                            </button>
                        </ValidationObserver>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../state';
import { postRequestTokenPasswordChange } from '../../client/password';
import EmailField from '../../fields/EmailField.vue';

@Component({
    components: { EmailField, ValidationObserver },
})
export default class RequestPasswordChange extends Vue {
    shared = sharedState;
    local = {
        email: undefined,
    };

    async requestPasswordChange() {
        if (await postRequestTokenPasswordChange(this.local.email!!)) {
            this.$buefy.toast.open({
                type: 'is-success',
                message: this.$t('message.passwordChangeInstructionsSent').toString(),
            });
            this.local.email = undefined;
            await this.$router.push('/login');
        } else {
            this.$buefy.toast.open({
                type: 'is-warning',
                message: this.$t('message.passwordChangeInstructionsSendFailed').toString(),
            });
        }
    }

    async submit() {
        await this.requestPasswordChange();
    }

    back() {
        this.$router.go(-1);
    }
}
</script>

<style scoped>
.login-button {
    margin: 1rem 0 0;
}
</style>
