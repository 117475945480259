<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item
                        :label="String($t('title.info')).toString()"
                        icon="information-outline"
                        class="custom-tab-item"
                    >
                        <h1 class="title">{{ $t('title.addWeeklyReport') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="customer"
                                        v-model="local.row.customerId"
                                        resource="customer"
                                        field="firstName"
                                    ></AutoCompleteField>
                                </div>
                                <div class="column is-6">
                                    <AutoCompleteField
                                        :required="true"
                                        name="coach"
                                        v-model="local.row.coachId"
                                        resource="coach"
                                        field="firstName"
                                        :disabled="local.coachSelectDisabled"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <TextAreaField v-model="local.row.url" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateTimeField v-model="local.row.date" name="date" required inline />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(add)"
                                :disabled="!passes"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.add') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(addAndClose)"
                                :disabled="!passes"
                            >
                                <b-icon icon="plus-circle-outline"></b-icon>
                                <span>{{ $t('button.addAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import DateTimeField from '../../../../framework/fields/DateTimeField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import TextField from '../../../../framework/fields/TextField.vue';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import DateField from '../../../../framework/fields/DateField.vue';
import NumberField from '../../../../framework/fields/NumberField.vue';
import moment from 'moment';
import { WeeklyReport } from '../../../../../common/application/model/weekly_report';
import TextAreaField from "../../../../framework/fields/TextAreaField.vue";

@Component({
    components: {
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AutoCompleteField,
        DateTimeField,
        DateField,
        NumberField,
        TextAreaField
    },
})
export default class AddWeeklyReport extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.WEEKLY_REPORT;

    // Properties
    @Prop(String) readonly customerId!: string;
    @Prop(String) readonly coachId!: string;
    @Prop(String) readonly date!: string;

    // State
    shared = sharedState;
    local = {
        row: {
            date: moment().startOf('day').add(12, 'hours').toDate(),
        } as WeeklyReport,
    };

    // Functions
    async mounted() {
        if (this.coachId) {
            this.local.row = {
                ...this.local.row,
                coachId: this.coachId,
            };
        }

        if (this.date) {
            this.local.row = {
                ...this.local.row,
                date: new Date(this.date).toISOString() as any as Date,
            };
        }

        if (this.customerId) {
            this.local.row = {
                ...this.local.row,
                customerId: this.customerId,
            };
        }
    }

    add() {
        this.addResource(false);
    }

    addAndClose() {
        this.addResource(true);
    }

    async addResource(goBack: boolean) {
        try {
            const newWeeklyReport = await postResource<WeeklyReport>(this.resourceType, this.local.row);
            successToast(this, 'message.weeklyReportSaved');

            if (goBack) {
                this.back();
            } else {
                await this.$router.replace(`/${this.resourceType}/${newWeeklyReport.id}`);
            }
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
