<template>
    <b-field :label="$t('field.' + getFieldName()).toString()">
        <b-select :name="getFieldName()" v-model="local.value" class="" tag="div" :expanded="expanded">
            <option v-for="o in options" :value="o.id" :key="o.id">
                {{ o.label }}
            </option>
        </b-select>
    </b-field>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';
import { Option } from '../../application/service/application_options_service';

@Component
export default class SelectComponent extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(String) readonly value!: string;
    @Prop(Array) readonly options!: Option[];
    @Prop(Boolean) readonly expanded!: boolean;

    shared = sharedState;
    local = {
        value: undefined as string | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        this.$emit('input', newValue);
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
