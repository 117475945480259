import { sharedState } from './state';
import { FrameworkFeature } from '../../common/framework/enumeration/FrameworkFeature';

export function registerFrameworkNavigation() {
    console.log('loading framework navigation');
    sharedState.navigationEndMenus.splice(0, sharedState.navigationEndMenus.length);
    sharedState.navigationStartMenus.splice(0, sharedState.navigationStartMenus.length);
    sharedState.navigationEndMenus.unshift({
        key: 'settings',
        icon: 'cog-box',
        label: undefined,
        labelKey: '',
        url: undefined,
        dividerBefore: false,
        get visible() {
            return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
        },
        children: [
            {
                key: 'assets',
                icon: 'image',
                label: undefined,
                labelKey: 'nav.assets',
                url: '/asset',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'users',
                icon: 'account',
                label: undefined,
                labelKey: 'nav.users',
                url: '/users',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'groups',
                icon: 'account-multiple',
                label: undefined,
                labelKey: 'nav.groups',
                url: '/groups',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'roles',
                icon: 'hat-fedora',
                label: undefined,
                labelKey: 'nav.roles',
                url: '/roles',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'jobs',
                icon: 'briefcase',
                label: undefined,
                labelKey: 'nav.jobs',
                url: '/job',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'scheduled-tasks',
                icon: 'briefcase-variant',
                label: undefined,
                labelKey: 'nav.scheduledTasks',
                url: '/scheduled-task',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'node',
                icon: 'adjust',
                label: undefined,
                labelKey: 'nav.nodes',
                url: '/node',
                dividerBefore: false,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
            {
                key: 'options',
                icon: 'format-list-numbered',
                label: undefined,
                labelKey: 'nav.options',
                url: '/option',
                dividerBefore: true,
                get visible() {
                    return sharedState.admin && sharedState.enabled(FrameworkFeature.ADMIN_API);
                },
                children: [],
            },
        ],
    });

    sharedState.navigationEndMenus.push({
        key: 'profile',
        icon: 'account-outline',
        label: undefined,
        labelKey: '',
        url: undefined,
        dividerBefore: false,
        get visible() {
            return sharedState.authenticated;
        },
        children: [
            {
                key: 'passwordChange',
                icon: undefined,
                label: undefined,
                labelKey: 'nav.passwordChange',
                url: '/profile/password-change',
                dividerBefore: false,
                get visible() {
                    return sharedState.authenticated && sharedState.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION);
                },
                children: [],
            },
            {
                key: 'apiTokens',
                icon: undefined,
                label: undefined,
                labelKey: 'nav.apiTokens',
                url: '/api-token',
                dividerBefore: false,
                get visible() {
                    return sharedState.authenticated;
                },
                children: [],
            },
            {
                key: 'editProfile',
                icon: undefined,
                label: undefined,
                labelKey: 'nav.editProfile',
                url: '/profile/edit',
                dividerBefore: false,
                get visible() {
                    return sharedState.authenticated;
                },
                children: [],
            },
        ],
    });
}

/*
            <div
              v-if="shared.authenticated && shared.enabled('password-authentication')"
              class="navbar-item has-dropdown is-hoverable mobile-modal">

              <a class="navbar-link">
                <b-icon icon="account-outline" size="is-small" class="nav-icon"></b-icon>
                <span>{{$t('nav.profile')}}</span>
              </a>

              <div class="navbar-dropdown">
                <router-link
                  v-if="shared.authenticated && shared.enabled('password-authentication')"
                  class="navbar-item"
                  to="/profile/password-change">
                  <span>{{$t('nav.passwordChange')}}</span>
                </router-link>

                <router-link v-if="shared.authenticated"
                        class="navbar-item" to="/api-token">
                  <span>{{$t('nav.apiTokens')}}</span>
                </router-link>

                <router-link v-if="shared.authenticated"
                             class="navbar-item" to="/profile/edit">
                  <span>{{$t('nav.editProfile')}}</span>
                </router-link>
              </div>
            </div>
 */
