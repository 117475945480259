<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item
                        :label="String($t('title.info')).toString()"
                        icon="information-outline"
                        class="custom-tab-item"
                    >
                        <h1 class="title">{{ $t('title.physiotherapistDetail') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.userId"
                                        :options="local.physiotherapistUserOptions"
                                        :required="true"
                                        expanded
                                        name="user"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.firstName"
                                        :required="true"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.lastName"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.phoneNumber"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <EmailField
                                        v-model="local.row.email"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.street"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.city"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.zip"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.country"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(save)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(saveAndClose)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                    <b-tab-item
                        :label="String($t('title.sessions')).toString()"
                        icon="clipboard-text"
                        class="custom-tab-item"
                    >
                        <PhysiotherapistSessions :physiotherapist-id="id" />
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import EmailField from '../../../../framework/fields/EmailField.vue';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { successToast, errorToast } from '../../../service/toast_service';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { Physiotherapist } from '../../../../../common/application/model/physiotherapist';
import { getAccessibleOptions, Option } from '../../../service/application_options_service';
import PhysiotherapistSessions from './PhysiotherapistSessions.vue';

@Component({
    components: {
        EmailField,
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        PhysiotherapistSessions,
    },
})
export default class PhysiotherapistDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.PHYSIOTHERAPIST;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Physiotherapist,
        physiotherapistUserOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<Physiotherapist>(this.resourceType, this.id))!!;
        this.local.physiotherapistUserOptions = await getAccessibleOptions(FrameworkResource.USER);
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async saveResource(goBack: boolean) {
        try {
            await putResource<Physiotherapist>(this.resourceType, this.id, this.local.row);

            successToast(this, 'message.physiotherapistSaved');

            if (goBack) {
                this.back();
            }
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
