<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addUser') }}</h1>

                    <ValidationObserver v-slot="{ passes }">
                        <text-field v-model="local.firstName" :maxlength="32" :required="true" />
                        <text-field v-model="local.lastName" :maxlength="32" :required="true" />
                        <email-field v-model="local.email" :maxlength="64" :required="true" />
                        <phone-field v-model="local.phone" :maxlength="30" :required="false" />
                        <select-field
                            name="locale"
                            v-model="local.locale"
                            :options="local.localeOptions"
                            :required="true"
                        />
                        <switch-field v-model="local.locked" :required="true" />

                        <user-roles :id="id" ref="userRoles" />
                        <user-groups :id="id" ref="userGroups" />

                        <button class="button is-primary is-fullwidth login-button space-2" @click="passes(add)">
                            <b-icon icon="plus-circle-outline"></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                    </ValidationObserver>
                </div>
            </div>
        </section>
    </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { UserInfo } from '../../../../../common/framework/model/UserInfo';
import { postUser } from '../../../client/users';
import { postRequestTokenPasswordChange } from '../../../client/password';
import UserGroups from './UserGroups.vue';
import TextField from '../../../fields/TextField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import SelectField from '../../../fields/SelectField.vue';
import EmailField from '../../../fields/EmailField.vue';
import SwitchField from '../../../fields/SwitchField.vue';
import UserRoles from './UserRoles.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserRoles,
        UserGroups,
        ValidationObserver,
    },
})
export default class AddUser extends Vue {
    shared = sharedState;
    local = {
        localeOptions: [
            { id: 'fi', label: 'FI' },
            { id: 'en', label: 'EN' },
        ],
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        locale: '',
        locked: false,
    };
    id = '';

    override $refs!: {
        userRoles: UserRoles;
        userGroups: UserGroups;
    };

    async mounted() {}

    async add() {
        const addedUser = await postUser(
            new UserInfo(
                this.local.firstName,
                this.local.lastName,
                this.local.email,
                this.local.phone,
                this.local.locale,
                false,
            ),
        );
        this.id = addedUser.id;
        await (this.$refs.userRoles as any).saveWithUserId(addedUser.id);
        await (this.$refs.userGroups as any).saveWithUserId(addedUser.id);

        await this.$router.push('/users');
        if (await postRequestTokenPasswordChange(this.local.email)) {
            this.$buefy.toast.open({
                message: 'User added and invited.',
                type: 'is-success',
                position: 'is-top',
            });
        } else {
            this.$buefy.toast.open({
                message: 'User added but invitation failed.',
                type: 'is-warning',
                position: 'is-top',
            });
        }

        this.local.firstName = '';
        this.local.lastName = '';
        this.local.email = '';
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
<style scoped></style>
