import { render, staticRenderFns } from "./AddMonthlySalesInvoice.vue?vue&type=template&id=4ca335da"
import script from "./AddMonthlySalesInvoice.vue?vue&type=script&lang=ts"
export * from "./AddMonthlySalesInvoice.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports