<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item
                        :label="String($t('title.info')).toString()"
                        icon="information-outline"
                        class="custom-tab-item"
                    >
                        <div class="columns">
                            <div class="column is-6">
                                <h1 class="title">{{ $t('title.addBusinessPartner') }}</h1>
                            </div>
                        </div>
                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.name"
                                        :required="true"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.customerNumber"
                                        :required="true"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.email"
                                        :required="false"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.phoneNumber"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.invoiceChannel"
                                        :options="local.invoiceChannelOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.paymentTerms"
                                        :options="local.paymentTermsOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField v-model="local.row.externalKey" :maxlength="30" :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.language"
                                        :options="local.languageOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12" v-if="local.row.einvoiceAddress">
                                    <b-collapse class="card" animation="slide" aria-id="contentIdForA11y3">
                                        <template #trigger="props">
                                            <div
                                                class="card-header"
                                                role="button"
                                                aria-controls="contentIdForA11y3"
                                                :aria-expanded="props.open"
                                            >
                                                <p class="card-header-title">
                                                    {{ $t('field.einvoiceAddress') }}
                                                </p>
                                                <a class="card-header-icon">
                                                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
                                                </a>
                                            </div>
                                        </template>

                                        <div class="card-content">
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <TextField
                                                        v-model="local.row.einvoiceAddress.operator"
                                                        :required="false"
                                                        :maxlength="100"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                                <div class="column is-6">
                                                    <TextField
                                                        v-model="local.row.einvoiceAddress.address"
                                                        :required="false"
                                                        :maxlength="100"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                            <div class="columns">
                                                <div class="column is-6">
                                                    <TextField
                                                        v-model="local.row.einvoiceAddress.ediId"
                                                        :required="false"
                                                        :maxlength="100"
                                                        :disabled="!isAdmin"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.street"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.city"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.zip"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.country"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(save)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(saveAndClose)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import EmailField from '../../../../framework/fields/EmailField.vue';
import NumberField from '../../../../framework/fields/NumberField.vue';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';

import { sharedState } from '../../../../framework/state';
import { postResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { getSimpleEnumerationOptions } from '../../../../framework/service/options';
import { PaymentTerms } from '../../../../../common/application/model/enums/PaymentTerms';
import { InvoiceChannel } from '../../../../../common/application/model/enums/procountor/invoice_channel';

import { Option } from '../../../service/application_options_service';
import { InvoiceLanguage } from '../../../../../common/application/model/enums/procountor/invoice_language';
import { BusinessPartner } from '../../../../../common/application/model/business_partner';

@Component({
    components: {
        EmailField,
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AutoCompleteField,
        NumberField,
    },
})
export default class AddBusinessPartner extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.BUSINESS_PARTNER;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {
            country: 'FINLAND',
            invoiceChannel: InvoiceChannel.EMAIL,
            paymentTerms: 'TERM_14_DAYS' as PaymentTerms,
            language: InvoiceLanguage.FINNISH,
            einvoiceAddress: {},
        } as BusinessPartner,
        invoiceChannelOptions: [] as Option[],
        paymentTermsOptions: [] as Option[],
        countryOptions: [] as Option[],
        languageOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.invoiceChannelOptions = getSimpleEnumerationOptions(
            'InvoiceChannel',
            Object.keys(InvoiceChannel),
            this,
        );
        this.local.paymentTermsOptions = getSimpleEnumerationOptions('PaymentTerms', Object.keys(PaymentTerms), this);
        this.local.languageOptions = getSimpleEnumerationOptions('InvoiceLanguage', Object.keys(InvoiceLanguage), this);

        console.log('this.local.paymentTermsOptions', this.local.paymentTermsOptions);
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async saveResource(goBack: boolean) {
        try {
            const newBusinessPartner = await postResource<BusinessPartner>(this.resourceType, this.local.row);

            successToast(this, 'message.businessPartnerSaved');

            if (goBack) {
                this.back();
            } else {
                await this.$router.replace(`/${this.resourceType}/${newBusinessPartner.id}`);
            }
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }
}
</script>

<style scoped>
.card-header,
.collapse {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.11);
}
</style>
