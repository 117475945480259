<template>
    <div class="container panel">
        <section class="section">
            <h1 class="title">{{ $t('title.roles') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="edit" :disabled="!local.selected">
                    <b-icon icon="pencil-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.edit') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <b-table
                hoverable
                paginated
                striped
                :selected.sync="local.selected"
                :data="local.rows"
                :loading="local.loading"
                backend-pagination
                :total="local.total"
                :per-page="local.perPage"
                :current-page="local.page"
                @page-change="onPageChange"
                @click="onRowClick"
            >
                <b-table-column v-slot="props" field="name" :label="$t('field.name').toString()">
                    {{ props.row.name }}
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../state';
import { deleteResource, getResourcePaging, getResources } from '../../../client/resource';
import { Role } from '../../../../../common/framework/model/Role';
import { errorToast, successToast } from '../../../../application/service/toast_service';
import { PageSize } from '../../../../../common/application/model/enums/PageSize';

@Component
export default class Roles extends Vue {
    readonly resourceType = 'role';

    shared = sharedState;
    local = {
        rows: new Array<Role>(),
        total: 0,
        loading: false,
        page: 1,
        perPage: PageSize.S,
        selected: undefined as Role | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async onPageChange(page: number) {
        this.local.page = page;
        await this.loadAsyncData();
    }

    add() {
        this.$router.push('/roles/add');
    }

    edit() {
        if (this.local.selected) {
            this.$router.push('/roles/' + this.local.selected.id + '/edit');
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.total = (await getResourcePaging(this.resourceType)).pageCount * this.local.perPage;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as Array<Role>;
        rows.forEach((row) => {
            this.local.rows.push(row);
        });
        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    onRowClick(row: Role): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
