<template>
    <validation-provider
        :name="getFieldName()"
        :rules="{ required: required, regex: /^-?[0-9.]+$/ }"
        v-slot="{ errors, valid }"
        tag="div"
    >
        <b-field
            :label="$t(getFieldName() ? 'field.' + getFieldName() : '').toString()"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :horizontal="horizontal"
            :message="errors"
        >
            <b-input
                v-if="!readonly"
                :name="getFieldName()"
                :disabled="disabled === true"
                type="number"
                step="any"
                v-model="local.value"
            >
            </b-input>
            <span v-if="readonly">{{ local.value }}</span>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../state';
import { getFieldNameFromVModelProperty } from '../util/component_util';

@Component({
    components: { ValidationProvider },
})
export default class NumberField extends Vue {
    @Prop(String) readonly name!: string;
    @Prop(Number) readonly value!: number;
    @Prop(Boolean) readonly readonly!: string;
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Boolean) readonly horizontal!: boolean;

    shared = sharedState;
    local = {
        value: undefined as number | undefined,
    };

    async mounted() {
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: number) {
        this.local.value = newValue;
    }

    @Watch('local.value')
    async inputValueChange(newValue: string) {
        const numberValue =
            typeof newValue !== 'undefined' && newValue !== null && newValue.length > 0
                ? parseFloat(newValue)
                : undefined;
        if (typeof numberValue !== 'undefined') {
            this.$emit('input', numberValue);
        }
    }

    getFieldName() {
        return getFieldNameFromVModelProperty(this);
    }
}
</script>
