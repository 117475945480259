<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item
                        :label="String($t('title.info')).toString()"
                        icon="information-outline"
                        class="custom-tab-item"
                    >
                        <div class="columns">
                            <div class="column is-6">
                                <h1 class="title">{{ $t('title.customerDetail') }}</h1>
                            </div>
                            <div class="column is-6 is-flex-desktop is-justify-content-flex-end">
                                <b-button
                                    size="is-small"
                                    type="is-primary"
                                    @click="goToInvoicingCustomer"
                                    v-if="isAdmin && !local.selfInvoicing"
                                    class="ml-4 my-2"
                                    >{{ $t('button.goToInvoicingCustomer') }}
                                </b-button>
                                <b-tooltip
                                    :label="addMonthlyInvoiceButtonLabel"
                                    position="is-bottom"
                                    :active="!local.customerMemberships.length || local.uncompletedSessions.length > 0"
                                >
                                    <b-button
                                        size="is-small"
                                        type="is-primary"
                                        class="ml-4 my-2"
                                        @click="confirmSendMonthlyInvoice"
                                        v-if="isAdmin"
                                        :disabled="
                                            !local.customerMemberships.length ||
                                            !local.selfInvoicing ||
                                            local.uncompletedSessions.length > 0
                                        "
                                    >
                                        <b-icon icon="send" size="is-small"></b-icon>
                                        <span>{{ $t('button.addMonthlyInvoice') }}</span>
                                    </b-button>
                                </b-tooltip>
                            </div>
                        </div>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <SwitchField v-model="local.row.invoiceManually" :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.minimumInvoiceableSessions" required />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.coachGroupId"
                                        :options="local.coachGroupOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.plannedSessionsPerWeek" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.firstName"
                                        :required="true"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.lastName"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.customerNumber"
                                        :maxlength="128"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.customerState"
                                        :options="local.customerStateOptions"
                                        :required="true"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        name="coach"
                                        v-model="local.row.coachId"
                                        resource="coach"
                                        field="firstName,lastName"
                                        :query="coachesSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                                <div class="column is-6">
                                    <AutoCompleteField
                                        name="physiotherapist"
                                        v-model="local.row.physiotherapistId"
                                        resource="physiotherapist"
                                        field="firstName,lastName"
                                        :query="physiotherapistsSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.phoneNumber"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <EmailField
                                        v-model="local.row.email"
                                        :required="false"
                                        :maxlength="255"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="invoicingCustomer"
                                        v-model="local.row.invoicingCustomerId"
                                        resource="customer"
                                        field="firstName"
                                        :query="customersSelectorParams"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.street"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.city"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.zip"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <TextField
                                        v-model="local.row.country"
                                        :required="false"
                                        :maxlength="30"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <NumberField v-model="local.row.freeSessions" required :disabled="!isAdmin" />
                                </div>
                                <div class="column is-6">
                                    <NumberField v-model="local.row.bankSessions" required :disabled="!isAdmin" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.locationId"
                                        :options="local.locationOptions"
                                        :required="false"
                                        expanded
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SwitchField v-model="local.row.holdingFee" :disabled="!isAdmin" />
                                    <div v-if="local.sessionsLength > 0">
                                        <p class="help is-danger">
                                            <!--"help" class is needed to activate "is-danger" class-->
                                            {{ $t('field.customerHasSessionsInInvoiceableMonth') }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <TextField v-model="local.row.notes" :required="false" :disabled="!isAdmin" />
                                </div>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="passes(save)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(saveAndClose)"
                                v-if="isAdmin"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                    <b-tab-item
                        :label="String($t('title.sessions')).toString()"
                        icon="clipboard-text"
                        class="custom-tab-item"
                    >
                        <CustomerSessions
                            :customer-id="id"
                            :coach-id="local.row.coachId"
                            :physiotherapist-id="local.row.physiotherapistId"
                            :sessionsLength.sync="local.sessionsLength"
                            :uncompletedSessions.sync="local.uncompletedSessions"
                        />
                    </b-tab-item>
                    <b-tab-item
                        :label="String($t('title.weeklyReports')).toString()"
                        icon="video-outline"
                        class="custom-tab-item"
                    >
                        <CustomerWeekLyReports :customer-id="id" :coach-id="local.row.coachId" />
                    </b-tab-item>
                    <b-tab-item
                        :label="String($t('title.memberships')).toString()"
                        icon="currency-eur"
                        class="custom-tab-item"
                    >
                        <CustomerMemberships :customer-id="id" @memberships="getMemberships" />
                    </b-tab-item>
                    <b-tab-item
                        :label="String($t('title.beneficiaries')).toString()"
                        icon="account"
                        class="custom-tab-item"
                    >
                        <CustomerBeneficiaries :invoicing-customer-id="id" />
                    </b-tab-item>
                    <b-tab-item
                        :label="String($t('title.coachTasks')).toString()"
                        icon="calendar-check"
                        class="custom-tab-item"
                    >
                        <CustomerCoachTasks :customer-id="id" />
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import EmailField from '../../../../framework/fields/EmailField.vue';
import NumberField from '../../../../framework/fields/NumberField.vue';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { Customer } from '../../../../../common/application/model/customer';
import {
    getEnumerationOptions,
    getGroupOptions,
    getResourceOptions,
    getSimpleEnumerationOptions,
} from '../../../../framework/service/options';
import { CustomerState } from '../../../../../common/application/model/enums/CustomerState';
import { PaymentTerms } from '../../../../../common/application/model/enums/PaymentTerms';
import { InvoiceChannel } from '../../../../../common/application/model/enums/procountor/invoice_channel';
import CustomerSessions from './CustomerSessions.vue';
import CustomerMemberships from './CustomerMemberships.vue';
import {
    getCoachesSelectorParams,
    getCustomersSelectorParams,
    getPhysiotherapistsSelectorParams,
} from '../../../service/helper_service';
import { Option } from '../../../service/application_options_service';
import moment, { utc } from 'moment';
import { AddMonthlySalesInvoiceReq } from '../../../../../common/application/model/api/AddMonthlySalesInvoiceReq';
import { Membership } from '../../../../../common/application/model/membership';
import { InvoiceLanguage } from '../../../../../common/application/model/enums/procountor/invoice_language';
import { Session } from '../../../../../common/application/model/session';
import { postMonthlySalesInvoiceRequest } from '../../../client/sales_invoice_client';
import { getLastBusinessDayOfTheMonth } from '../../../../../common/application/service/query_helper_service';
import CustomerBeneficiaries from './CustomerBeneficiaries.vue';
import CustomerCoachTasks from './CustomerCoachTasks.vue';
import CustomerWeekLyReports from './CustomerWeeklyReports.vue';

@Component({
    components: {
        EmailField,
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        CustomerSessions,
        AutoCompleteField,
        CustomerMemberships,
        NumberField,
        CustomerBeneficiaries,
        CustomerCoachTasks,
        CustomerWeekLyReports,
    },
})
export default class CustomerDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.CUSTOMER;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Customer,
        customerStateOptions: [] as Option[],
        invoiceChannelOptions: [] as Option[],
        paymentTermsOptions: [] as Option[],
        countryOptions: [] as Option[],
        sessionsLength: 0,
        coachGroupOptions: [] as Option[],
        locationOptions: [] as Option[],
        customerMemberships: [] as Membership[],
        languageOptions: [] as Option[],
        selfInvoicing: true,
        uncompletedSessions: [] as Session[],
    };

    // Functions
    async mounted() {
        this.local.locationOptions = await getResourceOptions(ApplicationResource.LOCATION, 'name');
        this.local.customerStateOptions = getEnumerationOptions('CustomerState', Object.keys(CustomerState), this);
        this.local.invoiceChannelOptions = getSimpleEnumerationOptions(
            'InvoiceChannel',
            Object.keys(InvoiceChannel),
            this,
        );
        this.local.paymentTermsOptions = getSimpleEnumerationOptions('PaymentTerms', Object.keys(PaymentTerms), this);
        this.local.languageOptions = getSimpleEnumerationOptions('InvoiceLanguage', Object.keys(InvoiceLanguage), this);
        this.local.coachGroupOptions = await getGroupOptions();
        this.local.row = (await getResource<Customer>(this.resourceType, this.id))!!;
        this.local.selfInvoicing = this.local.row.id === this.local.row.invoicingCustomerId;
    }

    @Watch('local.row.invoicingCustomerId')
    invoicingCustomerChanged() {
        this.local.selfInvoicing = this.local.row.id === this.local.row.invoicingCustomerId;
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async saveResource(goBack: boolean) {
        console.log(!this.isAdmin || this.local.sessionsLength !== 0);
        try {
            await putResource<Customer>(this.resourceType, this.id, this.local.row);

            successToast(this, 'message.customerSaved');

            if (goBack) {
                this.back();
            }
        } catch (error) {
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }

    goToInvoicingCustomer() {
        this.$router.push(`/${this.resourceType}/${this.local.row.invoicingCustomerId}`);
    }

    confirmSendMonthlyInvoice() {
        let startDate: Date;

        const lastBusinessDayOfCurrentMonth = getLastBusinessDayOfTheMonth(new Date());
        if (moment(new Date()).isSame(lastBusinessDayOfCurrentMonth, 'day')) {
            startDate = utc().startOf('month').toDate(); // First day of this month
        } else {
            startDate = utc().subtract(1, 'month').startOf('month').toDate(); // First day of last month
        }

        const month = startDate.getMonth().toString();

        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmCreateMonthlyInvoice').toString(),
            message:
                this.$t('message.confirmCreateMonthlyInvoice').toString() +
                this.$t(`enum.month.${month}`).toString() +
                '?',
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                try {
                    const request: AddMonthlySalesInvoiceReq = {
                        customerId: this.id,
                        startDate,
                    };
                    const invoiceId = (await postMonthlySalesInvoiceRequest(request)).addedSalesInvoiceId;
                    successToast(this, 'message.invoiceCreated');
                    await this.$router.push(`/${ApplicationResource.SALES_INVOICE}/${invoiceId}`);
                } catch (e: any) {
                    errorToast(this, e.message);
                }
            },
        });
    }

    get coachesSelectorParams() {
        return getCoachesSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get physiotherapistsSelectorParams() {
        return getPhysiotherapistsSelectorParams(this.shared.context.scopes, this.local.row);
    }

    getMemberships(value: Membership[]) {
        this.local.customerMemberships = value;
    }

    get customersSelectorParams() {
        return getCustomersSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get addMonthlyInvoiceButtonLabel() {
        if (!this.local.customerMemberships.length) {
            return this.$t('field.customerNeedMembership').toString();
        }

        if (this.local.uncompletedSessions.length > 0) {
            return this.$t('field.sessionsAreUnfinished').toString();
        }
        
        return '-';
    }
}
</script>

<style scoped>
.card-header,
.collapse {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.11);
}
</style>
