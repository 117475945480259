<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item :label="$t('title.info').toString()" icon="information-outline" class="custom-tab-item">
                        <h1 class="title">{{ $t('title.coachTaskDetail') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="customer"
                                        v-model="local.row.customerId"
                                        resource="customer"
                                        field="firstName"
                                        :query="customersSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="coach"
                                        v-model="local.row.coachId"
                                        resource="coach"
                                        field="firstName"
                                        :query="coachesSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <TextField v-model="local.row.title" :required="false" :maxlength="30" />
                                </div>

                                <div class="column is-6">
                                    <SwitchField v-model="local.row.completed" />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateTimeField v-model="local.row.dueDate" required :disabled="!isAdmin" inline/>
                                </div>
                                <div class="column is-6">
                                    <DateTimeField v-model="local.row.completedOn" inline/>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-12">
                                    <TextAreaField v-model="local.row.description" />
                                </div>
                            </div>
                            <button class="button is-primary is-fullwidth login-button space-2" @click="passes(save)">
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                @click="passes(saveAndClose)"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                    <b-tab-item :label="$t('title.documents').toString()" icon="clipboard-text" class="custom-tab-item">
                        <AddDocument
                            :resource-id="id"
                            :resource-type="resourceType"
                            :customer-id="local.row.customerId"
                        />
                        <!--                            :upload-call-back="saveFileIdToCoachTask"-->
                    </b-tab-item>
                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import DateTimeField from '../../../../framework/fields/DateTimeField.vue';

import { sharedState } from '../../../../framework/state';
import { getResource, putResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { successToast, errorToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import { getCoachesSelectorParams, getCustomersSelectorParams } from '../../../service/helper_service';
import { CoachTask } from '../../../../../common/application/model/CoachTask';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';
import AddDocument from '../document/AddDocument.vue';

@Component({
    components: {
        SelectField,
        TextField,
        SwitchField,
        ValidationObserver,
        AutoCompleteField,
        DateTimeField,
        TextAreaField,
        AddDocument,
    },
})
export default class CoachTaskDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.COACH_TASK;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as CoachTask,
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<CoachTask>(this.resourceType, this.id))!!;
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    private async saveResource(goBack: boolean) {
        try {
            await putResource<CoachTask>(this.resourceType, this.id, this.local.row);

            successToast(this, 'message.coachTaskSaved');

            if (goBack) {
                this.back();
            }
        } catch (error) {
            errorToast(this);
        }
    }

    @Watch('local.row.completed')
    invoicingCustomerChanged() {
        if (this.local.row.completed) {
            this.local.row.completedOn = new Date();
        } else {
            this.local.row.completedOn = undefined;
        }
    }

    /*async saveFileIdToCoachTask(fileId: string) {
        try {
            await putResource<CoachTask>(this.resourceType, this.id, { ...this.local.row, fileId });

            successToast(this, 'message.addedDocumentToCoachTask');
        } catch (error) {
            errorToast(this);
        }
    }*/

    back() {
        this.$router.go(-1);
    }

    get customersSelectorParams() {
        return getCustomersSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get coachesSelectorParams() {
        return getCoachesSelectorParams(this.shared.context.scopes, this.local.row);
    }
}
</script>
