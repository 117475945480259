import { apiGet, apiPost, apiPut, apiDelete } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';
import { buildQuery } from '../../framework/client/resource';
import { Session } from '../../../common/application/model/session';
import { RecurringEndType } from '../../../common/application/model/enums/recurring_end_type';
import { RecurringUpdateType } from '../../../common/application/model/enums/recurring_update_type';

export async function getMonthlySessionsForCustomerRequest(customerId: string): Promise<any> {
    const response = await apiGet(`/api/session/customer/${customerId}`);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getSessionsForCoachByMonthRequest(coachId: string, firstDayOfMonth: string): Promise<any> {
    const response = await apiGet(`/api/session/customer/${coachId}/${firstDayOfMonth}`);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getNumberSessionsByRequest(
    page: number = -1,
    parameters: Map<string, string>,
    pageSize: number,
    column: string,
): Promise<any> {
    const queryParameters = parameters
        ? Array.from(parameters, ([key, value]) => encodeURI(key) + '=' + encodeURI(value)).join('&')
        : '';

    const response = await apiGet(`/api/session/number-sessions/${column}?${queryParameters}`, {
        page,
        pageSize,
    });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function getReportSessionsCount(parameters?: Map<string, any>, customPart?: string): Promise<any> {
    let body: any = {};
    let queryParameters = '';

    const pagingParameters = new Map<string, string>();
    let queryString = ``;

    if (parameters) {
        const query = buildQuery(parameters, queryString, body, pagingParameters, customPart);
        queryParameters = query.queryParameters;
        body = query.body;
    }

    const response = await apiPost(`/api/report/sessions-count${queryParameters}`, body);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postRecurringSessionRequest(
    recurringEndType: RecurringEndType,
    body: { session: Session; occurrences: number | undefined; recurringEndDate: Date | undefined },
): Promise<any> {
    const response = await apiPost(`/api/recurring-session/${recurringEndType}`, body);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function putRecurringSessionRequest(
    recurringUpdateType: RecurringUpdateType,
    id: string,
    session: Session,
): Promise<any> {
    const response = await apiPut(`/api/recurring-session/${id}/${recurringUpdateType}`, session);
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function deleteRecurringSessionRequest(
    recurringUpdateType: RecurringUpdateType,
    id: string,
): Promise<any> {
    const response = await apiDelete(`/api/recurring-session/${id}/${recurringUpdateType}`);
    if (response.status === 200) {
        return;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
