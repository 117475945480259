<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="box">
                    <h1 class="title">{{ $t('title.editProfile') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <TextField name="firstName" :required="true" :maxlength="32" v-model="local.row.firstName" />
                        <TextField name="lastName" :required="true" :maxlength="32" v-model="local.row.lastName" />
                        <EmailField name="email" :required="true" :maxlength="64" v-model="local.row.email" />
                        <PhoneField name="phone" :required="true" :maxlength="64" v-model="local.row.phone" />

                        <AutoCompleteField
                            name="avatarHead"
                            v-model="local.row.avatarHeadEntityId"
                            resource="entity"
                            field="name"
                            :query="new Map([['public', 'true']])"
                            :required="false"
                            v-if="shared.enabled('xr')"
                        />
                        <AutoCompleteField
                            name="avatarLeftHand"
                            v-model="local.row.avatarLeftHandEntityId"
                            resource="entity"
                            field="name"
                            :required="false"
                            v-if="shared.enabled('xr')"
                        />
                        <AutoCompleteField
                            name="avatarRightHand"
                            v-model="local.row.avatarRightHandEntityId"
                            resource="entity"
                            field="name"
                            :required="false"
                            v-if="shared.enabled('xr')"
                        />
                        <AutoCompleteField
                            name="avatarTorso"
                            v-model="local.row.avatarTorsoEntityId"
                            resource="entity"
                            field="name"
                            :required="false"
                            v-if="shared.enabled('xr')"
                        />

                        <button class="button is-primary is-fullwidth login-button pofile-2" @click="passes(save)">
                            <b-icon icon="content-save-outline"></b-icon>
                            <span>{{ $t('button.save') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../state';
import { Profile } from '../../../../common/framework/model/Profile';
import { getProfile, putProfile } from '../../client/profile';
import PhoneField from '../../fields/PhoneField.vue';
import EmailField from '../../fields/EmailField.vue';
import TextField from '../../fields/TextField.vue';
import AutoCompleteField from '../../fields/AutoCompleteField.vue';
import { Option } from '../../../application/service/application_options_service';

@Component({
    components: { EmailField, PhoneField, TextField, AutoCompleteField, ValidationObserver },
})
export default class EditProfile extends Vue {
    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        row: {} as Profile,
        typeOptions: [] as Option[],
    };

    // Functions
    async mounted() {
        this.local.row = await getProfile();
    }

    async save() {
        await putProfile(this.local.row);
        this.$buefy.toast.open({
            message: this.$t('message.profileSaved').toString(),
            type: 'is-success',
            position: 'is-top',
        });
    }
}
</script>
