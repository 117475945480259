const resizeFunctions: Map<any, () => void> = new Map();
const resizeTimeOuts: Map<any, any> = new Map();

export function addDelayedWindowResizeListenerFunction(key: any, resizeFunction: () => Promise<void>) {
    resizeFunctions.set(key, () => {
        if (resizeTimeOuts.has(key)) {
            clearTimeout(resizeTimeOuts.get(key));
        }
        resizeTimeOuts.set(key, setTimeout(resizeFunction, 250));
    });
    window.addEventListener('resize', resizeFunctions.get(key)!!);
}

export function removeDelayedWindowResizeListenerFunction(key: any, resizeFunction: () => void) {
    window.removeEventListener('resize', resizeFunctions.get(key)!!);
    if (resizeTimeOuts.has(key)) {
        clearTimeout(resizeTimeOuts.get(key));
    }
}
