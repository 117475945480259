<template>
    <validation-provider :name="getFieldName()" :rules="{ required: required }" v-slot="{ errors, valid }">
        <b-field :label="$t(getFieldName() ? 'field.' + getFieldName() : '').toString()">
            <multiselect
                label="label"
                track-by="id"
                :clear-on-select="false"
                :close-on-select="!multiple"
                :deselect-label="$t('label.deselectLabel').toString()"
                :hide-selected="false"
                :internal-search="false"
                :loading="local.isLoading"
                :multiple="multiple"
                :options="local.filteredObjects"
                :options-limit="local.pageSize"
                :placeholder="$t('field.search') + '...'"
                :select-label="$t('label.selectLabel').toString()"
                :selected-label="$t('label.selectedLabel').toString()"
                :tag-placeholder="$t('label.tagPlaceholder')"
                :taggable="taggable"
                v-model="local.selectedResources"
                @close="dropdownClosed(false)"
                @search-change="deBouncedSearch"
                @tag="addTag"
            >
                <template slot="tag" slot-scope="props"><span></span></template>
                <template slot="selection" slot-scope="{ values, search, isOpen }"
                    ><span class="multiselect__single" v-if="values.length && !isOpen"
                        >{{ $t('button.selected.' + resource + 's') }} ({{
                            values.length + '/' + local.filteredObjects.length
                        }})</span
                    ></template
                >
                <template slot="beforeList" slot-scope="props">
                    <div class="option__desc full-width">
                        <div class="multiselect__option selectHeader">
                            <div class="is-pulled-left" v-if="resource !== 'vehicle-state'">
                                <b-button @click="clearAll" size="is-small" icon-left="checkbox-blank-circle-outline">
                                    {{ $t('button.selectNone') }}
                                </b-button>
                            </div>
                            <div class="is-pulled-right">
                                <b-button @click="selectAll" size="is-small" icon-left="check-circle-outline"
                                    >{{ $t('button.selectAll') }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.label }}</span>
                    </div>
                </template>
                <span slot="noResult">{{ $t('title.noResults') }}</span>
                <span slot="noOptions">{{ $t('title.noResults') }}</span>
            </multiselect>
        </b-field>
    </validation-provider>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { debounce } from 'lodash';
import { ValidationProvider } from 'vee-validate';

import { sharedState } from '../../framework/state';
import { getResources } from '../../framework/client/resource';
import { P_TEXT_SEARCH_FIELDS, P_TEXT_SEARCH_PATTERN } from '../../../common/framework/constants';
import { getDatabaseOptions, getEnumerationOptions, OptionValue } from '../../framework/service/options';
import { getFieldNameFromVModelProperty } from '../../framework/util/component_util';

import { ApplicationResource } from '../../../common/application/enumeration/ApplicationResource';
import { FrameworkUserRole } from '../../../common/framework/enumeration/FrameworkUserRole';
import { sortAlphabetically } from '../service/helper_service';

@Component({
    components: {
        Multiselect,
        ValidationProvider,
    },
})
export default class AutoCompleteMultipleField extends Vue {
    @Prop(Boolean) readonly disabled!: string;
    @Prop(Boolean) readonly expanded!: boolean;
    @Prop(String) readonly searchField!: string;
    @Prop(String) readonly idField!: string;
    @Prop(Boolean) readonly multiple!: boolean;
    @Prop(String) readonly name!: string;
    @Prop(String) readonly resource!: ApplicationResource | 'invoice-row-type' | 'vehicle-state';
    @Prop(Boolean) readonly required!: boolean;
    @Prop(Array) readonly value!: string[];
    @Prop(Map) readonly parameters!: Map<string, string>;
    @Prop(Boolean) readonly taggable!: boolean;
    @Prop(Boolean) readonly isAdmin?: boolean;

    shared = sharedState;

    local = {
        filteredObjects: [] as OptionValue[],
        selectedResources: [] as OptionValue[],
        pageSize: 1000,
        isLoading: false,
    };

    async mounted() {
        await this.asyncFind('');
        await this.modelValueChange(this.value);
    }

    @Watch('value')
    async modelValueChange(newValue: string[]) {
        this.local.selectedResources = [];

        newValue.forEach((resourceId) => {
            const resource = this.local.filteredObjects.find(({ id }) => resourceId === id);

            if (resource) {
                this.local.selectedResources.push(resource);
            }
        });
    }

    @Watch('parameters')
    async queryValueChange() {
        await this.asyncFind('');
    }

    dropdownClosed(opened: boolean) {
        if (!opened) {
            let value: string[];

            if (this.multiple) {
                value = this.local.selectedResources.map(({ id }) => id!!);
            } else {
                value = this.local.selectedResources ? [(this.local.selectedResources as any).id] : [];
            }
            this.$emit('dropdown-closed', value, this.idField);
        }
    }

    deBouncedSearch = debounce((searchString: string) => {
        this.asyncFind(searchString);
    }, 500);

    clearAll(): void {
        this.local.selectedResources = [];
    }

    selectAll(): void {
        this.local.selectedResources = [...this.local.filteredObjects];
    }

    getFieldName(): string {
        return getFieldNameFromVModelProperty(this);
    }

    addTag(tagName: any) {
        this.$emit('tag', tagName);
    }

    private async asyncFind(query: string): Promise<void> {
        this.local.isLoading = true;
        const parameters = new Map();
        parameters.set('pageSize', this.local.pageSize);

        if (query && query.length) {
            parameters.set(P_TEXT_SEARCH_FIELDS, this.searchField);
            parameters.set(P_TEXT_SEARCH_PATTERN, '*' + query + '*');
        }

        if (this.parameters) {
            for (const key of this.parameters.keys()) {
                parameters.set(key, this.parameters.get(key));
            }
        }

        if (!sharedState.hasRole(FrameworkUserRole.ADMIN)) {
            if (!sharedState.context.scopes.coach.customerId) {
                this.local.filteredObjects = [];
                this.local.isLoading = false;
                return;
            }

            // TODO: we really don't need this right now
            // if (this.resource === ApplicationResource.CUSTOMER) {
            //     parameters.set('idIn', sharedState.context.scopes.coach.customerId!!.join(','));
            // } else {
            //     parameters.set('customerIdIn', sharedState.context.scopes.coach.customerId!!.join(','));
            // }
        }

        if (this.resource === 'invoice-row-type') {
            const objects = await getDatabaseOptions('InvoiceRowType', this);
            this.local.filteredObjects = objects
                .filter((object) => object.label.toLowerCase().includes(query.toLowerCase()));
        } else {
            this.local.filteredObjects = (await this.getOptions(this.resource, this.searchField, parameters)).sort(
                sortAlphabetically('label'),
            );
        }
        this.local.isLoading = false;
    }

    private async getOptions(resource: string, field: string, parameters: Map<string, string>) {
        return (await getResources<any>(resource, -1, parameters))
            .map((o) => {
                if (
                    this.resource === ApplicationResource.CUSTOMER ||
                    this.resource === ApplicationResource.COACH ||
                    this.resource === ApplicationResource.PHYSIOTHERAPIST
                ) {
                    return { id: o.id, label: `${o.firstName} ${o.lastName}` };
                }
                return { id: o.id, label: o[field] };
            })
            .sort(sortAlphabetically('label'));
    }
}
</script>
<style scoped>
.selectHeader {
    border-bottom: 1px solid white;
    color: white;
    margin-bottom: 8px;
}

.full-width {
    max-width: 100%;
}
</style>
