<template>
    <div class="container-fluid panel">
        <section class="section">
            <h1 class="title">{{ $t('title.businessPartners') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add" v-if="isAdmin">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="view" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="eye-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.detail') }}</span>
                </button>
                <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <div class="row" style="margin-bottom: 10px">
                <div class="columns">
                    <div class="column is-2">
                        <b-tooltip
                            :label="$t('message.searchNameCustomerNumberEmail')"
                            :active="local.showSearchInfo"
                            position="is-bottom"
                            type="is-info"
                            multilined
                        >
                            <b-field :label="$t('field.search').toString()" type="is-info">
                                <b-input
                                    :placeholder="$t('field.search')"
                                    v-model="local.parameters.textSearchPatterns"
                                    :expanded="true"
                                    @focus="local.showSearchInfo = true"
                                    @blur="local.showSearchInfo = false"
                                ></b-input>
                            </b-field>
                        </b-tooltip>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <b-table
                    :backend-sorting="true"
                    :backend-pagination="true"
                    :hoverable="true"
                    :striped="true"
                    :paginated="true"
                    :scrollable="true"
                    :sticky-header="true"
                    :current-page="local.parameters.page"
                    :data="local.rows"
                    :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                    :loading="local.loading"
                    :pagination-position="'top'"
                    :per-page="local.parameters.pageSize"
                    :selected.sync="local.selected"
                    :total="local.total"
                    @page-change="onPageChange"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left>
                        <div class="content">
                            <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id" width="5%">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="name"
                        :label="String($t('field.name')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="customerNumber"
                        :label="String($t('field.customerNumber')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.customerNumber }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="invoiceChannel"
                        :label="String($t('field.invoiceChannel')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.invoiceChannel }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="paymentTerms"
                        :label="String($t('field.paymentTerms')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.paymentTerms }}
                    </b-table-column>
                    <b-table-column
                        v-slot="props"
                        field="language"
                        :label="String($t('field.language')).toString()"
                        :sortable="true"
                    >
                        {{ props.row.language }}
                    </b-table-column>
                </b-table>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import { deleteResource, getResourcePaging, getResources } from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';

import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { debounce } from 'lodash';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { BusinessPartner } from '../../../../../common/application/model/business_partner';
import { P_TEXT_SEARCH_PATTERN } from '../../../../../common/framework/constants';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
    },
})
export default class BusinessPartners extends Vue {
    readonly resourceType = ApplicationResource.BUSINESS_PARTNER;

    shared = sharedState;
    local = {
        rows: new Array<BusinessPartner>(),
        total: 0,
        loading: false,
        selected: undefined as BusinessPartner | undefined,
        parameters: {} as QueryParameters,
        searchAbleColumns: ['name', 'customerNumber', 'email'],
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${ApplicationResource.BUSINESS_PARTNER}/add`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${ApplicationResource.BUSINESS_PARTNER}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.BUSINESS_PARTNER}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-warning',
            onConfirm: async () => {
                if (!this.local.selected) {
                    return;
                }

                try {
                    await deleteResource(this.resourceType, this.local.selected.id);
                    successToast(this, 'message.resourceDeleted');
                    await this.loadAsyncData();
                } catch (e) {
                    errorToast(this);
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this, this.local.searchAbleColumns.join(','));
        this.local.total = (await getResourcePaging(this.resourceType, parameters)).rowCount;

        this.local.rows = [];

        const rows: BusinessPartner[] = (await getResources(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
        )) as Array<BusinessPartner>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    asd() {}

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    @Watch('local.parameters.textSearchPatterns')
    searchChange(searchString: string) {
        if (this.local.loading) {
            return;
        }

        this.deBounceSearch(searchString);
    }

    private deBounceSearch = debounce((searchString: string) => {
        this.setSearch(searchString);
    }, 1200);

    private async setSearch(searchString: string | undefined) {
        if (searchString === '') {
            searchString = undefined;
        }

        setQueryParameter(this, P_TEXT_SEARCH_PATTERN, searchString, true);
        await this.loadAsyncData();
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${ApplicationResource.BUSINESS_PARTNER}/${this.local.selected.id}`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    onRowClick(row: BusinessPartner): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
