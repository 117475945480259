<template>
    <div>
        <h1 class="title">{{ $t('title.salesInvoices') }}</h1>
        <div class="buttons has-addons">
            <button class="button" @click="add" v-if="isAdmin">
                <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                <span>{{ $t('button.add') }}</span>
            </button>
            <button class="button" @click="createMonthlyInvoices" v-if="isAdmin">
                <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                <span>{{ $t('button.addMonthlyInvoices') }}</span>
            </button>
            <button class="button" @click="view" :disabled="!local.selected" v-if="isManager">
                <b-icon icon="eye-outline" size="is-small"></b-icon>
                <span>{{ $t('button.detail') }}</span>
            </button>
            <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                <b-icon icon="delete-outline" size="is-small"></b-icon>
                <span>{{ $t('button.delete') }}</span>
            </button>
            <button class="button" @click="sendTheseInvoicesToProcountor" v-if="isAdmin">
                <b-icon icon="send-outline" size="is-small"></b-icon>
                <span>{{ $t('button.sendTheseInvoicesToProcountor') }}</span>
            </button>
        </div>

        <div class="row" style="margin-bottom: 10px">
            <button class="button is-ghost is-hidden-desktop" @click="onShowFilter">
                >> {{ local.showFilter ? $t('button.hideFilters') : $t('button.showFilters') }}
            </button>
            <div class="columns" v-if="local.showFilter">
                <div class="column is-3">
                    <AutoCompleteMultipleField
                        expanded
                        multiple
                        resource="customer"
                        v-model="customerIds"
                        id-field="customerIdIn"
                        search-field="firstName"
                        name="customer"
                        @dropdown-closed="dropdownClosed"
                    ></AutoCompleteMultipleField>
                </div>
                <div class="column is-3">
                    <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" />
                </div>
                <div class="column is-2">
                    <SelectField name="status" v-model="local.parameters.status" :options="local.statusOptions" />
                </div>
                <div class="column is-2">
                    <SwitchField v-model="local.notSentToProcountor" />
                </div>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :backend-sorting="true"
                :backend-pagination="true"
                :hoverable="true"
                :striped="true"
                :paginated="true"
                :scrollable="true"
                :sticky-header="true"
                :detailed="true"
                :show-detail-icon="true"
                :current-page="local.parameters.page"
                :data="local.rows"
                :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                :loading="local.loading"
                :pagination-position="'top'"
                :per-page="local.parameters.pageSize"
                :selected.sync="local.selected"
                :total="local.total"
                detail-key="id"
                ref="salesInvoicesTable"
                @page-change="onPageChange"
                @sort="onSortChange"
                @click="onRowClick"
            >
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                        </div>
                    </section>
                </template>

                <template #top-left>
                    <div class="content">
                        <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                    </div>
                </template>

                <b-table-column v-slot="props" field="id" width="5%">
                    <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                        >{{ $t('button.detail') }}
                    </b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="id" width="5%">
                    <b-button
                        size="is-small"
                        type="is-primary"
                        @click="refreshStatus(props.row)"
                        :disabled="!props.row.procountorId"
                        >{{ $t('button.refreshStatus') }}
                    </b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="status" :label="$t('field.status')" :sortable="true">
                    {{ getStatusLabel(props.row.status) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="paymentInfo.dueDate"
                    :label="$t('field.dueDate')"
                    :sortable="true"
                >
                    {{ dateToDateString(props.row.paymentInfo.dueDate) }}
                </b-table-column>
                <b-table-column v-slot="props" field="customerId" :label="$t('field.customer')">
                    {{ getCustomerName(props.row.customerId) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="discountPercent"
                    :label="$t('field.discountPercent')"
                    :sortable="true"
                >
                    {{ props.row.discountPercent }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="invoiceChannel"
                    :label="$t('field.invoiceChannel')"
                    :sortable="true"
                >
                    {{ props.row.invoiceChannel }}
                </b-table-column>

                <template #detail="props">
                    <SalesInvoiceRows :sales-invoice-id="props.row.id"></SalesInvoiceRows>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

import SelectField from '../../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../../framework/state';
import { deleteResource, getResourcePaging, getResources, putResource } from '../../../../../framework/client/resource';
import { getOptionLabel, getSimpleEnumerationOptions } from '../../../../../framework/service/options';
import { SortOrder } from '../../../../../../common/framework/model/SortOrder';

import AutoCompleteMultipleField from '../../../../fields/AutoCompleteMultipleField.vue';
import SalesInvoiceRows from './row/SalesInvoiceRows.vue';
import MonthField from '../../../../../framework/fields/MonthField.vue';

import { SalesInvoice } from '../../../../../../common/application/model/sales_invoice';
import { getCustomerOptions, Option } from '../../../../service/application_options_service';
import { SalesInvoiceState } from '../../../../../../common/application/model/enums/SalesInvoiceState';
import { SalesInvoiceRow } from '../../../../../../common/application/model/sales_invoice_row';
import { ApplicationResource } from '../../../../../../common/application/enumeration/ApplicationResource';
import { QueryParameters } from '../../../../../../common/application/model/QueryParameters';

import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationUserRole } from '../../../../../../common/application/enumeration/ApplicationUserRole';
import { InvoiceStatus } from '../../../../../../common/application/model/enums/procountor/invoice_status';
import { formatToFinnishDate } from '../../../../service/helper_service';
import {
    getSalesInvoiceFromProcountorRequest,
    postSalesInvoicesToProcountorRequest,
} from '../../../../client/procountor_client';
import { getLastBusinessDayOfTheMonth } from '../../../../../../common/application/service/query_helper_service';
import moment, { utc } from 'moment';
import { postMonthlySalesInvoiceForAllCustomersRequest } from '../../../../client/sales_invoice_client';
import {
    P_PAGE,
    P_PAGE_SIZE,
    P_SORT_FIELD,
    P_SORT_FIELDS,
    P_SORT_ORDER,
    P_SORT_ORDERS,
} from '../../../../../../common/framework/constants';
import SwitchField from '../../../../../framework/fields/SwitchField.vue';
import { isNil } from 'lodash';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SalesInvoiceRows,
        MonthField,
        SwitchField,
    },
})
export default class SalesInvoicesDefaultTab extends Vue {
    readonly resourceType = ApplicationResource.SALES_INVOICE;

    shared = sharedState;
    local = {
        rows: new Array<SalesInvoice>(),
        total: 0,
        loading: false,
        selected: undefined as SalesInvoice | undefined,
        selectedCustomerId: undefined as string | undefined,
        customerOptions: [] as Option[],
        result: '',
        parameters: {} as QueryParameters,
        statusOptions: [] as Option[],
        showFilter: window.innerWidth >= 1023,
        monthFilter: utc().startOf('month').subtract(1, 'month').toDate().toISOString(),
        notSentToProcountor: null,
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        this.local.monthFilter = this.local.parameters['si:monthFilter'] ?? this.local.monthFilter;
        await this.loadAsyncData();
        this.local.customerOptions = await getCustomerOptions();
        this.local.statusOptions = getSimpleEnumerationOptions('InvoiceStatus', Object.keys(InvoiceStatus), this);

        this.local.loading = false;
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this);
        const monthFilter = utc(this.local.monthFilter);

        parameters.set('invoicingPeriodStart', monthFilter.clone().startOf('month').toISOString());
        parameters.set('invoicingPeriodEnd', monthFilter.clone().endOf('month').startOf('day').toISOString());

        if (this.local.notSentToProcountor === true) {
            parameters.set('procountorId', 'NULL');
        }

        this.local.total = (await getResourcePaging(this.resourceType, parameters)).rowCount;

        this.local.rows = [];

        const rows: SalesInvoice[] = await getResources(this.resourceType, this.local.parameters.page - 1, parameters);

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        if (this.local && !isNil(this.local.selected) && this.local.rows.indexOf(this.local.selected) == -1) {
            this.local.selected = undefined;
        }
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    onPageChange(page: number) {
        setQueryParameter(this, 'page', page.toString());
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.parameters.salesInvoiceState')
    async selectedSalesInvoiceStateChange(selectedSalesInvoiceState: SalesInvoiceState) {
        if (this.local.loading) {
            return;
        }

        setQueryParameter(this, 'salesInvoiceState', selectedSalesInvoiceState, true);
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.monthFilter')
    async selectedMonthFilterChange(monthFilter: string) {
        if (this.local.loading) {
            return;
        }

        setQueryParameter(this, 'si:monthFilter', monthFilter, true);
        this.local.parameters = getQueryParameters(this);
    }

    @Watch('local.parameters.status')
    async selectedSalesInvoiceStatusChange() {
        if (this.local.loading) {
            return;
        }

        if (!this.local.parameters.status) {
            delete this.local.parameters.status;
        }
        await this.loadAsyncData();
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    @Watch('local.notSentToProcountor')
    async notSentToProcountorSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    dropdownClosed(resourceIds: string[], idField: string): void {
        setQueryParameter(this, `${idField}`, resourceIds.join(','), true);
        this.local.parameters = getQueryParameters(this);
    }

    add() {
        this.$router.push(`/${this.resourceType}/add`);
    }

    createMonthlyInvoices() {
        let startDate: Date;

        const currentDate = moment();
        const lastBusinessDayOfCurrentMonth = moment(getLastBusinessDayOfTheMonth(new Date()));
        const lastDayOfCurrentMonth = moment(currentDate).endOf('month');
        if (
            currentDate.isSameOrAfter(lastBusinessDayOfCurrentMonth, 'day') &&
            currentDate.isSameOrBefore(lastDayOfCurrentMonth, 'day')
        ) {
            startDate = utc().startOf('month').toDate(); // First day of this month
        } else {
            startDate = utc().subtract(1, 'month').startOf('month').toDate(); // First day of last month
        }

        const month = startDate.getMonth().toString();

        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmCreateMonthlyInvoices').toString(),
            message:
                this.$t('message.confirmCreateMonthlyInvoices').toString() +
                this.$t(`enum.month.${month}`).toString() +
                '?',
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                try {
                    await postMonthlySalesInvoiceForAllCustomersRequest(startDate);
                    // TODO: Display the numbers successCreatingInvoiceCount, failedCreatingInvoiceCount
                    successToast(this, 'message.invoiceCreated');
                    // TODO: Announce to users that how many invoices were created and how many invoices were failed
                } catch (e) {
                    errorToast(this);
                }

                await this.loadAsyncData();
            },
        });
    }

    sendTheseInvoicesToProcountor() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.sendTheseInvoicesToProcountor').toString(),
            message: this.$t('message.sendTheseInvoicesToProcountor').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                try {
                    const parameters = new Map(Object.entries(this.local.parameters));
                    const pagingKeys = [P_SORT_FIELD, P_SORT_FIELDS, P_SORT_ORDER, P_SORT_ORDERS, P_PAGE, P_PAGE_SIZE];

                    const filteredParameters = new Map([...parameters].filter(([key]) => !pagingKeys.includes(key)));
                    const monthFilter = utc(this.local.monthFilter);

                    filteredParameters.set('invoicingPeriodStart', monthFilter.clone().startOf('month').toISOString());
                    filteredParameters.set(
                        'invoicingPeriodEnd',
                        monthFilter.clone().endOf('month').startOf('day').toISOString(),
                    );

                    const result = await postSalesInvoicesToProcountorRequest(filteredParameters);
                    if (result) {
                        await this.loadAsyncData();
                        successToast(this, result);
                    }
                } catch (e) {
                    errorToast(this);
                }

                await this.loadAsyncData();
            },
        });
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${this.resourceType}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    async refreshStatus(row?: SalesInvoice) {
        if (row) {
            const result = await getSalesInvoiceFromProcountorRequest(row.id);
            if (result) {
                await putResource<SalesInvoice>(this.resourceType, row.id, { ...row, status: result.status });
            }
        }

        await this.loadAsyncData();
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        const rows = await getResources<SalesInvoiceRow>(
                            ApplicationResource.SALES_INVOICE_ROW,
                            -1,
                            new Map([['salesInvoiceId', this.local.selected.id]]),
                        );
                        for (const row of rows) {
                            await deleteResource(ApplicationResource.SALES_INVOICE_ROW, row.id);
                        }
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');

                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    getCustomerName(id: string): string {
        return getOptionLabel(id, this.local.customerOptions);
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isManager(): boolean {
        return this.shared.hasRole(ApplicationUserRole.COACH) || this.shared.hasRole(FrameworkUserRole.MANAGER);
    }

    get customerIds(): string[] {
        return this.local.parameters.customerIdIn ? this.local.parameters.customerIdIn.split(',') : [];
    }

    onRowClick(row: SalesInvoice): boolean {
        return (
            this.$refs['salesInvoicesTable'] as Vue & {
                toggleDetails: (row: SalesInvoice) => boolean;
            }
        ).toggleDetails(row);
    }

    dateToDateString(timestamp: string) {
        const date = new Date(timestamp);
        return formatToFinnishDate(date);
    }

    getStatusLabel(status: InvoiceStatus): string {
        const invoiceStatus = this.local.statusOptions.find(({ id }) => id === status);
        return invoiceStatus ? invoiceStatus.label : '-';
    }

    onShowFilter() {
        this.local.showFilter = !this.local.showFilter;
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
