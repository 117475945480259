import { StateType } from '../../../common/framework/enumeration/StateType';

const globalStates = new Map<StateType, any>();
const globalStateAddListeners = new Map<StateType, Array<GlobalStateListener<any>>>();
const globalStateRemoveListeners = new Map<StateType, Array<GlobalStateListener<any>>>();

type GlobalStateListener<T> = (type: StateType, state: T) => Promise<void>;

export async function addGlobalStateSetListener<T>(type: StateType, listener: GlobalStateListener<T>) {
    if (!globalStateAddListeners.has(type)) {
        globalStateAddListeners.set(type, []);
    }
    globalStateAddListeners.get(type)!!.push(listener);
    if (hasGlobalState(type)) {
        await listener(type, getGlobalState(type));
    }
}

export function removeGlobalStateSetListener<T>(type: StateType, listener: GlobalStateListener<T>) {
    if (!globalStateAddListeners.has(type)) {
        globalStateAddListeners.set(type, []);
    }
    const index = globalStateAddListeners.get(type)!!.indexOf(listener);
    if (index != -1) {
        globalStateAddListeners.get(type)!!.splice(index, 1);
    }
}

export function addGlobalStateRemoveListener<T>(type: StateType, listener: GlobalStateListener<T>) {
    if (!globalStateRemoveListeners.has(type)) {
        globalStateRemoveListeners.set(type, []);
    }
    globalStateRemoveListeners.get(type)!!.push(listener);
}

export function removeGlobalStateRemoveListener<T>(type: StateType, listener: GlobalStateListener<T>) {
    if (!globalStateRemoveListeners.has(type)) {
        globalStateRemoveListeners.set(type, []);
    }
    const index = globalStateRemoveListeners.get(type)!!.indexOf(listener);
    if (index != -1) {
        globalStateRemoveListeners.get(type)!!.splice(index, 1);
    }
}

export async function setGlobalState<T>(type: StateType, state: T) {
    globalStates.set(type, state);
    if (globalStateAddListeners.has(type)) {
        for (const globalStateAddListener of globalStateAddListeners.get(type)!!) {
            globalStateAddListener(type, state).catch((e) => {
                console.error('Error in global state listener.', e);
            });
        }
    }
    console.log('global state set: ' + type);
}

export async function removeGlobalState<T>(type: StateType) {
    if (globalStates.has(type)) {
        const state = globalStates.get(type);
        globalStates.delete(type);
        if (globalStateRemoveListeners.has(type)) {
            for (const globalStateRemoveListener of globalStateRemoveListeners.get(type)!!) {
                await globalStateRemoveListener(type, state);
            }
        }
        console.log('global state removed: ' + type);
    } else {
        console.warn('global state does not exist and can not be removed: ' + type);
    }
}

export function hasGlobalState<T>(type: StateType) {
    return globalStates.has(type);
}

export function getGlobalState<T>(type: StateType): T {
    if (globalStates.has(type)) {
        return globalStates.get(type);
    } else {
        throw new Error('No such global state set: ' + type);
    }
}
