import Vue from 'vue';
import Component from 'vue-class-component';

import { sharedState } from './framework/state';
import { localizeVeeValidate } from '../i18n';
import { getProfile, putProfile } from './framework/client/profile';
import { logout } from './framework/service/authentication_service';
import { isNil } from 'lodash';

@Component
export default class App extends Vue {
    public shared = sharedState;
    public local = {
        langs: ['fi', 'en'],
        hideNavigation: false,
    };

    public async logout() {
        await logout();
    }

    public async changeLanguage(lang: string) {
        this.shared.mobileBurgerOpen = false;
        this.$i18n.locale = lang;
        localizeVeeValidate(lang);

        if (sharedState.authenticated) {
            const profile = await getProfile();
            await putProfile({ ...profile, locale: lang });
        }
    }

    // Functions
    public async mounted() {
        this.local.hideNavigation = getQueryVariable('hideNavigation') === 'true';
        console.log('hide navigation: ' + this.local.hideNavigation);
    }
}

function getQueryVariable(variable: string): string | undefined {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    const splitted = vars.map((it) => it.split('='));
    const founded = splitted.find((it) => decodeURIComponent(it[0]) === variable);

    if (isNil(founded)) {
        console.log('Query variable %s not found', variable);
    }

    return founded?.[1];
}
