<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addJob') }}</h1>

                    <validation-observer v-slot="{ passes }">
                        <TextField v-model="local.row.key" :disabled="true" />
                        <TextField v-model="local.row.description" :disabled="true" />
                        <TextField v-model="local.row.errorMessage" :disabled="true" />
                        <TextField v-model="local.row.dataTable" :disabled="true" />
                        <TextField v-model="local.row.dataId" :disabled="true" />
                        <TextAreaField v-model="local.row.errorStack" :disabled="true" />
                        <SwitchField v-model="local.row.correctiveActionRequired" :disabled="true" />
                        <TextField v-model="local.row.correctiveActionUrl" :disabled="true" />
                        <SwitchField v-model="local.row.correctiveActionCompleted" />

                        <DateField v-model="local.row.started" :disabled="true" />
                        <DateField v-model="local.row.ended" :disabled="true" />

                        <button
                            class="button is-primary is-fullwidth login-button space-2"
                            @submit="passes(add)"
                        >
                            <b-icon icon="plus-circle-outline" ></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import { postResource } from '../../../client/resource';
import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import { Job } from '../../../../../common/framework/model/Job';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import EmailField from '../../../fields/EmailField.vue';
import UserGroups from '../users/UserGroups.vue';
import TextAreaField from '../../../fields/TextAreaField.vue';
import DateTimeField from '../../../fields/DateTimeField.vue';

@Component({
    components: {
        SwitchField,
        SelectField,
        PhoneField,
        TextField,
        EmailField,
        UserGroups,
        TextAreaField,
        DateTimeField,
        ValidationObserver,
    },
})
export default class AddJob extends Vue {
    // Constants
    readonly resourceType = 'job';

    // Properties

    // State
    shared = sharedState;
    local = {
        row: {} as Job,
    };

    // Functions
    async mounted() {}

    async add() {
        const addedRow = await postResource<Job>(this.resourceType, this.local.row);
        this.back();
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
