export class UserInfo {
    public readonly firstName: string;
    public readonly lastName: string;
    public readonly email: string;
    public readonly phone: string;
    public readonly locale: string | undefined;
    public readonly locked: boolean;

    constructor(
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        locale: string | undefined,
        locked: boolean,
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phone = phone;
        this.locale = locale;
        this.locked = locked;
    }
}
