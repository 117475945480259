import { HttpMethod } from '../../../../common/framework/model/HttpMethod';
import { FrameworkServiceType } from '../../../../common/framework/enumeration/FrameworkServiceType';
import { requestService } from '../query_client';
import { MetadataResult } from '../../../../common/framework/api/service/metadata/MetadataResult';

export async function getMetadata(): Promise<MetadataResult> {
    return (await requestService<{}, {}, MetadataResult>({
        method: HttpMethod.GET,
        type: FrameworkServiceType.METADATA,
    }))!!;
}
