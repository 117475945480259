<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-10-desktop is-offset-1-desktop is-10 is-offset-1">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <b-tabs type="is-boxed">
                    <b-tab-item :label="$t('title.info')" icon="information-outline" class="custom-tab-item">
                        <h1 class="title">{{ $t('title.sessionDetail') }}</h1>

                        <validation-observer v-slot="{ passes }">
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        required
                                        name="customer"
                                        v-model="local.row.customerId"
                                        resource="customer"
                                        field="firstName,lastName"
                                        :query="customersSelectorParams"
                                        :disabled="!isAdmin"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <AutoCompleteField
                                        :required="!local.row.coachId && !local.row.physiotherapistId"
                                        name="coach"
                                        v-model="local.row.coachId"
                                        resource="coach"
                                        field="firstName,lastName"
                                        :query="coachesSelectorParams"
                                        :disabled="local.coachSelectDisabled"
                                    ></AutoCompleteField>
                                </div>
                                <div class="column is-6">
                                    <AutoCompleteField
                                        :required="!local.row.coachId && !local.row.physiotherapistId"
                                        name="physiotherapist"
                                        v-model="local.row.physiotherapistId"
                                        resource="physiotherapist"
                                        field="firstName,lastName"
                                        :query="physiotherapistsSelectorParams"
                                        :disabled="local.physioSelectDisabled"
                                    ></AutoCompleteField>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.locationId"
                                        :options="local.locationOptions"
                                        :required="true"
                                        expanded
                                        name="location"
                                        :disabled="!isAdmin"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SelectField
                                        v-model="local.row.productType"
                                        name="type"
                                        :options="local.productTypeOptions"
                                        :required="true"
                                        expanded
                                    />
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-6">
                                    <DateTimeField
                                        v-model="local.row.date"
                                        required
                                        :inline="local.dateTimeFieldInline"
                                    />
                                </div>
                                <div class="column is-6">
                                    <SwitchField v-model="local.row.completed" />
                                    <SwitchField v-model="local.row.lateCancel" />
                                </div>
                            </div>
                            <div class="content" v-if="local.recurringWeekdayName">
                                <h6>{{ $t('field.weeklyOn') }} {{ local.recurringWeekdayName }}</h6>
                            </div>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                @click="local.loading = !local.loading"
                            >
                                <span>Loading</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-2"
                                :disabled="local.loading"
                                @click="passes(save)"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.save') }}</span>
                            </button>
                            <button
                                class="button is-primary is-fullwidth login-button space-1"
                                :disabled="local.loading"
                                @click="passes(saveAndClose)"
                            >
                                <b-icon icon="content-save-outline"></b-icon>
                                <span>{{ $t('button.saveAndClose') }}</span>
                            </button>
                        </validation-observer>
                    </b-tab-item>
                </b-tabs>
                <b-modal
                    v-model="local.isModalActive"
                    :has-modal-card="true"
                    :trap-focus="true"
                    :destroy-on-hide="false"
                    aria-role="dialog"
                    aria-label="Session Modal"
                    close-button-aria-label="Close"
                    :aria-modal="true"
                >
                    <template #default="props">
                        <div class="modal-card" style="width: auto">
                            <header class="modal-card-head">
                                <p class="modal-card-title">{{ $t('field.updateOptions') }}</p>
                                <button type="button" class="delete" @click="props.close" />
                            </header>
                            <section class="modal-card-body">
                                <div class="is-flex is-flex-direction-column">
                                    <b-radio
                                        v-model="local.recurringUpdateType"
                                        native-value="all-occurrences"
                                        class="pb-6"
                                    >
                                        {{ $t('field.allOccurrences') }}
                                    </b-radio>
                                    <b-radio
                                        v-model="local.recurringUpdateType"
                                        native-value="current-and-future"
                                        class="pb-6"
                                        >{{ $t('field.currentAndFuture') }}
                                    </b-radio>
                                    <b-radio
                                        v-model="local.recurringUpdateType"
                                        native-value="only-current-occurrence"
                                        class="pb-6"
                                        >{{ $t('field.onlyCurrentOccurrence') }}
                                    </b-radio>
                                </div>
                            </section>
                            <footer class="modal-card-foot">
                                <b-button @click="props.close">{{ $t('button.close') }}</b-button>
                                <b-button
                                    type="is-primary"
                                    :disabled="local.loading"
                                    @click="recurringSessionSave(props.close)"
                                    >{{ $t('button.save') }}
                                </b-button>
                            </footer>
                        </div>
                    </template>
                </b-modal>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import TextField from '../../../../framework/fields/TextField.vue';
import SelectField from '../../../../framework/fields/SelectField.vue';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import DateTimeField from '../../../../framework/fields/DateTimeField.vue';

import { sharedState } from '../../../../framework/state';
import { getResource, getResources, putResource } from '../../../../framework/client/resource';

import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { Option } from '../../../service/application_options_service';
import AutoCompleteField from '../../../../framework/fields/AutoCompleteField.vue';
import { Session } from '../../../../../common/application/model/session';
import {
    getCoachesSelectorParams,
    getCustomersSelectorParams,
    getPhysiotherapistsSelectorParams,
} from '../../../service/helper_service';
import { getEnumerationOptions, getResourceOptions } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import { Membership } from '../../../../../common/application/model/membership';
import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { RecurringUpdateType } from '../../../../../common/application/model/enums/recurring_update_type';
import { putRecurringSessionRequest } from '../../../client/session_client';
import moment from 'moment';

@Component({
    components: { SelectField, TextField, SwitchField, ValidationObserver, AutoCompleteField, DateTimeField },
})
export default class SessionDetail extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.SESSION;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        loading: false,
        row: {} as Session,
        locationOptions: [] as Option[],
        productTypeOptions: [] as Option[],
        customerMemberships: [] as Membership[],
        coachSelectDisabled: false,
        physioSelectDisabled: false,
        isModalActive: false,
        recurringUpdateType: RecurringUpdateType.CURRENT_AND_FUTURE,
        recurringWeekdayName: '',
        dateTimeFieldInline: window.innerWidth >= 1023,
    };

    // Functions
    async mounted() {
        this.local.row = (await getResource<Session>(this.resourceType, this.id))!!;
        this.local.locationOptions = await getResourceOptions(ApplicationResource.LOCATION, 'name');
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this).filter(
            (option) =>
                option.id === ProductType.COACHING ||
                option.id === ProductType.MASSAGE_FIRST ||
                option.id === ProductType.MASSAGE_INTRO ||
                option.id === ProductType.PHYSIOTHERAPY,
        );

        if (!this.isAdmin) {
            if (this.isCoach) {
                this.local.productTypeOptions = this.local.productTypeOptions.filter(
                    (option) => option.id === ProductType.COACHING,
                );
            }
            if (this.isPhysiotherapist) {
                this.local.productTypeOptions = this.local.productTypeOptions.filter(
                    (option) => option.id === ProductType.PHYSIOTHERAPY,
                );
            }
        }

        this.updateRecurringWeekdayName(this.local.row.recurrenceCronExpression);
    }

    updateRecurringWeekdayName(recurrenceCronExpression: string | undefined) {
        if (recurrenceCronExpression) {
            const cronParts = recurrenceCronExpression.split(' ');
            const dayOfWeekAbbreviation = cronParts[4];

            const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const dayOfWeekIndex = daysOfWeek.indexOf(dayOfWeekAbbreviation);
            this.local.recurringWeekdayName = moment().day(dayOfWeekIndex).format('dddd');
        }
    }

    save() {
        if (this.local.row.recurrenceId) {
            this.local.isModalActive = true;
        } else {
            this.saveResource(false);
        }
    }

    saveAndClose() {
        if (this.local.row.recurrenceId) {
            this.local.isModalActive = true;
        } else {
            this.saveResource(true);
        }
    }

    recurringSessionSave(closedModal: any) {
        this.saveResource(false);
        closedModal();
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isCoach(): boolean {
        return this.shared.context.roles.some((role) => role === ApplicationUserRole.COACH);
    }

    get isPhysiotherapist(): boolean {
        return this.shared.context.roles.some((role) => role === ApplicationUserRole.PHYSIOTHERAPIST);
    }

    private async saveResource(goBack: boolean) {
        try {
            this.local.loading = true;

            let updatedRow: Session;
            if (this.local.row.recurrenceId) {
                updatedRow = await putRecurringSessionRequest(this.local.recurringUpdateType, this.id, this.local.row);
            } else {
                updatedRow = await putResource<Session>(this.resourceType, this.id, this.local.row);
            }

            this.updateRecurringWeekdayName(updatedRow.recurrenceCronExpression);

            this.local.loading = false;
            successToast(this, 'message.sessionSaved');

            if (goBack) {
                this.back();
            }
        } catch (error) {
            this.local.loading = false;
            errorToast(this);
        }
    }

    back() {
        this.$router.go(-1);
    }

    get customersSelectorParams() {
        return getCustomersSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get coachesSelectorParams() {
        return getCoachesSelectorParams(this.shared.context.scopes, this.local.row);
    }

    get physiotherapistsSelectorParams() {
        return getPhysiotherapistsSelectorParams(this.shared.context.scopes, this.local.row);
    }

    @Watch('local.row.customerId')
    async customerChange() {
        if (this.local.row.customerId) {
            const parameters = new Map<string, string>();
            parameters.set('customerId', this.local.row.customerId);
            this.local.customerMemberships = await getResources<Membership>(
                ApplicationResource.MEMBERSHIP,
                -1,
                parameters,
            );
        }
    }

    @Watch('local.row.coachId')
    async coachSelectChange() {
        this.local.physioSelectDisabled = !!this.local.row.coachId;
    }

    @Watch('local.row.physiotherapistId')
    async coachOrPhysioChange() {
        this.local.coachSelectDisabled = !!this.local.row.physiotherapistId;
    }

    @Watch('local.row.lateCancel')
    async LateCancelChange() {
        if (this.local.row.lateCancel) {
            this.local.row = { ...this.local.row, completed: false };
        }
    }

    @Watch('local.row.completed')
    async completedChange() {
        if (this.local.row.completed) {
            this.local.row = { ...this.local.row, lateCancel: false };
        }
    }
}
</script>
