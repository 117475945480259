<template>
    <div class="is-vertical-center full-height">
        <div class="container">
            <section class="section">
                <div class="columns">
                    <div class="column is-6 is-offset-3 box">
                        <div v-if="shared.connected">
                            <h1 class="title">{{ $t('title.login') }}</h1>
                            <ValidationObserver v-slot="{ passes }" v-if="showLogin">
                                <email-field v-model="local.email" :required="true" />
                                <password-field v-model="local.password" :required="false" @enter="passes(submit)" />

                                <button
                                    class="button is-primary is-fullwidth login-button space-bottom-2"
                                    @click="passes(submit)"
                                >
                                    <b-icon icon="login" ></b-icon>
                                    <span>{{ $t('button.login') }}</span>
                                </button>
                                <button
                                    class="button is-fullwidth login-button"
                                    @click="signInWithGoogle"
                                    v-if="shared.enabled('google-authentication')"
                                >
                                    <!--<b-icon icon="login" ></b-icon>-->
                                    <img class="sign-in-icon" src="img/icons/google.svg" />
                                    <span>{{ $t('button.signInWithGoogle') }}</span>
                                </button>
                                <button
                                    class="button is-fullwidth login-button"
                                    @click="signInWithMicrosoft"
                                    v-if="shared.enabled('microsoft-authentication')"
                                >
                                    <!--<b-icon icon="login" ></b-icon>-->
                                    <img class="sign-in-icon" src="img/icons/microsoft.svg" />
                                    <span>{{ $t('button.signInWithMicrosoft') }}</span>
                                </button>
                                <a class="button is-fullwidth login-button space-2" @click="forgotPassword">
                                    <span>{{ $t('button.forgotPassword') }}</span>
                                </a>
                                <a
                                    class="button is-fullwidth login-button"
                                    @click="selfRegister"
                                    v-if="shared.enabled('self-registration')"
                                >
                                    <span>{{ $t('button.selfRegister') }}</span>
                                </a>
                            </ValidationObserver>

                            <p class="space-2" style="text-align: center; width: 100%">
                                {{ $t('field.softwareVersion') }}: '{{ shared.version.version }}'
                            </p>
                            <p class="space-2" style="text-align: center; width: 100%">
                                <a v-if="shared.enabled('terms-of-service')" :href="$t('link.termsOfService')">{{
                                    $t('button.termsOfService')
                                }}</a
                                >&nbsp;- &nbsp;
                                <a v-if="shared.enabled('privacy-policy')" :href="$t('link.privacyPolicy')">{{
                                    $t('button.privacyPolicy')
                                }}</a>
                            </p>
                        </div>

                        <div v-if="!shared.connected">
                            {{ $t('error.network.error.connection') }}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../state';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import EmailField from '../../fields/EmailField.vue';
import PasswordField from '../../fields/PasswordField.vue';
import { passwordLogin } from '../../service/authentication_service';

@Component({
    components: { EmailField, PasswordField, ValidationObserver },
})
export default class LoginForm extends Vue {
    shared = sharedState;
    local = {
        email: '',
        password: '',
    };

    mounted() {
        if (
            !sharedState.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION) &&
            sharedState.enabled(FrameworkFeature.SAML_AUTHENTICATION)
        ) {
            window.location.href = '/saml/login';
        }
        if (sharedState.email) {
            this.local.email = sharedState.email;
        }
    }

    async login() {
        const email = this.local.email!!;
        const password = this.local.password!!;
        if (await passwordLogin(email, password)) {
            this.local.email = '';
            this.local.password = '';
            sharedState.email = '';
            if (sharedState.routeAfterLogin) {
                await this.$router.replace(sharedState.routeAfterLogin);
                sharedState.routeAfterLogin = '';
            } else {
                await this.$router.replace('/');
            }
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.loginFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    async submit() {
        await this.login();
    }

    async signInWithGoogle() {
        window.location.href = '/auth/google/login';
    }

    async signInWithMicrosoft() {
        window.location.href = '/auth/microsoft/login';
    }

    forgotPassword() {
        this.$router.push('/login/request-password-change');
    }

    selfRegister() {
        this.$router.push('/login/self-register');
    }

    get showLogin(): boolean {
        return !this.shared.authenticated && this.shared.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION);
    }
}
</script>

<style scoped>
.login-button {
    margin: 1rem 0 0;
}
</style>
