<template>
    <div class="container">
        <div class="columns">
            <div class="column is-4 is-offset-4 is-vertical-center mobile-center">
                <section class="section has-background-primary" style="border-radius: 4px">
                    <div class="has-text-centered">
                        <img src="/img/Performance_Unit_whitelogo.png" alt="Performance Unit logo" />
                    </div>

                    <div v-if="shared.connected">
                        <ValidationObserver v-slot="{ passes }" v-if="showLogin">
                            <b-button
                                size="is-medium"
                                class="mt-4 mb-6"
                                type="is-primary"
                                :expanded="true"
                                :inverted="true"
                                @click="signInWithGoogle"
                                v-if="showGoogleLogin"
                            >
                                <div style="display: flex;justify-content: space-evenly; align-items:center;">
                                    <img class="sign-in-icon" src="img/icons/google.svg" />
                                    <div style="flex-grow: 4;">{{ $t('button.signInWithGoogle') }}</div>
                                </div>
                            </b-button>
                            <email-field v-model="local.email" :required="true" />
                            <password-field v-model="local.password" :required="true" @enter="passes(login)" />
                            <b-button
                                icon-left="login"
                                type="is-primary"
                                :expanded="true"
                                :inverted="true"
                                @click="passes(login)"
                            >
                                <span>{{ $t('button.login') }}</span>
                            </b-button>
                            <b-button
                                class="mt-2"
                                type="is-warning is-light"
                                :expanded="true"
                                :inverted="true"
                                @click="forgotPassword"
                            >
                                {{ $t('button.forgotPassword') }}
                            </b-button>
                        </ValidationObserver>

                        <p class="software-version has-text-centered mt-1 has-text-warning-light">
                            {{ $t('field.softwareVersion') }}: '{{ shared.version.version }}'
                        </p>
                    </div>

                    <div v-if="!shared.connected">
                        {{ $t('error.network.error') }}
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../framework/state';
import { ValidationObserver } from 'vee-validate';
import EmailField from '../../../framework/fields/EmailField.vue';
import PasswordField from '../../../framework/fields/PasswordField.vue';
import { FrameworkFeature } from '../../../../common/framework/enumeration/FrameworkFeature';
import { passwordLogin } from '../../../framework/service/authentication_service';

@Component({
    components: { EmailField, PasswordField, ValidationObserver },
})
export default class SelkrigLoginView extends Vue {
    shared = sharedState;
    local = {
        email: '',
        password: '',
    };

    mounted() {
        if (
            !sharedState.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION) &&
            sharedState.enabled(FrameworkFeature.SAML_AUTHENTICATION)
        ) {
            window.location.href = '/saml/login';
        }
        if (this.shared.email) {
            this.local.email = this.shared.email;
        }
    }

    async login() {
        const email = this.local.email!!;
        const password = this.local.password!!;
        if (await passwordLogin(email, password)) {
            this.local.email = '';
            this.local.password = '';
            sharedState.email = '';
            if (sharedState.routeAfterLogin) {
                await this.$router.replace(sharedState.routeAfterLogin);
                sharedState.routeAfterLogin = '';
            } else {
                await this.$router.replace('/');
            }
        } else {
            this.$buefy.toast.open({
                message: this.$t('message.loginFailed').toString(),
                type: 'is-warning',
                position: 'is-top',
            });
        }
    }

    forgotPassword() {
        this.$router.push('/login/request-password-change');
    }

    get showLogin(): boolean {
        return !this.shared.authenticated && this.shared.enabled(FrameworkFeature.PASSWORD_AUTHENTICATION);
    }

    get showGoogleLogin(): boolean {
        return !this.shared.authenticated && this.shared.enabled(FrameworkFeature.GOOGLE_AUTHENTICATION);
    }

    async signInWithGoogle() {
        window.location.href = '/auth/google/login';
    }
}
</script>

<style scoped>
/deep/ .label {
    color: white;
}

.software-version {
    font-size: smaller;
}

/* hide it on small screens */
</style>
