import { P_QUERY, P_TEXT_SEARCH_FIELDS, P_TEXT_SEARCH_PATTERN } from '../../../common/framework/constants';
import { PageSize } from '../../../common/application/model/enums/PageSize';
import { QueryParameters } from '../../../common/application/model/QueryParameters';
import { SortOrder } from '../../../common/framework/model/SortOrder';
import { ApplicationResource } from '../../../common/application/enumeration/ApplicationResource';

export function setQueryParameter(
    context: any,
    parameterName: string,
    parameterValue: string | string[] | undefined,
    resetPaging?: boolean,
): void {
    const query = { ...context.$route.query };

    if (parameterValue === query[parameterName]) {
        return;
    }

    if (
        parameterValue &&
        parameterValue.length > 0 &&
        !(Array.isArray(parameterValue) && parameterValue[0] === undefined)
    ) {
        query[parameterName] = parameterValue;
    } else {
        delete query[parameterName];
    }

    if (resetPaging) {
        query.page = 1;
    }

    context.$router.replace({ query }).catch(() => {});
}

export function setQueryParameters(context: any, parameters: QueryParameters): void {
    const query = { ...context.$route.query };
    const params = { ...parameters } as any;

    for (const key of Object.keys(params)) {
        if (params[key] === query[key]) {
            continue;
        }

        if (params[key]) {
            query[String(key).toString()] = String(params[key]).toString();
        } else {
            delete query[key];
        }
    }

    context.$router.replace({ query });
}

export function getQueryParameters(component: any): QueryParameters {
    const castParameters: QueryParameters = getDefaultParameters(component.resourceType);

    for (const key of Object.keys(component.$route.query)) {
        switch (key) {
            case 'page':
            case 'pageSize':
                castParameters[key] = parseInt(component.$route.query[key] as string);
                break;
            case 'si:monthFilter':
                castParameters[key] = component.$route.query[key] as string;
                break;
            case 'activeTab':
                castParameters[key] = parseInt(component.$route.query[key] as string);
                break;
            case 'expandedView':
                castParameters.expandedView = component.$route.query[key] === 'true';
                break;
            case 'invoiceManually':
                if (component.$route.query[key] === 'true') {
                    castParameters.invoiceManually = true;
                }
                break;
            case 'customerId':
            case 'customerIdIn':
            case 'customerType':
            case 'id':
            case 'idIn':
            case 'coachId':
            case 'coachIdIn':
            case 'physiotherapistId':
            case 'physiotherapistIdIn':
            case 'invoiceRowTypeIn':
            case 'dateIn':
            case P_QUERY:
            case 'salesInvoiceState':
            case 'status':
            case 'type':
            case 'sortField':
            case 'sortFields':
            case 'sortOrder':
            case 'sortOrders':
            case 'completed':
            case 'invoicingPeriodStart':
            case 'invoicingPeriodEnd':
            case 'priceType':
            case P_TEXT_SEARCH_FIELDS:
            case P_TEXT_SEARCH_PATTERN:
                castParameters[key] = component.$route.query[key];
                break;
        }
    }

    if (!castParameters.sortField && !castParameters.sortFields) {
        castParameters.sortField = 'created';
    }

    if (component.$route.path.includes('company')) {
        castParameters.customerId = component.$route.path.split('/')[2];
    }

    return castParameters;
}

export function getQueryParametersForBackend(component: any, searchAbleColumns?: string) {
    const backendParameters = new Map();
    const queryParameters = component.local.parameters || getQueryParameters(component);

    for (const key of Object.keys(queryParameters)) {
        switch (key) {
            case P_TEXT_SEARCH_PATTERN:
                if (queryParameters[P_TEXT_SEARCH_PATTERN] === '') {
                    break;
                }
                backendParameters.set(P_TEXT_SEARCH_FIELDS, searchAbleColumns);
                backendParameters.set(P_TEXT_SEARCH_PATTERN, `%${queryParameters[P_TEXT_SEARCH_PATTERN]}%`);
                break;
            case 'pageSize':
                backendParameters.set('pageSize', parseInt(component.$route.query[key] as string));
                break;
            case 'invoiceManually':
                if ((component.$route.query[key] as string) === 'true') {
                    backendParameters.set('invoiceManually', (component.$route.query[key] as string) === 'true');
                }
                break;
            case 'customerId':
            case 'invoicingCustomerId':
            case 'customerIdIn':
            case 'customerType':
            case 'id':
            case 'idIn':
            case 'coachId':
            case 'coachIdIn':
            case 'physiotherapistId':
            case 'physiotherapistIdIn':
            case 'invoiceRowTypeIn':
            case 'monthIn':
            case 'dateIn':
            case P_QUERY:
            case 'salesInvoiceState':
            case 'status':
            case 'sortField':
            case 'sortFields':
            case 'sortOrder':
            case 'sortOrders':
            case 'completed':
            case 'invoicingPeriodStart':
            case 'invoicingPeriodEnd':
            case 'priceType':
            case 'type':
                backendParameters.set(key, queryParameters[key]);
                break;
        }
    }

    if (backendParameters.has(P_QUERY)) {
        const searchFields = String(backendParameters.get(P_TEXT_SEARCH_FIELDS)).split(',');

        backendParameters.forEach((value, key) => {
            switch (key) {
                case 'pageSize':
                case 'sortField':
                case 'sortOrder':
                case P_TEXT_SEARCH_PATTERN:
                case P_QUERY:
                    break;
                case P_TEXT_SEARCH_FIELDS:
                    const searchString = backendParameters.get(P_TEXT_SEARCH_PATTERN);

                    searchFields.forEach((field) => {
                        backendParameters.set(field, searchString);
                    });

                    backendParameters.delete(P_TEXT_SEARCH_FIELDS);
                    break;
                default:
                    break;
            }
        });

        backendParameters.delete(P_TEXT_SEARCH_PATTERN);
    }

    // Ensure some pageSize is sent to backend
    if (!backendParameters.get('pageSize')) {
        backendParameters.set('pageSize', PageSize.L);
    }

    return backendParameters;
}

function getDefaultParameters(resourceType: string): QueryParameters {
    switch (resourceType) {
        case ApplicationResource.SALES_INVOICE:
            return { ...defaultInvoiceParams };
        case ApplicationResource.FILE_META:
            return { ...defaultFileParams };
        case ApplicationResource.CUSTOMER:
            return { ...defaultCustomerParams };
        case ApplicationResource.SESSION:
            return { ...defaultSessionParams };
        default:
            return {
                sortField: 'name',
                sortOrder: SortOrder.ASC,
                page: 1,
                pageSize: PageSize.L,
            };
    }
}

export const defaultInvoiceParams: QueryParameters = {
    page: 1,
    pageSize: PageSize.L,
    sortField: 'date',
    sortOrder: SortOrder.DESC,
};

export const defaultFileParams: QueryParameters = {
    page: 1,
    pageSize: PageSize.L,
    sortField: 'created',
    sortOrder: SortOrder.ASC,
};

export const defaultCustomerParams: QueryParameters = {
    page: 1,
    pageSize: 100000 as PageSize,
    sortField: 'lastName',
    sortOrder: SortOrder.ASC,
};

export const defaultSessionParams: QueryParameters = {
    page: 1,
    pageSize: 100000 as PageSize,
    sortField: 'date',
    sortOrder: SortOrder.DESC,
};

export function getVehicleSelectorParams(parameters: QueryParameters): Map<string, string> {
    const params = new Map();

    if (parameters.id) {
        params.set('id', parameters.id);
    }

    if (parameters.customerId) {
        params.set('customerId', parameters.customerId);
    }

    if (parameters.customerIdIn) {
        params.set('customerIdIn', parameters.customerIdIn);
    }

    return params;
}

export function getAutoCompleteFieldParams(key: string, parameters: any, keyToSet?: string): Map<string, string> {
    const params = new Map();

    if (parameters[key]) {
        if (keyToSet) {
            params.set(keyToSet, parameters[key]);
        } else {
            params.set(key, parameters[key]);
        }
    }

    return params;
}
