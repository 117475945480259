<template>
    <div class="container-fluid panel mx-10-fullhd">
        <section class="section">
            <h1 class="title">{{ $t('title.sessions') }}</h1>

            <div class="buttons has-addons">
                <button class="button" @click="add">
                    <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.add') }}</span>
                </button>
                <button class="button" @click="view" :disabled="!local.selected">
                    <b-icon icon="eye-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.detail') }}</span>
                </button>
                <button class="button" @click="deleteSession" :disabled="!local.selected">
                    <b-icon icon="delete-outline" size="is-small"></b-icon>
                    <span>{{ $t('button.delete') }}</span>
                </button>
            </div>

            <div class="row" style="margin-bottom: 10px">
                <button class="button is-ghost is-hidden-desktop" @click="onShowFilter">
                    >> {{ local.showFilter ? $t('button.hideFilters') : $t('button.showFilters') }}
                </button>
                <div class="columns" v-if="local.showFilter">
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="customer"
                            v-model="customerIds"
                            id-field="customerId"
                            search-field="firstName"
                            name="customer"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="coach"
                            v-model="coachIds"
                            id-field="coachId"
                            search-field="firstName"
                            name="coach"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-3">
                        <AutoCompleteMultipleField
                            expanded
                            multiple
                            resource="physiotherapist"
                            v-model="physiotherapistIds"
                            id-field="physiotherapistId"
                            search-field="firstName"
                            name="physiotherapist"
                            @dropdown-closed="dropdownClosed"
                        ></AutoCompleteMultipleField>
                    </div>
                    <div class="column is-2">
                        <SwitchField v-model="local.parameters.completed" />
                    </div>
                </div>
                <div class="columns" v-if="local.showFilter">
                    <div class="column is-3">
                        <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                    </div>
                </div>
            </div>
            <div class="table-container">
                <b-table
                    :backend-sorting="true"
                    :backend-pagination="true"
                    :hoverable="true"
                    :striped="true"
                    :paginated="true"
                    :scrollable="true"
                    :sticky-header="true"
                    :current-page="local.parameters.page"
                    :data="local.rows"
                    :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                    :loading="local.loading"
                    :pagination-position="'top'"
                    :per-page="local.parameters.pageSize"
                    :selected.sync="local.selected"
                    :total="local.total"
                    @page-change="onPageChange"
                    @sort="onSortChange"
                    @click="onRowClick"
                >
                    <template #empty>
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                            </div>
                        </section>
                    </template>

                    <template #top-left v-if="!physiotherapistIds.length">
                        <div class="content">
                            <h6>{{ $t('field.numberOfSessions') }}: {{ local.total }}</h6>
                            <h6>{{ $t('field.completedSessions') }}: {{ local.completedSessionCount }}</h6>
                            <h6>{{ $t('field.lateCancelSessions') }}: {{ local.lateCancelSessionCount }}</h6>
                            <h6>
                                {{ $t('field.sessionsDoneByOtherCoaches') }}: {{ local.sessionsDoneByOtherCoaches }}
                            </h6>
                        </div>
                    </template>

                    <b-table-column v-slot="props" field="id" width="5%">
                        <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                            >{{ $t('button.detail') }}
                        </b-button>
                    </b-table-column>

                    <b-table-column v-slot="props" field="id2" width="5%">
                        <div v-if="!props.row.completed && !props.row.lateCancel">
                            <b-button size="is-small" type="is-info" @click="markSessionCompleted(props.row)">
                                {{ $t('button.clickToComplete') }}
                            </b-button>
                        </div>
                        <div v-if="props.row.completed">
                            <b-button size="is-small" type="is-success" @click="markSessionUncompleted(props.row)">
                                {{ $t('button.completed') }}
                            </b-button>
                        </div>
                        <div v-if="props.row.lateCancel">
                            <b-button size="is-small" type="is-purple">
                                {{ $t('button.lateCancel') }}
                            </b-button>
                        </div>
                    </b-table-column>
                    <b-table-column v-slot="props" field="date" :label="$t('field.date')" :sortable="true">
                        {{ dateToDateTimeString(props.row.date) || '-' }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="coach" :label="$t('field.coach')">
                        {{ getCoachLabel(props.row.coachId) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="physiotherapist" :label="$t('field.physiotherapist')">
                        {{ getPhysiotherapistLabel(props.row.physiotherapistId) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="customer" :label="$t('field.customer')" :sortable="true">
                        {{ getCustomerLabel(props.row.customerId) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="productType" :label="$t('field.type')">
                        {{ getProductTypeLabel(props.row.productType) }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="location" :label="$t('field.location')" :sortable="true">
                        {{ getLocationLabel(props.row.locationId) }}
                    </b-table-column>
                </b-table>
            </div>
        </section>
        <b-modal
            v-model="local.isModalActive"
            :has-modal-card="true"
            :trap-focus="true"
            :destroy-on-hide="false"
            aria-role="dialog"
            aria-label="Session Modal"
            close-button-aria-label="Close"
            :aria-modal="true"
        >
            <template #default="props">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">{{ $t('field.deleteOptions') }}</p>
                        <button type="button" class="delete" @click="props.close" />
                    </header>
                    <section class="modal-card-body">
                        <div class="is-flex is-flex-direction-column">
                            <b-radio v-model="local.recurringUpdateType" native-value="all-occurrences" class="pb-6">
                                {{ $t('field.allOccurrences') }}
                            </b-radio>
                            <b-radio v-model="local.recurringUpdateType" native-value="current-and-future" class="pb-6"
                                >{{ $t('field.currentAndFuture') }}
                            </b-radio>
                            <b-radio
                                v-model="local.recurringUpdateType"
                                native-value="only-current-occurrence"
                                class="pb-6"
                                >{{ $t('field.onlyCurrentOccurrence') }}
                            </b-radio>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <b-button @click="props.close">{{ $t('button.close') }}</b-button>
                        <b-button type="is-primary" @click="recurringSessionDelete(props.close)"
                            >{{ $t('button.delete') }}
                        </b-button>
                    </footer>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import SelectField from '../../../../framework/fields/SelectField.vue';
import { sharedState } from '../../../../framework/state';
import {
    deleteResource,
    getResourcePagingNew,
    getResources,
    getResourcesNew,
    putResource,
} from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameter,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationUserRole } from '../../../../../common/application/enumeration/ApplicationUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { getResourceLabels } from '../../../../framework/service/label_service';
import { Session } from '../../../../../common/application/model/session';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import {
    formatToFinnishDateTime,
    getCoachResourceLabel,
    getPhysiotherapistResourceLabel,
} from '../../../service/helper_service';
import { getEnumerationOptions, getOptionLabel } from '../../../../framework/service/options';
import { getCustomerOptions, Option } from '../../../service/application_options_service';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import MonthField from '../../../../framework/fields/MonthField.vue';
import moment, { utc } from 'moment';
import { deleteRecurringSessionRequest, getReportSessionsCount } from '../../../client/session_client';
import { Coach } from '../../../../../common/application/model/coach';
import { isNil } from 'lodash';
import { RecurringUpdateType } from '../../../../../common/application/model/enums/recurring_update_type';
import { P_PAGE, P_PAGE_SIZE } from '../../../../../common/framework/constants';
import { Physiotherapist } from '../../../../../common/application/model/physiotherapist';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
        MonthField,
    },
})
export default class Sessions extends Vue {
    readonly resourceType = ApplicationResource.SESSION;

    @Prop(Array) readonly customerId!: string[];
    @Prop(Array) readonly coachId!: string[];
    @Prop(Array) readonly physiotherapistId!: string[];

    shared = sharedState;
    local = {
        rows: new Array<Session>(),
        total: 0,
        loading: false,
        selected: undefined as Session | undefined,
        parameters: {} as QueryParameters,
        coachLabels: new Map<string, string>(),
        physiotherapistLabels: new Map<string, string>(),
        locationLabels: new Map<string, string>(),
        customerOptions: [] as Option[],
        productTypeOptions: [] as Option[],
        showFilter: window.innerWidth >= 1023,
        monthFilter: utc().startOf('month').toDate().toDateString(),
        completedSessionCount: 0,
        lateCancelSessionCount: 0,
        sessionsDoneByOtherCoaches: 0,
        isModalActive: false,
        recurringUpdateType: RecurringUpdateType.CURRENT_AND_FUTURE,
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this).filter(
            (option) =>
                option.id === ProductType.COACHING ||
                option.id === ProductType.MASSAGE_FIRST ||
                option.id === ProductType.MASSAGE_INTRO ||
                option.id === ProductType.PHYSIOTHERAPY,
        );
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${this.resourceType}/add`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${this.resourceType}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    deleteSession() {
        if (this.local.selected) {
            if (this.local.selected.recurrenceId) {
                this.local.isModalActive = true;
            } else {
                this.confirmDelete();
            }
        }
    }

    recurringSessionDelete(closedModal: any) {
        this.confirmDelete();
        closedModal();
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        if (this.local.selected.recurrenceId) {
                            await deleteRecurringSessionRequest(this.local.recurringUpdateType, this.local.selected.id);
                        } else {
                            await deleteResource(this.resourceType, this.local.selected.id);
                        }
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const parameters = getQueryParametersForBackend(this);
        parameters.set(P_PAGE, this.local.parameters.page ? this.local.parameters.page : 1);
        parameters.set(P_PAGE_SIZE, this.local.parameters.pageSize ? this.local.parameters.pageSize : 100000);

        this.local.rows = [];

        if (this.coachId) {
            parameters.set('coachId', this.coachId);
        }

        if (this.customerId) {
            parameters.set('customerId', this.customerId);
        }

        if (this.physiotherapistId) {
            parameters.set('physiotherapistId', this.physiotherapistId);
        }

        if (!isNil(parameters.get('completed'))) {
            parameters.set('completed', parameters.get('completed').toString());
        }

        parameters.set('startDateOfMonth', moment(new Date(this.local.monthFilter)).startOf('month').toISOString());
        parameters.set('endDateOfMonth', moment(new Date(this.local.monthFilter)).endOf('month').toISOString());

        let query = 'date>={startDateOfMonth} AND date<={endDateOfMonth}';

        this.local.total = (await getResourcePagingNew(this.resourceType, parameters, query)).rowCount;

        const rows: Session[] = (await getResourcesNew(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
            query,
        )) as Array<Session>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        await this.getCoachLabels(rows);
        /*await this.getCustomerLabels(rows);*/
        await this.getLocationLabels(rows);
        await this.getPhysiotherapistLabels(rows);
        await this.getSessionsPerCoach();

        this.local.customerOptions = await getCustomerOptions();

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }

        console.log('this.customerIds', this.customerIds);
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    @Watch('local.parameters.completed')
    async completedSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    async markSessionCompleted(session: Session): Promise<void> {
        await putResource<Session>(this.resourceType, session.id, {
            ...session,
            completed: true,
        });

        await this.loadAsyncData();
    }

    async markSessionUncompleted(session: Session): Promise<void> {
        await putResource<Session>(this.resourceType, session.id, {
            ...session,
            completed: false,
        });

        await this.loadAsyncData();
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    get isManager(): boolean {
        return this.shared.hasRole(ApplicationUserRole.COACH) || this.shared.hasRole(FrameworkUserRole.MANAGER);
    }

    onRowClick(row: Session): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    getCoachLabel(id: string): string | undefined {
        return this.local.coachLabels.get(id);
    }

    private async getCoachLabels(rows: Session[]) {
        this.local.coachLabels = await getResourceLabels<Coach>(
            rows,
            'coachId',
            ApplicationResource.COACH,
            getCoachResourceLabel,
        );
    }

    getPhysiotherapistLabel(id: string): string | undefined {
        return this.local.physiotherapistLabels.get(id);
    }

    private async getPhysiotherapistLabels(rows: Session[]) {
        this.local.physiotherapistLabels = await getResourceLabels<Physiotherapist>(
            rows,
            'physiotherapistId',
            ApplicationResource.PHYSIOTHERAPIST,
            getPhysiotherapistResourceLabel,
        );
    }

    async getSessionsPerCoach() {
        let coachIdParams = [];
        if (this.coachIds.length > 0) {
            coachIdParams = this.coachIds;
        } else {
            const coaches: Coach[] = (await getResources(ApplicationResource.COACH, -1)) as Array<Coach>;

            coachIdParams = coaches.map((coach) => coach.id);
        }

        const parameters = new Map<string, any>([
            ['coachId', coachIdParams],
            ['startDateOfMonth', moment(new Date(this.local.monthFilter)).startOf('month').toISOString()],
            ['endDateOfMonth', moment(new Date(this.local.monthFilter)).endOf('month').toISOString()],
        ]);

        if (this.customerIds.length > 0) {
            parameters.set('customerId', this.customerIds);
        }

        let queryString = 'date >= {startDateOfMonth} AND date <= {endDateOfMonth}';

        const result = await getReportSessionsCount(parameters, queryString);

        this.local.completedSessionCount = result.completedSessionCount;
        this.local.lateCancelSessionCount = result.lateCancelSessionCount;
        this.local.sessionsDoneByOtherCoaches = result.sessionsDoneByOtherCoaches;
    }

    getCustomerLabel(id: string): string | undefined {
        return getOptionLabel(id, this.local.customerOptions);
    }

    getLocationLabel(id: string): string | undefined {
        return this.local.locationLabels.get(id);
    }

    private async getLocationLabels(rows: Session[]) {
        this.local.locationLabels = await getResourceLabels(rows, 'locationId', ApplicationResource.LOCATION, 'name');
    }

    dateToDateTimeString(timestamp: string) {
        const date = new Date(timestamp);
        return formatToFinnishDateTime(date);
    }

    dropdownClosed(resourceIds: string[], idField: string): void {
        setQueryParameter(this, `${idField}`, resourceIds, true);
        this.local.parameters = getQueryParameters(this);
    }

    get customerIds() {
        /* return this.local.parameters.customerId ? this.local.parameters.customerId : [];*/
        if (Array.isArray(this.local.parameters.customerId)) {
            return this.local.parameters.customerId;
        } else {
            return this.local.parameters.customerId ? this.local.parameters.customerId.split(',') : [];
        }
    }

    get coachIds() {
        if (Array.isArray(this.local.parameters.coachId)) {
            return this.local.parameters.coachId;
        } else {
            return this.local.parameters.coachId ? this.local.parameters.coachId.split(',') : [];
        }
    }

    get physiotherapistIds() {
        if (Array.isArray(this.local.parameters.physiotherapistId)) {
            return this.local.parameters.physiotherapistId;
        } else {
            return this.local.parameters.physiotherapistId ? this.local.parameters.physiotherapistId.split(',') : [];
        }
    }

    getProductTypeLabel(productType: string): string | undefined {
        return this.local.productTypeOptions.find(({ id }) => id === productType)!!.label;
    }

    onShowFilter() {
        this.local.showFilter = !this.local.showFilter;
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
