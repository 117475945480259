<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-12 is-offset-0">
                <div class="buttons has-addons">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>

                <div class="box">
                    <h1 class="title">{{ $t('title.addApiToken') }}</h1>

                    <validation-observer v-slot="{ passes }" v-if="!local.generatedApiToken">
                        <text-field v-model="local.row.name" :maxlength="128" :required="true" />
                        <date-field v-model="local.row.expiration" :required="true" />

                        <button
                            class="button is-primary is-fullwidth login-button space-2"
                            @click="passes(add)"
                        >
                            <b-icon icon="plus-circle-outline" ></b-icon>
                            <span>{{ $t('button.add') }}</span>
                        </button>
                    </validation-observer>

                    <div v-if="local.generatedApiToken">
                        <h4 class="title is-4">{{ $t('field.apiToken') }}</h4>
                        <span>{{ local.generatedApiToken }}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { sharedState } from '../../../state';
import SwitchField from '../../../fields/SwitchField.vue';
import SelectField from '../../../fields/SelectField.vue';
import PhoneField from '../../../fields/PhoneField.vue';
import TextField from '../../../fields/TextField.vue';
import EmailField from '../../../fields/EmailField.vue';
import { GenerateApiTokenRequest } from '../../../../../common/framework/model/GenerateApiTokenRequest';
import DateField from '../../../fields/DateField.vue';
import { postGenerateApiToken } from '../../../client/api_token_client';

@Component({
    components: { DateField, SwitchField, SelectField, PhoneField, TextField, EmailField, ValidationObserver },
})
export default class AddApiToken extends Vue {
    shared = sharedState;
    local = {
        row: {} as GenerateApiTokenRequest,
        generatedApiToken: undefined as string | undefined,
    };

    async add() {
        this.local.generatedApiToken = (await postGenerateApiToken(this.local.row)).token;
    }

    back() {
        this.$router.go(-1);
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
