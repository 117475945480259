<template>
    <validation-observer v-slot="{ passes }">
        <h1 class="title">{{ $t('title.ownCustomerSessions') }}</h1>
        <div class="buttons has-addons">
            <button class="button" @click="add" v-if="isAdmin">
                <b-icon icon="plus-circle-outline" size="is-small"></b-icon>
                <span>{{ $t('button.add') }}</span>
            </button>
            <button class="button" @click="confirmDelete" :disabled="!local.selected" v-if="isAdmin">
                <b-icon icon="delete-outline" size="is-small"></b-icon>
                <span>{{ $t('button.delete') }}</span>
            </button>
        </div>
        <div class="row" style="margin-bottom: 10px">
            <div class="columns">
                <div class="column is-3">
                    <MonthField name="month" v-model="local.monthFilter" :minDate="minDate" :required="true" />
                </div>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :backend-sorting="true"
                :backend-pagination="true"
                :hoverable="true"
                :striped="true"
                :paginated="true"
                :scrollable="true"
                :sticky-header="true"
                :current-page="local.parameters.page"
                :data="local.rows"
                :default-sort="[local.parameters.sortField, local.parameters.sortOrder]"
                :loading="local.loading"
                :pagination-position="'top'"
                :per-page="local.parameters.pageSize"
                :selected.sync="local.selected"
                :total="local.total"
                @page-change="onPageChange"
                @sort="onSortChange"
                @click="onRowClick"
            >
                <template #empty>
                    <section class="section">
                        <div class="content has-text-grey has-text-centered">
                            <h3 v-if="!local.loading">{{ $t('title.noResults') }}</h3>
                        </div>
                    </section>
                </template>

                <template #top-left>
                    <div class="content">
                        <h6>{{ $t('field.total') }}: {{ local.total }}</h6>
                    </div>
                </template>

                <b-table-column v-slot="props" field="id" :visible="isAdmin">
                    <b-button size="is-small" type="is-primary" @click="detail(props.row.id)"
                        >{{ $t('button.detail') }}
                    </b-button>
                </b-table-column>
                <b-table-column v-slot="props" field="id2" width="5%">
                    <div v-if="!props.row.completed && !props.row.lateCancel">
                        <b-button size="is-small" type="is-info" @click="markSessionCompleted(props.row)">
                            {{ $t('button.clickToComplete') }}
                        </b-button>
                    </div>
                    <div v-if="props.row.completed">
                        <b-button size="is-small" type="is-success" @click="markSessionUncompleted(props.row)">
                            {{ $t('button.completed') }}
                        </b-button>
                    </div>
                    <div v-if="props.row.lateCancel">
                        <b-button size="is-small" type="is-purple">
                            {{ $t('button.lateCancel') }}
                        </b-button>
                    </div>
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="date"
                    :label="String($t('field.date')).toString()"
                    :sortable="true"
                >
                    {{ dateToDateTimeString(props.row.date) || '-' }}
                </b-table-column>
                <b-table-column v-slot="props" field="coach" :label="String($t('field.coach')).toString()">
                    {{ getCoachLabel(props.row.coachId) }}
                </b-table-column>
                <b-table-column v-slot="props" field="coach" :label="String($t('field.customer')).toString()">
                    {{ getCustomerLabel(props.row.customerId) || '-' }}
                </b-table-column>
                <b-table-column v-slot="props" field="productType" :label="String($t('field.type')).toString()">
                    {{ getProductTypeLabel(props.row.productType) }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="location"
                    :label="String($t('field.location')).toString()"
                    :sortable="true"
                >
                    {{ getLocationLabel(props.row.locationId) }}
                </b-table-column>
            </b-table>
        </div>
    </validation-observer>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import SelectField from '../../../../framework/fields/SelectField.vue';
import MonthField from '../../../../framework/fields/MonthField.vue';
import { sharedState } from '../../../../framework/state';
import {
    deleteResource,
    getResourcePagingNew,
    getResources,
    getResourcesNew,
    putResource,
} from '../../../../framework/client/resource';
import { SortOrder } from '../../../../../common/framework/model/SortOrder';
import { QueryParameters } from '../../../../../common/application/model/QueryParameters';
import {
    getQueryParameters,
    getQueryParametersForBackend,
    setQueryParameters,
} from '../../../service/query_parameter_service';
import { errorToast, successToast } from '../../../service/toast_service';
import { FrameworkUserRole } from '../../../../../common/framework/enumeration/FrameworkUserRole';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';

import AutoCompleteMultipleField from '../../../fields/AutoCompleteMultipleField.vue';
import { getResourceLabels } from '../../../../framework/service/label_service';
import { Session } from '../../../../../common/application/model/session';
import SwitchField from '../../../../framework/fields/SwitchField.vue';
import { P_PAGE, P_SORT_FIELD, P_SORT_ORDER } from '../../../../../common/framework/constants';
import { formatToFinnishDateTime, getCoachResourceLabel } from '../../../service/helper_service';
import { getCustomerOptions, Option } from '../../../service/application_options_service';
import { getEnumerationOptions } from '../../../../framework/service/options';
import { ProductType } from '../../../../../common/application/model/enums/product_type';
import { Customer } from '../../../../../common/application/model/customer';
import moment, { utc } from 'moment';
import { Coach } from '../../../../../common/application/model/coach';

@Component({
    components: {
        SelectField,
        AutoCompleteMultipleField,
        SwitchField,
        ValidationObserver,
        MonthField,
    },
})
export default class OwnCustomerSessions extends Vue {
    @Prop(String) readonly coachId!: string;

    readonly resourceType = ApplicationResource.SESSION;

    shared = sharedState;
    local = {
        rows: new Array<Session>(),
        total: 0,
        loading: false,
        selected: undefined as Session | undefined,
        parameters: {} as QueryParameters,
        coachLabels: new Map<string, string>(),
        locationLabels: new Map<string, string>(),
        productTypeOptions: [] as Option[],
        customerOptions: [] as Option[],
        monthFilter: utc().startOf('month').toDate().toDateString(),
    };

    async mounted() {
        this.local.loading = true;

        this.local.parameters = getQueryParameters(this);
        this.local.productTypeOptions = getEnumerationOptions('ProductType', Object.keys(ProductType), this);
        await this.loadAsyncData();

        this.local.loading = false;
    }

    add() {
        this.$router.push(`/${this.resourceType}/add-from-coach/${this.coachId}`);
    }

    detail(id?: string) {
        if (id) {
            this.$router.push(`/${this.resourceType}/${id}`);
        } else if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    confirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('title.confirmDelete').toString(),
            message: this.$t('message.confirmDelete').toString(),
            cancelText: this.$t('button.cancel').toString(),
            confirmText: this.$t('button.ok').toString(),
            type: 'is-success',
            onConfirm: async () => {
                if (this.local.selected) {
                    try {
                        await deleteResource(this.resourceType, this.local.selected.id);
                        successToast(this, 'message.resourceDeleted');
                        await this.loadAsyncData();
                    } catch (e) {
                        errorToast(this);
                    }
                }
            },
        });
    }

    async loadAsyncData() {
        const customerParameters = new Map<string, string>();
        customerParameters.set('pageSize', '100');
        customerParameters.set(P_SORT_FIELD, 'customer_number');
        customerParameters.set(P_SORT_ORDER, SortOrder.ASC);
        customerParameters.set('coachId', this.coachId);
        const coachCustomers: Customer[] = (await getResources(
            ApplicationResource.CUSTOMER,
            -1,
            customerParameters,
        )) as Array<Customer>;

        const customerIds = coachCustomers.map((customer) => customer.id).join(',');

        const parameters = getQueryParametersForBackend(this);
        parameters.set(P_PAGE, this.local.parameters.page ? this.local.parameters.page : 1);
        if (customerIds.length > 0) {
            parameters.set('customerId', customerIds.split(','));
        }
        parameters.set(P_SORT_FIELD, 'date');
        parameters.set(P_SORT_ORDER, SortOrder.DESC);
        parameters.set('productType', ProductType.COACHING);
        parameters.set('startDateOfMonth', moment(new Date(this.local.monthFilter)).startOf('month').toISOString());
        parameters.set('endDateOfMonth', moment(new Date(this.local.monthFilter)).endOf('month').toISOString());

        let query = 'date>={startDateOfMonth} AND date<={endDateOfMonth}';

        this.local.total = (await getResourcePagingNew(this.resourceType, parameters, query)).rowCount;

        this.local.rows = [];

        const rows: Session[] = (await getResourcesNew(
            this.resourceType,
            this.local.parameters.page - 1,
            parameters,
            query,
        )) as Array<Session>;

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        await this.getCoachLabels(rows);
        await this.getLocationLabels(rows);

        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }

        this.local.customerOptions = await getCustomerOptions();
    }

    onSortChange(field: string, order: 'desc' | 'asc') {
        const parameters: QueryParameters = {
            ...this.local.parameters,
            sortField: field,
            sortOrder: order as SortOrder,
            page: 1,
        };

        setQueryParameters(this, parameters);
        this.local.parameters = getQueryParameters(this);
    }

    async onPageChange(page: number) {
        if (this.local.loading) {
            return;
        }
        this.local.parameters.page = page;
        await this.loadAsyncData();
    }

    @Watch('local.parameters.completed')
    async completedSwitchChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    @Watch('local.monthFilter')
    async monthFilterChanged() {
        if (this.local.loading) {
            return;
        }
        await this.loadAsyncData();
    }

    view() {
        if (this.local.selected) {
            this.$router.push(`/${this.resourceType}/${this.local.selected.id}`);
        }
    }

    get isAdmin(): boolean {
        return this.shared.hasRole(FrameworkUserRole.ADMIN);
    }

    onRowClick(row: Session): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }

    getCoachLabel(id: string): string | undefined {
        return this.local.coachLabels.get(id);
    }

    private async getCoachLabels(rows: Session[]) {
        this.local.coachLabels = await getResourceLabels<Coach>(
            rows,
            'coachId',
            ApplicationResource.COACH,
            getCoachResourceLabel,
        );
    }

    getLocationLabel(id: string): string | undefined {
        return this.local.locationLabels.get(id);
    }

    private async getLocationLabels(rows: Session[]) {
        this.local.locationLabels = await getResourceLabels(rows, 'locationId', ApplicationResource.LOCATION, 'name');
    }

    dateToDateTimeString(timestamp: string) {
        const date = new Date(timestamp);
        return formatToFinnishDateTime(date);
    }

    getProductTypeLabel(productType: string): string | undefined {
        return this.local.productTypeOptions.find(({ id }) => id === productType)!!.label;
    }

    getCustomerLabel(customerId: string): string | undefined {
        const customer = this.local.customerOptions.find(({ id }) => id === customerId);
        return customer ? customer.label : '-';
    }

    async markSessionCompleted(session: Session): Promise<void> {
        await putResource<Session>(this.resourceType, session.id, {
            ...session,
            completed: true,
        });

        await this.loadAsyncData();
    }

    async markSessionUncompleted(session: Session): Promise<void> {
        await putResource<Session>(this.resourceType, session.id, {
            ...session,
            completed: false,
        });

        await this.loadAsyncData();
    }

    get minDate(): Date {
        return new Date('1-1-2000');
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table-container {
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>
