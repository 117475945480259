import { getAccessibleCoaches, getAccessibleCustomers, getAccessiblePhysiotherapists } from './resource_service';
import { sortAlphabetically } from './helper_service';
import { ApplicationResource } from '../../../common/application/enumeration/ApplicationResource';
import { sharedState } from '../../framework/state';
import { FrameworkUserRole } from '../../../common/framework/enumeration/FrameworkUserRole';
import { getResources } from '../../framework/client/resource';
import { FrameworkResource } from '../../../common/framework/enumeration/FrameworkResource';

export interface Option {
    id: string | undefined;
    label: string;
}

interface DatabaseOption {
    readonly id: string | undefined;
    readonly name: string;
}

export async function getCustomerOptions() {
    return [{ id: undefined as string | undefined, label: '' }]
        .concat(
            (await getAccessibleCustomers()).map((o) => {
                return { id: o.id, label: `${o.firstName} ${o.lastName}` };
            }),
        )
        .sort(sortAlphabetically('label'));
}

export async function getCoachOptions() {
    return [{ id: undefined as string | undefined, label: '' }]
        .concat(
            (await getAccessibleCoaches()).map((o) => {
                return { id: o.id, label: `${o.firstName} ${o.lastName}` };
            }),
        )
        .sort(sortAlphabetically('label'));
}

export async function getPhysiotherapistOptions() {
    return [{ id: undefined as string | undefined, label: '' }]
        .concat(
            (await getAccessiblePhysiotherapists()).map((o) => {
                return { id: o.id, label: `${o.firstName} ${o.lastName}` };
            }),
        )
        .sort(sortAlphabetically('label'));
}

export const getAccessibleOptions = async (resource: ApplicationResource | FrameworkResource) => {
    return [{ id: undefined as string | undefined, label: '' }].concat(
        (await getAccessibleResources(resource))
            .map((o) => {
                return { id: o.id, label: o.name };
            })
            .sort(sortAlphabetically('label')),
    );
};

export const getAccessibleResources = async (
    resource: ApplicationResource | FrameworkResource,
): Promise<DatabaseOption[]> => {
    if (sharedState.hasRole(FrameworkUserRole.ADMIN)) {
        return [{ id: undefined as string | undefined, name: '-' }].concat(
            (await getResources<any>(resource, -1)).map((o) => {
                if (resource === FrameworkResource.USER) {
                    return { id: o.id, name: `${o.firstName} ${o.lastName}` };
                }
                return { id: o.id, name: o.name };
            }),
        );
    } else {
        return [] as DatabaseOption[];

        /*const userAccessibleMakes = sharedState.context.scopes.user.vehicleMakeId;
        if (userAccessibleMakes.length > 0) {
            const parameters = new Map<string, string>().set(idField, userAccessibleMakes.join(','));

            return [{ id: undefined as string | undefined, name: '-' }].concat(
                (await getResources<any>(resource, -1, parameters)).map((o) => {
                    return { id: o.id, name: o.name };
                }),
            );
        } else {
            return [] as DatabaseOption[];
        }*/
    }
};
