<template>
    <div class="container-fluid panel">
        <section class="section">
            <h1 class="title">{{ $t('title.scheduledTasks') }}</h1>

            <div class="buttons has-addons"></div>

            <b-table
                hoverable
                paginated
                striped
                default-sort="key"
                default-sort-direction="asc"
                :current-page="local.page"
                :data="local.rows"
                :loading="local.loading"
                :per-page="local.perPage"
                :selected.sync="local.selected"
                :total="local.total"
                @click="onRowClick"
            >
                <b-table-column v-slot="props" field="key" :label="$t('field.key').toString()" :sortable="true">
                    {{ props.row.key }}
                </b-table-column>
                <b-table-column
                    v-slot="props"
                    field="cronExpression"
                    :label="$t('field.cronExpression').toString()"
                    :sortable="true"
                >
                    {{ props.row.cronExpression }}
                </b-table-column>
                <b-table-column v-slot="props" field="started" :label="$t('field.started').toString()" :sortable="true">
                    {{ dateTimeToString(props.row.started) }}
                </b-table-column>
                <b-table-column v-slot="props" field="ended" :label="$t('field.ended').toString()" :sortable="true">
                    {{ dateTimeToString(props.row.ended) }}
                </b-table-column>
                <b-table-column v-slot="props" field="duration" :label="$t('field.duration').toString()">
                    {{ getDuration(props.row) }}
                </b-table-column>
                <b-table-column v-slot="props" field="error" :label="$t('field.error').toString()" :sortable="true">
                    {{ props.row.error }}
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { sharedState } from '../../../../framework/state';

import { getResources } from '../../../../framework/client/resource';
import { dateToUiDateTimeString } from '../../../../../common/framework/util/convert';
import ScheduledTask from '../../../../../common/framework/model/ScheduledTask';
import { FrameworkResource } from '../../../../../common/framework/enumeration/FrameworkResource';

@Component
export default class ScheduledTasks extends Vue {
    readonly resourceType = FrameworkResource.SCHEDULED_TASK;

    shared = sharedState;
    local = {
        rows: new Array<ScheduledTask>(),
        total: 0,
        loading: false,
        page: 1,
        selected: undefined as ScheduledTask | undefined,
    };

    async mounted() {
        await this.loadAsyncData();
    }

    async loadAsyncData() {
        this.local.loading = true;
        this.local.rows = [];
        const rows = (await getResources(this.resourceType, this.local.page - 1)) as ScheduledTask[];

        rows.forEach((row) => {
            this.local.rows.push(row);
        });

        this.local.total = rows.length;

        this.local.loading = false;
        if (this.local && this.local.rows.indexOf(this.local.selected!!) == -1) {
            this.local.selected = undefined;
        }
    }

    dateTimeToString(timestamp: Date) {
        return dateToUiDateTimeString(timestamp);
    }

    getDuration(row: ScheduledTask): string {
        if (!row.ended || !row.started) {
            return '-';
        }

        const diffInMS = new Date(row.ended).getTime() - new Date(row.started).getTime();
        return `${diffInMS / 1000} s`;
    }

    onRowClick(row: ScheduledTask): void {
        if (this.local.selected && row.id === this.local.selected.id) {
            this.local.selected = undefined;
        }
    }
}
</script>
