<template>
    <div class="container panel">
        <section class="section">
            <div class="column is-6 is-offset-3">
                <div class="buttons has-addons closer-2-top">
                    <button class="button" @click="back">
                        <b-icon icon="arrow-left" size="is-small"></b-icon>
                        <span>{{ $t('button.back') }}</span>
                    </button>
                </div>
                <div class="box">
                    <validation-observer v-slot="{ passes }">
                        <div>
                            <h2 class="title">{{ $t('title.editDocument') }}</h2>
                            <div class="column is-6">
                                <TextField
                                    v-model="local.row.name"
                                    :required="true"
                                    :maxlength="100"
                                    :disabled="true"
                                />
                            </div>
                            <div class="column is-6">
                                <b-field :label="$t('field.category').toString()">
                                    <Multiselect
                                        v-model="local.row.category"
                                        :tag-placeholder="$t('field.tagPlaceholderCategory')"
                                        :placeholder="$t('field.placeholderCategory')"
                                        :select-label="$t('field.selectLabel')"
                                        :deselect-label="$t('field.deselectLabel')"
                                        :options="local.categoryOptions.map((category) => category.label)"
                                        :multiple="false"
                                        :taggable="true"
                                        @tag="addTag($event)"
                                        @close="onTouch"
                                    ></Multiselect>
                                </b-field>
                            </div>
                            <div class="column is-12">
                                <TextAreaField
                                    name="description"
                                    :required="false"
                                    :maxlength="5000"
                                    v-model="local.row.description"
                                />
                            </div>
                            <div class="column is-12">
                                <b-button
                                    class="button is-primary is-fullwidth login-button space-2"
                                    icon-left="content-save-outline"
                                    @click="passes(save)"
                                    :disabled="local.validBtn"
                                >
                                    <span>{{ $t('button.save') }}</span>
                                </b-button>
                                <b-button
                                    class="button is-primary is-fullwidth login-button space-2"
                                    icon-left="content-save-outline"
                                    @click="passes(saveAndClose)"
                                    :disabled="local.validBtn"
                                >
                                    <span>{{ $t('button.saveAndClose') }}</span>
                                </b-button>
                            </div>
                        </div>
                    </validation-observer>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';

import { sharedState } from '../../../../framework/state';
import { getResource, postResource, putResource } from '../../../../framework/client/resource';
import { getDatabaseOptions, OptionValue } from '../../../../framework/service/options';
import { FileMeta } from '../../../../../common/application/model/FileMeta';
import { ApplicationResource } from '../../../../../common/application/enumeration/ApplicationResource';
import TextField from '../../../../framework/fields/TextField.vue';
import TextAreaField from '../../../../framework/fields/TextAreaField.vue';
import { ValidationObserver } from 'vee-validate';
import { Option } from '../../../../../common/framework/model/Option';

@Component({
    components: {
        TextField,
        ValidationObserver,
        Multiselect,
        TextAreaField,
    },
})
export default class EditDocument extends Vue {
    // Constants
    readonly resourceType = ApplicationResource.FILE_META;

    // Properties
    @Prop(String) readonly id!: string;

    // State
    shared = sharedState;
    local = {
        total: 0,
        loading: false,
        file: null as { name: string; size: number; type: string; lastModified: number; uploadName: string } | null,
        categoryOptions: [] as OptionValue[],
        row: {} as FileMeta,
        validBtn: true,
        isTouched: false,
    };

    // Functions
    async mounted() {
        this.local.loading = true;
        await this.loadAsyncData();
        this.local.loading = false;
    }

    async loadAsyncData() {
        this.local.row = (await getResource<FileMeta>(this.resourceType, this.id))!!;
        if (!this.local.row) {
            this.local.row = {} as FileMeta;
        }

        this.local.categoryOptions = (await getDatabaseOptions('DocumentCategory', this)).filter(
            ({ id }) => id !== undefined,
        );
    }

    save() {
        this.saveResource(false);
    }

    saveAndClose() {
        this.saveResource(true);
    }

    async saveResource(goBack: boolean) {
        this.local.row = { ...this.local.row, modified: new Date() };
        await putResource<FileMeta>(this.resourceType, this.id, this.local.row);

        if (goBack) {
            this.back();
        }
    }

    async addTag(label: string) {
        const newOption = {
            id: '',
            type: 'document-category',
            key: label,
            index: 1,
            metadata: {
                labelEn: label,
                labelFi: label,
            },
            created: new Date(),
            modified: new Date(),
        };
        this.local.row = { ...this.local.row, category: label };
        const option = await postResource<Option>('option', newOption);
        this.local.categoryOptions.push({
            id: option.id,
            label: option.key,
        });
    }

    @Watch('local.row')
    async isValidBtn() {
        if (this.local.row.name && this.local.row.category) {
            this.local.validBtn = false;
        }
    }

    onTouch() {
        this.local.isTouched = true;
    }

    back() {
        this.$router.go(-1);
    }
}
</script>
