import { apiPost } from '../../framework/client';
import { ERROR_API_CALL_FAILED } from '../../../common/framework/constants';

import { AddMonthlySalesInvoiceReq } from '../../../common/application/model/api/AddMonthlySalesInvoiceReq';
import { AddMonthlySalesInvoiceResp } from '../../../common/application/model/api/AddMonthlySalesInvoiceResp';
import { AddNextMonthlySalesInvoiceResp } from '../../../common/application/model/api/AddNextMonthlySalesInvoiceResp';
import { AddNextMonthlySalesInvoiceReq } from '../../../common/application/model/api/AddNextMonthlySalesInvoiceReq';
import { AddBalanceInvoiceReq } from '../../../common/application/model/api/AddBalanceInvoiceReq';
import { AddBalanceInvoiceResp } from '../../../common/application/model/api/AddBalanceInvoiceResp';

export async function postMonthlySalesInvoiceRequest(
    addMonthlySalesInvoiceReq: AddMonthlySalesInvoiceReq,
): Promise<AddMonthlySalesInvoiceResp> {
    const response = await apiPost('/api/sales-invoice/add-monthly', addMonthlySalesInvoiceReq);
    if (response.status === 200) {
        return (await response.json()) as AddMonthlySalesInvoiceResp;
    } else {
        const { errorMessage } = await response.json();
        throw new Error(errorMessage ? errorMessage : ERROR_API_CALL_FAILED);
    }
}

export async function postMonthlySalesInvoiceForAllCustomersRequest(
    startDate: Date,
): Promise<AddMonthlySalesInvoiceResp> {
    const response = await apiPost('/api/sales-invoice/add-monthly-for-all-customers', { startDate });
    if (response.status === 200) {
        return await response.json();
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postBalanceInvoiceRequest(
    addBalanceInvoiceReq: AddBalanceInvoiceReq,
): Promise<AddBalanceInvoiceResp> {
    const response = await apiPost('/api/balance-invoice/add', addBalanceInvoiceReq);
    if (response.status === 200) {
        return (await response.json()) as AddBalanceInvoiceResp;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}

export async function postNextMonthlySalesInvoiceRequest(
    addNextMonthlySalesInvoiceReq: AddNextMonthlySalesInvoiceReq,
): Promise<AddNextMonthlySalesInvoiceResp> {
    const response = await apiPost('/api/sales-invoice/add-next-monthly', addNextMonthlySalesInvoiceReq);
    if (response.status === 200) {
        return (await response.json()) as AddNextMonthlySalesInvoiceResp;
    } else {
        throw new Error(ERROR_API_CALL_FAILED);
    }
}
